import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const createOrder = async (treatmentId, message) => {
  try {
    const response = await axios.post(`${API_URL}/treatments/${treatmentId}/orders`, { message });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};

api.createOrder = createOrder;

export default api;
