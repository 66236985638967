<template>
  <section class="register">
    <div class="register-container">
      <div class="image-container">
        <img src="~@/assets/img/illustrations/polygon.png" alt="Logo E-Aligner"/>
      </div>
      <div class="content-container">
        <nav class="nav-header">
          <router-link :to="{ name: 'login' }">
            <SvgArrowLeft />
            <span>Retour site</span>
          </router-link>
          <img class="logo" src="~@/assets/img/illustrations/logo-dark.png" alt="Logo E-Aligner"/>
        </nav>
        <div class="content" v-if="!isRegistrationSuccessful">
          <div class="instruction">
            <h1>Inscription</h1>
            <p>
              Veuillez renseigner les informations ci-dessous. Votre demande sera ensuite soumise à modération par notre équipe.
              L’inscription est réservée aux praticiens et laboratoires, prescripters des traitements E-ALIGNER
            </p>
          </div>

          <div v-show="steps === 1">
            <form ref="firstStep">
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Identifiant (votre email)</app-label>
                  <app-input
                    type="text"
                    placeholder="xxxxx@mail.com"
                    v-model="practitioner.email"
                    maxlength="42"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Répétez votre identifiant (votre email)</app-label>
                  <app-input
                    type="text"
                    placeholder="xxxxx@mail.com"
                    v-model="practitioner.repeatEmail"
                    maxlength="42"
                    required
                    :errorMessage="emailErrorMessage"
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Mot de passe</app-label>
                  <app-input
                    type="password"
                    placeholder="************"
                    v-model="practitioner.password"
                    minlength="8"
                    maxlength="32"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Répétez votre mot de passe</app-label>
                  <app-input
                    type="password"
                    placeholder="************"
                    v-model="practitioner.repeatPassword"
                    minlength="8"
                    maxlength="32"
                    required
                    :errorMessage="passwordErrorMessage"
                  />
                </div>
              </div>
              <app-button class="form-submit" @click="nextStep">Valider {{ steps }}/5</app-button>
            </form>
          </div>

          <div v-show="steps === 2">
            <h4>Vos informations personnelles</h4>
            <form ref="secondStep">
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Nom</app-label>
                  <app-input
                    type="text"
                    placeholder="Dupont"
                    v-model="practitioner.lastname"
                    minlength="2"
                    required
                  />
                </div>
                <div class="cell auto">
                  <app-label required>Prénom</app-label>
                  <app-input
                    type="text"
                    placeholder="Dominique"
                    v-model="practitioner.firstname"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Vous êtes ?</app-label>
                  <app-select
                    name="occupation"
                    :options="occupationOptions"
                    v-model="practitioner.occupation"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Numéro professionel</app-label>
                  <app-input
                    type="text"
                    placeholder="000000"
                    v-model="practitioner.professionalId"
                    minlength="5"
                    required
                  />
                </div>
                <div class="cell auto">
                  <app-label required>Civilité</app-label>
                  <app-select
                    name="gender"
                    :options="genderOptions"
                    v-model="practitioner.gender"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label>Type de matériel pour les empreintes numériques (S Connect, Medit Link, 3Shape, etc)</app-label>
                  <app-input
                    type="textarea"
                    placeholder="Matériel..."
                    v-model="practitioner.imprintsScanMaterial"
                    :rows="3"
                  />
                </div>
              </div>
              <div class="row-field form-submit">
                <app-button look="secondary" @click="previousStep">Précédent</app-button>
                <app-button @click="nextStep">Valider {{ steps }}/5</app-button>
              </div>
            </form>
          </div>

          <div v-show="steps === 3">
            <h4>Informations de votre cabinet</h4>
            <form ref="thirdStep">
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Nom du cabinet</app-label>
                  <app-input
                    type="text"
                    placeholder="Cabinet..."
                    v-model="practitioner.officeName"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label>N° Siret</app-label>
                  <app-input
                    type="text"
                    placeholder="Siret..."
                    v-model="practitioner.siret"
                    minlength="14"
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label>N° TVA Intracom</app-label>
                  <app-input
                    type="text"
                    placeholder="TVA..."
                    v-model="practitioner.tvaNumber"
                    minlength="5"
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <p>Si vous êtes client DPI, merci d’indiquer votre numéro</p>
                  <app-label>N° DPI</app-label>
                  <app-input
                    type="text"
                    placeholder="numéro DPI..."
                    v-model="practitioner.dpiNumber"
                    minlength="5"
                  />
                </div>
              </div>
              <div class="row-field form-submit">
                <app-button look="secondary" @click="previousStep">Précédent</app-button>
                <app-button @click="nextStep">Valider {{ steps }}/5</app-button>
              </div>
            </form>
          </div>

          <div v-show="steps === 4">
            <h4>Adresse postale</h4>
            <form ref="fourthStep">
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Adresse</app-label>
                  <app-input
                    type="text"
                    placeholder="10 rue des Champs-Élysées"
                    v-model="practitioner.deliveryAddress.addressLine"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell small-4">
                  <app-label required>Code Postal</app-label>
                  <app-input
                    type="text"
                    placeholder="XXXXX"
                    minlength="5"
                    maxlength="5"
                    v-model="practitioner.deliveryAddress.zipCode"
                    required
                  />
                </div>
                <div class="cell small-8">
                  <app-label required>Ville</app-label>
                  <app-input
                    type="text"
                    placeholder="NANTES"
                    v-model="practitioner.deliveryAddress.city"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Pays</app-label>
                  <app-input
                    type="text"
                    placeholder="Pays..."
                    v-model="practitioner.deliveryAddress.country"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <h4>Adresse fiscale</h4>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-button @click="sameAddress" :disabled="!(practitioner.deliveryAddress.addressLine && practitioner.deliveryAddress.country && practitioner.deliveryAddress.city && practitioner.deliveryAddress.zipCode)"
                  >Reprendre adresse postale</app-button>
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Adresse</app-label>
                  <app-input
                    type="text"
                    placeholder="10 rue des Champs-Élysées"
                    v-model="practitioner.invoiceAddress.addressLine"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell small-4">
                  <app-label required>Code Postal</app-label>
                  <app-input
                    type="text"
                    placeholder="XXXXX"
                    minlength="5"
                    maxlength="5"
                    v-model="practitioner.invoiceAddress.zipCode"
                    required
                  />
                </div>
                <div class="cell small-8">
                  <app-label required>Ville</app-label>
                  <app-input
                    type="text"
                    placeholder="NANTES"
                    v-model="practitioner.invoiceAddress.city"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Pays</app-label>
                  <app-input
                    type="text"
                    placeholder="Pays..."
                    v-model="practitioner.invoiceAddress.country"
                    minlength="2"
                    required
                  />
                </div>
              </div>
              <div class="row-field form-submit">
                <app-button look="secondary" @click="previousStep">Précédent</app-button>
                <app-button @click="nextStep">Valider {{ steps }}/5</app-button>
              </div>
            </form>
          </div>

          <div v-show="steps === 5">
            <h4>Contact du cabinet</h4>
            <form ref="fifthStep">
              <div class="form-field grid-x grid-margin-x">
                <div class="cell small-6">
                  <app-label required>Téléphone fixe</app-label>
                  <app-input
                    type="text"
                    placeholder="0X XX XX XX XX"
                    v-model="practitioner.deskPhone"
                    minlength="10"
                    maxlength="10"
                    required
                  />
                </div>
                <div class="cell small-6">
                  <app-label>Téléphone portable</app-label>
                  <app-input
                    type="text"
                    placeholder="0X XX XX XX XX"
                    minlength="10"
                    maxlength="10"
                    v-model="practitioner.mobilePhone"
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Vos horaires d’ouverture :</app-label>
                  <app-input
                    type="textarea"
                    placeholder="Horaires..."
                    v-model="practitioner.openHours"
                    :rows="3"
                  />
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <app-checkbox value="accept" v-model="practitioner.acceptConditions" required>
                    <p>J’ai lu et j’accepte les <a href="/assets/documents/cgv-2022.pdf" target="_blank">Conditions générales de ventes</a></p>
                  </app-checkbox>
                </div>
              </div>
              <div class="form-field grid-x grid-margin-x">
                <div class="cell auto">
                  <small class="error-msg" v-if="conditionsErrorMessage">{{ conditionsErrorMessage }}</small>
                </div>
              </div>
              <div class="row-field form-submit">
                <app-button look="secondary" @click="previousStep">Précédent</app-button>
                <app-button @click="nextStep" :loading="isLoading">Valider {{ steps }}/5</app-button>
              </div>
            </form>
          </div>
        </div>

        <div class="inscription-success" v-else>
          <div>
            <div class="grid-x grid-margin-x">
              <div class="img-success">
                <img src="~@/assets/img/illustrations/request-validated.png" alt="Inscription validée" />
              </div>
            </div>
            <div class="form-submit grid-x grid-margin-x">
              <div class="cell auto">
                <h1>Merci pour votre inscription !</h1>
              </div>
            </div>
            <div class="grid-x grid-margin-x">
              <div class="cell auto">
                <p>Votre demande va être étudiée par notre équipe ; vous recevrez un mail de confirmation à l’adresse renseignée lorsque votre inscription sera validée.</p>
              </div>
            </div>
            <div class="form-submit grid-x grid-margin-x">
              <div class="cell auto">
                <app-button @click="redirect">Retour au site</app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg?inline';

import utils from '@/services/utils/utils';
import practitionerApi from '@/services/api/practitioner';
import gender from '@/services/enums/gender.enum';
import occupationTypeLabel from '@/services/enums/occupation-type-label.enum';

export default {
  name: 'register',
  data() {
    return {
      genderOptions: utils.optionsFromEnum(gender),
      occupationOptions: utils.optionsFromEnum(occupationTypeLabel),
      steps: 1,
      isRegistrationSuccessful: false,
      isLoading: false,
      emailErrorMessage: null,
      conditionsErrorMessage: null,
      passwordErrorMessage: null,
      practitioner: {
        email: null,
        repeatEmail: null,
        password: null,
        repeatPassword: null,
        firstname: null,
        lastname: null,
        occupation: null,
        professionalId: null,
        gender: null,
        imprintsScanMaterial: null,
        siret: null,
        tvaNumber: null,
        dpiNumber: null,
        deskPhone: null,
        mobilePhone: null,
        openHours: null,
        officeName: null,
        acceptConditions: [],
        deliveryAddress: {
          city: null,
          zipCode: null,
          addressLine: null,
          country: null,
        },
        invoiceAddress: {
          city: null,
          zipCode: null,
          addressLine: null,
          country: null,
        },
      },
    };
  },
  components: {
    SvgArrowLeft,
  },
  methods: {
    previousStep() {
      this.steps -= 1;
    },
    sameAddress() {
      this.practitioner.invoiceAddress.city = this.practitioner.deliveryAddress.city;
      this.practitioner.invoiceAddress.zipCode = this.practitioner.deliveryAddress.zipCode;
      this.practitioner.invoiceAddress.addressLine = this.practitioner.deliveryAddress.addressLine;
      this.practitioner.invoiceAddress.country = this.practitioner.deliveryAddress.country;
    },
    nextStep() {
      switch (this.steps) {
        case 1:
          if (this.$refs.firstStep.checkValidity() && this.firstStepValidity()) {
            this.steps += 1;
          } else {
            this.$refs.firstStep.reportValidity();
          }
          break;
        case 2:
          if (this.$refs.secondStep.checkValidity() && this.practitioner.gender && this.practitioner.occupation) {
            this.steps += 1;
          } else {
            this.$refs.secondStep.reportValidity();
          }
          break;
        case 3:
          if (this.$refs.thirdStep.checkValidity()) {
            this.steps += 1;
          } else {
            this.$refs.thirdStep.reportValidity();
          }
          break;
        case 4:
          if (
            this.$refs.fourthStep.checkValidity()
          ) {
            this.steps += 1;
          } else {
            this.$refs.fourthStep.reportValidity();
          }
          break;
        case 5:
          this.conditionsErrorMessage = null;
          if (this.$refs.fifthStep.checkValidity()) {
            if (this.practitioner.acceptConditions.includes('accept')) {
              this.register();
            } else {
              this.conditionsErrorMessage = 'Vous devez accepter les conditions.';
            }
          } else {
            this.$refs.fourthStep.reportValidity();
          }
          break;
        default:
          throw new Error();
      }
    },
    firstStepValidity() {
      this.emailErrorMessage = null;
      this.passwordErrorMessage = null;
      let isValid = true;
      if (this.practitioner.email !== this.practitioner.repeatEmail) {
        this.emailErrorMessage = 'Les deux champs “email” ne correspondent pas';
        isValid = false;
      }
      if (this.practitioner.password !== this.practitioner.repeatPassword) {
        this.passwordErrorMessage = 'Les deux champs “mot de passe” ne correspondent pas';
        isValid = false;
      }
      return isValid;
    },
    redirect() {
      this.$router.push({ name: 'login' });
    },
    errorMessage(msg) {
      let errorMessage = msg;
      if (msg === 'Email already in use') {
        errorMessage = 'Un praticien avec cet email existe déjà';
      } else if (msg === 'Professional Id already in use') {
        errorMessage = 'Un praticien avec ce N° professionnel existe déjà';
      }
      return errorMessage;
    },
    async register() {
      this.isLoading = true;
      try {
        if (this.$refs.fifthStep.checkValidity()) {
          const practitioner = {
            email: this.practitioner.email,
            password: this.practitioner.password,
            firstname: this.practitioner.firstname,
            lastname: this.practitioner.lastname,
            gender: this.practitioner.gender,
            professionalId: this.practitioner.professionalId,
            tvaNumber: this.practitioner.tvaNumber,
            siret: this.practitioner.siret,
            dpiNumber: this.practitioner.dpiNumber,
            deskPhone: this.practitioner.deskPhone,
            mobilePhone: this.practitioner.mobilePhone,
            officeName: this.practitioner.officeName,
            openHours: this.practitioner.openHours,
            affiliation: this.practitioner.affiliation,
            affiliatedCountry: this.practitioner.affiliatedCountry,
            occupation: this.practitioner.occupation,
            invoiceAddress: this.practitioner.invoiceAddress,
            deliveryAddress: this.practitioner.deliveryAddress,
            imprintsScanMaterial: this.practitioner.imprintsScanMaterial,
          };
          await practitionerApi.createPractitioner(practitioner);
          this.isRegistrationSuccessful = true;
        } else {
          this.$refs.register.reportValidity();
        }
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: `Impossible de vous inscrire : ${this.errorMessage(err.response.data.message)}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.register
  display: flex
  height: 100%

  &-container
    display: flex
    flex-direction: row
    width: 100%

  .image-container
    flex-grow: 1
    height: 100vh

    img
      object-fit: cover
      width: 100%
      height: 100%

  .content-container
    flex-basis: 0
    flex-grow: 999
    margin: 3rem 0

    .nav-header
      display: flex
      flex-direction: row
      justify-content: space-between
      padding: 0 4rem

      > a
        display: flex
        align-items: center
        span
          margin-left: 0.5rem
        svg
          transform: rotate(180deg)
          height: 0.75rem

      .logo
        display: flex
        width: 148px
        height: 26px

    .img-success
      width: 80px

      img
        object-fit: cover
        width: 100%

    .inscription-success
      display: flex
      flex-direction: column
      margin: 0 auto
      width: 500px
      max-width: 90%
      height: 100%
      justify-content: center

    .content
      display: flex
      flex-direction: column
      margin: 4rem auto 0 auto
      width: 500px
      max-width: 90%

      > div:not(:first-child)
        max-width: 400px

      h1
        margin: 0

      p
        color: $color-gray-70

      .form-field
        margin-top: 24px

      .form-submit
        margin-top: 44px

      .row-field
        display: flex
        flex-direction: row
        column-gap: 1rem

      .error-msg
        color: $color-avatar-8
</style>
