<!--
****************************************************************************************************
***                                         Input                                               ***
****************************************************************************************************

Champ texte simple ou multiligne

*************
*** PROPS ***
*************
  - type :
    Type du champ

  Exemple :
  <app-input type="text" v-model="answer4"/>
  <app-input type="textarea" v-model="answer4"/>

-->
<template>
  <div class="app-input">
    <textarea
      ref="textarea"
      v-if="type === 'textarea'"
      class="app-input-input"
      contenteditable="true"
      :rows="rows"
      :class="{ invalid: isInvalid, 'has-icon': hasIcon }"
      :value="value"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      @invalid.prevent="handleInvalid"
      @input="$emit('input', $event.target.value)"
      @keyup.enter.exact="$emit('send', input)"
      @keyup.ctrl.enter="$emit('input', input + '\n')"
    />
    <input
      v-else class="app-input-input"
      :class="{ invalid: isInvalid, 'has-suffix': type === 'password', 'has-icon': hasIcon }"
      :type="appliedType"
      :value="value"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :minlength="minlength"
      :maxlength="maxlength"
      @invalid.prevent="handleInvalid"
      @input="$emit('input', $event.target.value)"
    />
    <span
      class="icon-container"
      :class="{ visible: isPasswordVisible }"
      v-if="type === 'password'"
      @click="isPasswordVisible = !isPasswordVisible"
    >
      <SvgEye />
    </span>
    <span
      v-if="context && context === 'message'"
      class="icon-container message-icon"
      :class="{ 'in-textarea': type === 'textarea', 'message-icon-actif': input && input !== '' }"
      @click="$emit('send', input)"
    >
      <SvgMessage />
    </span>
    <div v-if="errorText" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
import SvgEye from '@/assets/img/icons/eye.svg';
import SvgMessage from '@/assets/img/icons/message.svg';

export default {
  components: {
    SvgEye,
    SvgMessage,
  },

  props: {
    value: [String, Array, Number],
    type: {
      default: 'text',
      validator: (val) => ['text', 'number', 'email', 'password', 'url', 'textarea'].indexOf(val) !== -1,
    },
    autocomplete: {
      default: 'off',
    },
    placeholder: {
      default: 'Placeholder',
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    context: {
      type: String,
    },
    minlength: {
      type: String,
    },
    maxlength: {
      type: String,
    },
    rows: {
      type: Number,
    },
  },

  data() {
    return {
      errorText: this.errorMessage,
      isInvalid: !!this.errorMessage,
      isPasswordVisible: false,
    };
  },

  mounted() {
    if (this.type === 'textarea' && !this.$props.rows) {
      this.$refs.textarea.setAttribute('style', `height:${this.$refs.textarea.scrollHeight}px;overflow-y:hidden;`);
      this.$refs.textarea.addEventListener('input', this.onInput, false);
    }
  },

  computed: {
    input() {
      return this.value;
    },

    appliedType() {
      if (this.type === 'password') {
        return this.isPasswordVisible ? 'text' : 'password';
      }
      return this.type;
    },

    hasIcon() {
      return this.type === 'password' || this.context === 'message';
    },
  },

  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.isInvalid = true;
        this.errorText = this.errorMessage;
      } else {
        this.isInvalid = false;
        this.errorText = null;
      }
    },
  },

  methods: {
    handleInvalid(evt) {
      this.errorText = evt.target.validationMessage;
      this.isInvalid = true;
    },

    onInput() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },
  },
};
</script>

<style lang="sass">
.app-input
  width: 100%
  max-width: 400px
  margin: 4px 0
  position: relative

  .app-input-input
    width: 100%
    padding: 12px 16px
    background: white
    font-size: 14px
    line-height: 22px
    border-radius: $global-border-radius
    border: 1px solid $color-gray-20
    transition: all 0.2s ease-in-out
    @include text
    &::placeholder
      color: $color-gray-60
    &:hover:not(:disabled)
      border-color: $color-primary-100
    &:focus:not(:disabled)
      outline: 0
      border-color: $color-primary-100
    &.invalid
      border-color: $color-secondary-100
    &:disabled
      background-color: $color-gray-10
      cursor: not-allowed
    &.has-suffix
      padding-right: 40px
    &.has-icon
      padding: 12px 50px 12px 16px

  textarea
    resize: none
    line-height: 22px

  .icon-container
    position: absolute
    right: 16px
    top: 18px
    svg
      width: 18px
      height: 18px
    &:hover
      cursor: pointer
    &.message-icon
      stroke: $color-gray-60
      margin-bottom: 3px
      cursor: initial
      pointer-events: none
    &.message-icon-actif
      stroke: $color-primary-100
      cursor: pointer
      pointer-events: auto
    &.visible
      path:last-child
        display: none

  .error-text
    color: $color-secondary-100
</style>
