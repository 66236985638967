<template>
  <section class="reset-password">
    <div class="grid-container">
      <img class="logo" src="~@/assets/img/illustrations/logo-dark.png" alt="Logo E-Aligner"/>

      <div class="form-container">
        <div class="form-content">
          <app-title>Réinitialiser mot de passe</app-title>
          <p class="sub-title">Choisissez un nouveau mot de passe de connexion pour vous connecter à votre espace.</p>

          <form ref="register" @submit.prevent="resetPassword()">
            <div>
              <app-label class="form-label" required>Nouveau mot de passe</app-label>
              <app-input
                autocomplete="password"
                type="password"
                placeholder="mot de passe"
                required
                v-model="password"
                :errorMessage="errorMessage"
              />
              <app-label class="form-label" required>Répétez le nouveau mot de passe</app-label>
              <app-input
                autocomplete="password"
                type="password"
                placeholder="mot de passe"
                required
                v-model="repeatedPassword"
                :errorMessage="errorMessage"
              />
            </div>

            <p v-if="showInvalidPasswordError" class="errorMessage">Les mots de passe ne correspondent pas.</p>

            <div class="btn-container">
              <app-button type="submit" :loading="isLoading">Réinitialiser mot de passe</app-button>
            </div>
          </form>
        </div>

        <h6 class="login-link">Vous connaissez votre mot de passe ? <router-link :to="{ name: `${isAdmin ? 'admin-' : ''}login` }">Se connecter</router-link></h6>
      </div>
    </div>

    <div class="image-container">
      <img src="~@/assets/img/illustrations/smile.png" alt="Illustration"/>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import UserRole from '@/services/enums/user-role.enum';

export default {
  name: 'reset-password',

  data() {
    return {
      password: null,
      repeatedPassword: null,
      showInvalidPasswordError: false,
      errorMessage: null,
      isLoading: false,
      isAdmin: this.$route.name.includes('admin'),
    };
  },

  methods: {
    // Demande de changement de mot de passe
    async resetPassword() {
      // On supprime les messages d'erreurs précédents
      this.errorMessage = null;
      this.showInvalidPasswordError = false;

      // On vérifie si la saisie du nouveau mot de passe est correcte
      if (this.password !== this.repeatedPassword) {
        this.showInvalidPasswordError = true;
        return;
      }

      this.isLoading = true;

      try {
        await authApi.newPassword(
          this.isAdmin ? UserRole.ADMIN : UserRole.PRACTITIONER,
          this.password,
          this.$route.query.token,
        );

        this.$message.show({
          title: 'Mot de passe modifié',
          text: 'Le mot de passe a été réinitialisé avec succès.',
          confirmText: 'Retour à la page de connexion',
          onConfirm: this.goToLoginPage,
          hasCancel: false,
        });
      } catch (error) {
        this.errorMessage = 'Une erreur est survenu';
      }

      this.isLoading = false;
    },

    // Redirection vers la page de connexion
    goToLoginPage() {
      this.$router.push({ name: 'admin-login' });
    },
  },
};
</script>

<style lang="sass">
.reset-password
  display: flex
  overflow: hidden
  height: 100vh

  .grid-container
    text-align: left
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    padding: 40px 30px 50px 30px

    .logo
      width: 15vw
      left: 24px

    .form-container
      width: 30vw
      max-width: 500px
      min-width: 200px
      margin: 80px auto 0 auto
      flex: 1
      display: flex
      flex-direction: column
      justify-content: space-between

      .app-title
        @include h1
        color: $color-gray-100
        margin: 0

      .sub-title
        color: $color-gray-70

      form
        margin-top: 15px

        > div:not(:first-child):not(.btn-container)
          margin-top: 24px

        .form-label
          margin-top: 30px

        .btn-container
          margin-top: 44px

          .app-button:not(:first-child)
            margin-left: 16px

      .login-link
        margin: 0
        color: $color-gray-60

      .errorMessage
        margin-top: 10px
        color: $color-secondary-100

  .image-container
    width: 50%
    height: 100%

    img
      width: 100%
      height: 100%
      object-fit: cover
</style>
