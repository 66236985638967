<template>
  <div class="app-file" :class="{ loading: isLoading }">
    <span class="svg-container">
      <app-spinner v-if="isLoading" small/>
      <SvgFileUpload v-else/>
    </span>
    <span class="filename">{{ filename }}</span>
    <span class="svg-container gray-svg" @click="handleDeleteClick" v-if="!isLoading">
      <a v-if="readonly" :href="downloadUrl" download><SvgDownload /></a>
      <SvgCross v-else/>
    </span>
  </div>
</template>

<script>
import SvgFileUpload from '@/assets/img/icons/file-upload.svg';
import SvgCross from '@/assets/img/icons/cross.svg';
import SvgDownload from '@/assets/img/icons/download.svg';

export default {
  name: 'app-file',
  components: {
    SvgFileUpload,
    SvgCross,
    SvgDownload,
  },
  events: ['click:delete'],
  props: {
    file: { // Cas d'un nouveau fichier
      type: File,
      required: false,
    },
    fileUrl: { // Cas d'un fichier où nous n'avons que l'url S3
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // Nom du fichier
    filename() {
      return this.file ? this.file.name : this.getFilename(this.fileUrl);
    },
    fileUrlDeconstruction() {
      if (!this.fileUrl) {
        return null;
      }
      // le constructeur URL() n'étant pas compatible avec Internet Explorer, on fait avec les regexp
      const codeRegex = /(?<origin>.+)\/(?<fileKey>.+)\?(?<searchParams>.+)/;
      const { origin, fileKey, searchParams } = this.fileUrl.match(codeRegex).groups;
      const params = searchParams.match(/(?<auth>.+)&filename=(?<filename>.+)/)?.groups;
      return {
        origin,
        fileKey,
        searchParams,
        params,
      };
    },
    // Url de téléchargement : Enlever le paramètre filename de l'url pour ne pas fausser l'autorisation, s'il existe
    downloadUrl() {
      if (this.fileUrl) {
        const {
          params, fileKey, origin, searchParams,
        } = this.fileUrlDeconstruction;
        return params ? `${origin}/${fileKey}?${params.auth}` : `${origin}/${fileKey}?${searchParams}`;
      }
      return URL.createObjectURL(new Blob([this.file.blob]));
    },
  },
  methods: {
    getFilename() {
      // le constructeur URL() n'étant pas compatible avec Internet Explorer, on fait avec les regexp
      const { fileKey, params } = this.fileUrlDeconstruction;
      return params ? params.filename : fileKey;
    },
    // Supprimer seulement si modifiable
    handleDeleteClick() {
      if (!this.readonly) {
        this.$emit('click:delete');
      }
    },
  },
};
</script>

<style lang="sass">
.app-file
  display: flex
  align-items: center
  column-gap: 9px
  background: $color-primary-10
  padding: 12px
  border-radius: $global-border-radius
  max-width: 500px
  .svg-container
    &:hover
      cursor: pointer
    svg
      width: 16px
      vertical-align: bottom
      path
        fill: $main-color
  .gray-svg
    svg path
      fill: $color-gray-60
  .filename
    flex-grow: 2
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
    @include text
    color: $color-gray-90
  &.loading
    color: $color-gray-60
</style>
