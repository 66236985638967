import axios from 'axios';
import ls from 'local-storage';
import UserRole from './enums/user-role.enum';

// Utilisation de require pour contourner un bug de webpack
const router = require('../router');

const API_URL = process.env.VUE_APP_API_URL;
let TOKEN = ls('TOKEN') || false;

// Récupération du token
const getToken = () => ls('TOKEN');

// Récupération du user
const getUser = () => ls('USER');

// L'utilisateur est-il loggué
const isLoggedIn = () => !!getToken();

// L'utilisateur est-il admin
const isAdmin = () => (getUser() ? getUser().role === UserRole.ADMIN : false);

// L'utilisateur est-il un praticien DPI
const isDpi = () => (getUser() ? getUser().isDpi : false);

const redirectRouteName = (isLoggedIn() && getUser() && (getUser().role === UserRole.ADMIN || getUser().isImpersonated)) ? 'admin-login' : 'login';

// Token du local storage
if (TOKEN) {
  axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;
}

// Récupération par axios d'une 401 (unauthorized) et redirection login
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401) {
    ls.remove('TOKEN');
    ls.remove('USER');
    if (router.default.history.current.name !== redirectRouteName) {
      router.default.push({ name: redirectRouteName });
      return new Promise(() => {});
    }
  }
  return Promise.reject(error);
});

// Impersonate
const impersonate = async (practitionerId) => {
  try {
    const response = await axios.post(`${API_URL}/admins/auth/impersonate`, { practitionerId });

    TOKEN = response.data;
    ls('TOKEN', response.data);
    ls('USER', response.data.user);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;
  } catch (error) {
    throw error;
  }
};

// Login
const login = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/practitioners/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data;
    ls('TOKEN', response.data);
    ls('USER', response.data.user);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

// Login
const loginAdmin = async (user) => {
  try {
    const response = await axios.post(`${API_URL}/admins/auth/login`, {
      email: user.email,
      password: user.password,
    });

    TOKEN = response.data;
    ls('TOKEN', response.data);
    ls('USER', response.data.user);
    axios.defaults.headers.common.Authorization = `Bearer ${TOKEN.token}`;

    return true;
  } catch (error) {
    throw error;
  }
};

// Logout
const logout = () => {
  ls.remove('TOKEN');
  ls.remove('USER');
  delete axios.defaults.headers.common.Authorization;
};

// Mot de mot passe oublié
const resetPassword = async (role, email) => {
  try {
    if (role === UserRole.ADMIN) {
      await axios.post(`${API_URL}/admins/auth/reset-password`, { email });
    } else {
      await axios.post(`${API_URL}/practitioners/auth/reset-password`, { email });
    }

    return true;
  } catch (error) {
    throw error;
  }
};

// Nouveau mot de passe
const newPassword = async (role, password, token) => {
  try {
    if (role === UserRole.ADMIN) {
      await axios.post(`${API_URL}/admins/auth/reset-password/new?token=${token}`, { password });
    } else {
      await axios.post(`${API_URL}/practitioners/auth/reset-password/new?token=${token}`, { password });
    }

    return true;
  } catch (error) {
    throw error;
  }
};

// Authorization Header
const getAuthorizationHeader = () => axios.defaults.headers.common.Authorization;

const auth = {};
auth.login = login;
auth.loginAdmin = loginAdmin;
auth.isLoggedIn = isLoggedIn;
auth.logout = logout;
auth.resetPassword = resetPassword;
auth.newPassword = newPassword;
auth.getToken = getToken;
auth.getUser = getUser;
auth.isAdmin = isAdmin;
auth.getAuthorizationHeader = getAuthorizationHeader;
auth.impersonate = impersonate;
auth.isDpi = isDpi;

export default auth;
