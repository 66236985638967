<template>
  <section class="treatment-order">
    <!-- Header admin -->
    <template v-if="isAdmin">
      <h2>Détail des commandes</h2>
    </template>

    <!-- Header praticien -->
    <template v-else>
      <div class="header grid-x align-middle">
        <div class="cell auto">
          <h2>Détail des commandes</h2>
        </div>
        <div class="cell shrink" v-if="canOrder">
          <app-button size="medium" look="primary" @click="isModalVisible = true">Gouttière cassée ou perdue</app-button>
        </div>
      </div>

      <!-- Modale de création d'une commande -->
      <app-modal :show.sync="isModalVisible" title="Nouvelle commande" isAction>
        <template v-slot:modal-content>
          <p class="commande-title">Merci de nous indiquer le nombre de gouttières souhaité ci-dessous</p>
          <h6>Si besoin, vous pouvez ajouter des détails à la suite du nombre de gouttières dans le champ ci-dessous :</h6>
          <form id="order-form" @submit.prevent="createOrder(message)">
            <app-input type="textarea" placeholder="Indiquer le nombre de gouttières, exemple : 2 Gouttières ou  2" v-model="message"/>
          </form>
        </template>
        <template v-slot:modal-footer>
          <app-button look="secondary" @click="isModalVisible = false">Annuler</app-button>
          <app-button :loading="isOrderCreationLoading"
          type="submit" form="order-form" :disabled="!message">Valider</app-button>
        </template>
      </app-modal>
    </template>

    <!-- Liste des commandes -->
    <div class="grid-x">
      <div class="cell medium-5 orders">
        <app-table
          :headers="tableHeader"
          :data="shippedOrders"
          :loading="false"
          :clickable="false"
        >
          <template v-slot:empty-table>
            <p class="empty-table">Aucune commande de disponible</p>
          </template>

          <template v-slot:shippingDate="{ data }">
            {{ data.shippedAt | formatDay }}
          </template>

          <template v-slot:code="{ data }">
            <a id="code" class="copyable" title="Copier le numéro de suivi" v-on:click="copy(data.colissimoCode)">{{ data.colissimoCode }}<CopySvg class="copy-svg"/></a>
          </template>
        </app-table>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import treatmentApi from '@/services/api/treatment';
import treatmentStep from '@/services/enums/treatment-step.enum';
import CopySvg from '@/assets/img/icons/copy.svg?inline';
import orderApi from '@/services/api/order';

export default {
  name: 'treatment-order',

  components: {
    CopySvg,
  },

  props: {
    treatment: Object,
  },

  model: {
    prop: 'treatment',
    event: 'update',
  },

  data() {
    return {
      user: auth.getUser(),
      isAdmin: auth.isAdmin(),
      isModalVisible: false,
      isOrderCreationLoading: false,
      tableHeader: [
        { label: 'Date d\'envoi', key: 'shippingDate' },
        { label: 'N° de suivi', key: 'code' },
      ],
      message: null,
    };
  },

  computed: {
    // Vérifie que la dernière commande a été envoyée
    canOrder() {
      return this.treatment.step ? this.treatment.step === treatmentStep.ORDER_SHIPPED && this.treatment.orders.length : false;
    },

    // Filtre les commandes envoyées
    shippedOrders() {
      return this.treatment.orders.filter((order) => order.colissimoCode && order.shippedAt);
    },
  },

  methods: {
    // Création d'une commande
    async createOrder(message) {
      this.isOrderCreationLoading = true;

      try {
        await orderApi.createOrder(this.treatment.treatmentId, message);
        this.getTreatment();
        this.$notification.show({ text: 'Commande effectuée !' });
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
        });
      }

      this.isOrderCreationLoading = false;
      this.isModalVisible = false;
    },

    // Récupération du traitement
    async getTreatment() {
      try {
        const response = await treatmentApi.getTreatment(this.treatment.treatmentId);
        this.$emit('update', response.data);
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
        });
      }
    },

    // Copie du numéro de suivi dans le presse-papier
    copy(text) {
      navigator.clipboard.writeText(text);
      this.$notification.show({ text: 'Copié dans le presse-papier' });
    },
  },
};
</script>

<style lang="sass">
.treatment-order

  .header

    h2, button
      margin: 0

  .orders
    margin-top: 25px

    .empty-table
      text-align: center
      margin: 0
      padding: 15px 0 20px 0
      color: $color-gray-60
      background-color: $white
      border-bottom-left-radius: $global-border-radius
      border-bottom-right-radius: $global-border-radius

    .table-line
      border: 0

    h6
      margin: auto

  .copyable
    display: inline-flex
    align-items: center
    font-size: 14px

    svg
      margin-left: 5px
      width: 18px

  .modal-wrapper.action-modal
    // max-width: 500px

    .modal-content
      margin-top: 20px
      text-align: left

      .commande-title
        @include text-bold
        margin: 0
        color: $color-gray-90

      h6
        margin-top: 8px
        margin-bottom: 20px
        color: $color-gray-70

</style>
