<template>
  <section class="home">
    <div class="grid-container">
      <app-subtitle>Grille</app-subtitle>
      <p>
        Le grille est basée sur celle de Fondation pour en savoir plus :
        <a href="https://foundation.zurb.com/sites/docs/xy-grid.html" target="_blank">Grille XY Fondation</a>
      </p>
      <div class="grid-x">
        <div class="cell small-4">Colonne 1</div>
        <div class="cell small-4">Colonne 2</div>
        <div class="cell small-4">Colonne 3</div>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Boutons</app-subtitle>
      <div>
        <app-button size="small" look="primary">Bouton petit</app-button>
        <app-button size="medium" look="primary">Bouton moyen</app-button>
        <app-button size="medium" look="primary" disabled>Bouton disabled</app-button>
      </div>
      <div>
        <app-button size="small" look="primary" theme="warning">Bouton warning petit</app-button>
        <app-button size="medium" look="primary" theme="warning">Bouton warning moyen</app-button>
        <app-button size="medium" look="primary" theme="warning" disabled>Bouton warning disabled</app-button>
      </div>
      <div>
        <app-button size="small" look="secondary" theme="warning">Bouton secondary warning petit</app-button>
        <app-button size="medium" look="secondary" theme="warning">Bouton secondary warning moyen</app-button>
        <app-button size="large" look="secondary" theme="warning" disabled>Bouton secondary warning disabled</app-button>
      </div>
      <div>
        <app-button size="small" look="secondary">Bouton secondary petit</app-button>
        <app-button size="medium" look="secondary">Bouton secondary moyen</app-button>
        <app-button size="medium" look="secondary" disabled>Bouton secondary disabled</app-button>
      </div>
      <div>
        <app-button size="small" look="primary" :loading="true">Bouton petit avec loader</app-button>
        <app-button size="medium" look="primary" :loading="true">Bouton moyen avec loader</app-button>
        <app-button size="medium" look="primary" :loading="true" disabled>Bouton moyen avec loader</app-button>
      </div>
      <div>
        <app-button size="small" look="primary"><plusSvg class="leading" /> Bouton petit avec icone avant</app-button>
        <app-button size="medium" look="primary"><plusSvg class="leading" /> Bouton moyen avec icone avant</app-button>
        <app-button size="medium" look="primary" disabled><plusSvg class="leading" /> Bouton moyen avec icone avant</app-button>
      </div>
      <div>
        <app-button size="small" look="primary">Bouton petit avec icone après<plusSvg class="following" /></app-button>
        <app-button size="medium" look="primary">Bouton moyen avec icone après<plusSvg class="following" /></app-button>
        <app-button size="medium" look="primary" disabled>Bouton moyen avec icone après<plusSvg class="following" /></app-button>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Titre</app-subtitle>
      <div>
        <app-subtitle>Sous-titre</app-subtitle>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Card</app-subtitle>
      <div class="grid-x grid-margin-x">
        <div class="cell medium-3">
          <app-card
            title="Lorem Ipsum"
            text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, deserunt."
            image="https://images.unsplash.com/photo-1632196068933-23998d66a2dd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"/>
        </div>
        <div class="cell medium-3">
          <app-card
            title="Lorem Ipsum"
            text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, deserunt."
            image="https://images.unsplash.com/photo-1632196068933-23998d66a2dd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"/>
        </div>
        <div class="cell medium-3">
          <app-card
            title="Lorem Ipsum"
            text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, deserunt."
            image="https://images.unsplash.com/photo-1632196068933-23998d66a2dd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"/>
        </div>
        <div class="cell medium-3">
          <app-card
            title="Lorem Ipsum"
            text="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laboriosam, deserunt."
            image="https://images.unsplash.com/photo-1632196068933-23998d66a2dd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"/>
        </div>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Message</app-subtitle>
      <div>
        <app-button @click="showMessage()">Afficher un message</app-button>
        <app-button @click="showMessage(true)" theme="warning">Afficher un message destructif</app-button>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Modale</app-subtitle>
      <div>
        <app-button @click="isActionModal = false; isModalVisible = true">Afficher une modale</app-button>
        <app-button @click="isActionModal = true; isModalVisible = true">Afficher une modale d'action</app-button>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Notification</app-subtitle>
      <div>
        <app-button @click="showNotification()">Afficher une notification</app-button>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Input</app-subtitle>
      <form>
        <div>
          <app-input type="text" v-model="answer4" required/>
          <app-input type="text" errorMessage="this is an error message"/>
          <app-input type="text" disabled value="value disabled"/>
        </div>
        <app-button type="submit">Submit</app-button>
      </form>
    </div>

    <div class="grid-container">
      <app-subtitle>TextArea</app-subtitle>
      <div>
        <app-input type="textarea" v-model="answer4"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Checkbox</app-subtitle>
      <div>
        <app-checkbox v-for="item in data" :key="`c${item.name}`" :value="item.name" v-model="answer1">{{ item.label }}</app-checkbox>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Radio</app-subtitle>
      <div>
        <div>Radio classique</div>
        <app-radio v-for="item in data" :key="`r${item.name}`" :value="item.name" v-model="answer2">{{ item.label }}</app-radio>
      </div>
      <div>
        <div>Radio Card</div>
        <div class="grid-x grid-margin-y grid-margin-x">
          <div class="cell shrink">
            <app-radio value="a1" v-model="answer17" look="card" title="Titre" />
          </div>
          <div class="cell shrink">
            <app-radio value="a2" v-model="answer17" look="card" title="Titre" subtitle="avec sous-titres"/>
          </div>
          <div class="cell shrink">
            <app-radio value="a3" v-model="answer17" look="card" title="Titre" subtitle="avec sous-titres">
              <img src="~@/assets/img/illustrations/request-validated.png" />
            </app-radio>
          </div>
          <div class="cell shrink">
            <app-radio value="a4" v-model="answer17" look="card" title="Titre">
              <img src="~@/assets/img/illustrations/request-validated.png" />
            </app-radio>
          </div>
          <div class="cell shrink">
            <app-radio value="a5" v-model="answer17" look="card" title="Titre" size="medium">
            </app-radio>
          </div>
          <div class="cell shrink">
            <app-radio value="a6" v-model="answer17" look="card" title="Titre"  subtitle="avec sous-titres" size="medium">
            </app-radio>
          </div>
          <div class="cell shrink">
            <app-radio value="a7" v-model="answer17" look="card" title="Titre" subtitle="avec sous-titres" size="medium">
              <img src="~@/assets/img/illustrations/request-validated.png" />
            </app-radio>
          </div>
          <div class="cell shrink">
            <app-radio value="a8" v-model="answer17" look="card" title="Titre" size="medium">
              <img src="~@/assets/img/illustrations/request-validated.png" />
            </app-radio>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Switch</app-subtitle>
      <div>
        <app-switch v-model="answer9">Je suis un switch</app-switch>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Select simple</app-subtitle>
      <div>
        <app-select v-model="answer3" :options="data"></app-select>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Select multiple</app-subtitle>
      <div>
        <app-select v-model="answer12" :options="data" :multiple=true></app-select>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Filtre</app-subtitle>
      <div>
        <app-label>Filtre simple</app-label>
        <app-filter v-model="answer13" :options="data" name="Réponse"></app-filter>
      </div>
      <div>
        <app-label>Filtre avec slot</app-label>
        <app-filter v-model="answer14" :options="data" name="Réponse">
          <template v-for="answer in data" v-slot:[answer.name]>
            <div class="filter-option" :key="answer.name">Custom: {{ answer.label }}</div>
          </template>
        </app-filter>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Search</app-subtitle>
      <div>
        <app-search v-model="answer12"></app-search>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Datepicker</app-subtitle>
      <div>
        <app-datepicker v-model="answer5"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Slider</app-subtitle>
      <div>
        <app-slider v-model="answer7"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Label</app-subtitle>
      <div>
        <app-label>Label</app-label>
        <app-input type="text"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Progress</app-subtitle>
      <div>
        <app-progress :steps="steps"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Table</app-subtitle>
      <div>
        <app-table
          :headers="tableHeaders"
          :data="tableData">
          <template slot="objectId" slot-scope="{ data }">
            <p><strong>{{ data.objectId }}</strong></p>
          </template>

          <template slot="metadata.pagination.page" slot-scope="{ data }">
            <p><strong>{{ data.metadata.pagination.page }}</strong></p>
          </template>
        </app-table>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Tooltip</app-subtitle>
      <div class="tooltip-container">
        <app-button @click="isTooltipVisible = !isTooltipVisible">
          {{ isTooltipVisible ? 'Cacher' : 'Afficher' }} un tooltip
        </app-button>
        <app-tooltip :show="isTooltipVisible">Ceci est un tooltip</app-tooltip>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Files multiples</app-subtitle>
      <div>
        <app-upload v-model="answer10" :progress="multipleProgressUpload" :multiple="true"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Upload picture</app-subtitle>
      <div class="grid-x margin-x-y">
        <div class="cell auto">
          Empty
          <upload-picture :value="answer15" @input="answer15 = getFileUrl($event)"/>
        </div>
        <div class="cell auto">
          Filled editable
          <upload-picture :value="answer16" @input="answer16 = getFileUrl($event)"/>
        </div>
        <div class="cell auto">
          Filled readonly
          <upload-picture :value="answer16" readonly/>
        </div>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Pagination</app-subtitle>
      <div>
        <app-pagination :offset="pagination.offset" :limit="pagination.limit" :count="pagination.count"/>
      </div>
      <div>
        <app-pagination :offset="50" :limit="10" :count="300"/>
      </div>
      <div>
        <app-pagination :offset="0" :limit="10" :count="200"/>
      </div>
      <div>
        <app-pagination :offset="200" :limit="10" :count="200"/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Tabs</app-subtitle>
      <div>
        <app-tabs :tabs="[
          { label: 'Tab 1', route: { name: 'login' }, notification: true },
          { label: 'Tab 2', route: { name: 'demo' }, notification: true },
          { label: 'Tab 3', route: { name: 'login' } }]">
        </app-tabs>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Import SVG</app-subtitle>
      <div>
        <validated-icon/>
      </div>
    </div>

    <app-modal :show.sync="isModalVisible" title="Modale" :isAction="isActionModal">
      <template v-slot:modal-content>
        Vous pouvez mettre ce que vous voulez dans le contenu, même des inputs.
      </template>
      <template v-slot:modal-footer>
        <app-button look="secondary" @click="isModalVisible = false">Annuler</app-button>
        <app-button @click="isModalVisible = false">J'ai compris</app-button>
      </template>
    </app-modal>

    <div class="grid-container">
      <app-subtitle>Spinner</app-subtitle>
      <div>
        <app-spinner small/>
        <app-spinner/>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Collapse</app-subtitle>
      <div>
        <app-collapse title="Lorem 1" v-model="activeCollapse" name="collapse1">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
        </app-collapse>
        <app-collapse title="Lorem 2" v-model="activeCollapse" name="collapse2">
          Lorem ipsum dolor sit at consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
        </app-collapse>
        <app-collapse title="Lorem 3" v-model="activeCollapse" name="collapse3">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?
        </app-collapse>
      </div>
    </div>

    <div class="grid-container">
      <app-subtitle>Modal pour la sélection d'un patient</app-subtitle>
      <app-button v-on:click="showSelectPatienModal = true">Afficher la liste des patients</app-button>

      <app-modal
        title="Choisir un patient"
        :show="showSelectPatienModal"
        v-on:update:show="showSelectPatienModal = false"
      >
        <template v-slot:modal-content>
          <PersonSelect @select="onPatientSelect" person="patient" />
        </template>
      </app-modal>
    </div>

    <div class="grid-container">
      <app-subtitle>Teeth</app-subtitle>
      <teeth v-model="specialTeeth" />
    </div>

    <!-- COMPOSANT MESSAGE -->
    <div class="grid-container">
      <app-subtitle>Message</app-subtitle>
      <h2>Version large</h2>
      <Message
        :practitioner="practitioner"
        :publicMessages="publicMessages"
        @saveMessage="onSaveMessage"
      />

      <h2>Version réduite avec liste de conversations</h2>
      <Message
        :practitioner="practitioner"
        :publicMessages="publicMessages"
        :adminMessages="adminMessages"
        :note="note"
        @saveMessage="onSaveMessage"
        @saveNote="onSaveNote"
        size="small"
      />
    </div>
  </section>
</template>

<script>
import document from '@/services/api/document';
import uploadService from '@/services/utils/upload';

import validatedIcon from '@/assets/img/validated.svg';
import plusSvg from '@/assets/img/icons/plus.svg?inline';
import PersonSelect from '@/components/PersonSelect.vue';
import ExamplePicture from '@/assets/img/illustrations/smile.png';
import teeth from '@/components/Teeth.vue';

import Message from '../components/Message.vue';
import UploadPicture from './Treatments/components/UploadPicture.vue';

export default {
  name: 'demo',
  components: {
    Message,
    UploadPicture,
    PersonSelect,
    validatedIcon,
    plusSvg,
    teeth,
  },
  data() {
    return {
      specialTeeth: { T11: 'CROWN', T12: 'MISSING' },
      isModalVisible: false,
      isActionModal: false,

      answer1: null,
      answer2: null,
      answer3: null,
      answer4: null,
      answer5: null,
      answer7: null,
      answer8: 6,
      answer9: true,
      answer10: null,
      answer11: null,
      answer12: null,
      answer13: null,
      answer14: null,
      answer15: null,
      answer16: ExamplePicture,
      answer17: null,

      dataAnswer8: [{
        label: 'Partie 1',
        number: 1,
        type: 'large',
        status: 'done',
      }, {
        label: null,
        type: 'small',
        status: 'done',
      }, {
        label: null,
        type: 'small',
        status: 'done',
      }, {
        label: null,
        type: 'small',
        status: 'done',
      }, {
        label: 'Partie 2',
        number: 2,
        type: 'large',
        status: 'in-progress',
      }, {
        label: null,
        type: 'small',
        status: 'in-progress',
      }, {
        label: null,
        type: 'small',
        status: 'pending',
      }, {
        label: 'Partie 3',
        number: 3,
        type: 'large',
        status: 'pending',
      }, {
        label: null,
        type: 'small',
        status: 'pending',
      }, {
        label: null,
        type: 'small',
        status: 'pending',
      }, {
        label: null,
        type: 'small',
        status: 'pending',
      }],

      data: [
        { name: 'a1', label: 'Réponse A1' },
        { name: 'a2', label: 'Réponse A2' },
        { name: 'a3', label: 'Réponse A3' },
        { name: 'a4', label: 'Réponse A4' },
        { name: 'a5', label: 'Réponse A5' },
        { name: 'a6', label: 'Réponse A6' },
        { name: 'a7', label: 'Réponse A7' },
        { name: 'a9', label: 'Réponse A9' },
        { name: 'a10', label: 'Réponse A10' },
        { name: 'a11', label: 'Réponse A11' },
      ],

      tableHeaders: [{ label: 'Object ID', key: 'objectId' }, { label: 'Color', key: 'color' }, { label: 'Digit', key: 'metadata.pagination.page' }, { label: 'Bool', key: 'bool' }],
      tableData: [{
        objectId: '1', color: 'red', digit: 1, bool: true, metadata: { pagination: { page: 10 } },
      },
      {
        objectId: '2', color: 'blue', digit: 2, bool: true, metadata: { pagination: { page: 22 } },
      },
      {
        objectId: '3', color: 'yellow', digit: 3, bool: false, metadata: { pagination: { page: 57 } },
      }],

      isTooltipVisible: false,

      multipleProgressUpload: 0,
      progressUpload: 0,

      pagination: {
        offset: 20,
        limit: 10,
        count: 125,
      },

      activeCollapse: null,

      publicMessages: [
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'PUBLIC',
          message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?',
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'PUBLIC',
          message: 'Yes!',
          authorAdmin: {
            adminId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Marc',
            lastname: 'Henry',
          },
          authorPractitioner: null,
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'PUBLIC',
          message: 'Beautiful day, is\'nt it?',
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
      ],
      adminMessages: [
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'ADMIN',
          message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum a error amet quae id officiis accusamus voluptatibus eligendi quisquam non assumenda praesentium porro, consequuntur quaerat distinctio aut nostrum consectetur quo?',
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'ADMIN',
          message: 'Yes!',
          authorAdmin: {
            adminId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Marc',
            lastname: 'Henry',
          },
          authorPractitioner: null,
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'ADMIN',
          message: 'Beautiful day, is\'nt it?',
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
        {
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'ADMIN',
          message: 'Beautiful day, is\'nt it?',
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        },
      ],
      note: 'Une note de base',

      showSelectPatienModal: false,

      practitioner: {
        practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
        firstname: 'Jean',
        lastname: 'Dupont',
      },

      steps: [
        {
          name: 'Pré-étude',
          date: '2022-02-16T09:02:51.294',
        },
        {
          name: 'Plan de traitement',
          date: '2022-02-16T09:02:51.294',
        },
        {
          name: 'Production',
          date: '2022-02-16T09:02:51.294',
        },
        {
          name: 'Expédition',
          date: '2022-02-16T09:02:51.294',
        },
      ],
    };
  },
  watch: {
    answer10: 'handleFiles',
    answer11: 'handleSimpleFile',
  },
  methods: {
    closeModal() {
      this.$notification.show({
        text: 'Confirmer !',
      });
    },
    showMessage(isDestructive = false) {
      this.$message.show({
        title: 'Lorem',
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero aperiam repellendus officiis optio eum error cupiditate ad explicabo eveniet!',
        onConfirm: () => {
          this.closeModal();
        },
        isDestructive,
        hasCancel: true,
      });
    },
    showNotification() {
      this.$notification.show({
        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero aperiam repellendus officiis optio eum error cupiditate ad explicabo eveniet!',
      });
    },
    handleSpecialTooth(teethData) {
      this.specialTeeth = teethData;
    },
    getFileUrl(file) {
      if (!file) return null;
      const url = URL.createObjectURL(file);
      return url;
    },
    async handleFiles() {
      if (this.answer10) {
        // Si on a un upload avec des fichiers multiples
        const files = this.answer10;

        // On construit le tableau de promesses
        const uploadPromises = files.map(async (file, index) => {
          await document.upload(file, (progressEvent) => {
            this.multipleProgressUpload = uploadService.getProgress(progressEvent, index);
          });

          this.$notification.show({
            text: `Fichier ${file.name} uploadé !`,
          });
        });

        try {
          await Promise.allSettled(uploadPromises);

          this.multipleProgressUpload = 0;
          this.answer10 = null;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu une erreur lors de l’envoi de vos fichiers.',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        }
      }
    },
    async handleSimpleFile() {
      if (this.answer11) {
        try {
          await document.upload(this.answer11, (progressEvent) => {
            // On calcule le pourcentage de la progression
            this.progressUpload = uploadService.getProgress(progressEvent);
          });

          this.$notification.show({
            text: `Fichier ${this.answer11.name} uploadé !`,
          });

          this.progressUpload = 0;
          this.answer11 = null;
        } catch (error) {
          this.$message.show({
            title: 'Erreur',
            text: 'Il y a eu une erreur lors de l’envoi de votre fichier.',
            confirmText: 'OK',
            hasCancel: false,
          });

          throw error;
        }
      }
    },

    onPatientSelect(value) {
      this.showSelectPatienModal = false;
      // eslint-disable-next-line no-console
      console.log('Patient : ', value);
    },

    onSaveMessage(message) {
      // eslint-disable-next-line no-console
      console.log('Sauvegarde du message : ', message);

      if (message.messageType === 'PUBLIC') {
        this.publicMessages.push({
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'PUBLIC',
          message: message.message,
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        });
      } else {
        this.adminMessages.push({
          messageId: 'e5169a2d-cc29-4a98-88ed-07f2a58483dc',
          treatmentId: '198761af-2ce7-4e63-829a-0231a20ec304',
          visibility: 'ADMIN',
          message: message.message,
          authorAdmin: null,
          authorPractitioner: {
            practitionerId: '7ee35855-bdc8-4a87-8cd6-ffd9ca51129a',
            firstname: 'Pauline',
            lastname: 'Pernet',
          },
          createdAt: '2022-02-16T09:02:51.294Z',
          updatedAt: '2022-02-16T09:02:51.294Z',
          deletedAt: null,
        });
      }
    },

    onSaveNote(note) {
      // eslint-disable-next-line no-console
      console.log('Sauvegarde de la note : ', note);
    },
  },
};
</script>

<style lang="sass">
.home
  padding: 1rem 0 4rem
  background: $color-primary-10
  .grid-container
    text-align: left
  .app-subtitle
    margin-top: 4rem
  .tooltip-container
    position: relative
    width: fit-content
  svg
    max-width: 20px

  .app-filter
    .filter-option
      background: $color-secondary-20
      border-radius: $global-border-radius
      padding: 3px
</style>
