<template>
  <section id="app">
    <div v-if="!$route.meta.requiresAuth || $route.meta.isFullScreen" class="view-container">
      <router-view />
    </div>

    <div v-else class="grid-x view-container">
      <div class="cell left-panel">
        <side-nav/>
      </div>
      <div class="cell auto">
        <router-view :unpaidInvoices="hideUnpaidInvoices ? [] : unpaidInvoices" @closeBanner="hideUnpaidInvoices = true" @update:unpaidInvoices="getUnpaidInvoices"/>
      </div>
    </div>

    <!-- Global basics components integration -->
    <app-message/>
    <app-notifications/>
  </section>
</template>

<script>
import auth from '@/services/auth';
import treatmentApi from '@/services/api/treatment';
import SideNav from './components/SideNav.vue';

export default {
  name: 'app',

  components: {
    SideNav,
  },

  data() {
    return {
      unpaidInvoices: null,
      hideUnpaidInvoices: false,
    };
  },

  mounted() {
    this.getUnpaidInvoices();
  },

  methods: {
    // Récupération des factures impayées
    async getUnpaidInvoices() {
      if (auth.isLoggedIn() && !auth.isAdmin() && !auth.isDpi() && !this.hideUnpaidInvoices) {
        try {
          const response = await treatmentApi.getUnpaidInvoices();
          this.unpaidInvoices = response.data;
        } catch (error) {
          // RAS
        }
      }
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/sass/app.sass"
#app
  text-align: left
  background: $background-color
  display: flex
  color: $text-color
  .view-container
    width: 100%
    min-height: 100vh
    .left-panel
      width: 240px
</style>
