export default Object.freeze({
  IMPRINT_CAST_DONE: 'Empreintes à couler',
  MOLD_SCAN_DONE: 'Moulage à scanner',
  TREATMENT_PLAN_DONE: 'Plan de traitement à faire',
  ORDER_CONTROL_DONE: 'Commande validée à contrôler',
  DMSM_DONE: 'DMSM à importer',
  INVOICE_DONE: 'Facture à importer',
  LABELS_DONE: 'Générations étiquettes',
  ARCADE_PRINTING_PENDING: 'Impression arcades à lancer',
  ARCADE_PRINTING_ONGOING: 'Impression arcades en cours',
  ARCADE_PRINTING_DONE: 'Impression arcades ok',
  GUTTERS_DONE: 'Gouttières à fabriquer',
  FABRICATION_DONE: 'Fabrication OK pour colis',
});
