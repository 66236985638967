<template>
  <div class="slide">
    <!-- Slide vide (pas d'images) -->
    <div v-if="!slide" class="slide-empty" @click="$emit('update:slide')">
      <div><SvgPlus /></div>
      <p>Ajouter une slide</p>
    </div>
    <!-- Slide remplie -->
    <div v-else class="slide-filled">
      <img :src="imgSrc" />
      <div class="slide-cta-container">
        <app-button look="secondary" title="modifier la slide" @click="$emit('update:slide')"><SvgEdit /></app-button>
        <app-button look="secondary" title="supprimer la slide" @click="$emit('delete:slide')"><SvgCross /></app-button>
      </div>
    </div>

  </div>
</template>

<script>
import SvgPlus from '@/assets/img/icons/plus.svg';
import SvgEdit from '@/assets/img/icons/edit.svg';
import SvgCross from '@/assets/img/icons/cross.svg';

export default {
  components: {
    SvgPlus,
    SvgEdit,
    SvgCross,
  },
  props: {
    slide: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    imgSrc() {
      if (this.slide.image) {
        return `data:image/jpeg;base64,${this.slide.image}`;
      }
      return null;
    },
  },
};
</script>

<style lang="sass">
.slide
  .slide-empty, .slide-filled
    border-radius: $global-border-radius
    overflow: hidden
    width: 100%
    height: 100%
  .slide-empty
    background: $color-primary-10
    border: 1px dashed $color-primary-50
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    cursor: pointer
    > div
      background: $main-color
      border-radius: 50%
      padding: 10px
      width: 40px
      height: 40px
      svg
        path
          fill: white
    &:hover
      background: $color-primary-20

  .slide-filled
    position: relative
    & > img
      width: 100%
      height: 100%
      object-fit: cover
    .slide-cta-container
      position: absolute
      bottom: 0
      width: 100%
      display: flex
      justify-content: flex-end
      padding: 0.1rem
      background: rgba(255, 255, 255, 0.8)
      transform: translateY(100%)
      transition: transform 0.3s ease-in-out
      .app-button
        border: none
        svg
          width: 20px
    &:hover
      .slide-cta-container
        transform: translateY(0)
</style>
