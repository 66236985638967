import axios from 'axios';
import MessageVisibility from '../enums/message-type.enum';

const API_URL = process.env.VUE_APP_API_URL;

// Récupération des messages
const get = async (treatmentId, visibility = MessageVisibility.PUBLIC) => {
  try {
    const response = await axios.get(`${API_URL}/treatments/${treatmentId}/messages`, { params: { visibility } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Création d'un message
const create = async (treatmentId, body) => {
  try {
    const response = await axios.post(`${API_URL}/treatments/${treatmentId}/messages`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.get = get;
api.create = create;

export default api;
