import Vue from 'vue';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import { StripePlugin } from '@vue-stripe/vue-stripe';

import App from './App.vue';
import router from './router';
import Basics from './basics/index';

Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

Vue.use(StripePlugin, {
  pk: process.env.VUE_APP_STRIPE_KEY,
  testMode: process.env.NODE_ENV !== 'production',
});

// Formate la date au format JJ/MM/AAAA avec en option l'heure au format 00h00
Vue.filter('formatDate', (date, prefix, showHour) => {
  // Création d'une seule instance de Dayjs
  const dayjsDate = dayjs(date);

  // Récupération de la date
  const formattedDate = dayjsDate.format('DD/MM/YYYY');

  // Récupération de l'heure
  if (showHour) {
    const formattedHour = dayjsDate.format('HH');
    const formattedMinutes = dayjsDate.format('mm');

    return `${prefix} ${formattedDate} à ${formattedHour}h${formattedMinutes}`;
  }

  return `${prefix} ${formattedDate}`;
});

Vue.use(Basics);

dayjs.extend(dayjsBusinessDays);
Vue.prototype.$dayjs = dayjs;

// Filters
Vue.filter('formatDay', (date) => (date ? dayjs(date).format('DD/MM/YYYY') : ''));

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
