<template>
  <section class="treatment-discussions">

    <app-spinner v-if="isLoading" />
    <!-- MESSAGES -->
    <Message
      v-else
      class="message"
      :publicMessages="publicMessages"
      :maxHeight="false"
      @saveMessage="onSaveMessage"
    />

  </section>
</template>

<script>
import Message from '@/components/Message.vue';
import messageApi from '@/services/api/message';

export default {
  name: 'treatment-discussions',

  components: {
    Message,
  },

  data() {
    return {
      publicMessages: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getMesssagesWithLoader();
    // Mise à jour des messages toutes les 5 s
    this.refreshInterval = setInterval(this.getMessages, 5000);
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },

  methods: {
    // REQUÊTE API : Récupération des messages
    async getMessages() {
      try {
        const response = await messageApi.get(this.$route.params.treatmentId);
        this.publicMessages = response.data;
      } catch (error) {
        if (!error.response || error.response.data.statusCode === 503) {
          // Probablement une erreur de CORS venant exclusivement de Clever-cloud en pré-prod: à ignorer
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la récupération des messages.',
            confirmText: 'Ok',
          });
        }
      }
    },

    async getMesssagesWithLoader() {
      this.isLoading = true;
      await this.getMessages();
      this.isLoading = false;
    },

    // Création d'un nouveau message
    async onSaveMessage(message) {
      try {
        await messageApi.create(this.$route.params.treatmentId, message);
        this.getMessages();
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la création du message.',
          confirmText: 'Ok',
        });
      }
    },
  },
};
</script>

<style lang="sass">
.treatment-discussions
  .app-spinner
    margin: 40px auto 0 auto
</style>
