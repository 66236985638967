<template>
  <div class="imprint-card">
    <!-- Header de la carte : type d'empreintes + bouton demande de validation -->
    <div class="imprint-card-header">
      <h3>{{ ImprintTypeLabel[imprint.imprintType] }}</h3>
      <app-button v-if="!isAdmin && isRefused" size="small" @click="confirmValidationRequest" :loading="isLoading">Demander vérification</app-button>
    </div>

    <div class="imprint-card-content">
      <!-- Encart empreintes acceptées/refusées le -->
      <div
        class="imprint-banner"
        v-if="isAdmin && imprint.validation !== ImprintValidation.PENDING"
        :class="{ 'primary': imprint.validation === ImprintValidation.VALIDATED, 'secondary': imprint.validation === ImprintValidation.REFUSED }">
        <p>Empreintes {{ imprint.validation === ImprintValidation.VALIDATED ? 'acceptées' : 'refusées' }} le {{ imprint.validationUpdatedAt | formatDay }}</p>
      </div>

      <!-- Empreintes photos -->
      <div class="pictures-container" v-if="imprint.imprintType === ImprintType.PICTURES">
        <template v-for="view in views">
          <UploadPicture :key="view" :value="imprint[view]" :readonly="isReadonly" v-if="imprint[view]" @input="uploadFile($event, view)" :isLoading="!!loadingFile[view]"/>
        </template>
        <UploadPicture v-if="!isReadonly && viewsCounter < views.length" @input="uploadFile($event, firstAvailableView)" :value="getUrl(loadingFile[firstAvailableView])" :isLoading="!!loadingFile[firstAvailableView]"/>
      </div>

      <div class="scans-container" v-else>
        <!-- Empreintes STL externes -->
        <template v-if="imprint.imprintType === ImprintType.EXTERNAL_SCAN">
          <div v-if="isAdmin && imprint.validation === ImprintValidation.PENDING" class="scan-info">
            <p>Empreintes lien STL envoyées</p>
          </div>
          <div class="scan-info" v-else-if="!isAdmin">
            <p>{{ `Empreintes ${ isRefused ? 'à transmettre' : 'transmises'} par mail à l'adresse` }}</p>
            <p>labo@ealigner.com</p>
          </div>
        </template>

        <!-- Empreintes physiques -->
        <template v-else-if="imprint.imprintType === ImprintType.MATERIAL">
          <div class="scan-info" v-if="isAdmin && imprint.validation === ImprintValidation.PENDING">
            <p>Empreintes physiques en {{ ImprintMaterialTypeLabel[imprint.materialType] }} envoyées</p>
          </div>
          <div class="scan-info" v-else-if="!isAdmin">
            <p v-if="isRefused">Merci de nous envoyer vos empreintes à l'adresse :</p>
            <p v-else>Empreintes envoyées à l'adresse suivante</p>
            <p>
              {{ address.line1 }}<br/>
              {{ address.line2 }}<br/>
              {{ address.line3 }}<br/>
            </p>
          </div>
          <div class="radio-field" v-if="isRefused && !isAdmin">
            <span>Type d'empreintes :</span>
            <app-radio v-for="item in materialTypeOptions" :key="`${item.name}`" :value="item.name" v-model="materialType">{{ item.label }}</app-radio>
          </div>
        </template>

        <!-- Empreintes STL internes -->
        <template v-else-if="imprint.imprintType === ImprintType.INTERNAL_SCAN">
          <template v-if="!isReadonly">
            <app-upload @input="uploadStlFile" accept=".stl" compress/>
            <div>Fichiers ajoutés :</div>
          </template>
          <template v-for="view in views">
            <app-file :key="view" :fileUrl="imprint[view]" v-if="imprint[view]" @click:delete="uploadFile(null, view)" :isLoading="loadingFile[view]" :readonly="isReadonly"/>
          </template>
          <app-file v-if="loadingFile[firstAvailableView]" :file="loadingFile[firstAvailableView]" isLoading />
        </template>

        <!-- Empreintes traitement -->
        <template v-else-if="imprint.imprintType === ImprintType.OLD_TREATMENT">
          <p>(Contention conçue sur la dernière gouttière portée)</p>
        </template>
      </div>
    </div>

    <!-- Bouton footer de validation -->
    <div class="imprint-card-footer" v-if="isAdmin && imprint.validation === ImprintValidation.PENDING">
      <app-button size="large" @click="updateImprint(ImprintValidation.VALIDATED)" :loading="isLoading">Valider</app-button>
      <app-button size="large" theme="warning" :loading="isLoading" @click="showRefuseModal = true">Refuser</app-button>
    </div>

    <!-- Modale de refus -->
    <app-modal title="Refuser empreintes" @update:show="showRefuseModal = $event" isAction :show="showRefuseModal" class="imprint-refuse-modal">
      <template v-slot:modal-content>
        <p>Précisez les raisons pour le praticien</p>
        <app-input type="textarea" placeholder="Description libre" v-model="invalidReason"></app-input>
      </template>
      <template v-slot:modal-footer>
        <app-button look="secondary" @click="showRefuseModal = false">Annuler</app-button>
        <app-button @click="updateImprint(ImprintValidation.REFUSED); showRefuseModal = false">Envoyer</app-button>
      </template>
    </app-modal>
  </div>
</template>

<script>
// Api & utils
import auth from '@/services/auth';
import imprintApi from '@/services/api/imprint';
import utils from '@/services/utils/utils';

// Enums
import ImprintType from '@/services/enums/imprint-type.enum';
import ImprintTypeLabel from '@/services/enums/imprint-type-label.enum';
import ImprintValidation from '@/services/enums/imprint-validation.enum';
import ImprintMaterialTypeLabel from '@/services/enums/imprint-material-type-label.enum';

// Composants & Svg
import UploadPicture from './UploadPicture.vue';

export default {
  model: {
    prop: 'imprint',
    event: 'input',
  },
  components: {
    UploadPicture,
  },
  props: {
    imprint: Object,
  },

  data() {
    return {
      // Données dynamiques
      materialType: this.imprint.materialType,
      invalidReason: null,
      showRefuseModal: false,
      loadingFile: {}, // Fichier en train d'être ajouté (selon la vue)
      isLoading: false, // Empreinte en cours de modification

      // Données fixes
      isAdmin: auth.isAdmin(),
      ImprintTypeLabel,
      ImprintMaterialTypeLabel,
      ImprintValidation,
      ImprintType,
      materialTypeOptions: utils.optionsFromEnum(ImprintMaterialTypeLabel),
      views: ['view1', 'view2', 'view3', 'view4', 'view5', 'view6', 'view7', 'view8'],
      address: {
        line1: process.env.VUE_APP_ADDRESS_LINE_1,
        line2: process.env.VUE_APP_ADDRESS_LINE_2,
        line3: process.env.VUE_APP_ADDRESS_LINE_3,
      },
    };
  },
  watch: {
    imprint() {
      this.materialType = this.imprint.materialType;
    },
  },
  computed: {
    // Assure la réactivité de l'attribut validation
    imprintValidation() {
      return this.imprint.validation;
    },
    // Empreinte refusée
    isRefused() {
      return this.imprintValidation === ImprintValidation.REFUSED;
    },
    // Empreinte non modifiable
    isReadonly() {
      return this.isAdmin || !this.isRefused;
    },
    // Première 'view' disponible pour ajouter un fichier
    firstAvailableView() {
      return this.views.reduce((availableView, view) => {
        if (availableView) return availableView;
        return this.imprint[view] ? null : view;
      }, null);
    },
    // Nombre de fichiers déjà enregistrés
    viewsCounter() {
      return this.views.reduce((counter, view) => (this.imprint[view] ? counter + 1 : counter), 0);
    },
  },
  methods: {
    // Récupère l'URL pour un fichier local
    getUrl(file) {
      return file ? URL.createObjectURL(file) : null;
    },

    // Vérifie que le fichier STL peut être enregistré avant de le faire
    async uploadStlFile(file) {
      if (this.viewsCounter >= 4) {
        this.$message.show({
          title: 'Limite atteinte',
          text: 'Seulement 4 fichiers STL sont autorisés pour les empreintes. Veuillez d\'abord en supprimer avant d\'en ajouter de nouveaux.',
          confirmText: 'J\'ai compris',
        });
        return;
      }
      this.uploadFile(file, this.firstAvailableView);
    },

    // Confirme la demande de validation
    confirmValidationRequest() {
      const text = this.imprint.imprintType === ImprintType.PICTURES
        ? 'Vous validez avoir pris en compte les retours Ealigner et avoir modifié vos photos en conséquence.'
        : `Vous validez avoir pris en compte les retours Ealigner et avoir modifié vos empreintes en conséquence.

          Pour des empreintes physiques, vous validez avoir envoyé de nouvelles empreintes conformes à la demande et avoir renseigné le type de matériel utilisé.`;
      this.$message.show({
        title: 'Demande de vérification',
        text,
        confirmText: 'Valider ma demande',
        cancelText: 'Annuler',
        hasCancel: true,
        onConfirm: () => this.updateImprint(ImprintValidation.PENDING),
      });
    },

    // REQUÊTE API : Enregistre le nouveau fichier STL/photo sur l'empreinte
    async uploadFile(file, view) {
      this.$set(this.loadingFile, view, file || true);
      try {
        const response = await imprintApi.uploadFile(this.imprint.imprintId, file, view);
        this.$emit('input', response.data);
      } catch (error) {
        let text = `Un problème est survenu lors de ${file ? 'l\'ajout' : 'la suppression'} du fichier. Veuillez réessayer plus tard.`;
        if (error.response.data.message.startsWith('S3 Error')) {
          text = `Un problème est survenu lors de ${file ? 'l\'ajout' : 'la suppression'} du fichier. Cela peut être du à un nom de fichier non conforme. Évitez les accents, les caractères spéciaux (sauf - et _) et les espaces.`;
        }
        this.$message.show({ title: 'Erreur', text });
      }
      this.$set(this.loadingFile, view, undefined);
    },

    // REQUÊTE API : Modifie la validation de l'empreinte
    async updateImprint(validation) {
      this.isLoading = true;
      try {
        const response = await imprintApi.updateImprint(this.imprint.imprintId, validation, this.invalidReason, this.materialType);
        this.$emit('input', response.data);
      } catch (error) {
        const validationLabel = {
          REFUSED: 'du refus',
          VALIDATED: 'de la validation',
          PENDING: 'de la demande de validation',
        };
        this.$message.show({ title: 'Erreur', text: `Un problème est survenu lors ${validationLabel[validation]} de l'empreinte. Veuillez réessayer plus tard.` });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.imprint-card
  background: $white
  padding: 24px
  border-radius: $global-border-radius
  .imprint-card-header
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $color-gray-20
    padding-bottom: 16px
    h3
      margin: 0
  .imprint-card-content
    margin: 24px 0
    .pictures-container
      display: flex
      flex-wrap: wrap
      column-gap: 16px
      row-gap: 24px
    .scans-container
      .scan-info
        @include inset
        > p:first-child
          @include text-bold
          margin-top: 0
          color: $color-gray-90
        > p:last-child
          margin-bottom: 0
      .radio-field
        display: flex
        align-items: center
        column-gap: 8px
        margin-top: 24px
        > span
          @include text-bold
          flex-grow: 2
      .app-upload + div
        @include h6
        color: $color-gray-70
        margin-top: 24px
      .app-file
        margin: 8px 0
  .imprint-refuse-modal .modal-wrapper
    max-width: 500px
    height: fit-content
    .modal-header, h3
      margin-bottom: 0
    .modal-content
      p
        text-align: center
        color: $color-gray-70
        margin-bottom: 40px
      .app-input
        max-width: unset
        width: 100%
        > textarea
          min-height: 10rem

</style>
