<!--
****************************************************************************************************
***                                          Radio                                               ***
****************************************************************************************************

Le composant radio est proche de celui en HTML5, c'est à dire une variable qui peut prendre 1 valeur.

*************
*** PROPS ***
*************
  - Value :
    C'est la valeur que prendra la variable dans v-model

    Exemple :
      <app-radio value="value1" v-model="answer">Label 1</app-radio>
      <app-radio value="value2" v-model="answer">Label 2</app-radio>
      <app-radio value="value3" v-model="answer">Label 3</app-radio>

-->
<template>
  <div class="app-radio" :class="{ auto: size === 'auto' }">
      <label :class="input === value && 'checked'" v-if="look === 'classic'" class="radio-classic">
        <input type="radio"
          :checked="input === value"
          @change="onChange()"/>
        <span class="checkmark"></span>
        <slot></slot>
      </label>
      <label v-else-if="look === 'card'" class="radio-card" :class="[{ checked: input === value }, size]">
        <input type="radio"
          :checked="input === value"
          @change="onChange()"
        />
        <span :class="{ empty: !$slots.default }" class="img-container">
          <slot></slot>
        </span>
        <span class="text-container">
          <div class="title">{{ title || value }}</div>
          <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
          <slot name="below"></slot>
        </span>
        <slot name="after"></slot>
      </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    // Obligatoire
    input: [String, Array, Number],
    value: {
      type: String,
    },
    // Pour le look card
    look: {
      type: String,
      default: 'classic',
      validator: (val) => ['classic', 'card'].includes(val),
    },
    size: {
      type: String,
      default: 'small',
      validator: (val) => ['small', 'medium', 'auto'].includes(val),
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="sass">
.app-radio
  display: inline-block
  &.auto
    width: 100%
  label.radio-classic
    display: flex
    position: relative
    align-items: center
    justify-content: center
    padding: 12px 24px 12px 12px
    border: 1px solid $color-gray-40
    border-radius: $global-border-radius
    background: $white
    margin: 0
    cursor: pointer
    user-select: none
    @include text-bold

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark:after
        background-color: $color-primary-100
        border-color: transparent

      &:checked ~ .checkmark:after
        opacity: 1

    .checkmark
      display: inline-block
      margin-right: 8px
      height: 12px
      width: 12px
      border: 1px solid $color-gray-40
      background: white
      border-radius: 50%
      transform: translateY(0)
      transition: all ease-in-out 0.2s
      &:after
        content: ""
        position: absolute
        opacity: 0
        left: 1px
        top: 1px
        width: 8px
        height: 8px
        border-radius: 50%
        transition: opacity ease-in-out 0.2s

    &:hover
      border: 1px solid $color-primary-40
      background: $color-primary-20
      transition: all ease-in-out 0.2s
      .checkmark
        border-color: $color-primary-40
        background: transparent
    &.checked
      background: $color-primary-40
      border-color: $color-primary-100
      transition: all ease-in-out 0.2s
      .checkmark
        border-color: $color-primary-100

  label.radio-card
    background: $white
    border-radius: $global-border-radius
    border: 1px solid $white
    box-shadow: $medium-shadow
    min-height: 72px
    width: 253px
    height: 100%
    padding: 16px 10px
    display: flex
    column-gap: 16px
    input
      width: 0
      height: 0
      position: absolute
      opacity: 0
    .text-container
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: center
      text-align: left
      flex-grow: 2
      .title
        @include text-bold
        color: $color-gray-90
      .subtitle
        @include h6
        color: $color-gray-70
    .img-container
      min-width: 50px
      width: 50px
      display: flex
      align-items: center
      justify-content: center
      img, svg
        width: 100%
      &.empty
        display: none
    &:hover
      background: $color-primary-20
      border: 1px dashed $color-primary-40
      cursor: pointer
    &.checked
      background-color: $color-primary-40
      border-color: $color-primary-100
    &.auto
      width: 100%
    &.medium
      padding: 13px 13px 13px 16px
      height: 92px
      .img-container
        height: calc(100%-26px)
</style>
