<template>
  <div class="treatment-plan-request-tunnel">
    <!-- Header : toujours là -->
    <tunnel-steps :steps="steps" :activeIndex="activeStepIndex"/>
    <a @click="$router.back()" v-if="activeStepIndex <= steps.length"><SvgCross />Annuler demande</a>

    <div class="tunnel-content-container">
      <!-- Introduction: Step Null -->
      <div v-if="activeStepIndex == null" class="introduction-content">
        <template v-if="isExtendingTreatment">
          <img src="~@/assets/img/illustrations/treatment-extend.png" />
          <h1>Demande suite de traitement</h1>
        </template>
        <template v-else>
          <img src="~@/assets/img/illustrations/treatment-plan.png" />
          <h1>Demande plan de traitement</h1>
        </template>
        <p><strong>Pour poursuivre votre demande de traitement, nous avons besoin d’informations supplémentaires.</strong></p>
        <p v-if="!isExtendingTreatment">Pour recevoir votre plan de traitement, un acompte de {{ depositPrice }} vous sera demandé. <br/> Ce montant sera déduit de votre facture finale.</p>
        <app-button @click="activeStepIndex = 0" :disabled="isLoadingTreatment" :loading="isLoadingTreatment">J'ai compris</app-button>
      </div>

      <!-- Ecran de succès -->
      <template  v-else-if="activeStepIndex >= steps.length">
        <div class="step-content success-content">
          <img src="~@/assets/img/illustrations/request-validated.png" />
          <h4>Référence dossier : {{ treatment.reference }}</h4>
          <h2>Votre demande a bien été prise en compte</h2>
          <p>Vous recevrez une notification par email pour aller consulter notre proposition de plan de traitement sous 10 jours maximum.</p>
          <p>La facture d’acompte sera disponible sur votre interface sous 48h ouvrées.</p>
        </div>
        <div class="step-footer" v-if="paymentMethod === PaymentMethod.CARD">
          <app-button @click="$router.push({ name: 'treatment-payment-session' })">Payer par carte de crédit</app-button>
          <app-button look="secondary" @click="$router.push({ name: 'treatment-list' })">Retour à mon espace</app-button>
        </div>
        <div class="step-footer" v-else>
          <app-button @click="$router.push({ name: 'treatment-list' })">Retour à mon espace</app-button>
        </div>

      </template>

      <template v-else>
        <!-- Header commun à toutes les étapes -->
        <div class="step-common-header">
          <template v-if="isExtendingTreatment">
            <img src="~@/assets/img/illustrations/treatment-extend.png" />
            <h5>Demande suivi de traitement</h5>
          </template>
          <template v-else>
            <img src="~@/assets/img/illustrations/treatment-plan.png" />
            <h5>Demande plan de traitement</h5>
          </template>
        </div>

        <!-- Étape Zone de traitement -->
        <div v-if="activeStepId === 'zone'" class="step-content zone-step-content">
          <h2>Zone de traitement</h2>
          <div class="radio-container">
            <app-radio look="card" v-model="zone" :value="TreatmentZone.MAXIMILLAIRE" :title="TreatmentZoneLabel.MAXIMILLAIRE">
              <img src="~@/assets/img/illustrations/zone-maximillaire.png" />
            </app-radio>
            <app-radio look="card" v-model="zone" :value="TreatmentZone.MANDIBULAIRE" :title="TreatmentZoneLabel.MANDIBULAIRE">
              <img src="~@/assets/img/illustrations/zone-mandibulaire.png" />
            </app-radio>
            <app-radio look="card" v-model="zone" :value="TreatmentZone.COMPLETE" :title="TreatmentZoneLabel.COMPLETE">
              <img src="~@/assets/img/illustrations/zone-complete.png" />
            </app-radio>
          </div>
          <h3>Nature du traitement :</h3>
          <div class="pathologies-card-container">
            <div
              v-for="pathology in pathologyOptions"
              :key="pathology.key"
              class="pathology-card"
              @click="pathologies[pathology.key] = !pathologies[pathology.key]"
              :class="{ active: pathologies[pathology.key] }">
              <img :src="pathology.src" />
              <h4> {{ PathologyLabel[pathology.key] }}</h4>
            </div>
          </div>
        </div>

        <!-- Étape Empreintes  -->
        <div v-else-if="activeStepId === 'imprint'" class="step-content imprint-step-content">
          <template v-if="isImprintSaved">
            <h2>Empreintes envoyées</h2>
            <p>Des empreintes ont déjà été enregistrées sur ce traitement. Vous pourrez les modifier si besoin sur la page du traitement, dans l'onglet Empreintes.</p>
          </template>
          <template v-else>
            <h2>Avez-vous des empreintes à nous envoyer ?</h2>
            <div class="radio-container">
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.MATERIAL" :title="ImprintTypeLabel.MATERIAL">
                <img src="~@/assets/img/illustrations/imprint-material.png" />
              </app-radio>
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.INTERNAL_SCAN" :title="ImprintTypeLabel.INTERNAL_SCAN" subtitle="envoi immédiat des fichiers">
                <img src="~@/assets/img/illustrations/imprint-internal-scan.png" />
              </app-radio>
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.EXTERNAL_SCAN" :title="ImprintTypeLabel.EXTERNAL_SCAN" subtitle="(via plateforme externe : CS Connect, MEDIT Link, 3Shape...)">
                <img src="~@/assets/img/illustrations/imprint-external-scan.png" />
              </app-radio>
            </div>
            <!-- Empreintes physiques -->
            <div class="material-type-container" v-if="imprint.imprintType === ImprintType.MATERIAL">
              <h3>Type d'empreintes :</h3>
              <p>Silicone à privilégier pour une meilleure tenue dans le temps</p>
              <div class="radio-container">
                <app-radio v-for="[key, label] in Object.entries(ImprintMaterialTypeLabel)" :key="key" v-model="imprint.materialType" :value="key">{{ label }}</app-radio>
              </div>
              <div class="postal-address">
                <p><strong>Merci de nous envoyer vos empreintes à l'adresse :</strong></p>
                <p>{{ postalAddress.line1 }}<br/>
                {{ postalAddress.line2 }}<br/>
                {{ postalAddress.line3 }}</p>
              </div>
            </div>
            <!-- Empreintes STL internes -->
            <div class="imprint-file-container" v-if="imprint.imprintType === ImprintType.INTERNAL_SCAN">
              <h3>Ajoutez vos empreintes ci-dessous :</h3>
              <p>Vous pouvez nous envoyer jusqu’à 4 fichiers STL que nous analyserons.</p>
              <app-upload @input="imprint.files.push(...$event)" accept=".stl" multiple compress/>
              <div v-if="imprint.files.length" class="files-container">
                Fichiers ajoutés :
                <app-file v-for="(file, index) in imprint.files" :key="file.filename" :file="file" @click:delete="imprint.files.splice(index, 1)"/>
              </div>
            </div>
          </template>
        </div>

        <!-- Étape Instructions (dents spéciales + requirement) -->
        <div v-else-if="activeStepId === 'instructions'" class="step-content instructions-step-content">
          <h2>Instructions de traitement</h2>
          <div class="container-card radio-card">
            <div class="grid-x align-justify align-middle">
              <strong class="cell auto">J'accepte les versions dentaires :</strong>
              <div class="cell shrink">
                <app-radio v-model="isDentalVersionAllowed" value="true">Oui</app-radio>
                <app-radio v-model="isDentalVersionAllowed" value="false">Non</app-radio>
              </div>
            </div>
            <div class="grid-x align-justify align-middle">
              <strong class="cell auto">J'accepte les strippings :</strong>
              <div class="cell shrink">
                <app-radio v-model="isStrippingAllowed" value="true">Oui</app-radio>
                <app-radio v-model="isStrippingAllowed" value="false">Non</app-radio>
              </div>
            </div>
          </div>
          <div class="special-teeth-container container-card">
            <p>
              <strong>Dents spéciales du patient</strong><br/>
              Cliquez sur la dent et définissez le type de dent
            </p>
            <Teeth v-model="specialTeeth"/>
          </div>
          <div class="requirement-container container-card">
            <p>
              <strong>Avez-vous des précisions à nous faire ?</strong><br/>
              (Attentes, prescription médicale, doutes éventuels sur la faisabilité du traitement et raisons, classe orthodontique...)
            </p>
            <app-input type="textarea" v-model="requirement" placeholder="Description libre"/>
            <div class="grid-x align-middle appointment-date-container">
              <strong class="cell auto">Date de rendez-vous patient :</strong>
              <div class="cell shrink">
                <app-datepicker v-model="appointmentDate" :disabledDates="{ to: new Date($dayjs(suggestedAppointmentDate).subtract(1, 'd')) }" :openDate="appointmentDate ?  appointmentDate : new Date($dayjs(suggestedAppointmentDate).subtract(1, 'd'))"/>
              </div>
            </div>
            <div class="suggestion-container">
              <h4>Date de rdv conseillé : {{ suggestedAppointmentDate | formatDay }}</h4>
              <h6>sous réserve de la validation sous 24h de la proposition de traitement soumise sur votre interface</h6>
            </div>
          </div>
        </div>

        <!-- Étape: Paiement acompte -->
        <div v-else-if="activeStepId === 'payment'" class="step-content deposit-step-content">
          <div class="section-title" v-if="!isExtendingTreatment">
            <h2>Paiement acompte</h2>
            <p><strong>Pour recevoir notre plan de traitement, un acompte de {{ depositPrice }} vous est demandé. Ce montant sera réduit de votre facture finale.</strong></p>
          </div>
          <div class="section-title" v-else>
            <h2>Paiement</h2>
          </div>
          <div class="important">
            <strong>IMPORTANT</strong>
            <ul>
              <li>Pour les règlements par virement ou chèque, merci de bien renseigner systématiquement le numéro de facture et de dossier correspondant.</li>
              <li>Merci à nos clients DPI de bien attendre la facture de fin de mois habituelle pour effectuer votre règlement.</li>
            </ul>
          </div>
          <div class="radio-container">
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.TRANSFER" :title="PaymentMethodLabel.TRANSFER">
              <img src="~@/assets/img/illustrations/payment-method-transfer.png" />
            </app-radio>
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.CHEQUE" :title="PaymentMethodLabel.CHEQUE">
              <img src="~@/assets/img/illustrations/payment-method-cheque.png" />
            </app-radio>
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.CARD" :title="PaymentMethodLabel.CARD">
              <img src="~@/assets/img/illustrations/payment-method-card.png" />
            </app-radio>
          </div>
          <div class="container-card" v-if="paymentMethod === PaymentMethod.TRANSFER">
            <h3>Coordonnées bancaires</h3>
            <div class="inset">
              <p><strong>Merci d'effectuer le virement sur le compte suivant :</strong></p>
              <p>
                {{ ribInfo.line1 }}<br/>
                {{ ribInfo.line2 }}<br/>
                {{ ribInfo.line3 }}
              </p>
            </div>
          </div>
          <div class="container-card" v-else-if="paymentMethod === PaymentMethod.CHEQUE">
            <h3>Adresse d'envoi</h3>
            <div class="inset">
              <p><strong>Merci de nous envoyer votre chèque à l'adresse suivante :</strong></p>
              <p>
                {{ postalAddress.line1 }}<br/>
                {{ postalAddress.line2 }}<br/>
                {{ postalAddress.line3 }}
              </p>
            </div>
          </div>
        </div>

        <!-- Étape Récapitulatif -->
        <div v-else-if="activeStepId === 'recap'" class="step-content recap-step-content">
          <h2>Récapitulatif</h2>
          <div class="container-card">
            <div class="row">
              <span>Zone de traitement :</span>
              <span>{{ zoneCompleteLabel }}</span>
            </div>
            <div class="row">
              <span>Empreintes :</span>
              <span>{{ imprintCompleteLabel }}</span>
            </div>
            <div class="row">
              <span>Instructions :</span>
              <span>
                Versions dentaires - {{ isDentalVersionAllowed === 'true' ? 'OUI' : 'NON' }}<br/>
                Strippings - {{ isStrippingAllowed === 'true' ? 'OUI' : 'NON' }}
              </span>
            </div>
            <div class="row">
              <span>Prescription :</span>
              <span>{{ requirement }}</span>
            </div>
            <div class="row">
              <span>Date de rdv :</span>
              <span>{{ appointmentDate | formatDay }}</span>
            </div>
            <div class="row special-teeth-row">
              <span>Dents spéciales :</span>
              <Teeth readonly :specialTeeth="specialTeeth" />
            </div>
            <div class="row" v-if="!isUserDpi">
              <span v-if="!isExtendingTreatment">Acompte :</span>
              <span v-else>Type de règlement :</span>
              <span>{{ PaymentMethodLabel[paymentMethod] }}</span>
            </div>
          </div>
          <h3>Vos informations sont-elles correctes ?</h3>
          <h6>Vous pouvez encore les modifier en cliquant sur le bouton “précédent” pour revenir en arrière.</h6>
        </div>
      </template>
    </div>

    <!-- Footer commun : Précédent/Valider -->
    <div class="step-footer" v-if="activeStepIndex != null && activeStepIndex < steps.length">
      <app-button look="secondary" @click="activeStepIndex === 0 ? activeStepIndex = null : activeStepIndex-= 1" :disabled="isLoadingRequest">Précédent</app-button>
      <app-button v-if="activeStepId === 'recap'" @click="showValidationModal = true" :loading="isLoadingRequest" :disabled="isLoadingRequest">Valider ma demande de plan de traitement</app-button>
      <app-button v-else @click="activeStepIndex += 1" :disabled="isNextStepDisabled">Valider<SvgArrowLeft class="following"/></app-button>
    </div>

    <!-- Modale de validation -->
    <app-modal :show="showValidationModal" class="validation-modal" title="Valider plan de traitement" isAction @update:show="showValidationModal = $event">
      <template v-slot:modal-content>
        <form id="request-form" @submit.prevent="requestTreatmentPlan">
          <p>Attention ! Toute validation du plan de traitement vaudra commande de celui-ci. Toute demande de modification après l'envoi de celui-ci par nos services, concernant la nature du plan (maxillaire, mandibulaire, complet) et nécessitant la création d'un nouveau plan de traitement sera facturé {{ depositPrice }}. <strong>Les corrections restent gratuites.</strong></p>
          <p>Rappel : le plan de traitement a une validité de 3 mois. Nous contacter pour toute question</p>
          <div class="checkbox-container">
            <app-checkbox v-model="validationCheckbox" value="data-exact">Je valide l’exactitude des informations et je valide mon plan de traitement</app-checkbox>
            <app-checkbox v-model="validationCheckbox" value="cgv-accepted"><span>J’accepte les <a href="/assets/documents/cgv-2022.pdf" target="_blank">Conditions Générales de Ventes</a> de la plateforme Ealigner</span></app-checkbox>
          </div>
        </form>
      </template>
      <template v-slot:modal-footer>
        <app-button look="secondary" @click="showValidationModal = false" :disabled="isLoadingRequest">Annuler</app-button>
        <app-button type="submit" form="request-form" :disabled="!validationCheckbox.includes('data-exact') || !validationCheckbox.includes('cgv-accepted')" :loading="isLoadingRequest">Je valide ma demande</app-button>
      </template>
    </app-modal>

  </div>
</template>

<script>
// Services
import treatmentApi from '@/services/api/treatment';
import utils from '@/services/utils/utils';
import auth from '@/services/auth';

// SVG
import SvgCross from '@/assets/img/icons/cross.svg';
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';

// illustrations
import ObstructionIllustration from '@/assets/img/illustrations/pathology-obstruction.png';
import DiastemaIllustration from '@/assets/img/illustrations/pathology-diastema.png';
import RotationIllustration from '@/assets/img/illustrations/pathology-rotation.png';
import VersionIllustration from '@/assets/img/illustrations/pathology-version.png';
import OverhangIllustration from '@/assets/img/illustrations/pathology-overhang.png';
import RelapseIllustration from '@/assets/img/illustrations/pathology-relapse.png';

// Enums
import TreatmentZone from '@/services/enums/treatment-zone.enum';
import TreatmentZoneLabel from '@/services/enums/treatment-zone-label.enum';
import ImprintType from '@/services/enums/imprint-type.enum';
import ImprintTypeLabel from '@/services/enums/imprint-type-label.enum';
import ImprintMaterialTypeLabel from '@/services/enums/imprint-material-type-label.enum';
import TreatmentStep from '@/services/enums/treatment-step.enum';
import PathologyLabel from '@/services/enums/pathology-label.enum';
import PaymentMethod from '@/services/enums/payment-method.enum';
import PaymentMethodLabel from '@/services/enums/payment-method-label.enum';
import TreatmentType from '@/services/enums/treatment-type.enum';

// Composants
import Teeth from '@/components/Teeth.vue';
import TunnelSteps from './TunnelSteps.vue';

export default {
  name: 'treatment-plan-request-tunnel',
  components: {
    SvgCross,
    SvgArrowLeft,
    Teeth,
    TunnelSteps,
  },
  data() {
    return {
      /** Données dynamiques */
      // Données du traitement
      zone: null,
      pathologies: {
        isObstruction: false,
        isDiastema: false,
        isRotation: false,
        isVersion: false,
        isOverhang: false,
        isRelapse: false,
      },
      imprint: {
        imprintType: null,
        materialType: null,
        files: [],
      },
      isStrippingAllowed: null,
      isDentalVersionAllowed: null,
      specialTeeth: {},
      requirement: null,
      appointmentDate: null,
      paymentMethod: null,
      validationCheckbox: [],
      // Données autre
      activeStepIndex: null,
      showValidationModal: false,
      isLoadingRequest: false,
      isLoadingTreatment: false,
      isUserDpi: auth.isDpi(),
      treatment: {}, // traitement à prolonger, ou traitement actuel. Traitement demandé après réussite de la requête

      /** Données fixes */
      TreatmentZone,
      TreatmentZoneLabel,
      ImprintType,
      ImprintTypeLabel,
      ImprintMaterialTypeLabel,
      PathologyLabel,
      PaymentMethod,
      PaymentMethodLabel,
      postalAddress: {
        line1: process.env.VUE_APP_ADDRESS_LINE_1,
        line2: process.env.VUE_APP_ADDRESS_LINE_2,
        line3: process.env.VUE_APP_ADDRESS_LINE_3,
      },
      ribInfo: {
        line1: process.env.VUE_APP_RIB_LINE_1,
        line2: process.env.VUE_APP_RIB_LINE_2,
        line3: process.env.VUE_APP_RIB_LINE_3,
      },
      depositPrice: process.env.VUE_APP_DEPOSIT_PRICE,
      pathologyOptions: [
        { key: 'isObstruction', src: ObstructionIllustration },
        { key: 'isDiastema', src: DiastemaIllustration },
        { key: 'isRotation', src: RotationIllustration },
        { key: 'isVersion', src: VersionIllustration },
        { key: 'isOverhang', src: OverhangIllustration },
        { key: 'isRelapse', src: RelapseIllustration },
      ],
      isExtendingTreatment: this.$route.name === 'treatment-extend',
      suggestedAppointmentDate: this.$dayjs().add(1, 'M'),
    };
  },
  computed: {
    // Etapes à afficher
    steps() {
      const steps = [
        { id: 'zone', title: 'Zone de traitement', data: TreatmentZoneLabel[this.zone] },
        { id: 'imprint', title: 'Empreintes', data: this.imprintCompleteLabel },
        { id: 'instructions', title: 'Instructions' },
        { id: 'payment', title: this.isExtendingTreatment ? 'Paiement' : 'Paiement acompte' },
        { id: 'recap', title: 'Recapitulatif' },
      ];
      if (this.isUserDpi) {
        steps.splice(3, 1);
      }
      return steps;
    },
    activeStepId() {
      if (this.activeStepIndex < 0) return null;
      return this.steps[this.activeStepIndex].id;
    },
    // Bouton 'Suivant' disabled (étape actuelle remplie correctement)
    isNextStepDisabled() {
      switch (this.activeStepId) {
        case 'zone':
          return !this.zone || !Object.values(this.pathologies).some((pat) => pat);
        case 'imprint': // Étape Empreinte
          if (this.isImprintSaved) return false;
          switch (this.imprint.imprintType) {
            case ImprintType.MATERIAL:
              return !this.imprint.materialType;
            case ImprintType.INTERNAL_SCAN:
              return this.imprint.files.length < 1;
            case ImprintType.EXTERNAL_SCAN:
              return false;
            default:
              return true;
          }
        case 'instructions': // Étape Instructions
          return !this.appointmentDate;
        case 'payment': // Étape Paiement d'acompte
          return !this.paymentMethod;
        default:
          return false;
      }
    },
    // Empreintes déjà ajoutées à la pré-étude
    isImprintSaved() {
      return !this.isExtendingTreatment && this.treatment.imprints.some((imp) => imp.imprintType !== ImprintType.PICTURES);
    },
    // Type d'empreinte + matériel
    imprintCompleteLabel() {
      return utils.imprintCompleteLabel(this.imprint);
    },
    // Zone + Liste des pathologies
    zoneCompleteLabel() {
      const pathologiesList = Object.entries(this.pathologies)
        .filter(([, value]) => !!value)
        .map(([key]) => PathologyLabel[key]);
      const list = [TreatmentZoneLabel[this.zone]].concat(pathologiesList);
      return list.join(', ');
    },
  },
  created() {
    this.getTreatment();
  },
  methods: {
    // Initialiser les réponses avec la pré-étude
    initiateDataFromEstimate() {
      this.zone = this.treatment.zone;
      this.specialTeeth = this.treatment.specialTeeth || {};
      if (!this.isExtendingTreatment) {
        this.requirement = this.treatment.requirement;
      }
    },

    // REQUÊTE API : Demande de plan de traitement ou prolongement de traitement
    async requestTreatmentPlan() {
      // Construction du body
      const body = new FormData();
      body.append('step', TreatmentStep.TREATMENT_REQUEST);
      body.append('zone', this.zone);
      body.append('requirement', this.requirement || '');
      body.append('treatmentType', TreatmentType.GUTTER);
      Object.entries(this.specialTeeth).forEach(([key, value]) => {
        body.append(`specialTeeth[${key}]`, value);
      });
      if (!this.isUserDpi) body.append('paymentMethod', this.paymentMethod);
      Object.entries(this.pathologies).forEach(([key, value]) => {
        body.append(`pathologies[${key}]`, value);
      });
      if (this.isDentalVersionAllowed === 'true') {
        body.append('isDentalVersionAllowed', this.isDentalVersionAllowed);
      }
      if (this.isStrippingAllowed === 'true') {
        body.append('isStrippingAllowed', this.isStrippingAllowed);
      }
      body.append('appointmentDate', (new Date(this.appointmentDate)).toISOString());

      if (this.isExtendingTreatment) {
        // Si prolongement de traitement, on ajoute le patient et les empreintes dans un array
        body.append('patientId', this.treatment.patient.patientId);
        body.append('imprints[0][imprintType]', this.imprint.imprintType);
        if (this.imprint.imprintType === ImprintType.MATERIAL) {
          body.append('imprints[0][materialType]', this.imprint.materialType);
        }
        if (this.imprint.imprintType === ImprintType.INTERNAL_SCAN) {
          this.imprint.files.forEach((file) => body.append('scans', file));
        }
        if (this.requirement) {
          body.append('message', this.requirement);
        }
      } else if (!this.isImprintSaved) {
        body.append('imprint[imprintType]', this.imprint.imprintType);
        if (this.imprint.imprintType === ImprintType.MATERIAL) {
          body.append('imprint[materialType]', this.imprint.materialType);
        }
        if (this.imprint.imprintType === ImprintType.INTERNAL_SCAN) {
          this.imprint.files.forEach((file) => body.append('scans', file));
        }
      }

      // Requête API
      this.isLoadingRequest = true;
      try {
        const response = this.isExtendingTreatment ? await treatmentApi.createTreatment(body) : await treatmentApi.requestTreatmentPlan(this.$route.params.treatmentId, body);
        this.treatment = response.data;
        this.activeStepIndex += 1;
      } catch (e) {
        this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue durant votre demande de traitement. Veuillez réessayer plus tard.' });
      }
      this.isLoadingRequest = false;
      this.showValidationModal = false;
    },

    // REQUÊTE API : Récupérer le traitement (actuel ou le prolongé)
    async getTreatment() {
      this.isLoadingTreatment = true;
      try {
        const response = await treatmentApi.getTreatment(this.$route.params.treatmentId);
        this.treatment = await response.data;
        this.initiateDataFromEstimate();
      } catch (e) {
        let text = 'Une erreur inattendue est survenue durant votre demande de traitement. Veuillez rééssayer plus tard.';
        if (e.response.data.message.startsWith('S3 Error')) {
          text = 'Un problème est survenu en ajoutant un fichier. Cela peut être du à un nom de fichier non conforme. Évitez les accents, les caractères spéciaux (sauf - et _) et les espaces.';
        }
        this.$message.show({ title: 'Erreur', text });
      }
      this.isLoadingTreatment = false;
    },
  },
};
</script>

<style lang="sass">
.treatment-plan-request-tunnel
  @include request-tunnel

  .tunnel-content-container
    @include tunnel-content-container

    .success-content
      @include success-content

    .introduction-content
      @include introduction-content

    .zone-step-content
      h3
        text-align: left
      .pathologies-card-container
        display: flex
        gap: 24px
        flex-wrap: wrap
        max-width: 600px
        justify-content: center
        margin: auto
        .pathology-card
          @include container-card
          width: 160px
          cursor: pointer
          border: 1px solid $white
          img
            width: 80px
          &:hover
            border-color: $color-primary-50
          &.active
            border-color: $color-primary-100

    .imprint-step-content
      @include imprint-step-content

    .instructions-step-content
      .container-card
        @include container-card
        text-align: left
        margin: 24px 0
        p
          @include h6
          color: $color-gray-70
        strong
          @include text-bold
          color: $color-gray-90
        .app-input
          max-width: none
      .radio-card
        display: flex
        flex-direction: column
        gap: 16px
        .cell:last-child
          display: flex
          gap: 16px
      .appointment-date-container
        margin: 16px 0
        .vdp-datepicker__calendar
          top: 0
          transform: translateY(-100%)
      .suggestion-container
        width: 100%
        background-color: $color-primary-10
        text-align: center
        padding: 12px 40px
        h4
          margin: 0
          color: $main-color
        h6
          margin: 0 auto
          color: $color-gray-70
          max-width: 400px

    .deposit-step-content
      @include payment-step-content
      max-width: 800px
      display: flex
      flex-direction: column
      align-items: center
      .section-title
        padding-bottom: 20px
      .important
        background: $color-secondary-20
        padding: 16px
        border: 1px solid $color-secondary-100
        text-align: left
        max-width: 532px
        border-radius: 8px
        ul
          padding-left: 16px
          margin: 0
          li
            margin-top: 8px
    .recap-step-content
      @include recap-step-content

  .validation-modal .modal-wrapper
    .modal-content
      .checkbox-container
        text-align: left
        color: $color-gray-90
        margin-top: 40px
        .app-checkbox
          margin: 8px 0
          &:first-child label
            @include h5
</style>
