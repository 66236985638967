export default Object.freeze({
  ORDER_INVOICE: 'Facture',
  DEPOSIT_INVOICE: 'Facture d\'acompte',
  ESTIMATE: 'Pré-étude',
  TREATMENT_PLAN: 'Plan de traitement',
  DMSM: 'DMSM',
  CONFORMITY: 'Déclaration de conformité',
  LABELS: 'Étiquettes',
  TECHNICAL_DOCUMENT: 'Document technique',
  // IMPRINT_3D est volontairement ignoré des labels car il est traité différement des autres documents
});
