<template>
  <section class="contention-request-tunnel">
    <!-- Header : toujours là -->
    <tunnel-steps :steps="steps" :activeIndex="activeStepIndex"/>
    <router-link :to="{ name: 'treatment-list' }" v-if="activeStepIndex < steps.length"><SvgCross />Annuler demande</router-link>

    <!-- Ecran de succès -->
    <div class="tunnel-content-container">
      <template  v-if="activeStepIndex >= steps.length">
        <div class="step-content success-content">
          <img src="~@/assets/img/illustrations/request-validated.png" />
          <h4>Référence dossier : {{ treatment.reference }}</h4>
          <h2>Votre demande a bien été prise en compte</h2>
          <p>Vous serez avertis par mail dès que votre commande sera prête et expédiée.<br/>
            Merci pour votre confiance !</p>
        </div>
        <div class="step-footer">
          <app-button @click="$router.push({ name: 'treatment-list' })">Retour à mon espace</app-button>
        </div>
      </template>

      <template v-else>
        <!-- Header commun à toutes les étapes -->
        <div class="step-common-header">
          <img src="~@/assets/img/illustrations/contention.png" />
          <h5>Demande de contention</h5>
        </div>

        <!-- Étape Patient -->
        <div v-if="activeStepId === 'patient'" class="step-content patient-step-content ">
          <h2>Patient</h2>
          <p>Choisissez le patient à associer au traitement</p>
          <div>
            <app-input :value="patient ? patient.reference : null" placeholder="Référence patient" disabled />
            <app-button @click="showPatientSelectModal = true">Choisir patient</app-button>
          </div>
          <app-modal :show="showPatientSelectModal" title="Choisir un patient" @update:show="showPatientSelectModal = $event">
            <template v-slot:modal-content>
              <PersonSelect @select="patient = $event; showPatientSelectModal = false" person="patient" />
            </template>
          </app-modal>
        </div>

        <!-- Étape Zone de traitement -->
        <div v-else-if="activeStepId === 'treatment-zone'" class="step-content zone-step-content">
          <h2>Zone de traitement</h2>
          <div class="radio-container">
            <app-radio look="card" v-model="zone" :value="TreatmentZone.MAXIMILLAIRE" :title="TreatmentZoneLabel.MAXIMILLAIRE">
              <img src="~@/assets/img/illustrations/zone-maximillaire.png" alt=""/>
            </app-radio>
            <app-radio look="card" v-model="zone" :value="TreatmentZone.MANDIBULAIRE" :title="TreatmentZoneLabel.MANDIBULAIRE">
              <img src="~@/assets/img/illustrations/zone-mandibulaire.png" alt=""/>
            </app-radio>
            <app-radio look="card" v-model="zone" :value="TreatmentZone.COMPLETE" :title="TreatmentZoneLabel.COMPLETE">
              <img src="~@/assets/img/illustrations/zone-complete.png" alt=""/>
            </app-radio>
          </div>
        </div>

        <!-- Étape Clé de collage -->
        <div v-else-if="activeStepId === 'sticking-patch'" class="step-content sticking-patch-content">
          <h2>Clé de collage</h2>
          <div class="radio-container">
            <app-radio look="card" v-model="isStickingPatchIncluded" value="true" title="Avec clé de collage">
              <img src="~@/assets/img/illustrations/sticking-patch-included.png" alt=""/>
            </app-radio>
            <app-radio look="card" v-model="isStickingPatchIncluded" value="false" title="Sans clé de collage">
              <img src="~@/assets/img/illustrations/sticking-patch-excluded.png" alt=""/>
            </app-radio>
          </div>
        </div>

        <!-- Étape Empreintes -->
        <div v-else-if="activeStepId === 'imprints'" class="step-content imprint-step-content">
          <h2>Avez-vous des empreintes à nous envoyer ?</h2>
          <div class="radio-container">
            <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.MATERIAL" :title="ImprintTypeLabel.MATERIAL">
              <img src="~@/assets/img/illustrations/imprint-material.png" />
            </app-radio>
            <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.INTERNAL_SCAN" :title="ImprintTypeLabel.INTERNAL_SCAN" subtitle="envoi immédiat des fichiers">
              <img src="~@/assets/img/illustrations/imprint-internal-scan.png" />
            </app-radio>
            <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.EXTERNAL_SCAN" :title="ImprintTypeLabel.EXTERNAL_SCAN" subtitle="(via plateforme externe : CS Connect, MEDIT Link, 3Shape...)">
              <img src="~@/assets/img/illustrations/imprint-external-scan.png" />
            </app-radio>
            <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.OLD_TREATMENT" :title="ImprintTypeLabel.OLD_TREATMENT" subtitle="(Contention conçue sur le dernier traitement porté)">
              <img src="~@/assets/img/illustrations/imprint-treatments.png" />
            </app-radio>
          </div>
          <!-- Empreintes physiques -->
          <div class="material-type-container" v-if="imprint.imprintType === ImprintType.MATERIAL">
            <h3>Type d'empreintes :</h3>
            <p>Silicone à privilégier pour une meilleure tenue dans le temps</p>
            <div class="radio-container">
              <app-radio v-for="[key, label] in Object.entries(ImprintMaterialTypeLabel)" :key="key" v-model="imprint.materialType" :value="key">{{ label }}</app-radio>
            </div>
            <div class="postal-address">
              <p><strong>Merci de nous envoyer vos empreintes à l'adresse :</strong></p>
              <p>{{ postalAddress.line1 }}<br/>
              {{ postalAddress.line2 }}<br/>
              {{ postalAddress.line3 }}</p>
            </div>
          </div>
          <!-- Empreintes STL internes -->
          <div class="imprint-file-container" v-if="imprint.imprintType === ImprintType.INTERNAL_SCAN">
            <h3>Ajoutez vos empreintes ci-dessous :</h3>
            <p>Vous pouvez nous envoyer jusqu’à 4 fichiers STL que nous analyserons.</p>
            <app-upload @input="imprint.files.push(...$event)" accept=".stl" multiple compress/>
            <div v-if="imprint.files.length" class="files-container">
              Fichiers ajoutés :
              <app-file v-for="(file, index) in imprint.files" :key="file.filename" :file="file" @click:delete="imprint.files.splice(index, 1)"/>
            </div>
          </div>
        </div>

        <!-- Étape Commande -->
        <div v-else-if="activeStepId === 'order'" class="step-content order-step-content">
          <h2>Détails de votre commande</h2>
          <div class="container-card">
            <strong>Merci de nous indiquer le nombre de gouttières souhaité ci-dessous</strong>
            <p>Si besoin, vous pouvez ajouter des détails à la suite du nombre de gouttières dans le champs ci-dessous.<br><span>Pour des empreintes d'un précédent traitement, merci de préciser en commentaire le numéro de traitement.</span></p>
            <app-input type="textarea" v-model="message" placeholder="Indiquer le nombre de gouttières, exemple : 2 Gouttières ou 2. Numéro de traitement pour les empreintes, exemple : EXBMAT061-T111" />
          </div>
        </div>

        <!-- Étape: Paiement acompte -->
        <div v-else-if="activeStepId === 'payment'" class="step-content payment-step-content">
          <h2>Paiement</h2>
          <p>
            Pour les règlements par virement ou chèque, merci de bien renseigner systématiquement le numéro de facture et de dossier correspondant.
          </p>
          <div class="radio-container">
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.TRANSFER" :title="PaymentMethodLabel.TRANSFER">
              <img src="~@/assets/img/illustrations/payment-method-transfer.png" />
            </app-radio>
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.CHEQUE" :title="PaymentMethodLabel.CHEQUE">
              <img src="~@/assets/img/illustrations/payment-method-cheque.png" />
            </app-radio>
            <app-radio look="card" v-model="paymentMethod" :value="PaymentMethod.CARD" :title="PaymentMethodLabel.CARD">
              <img src="~@/assets/img/illustrations/payment-method-card.png" />
            </app-radio>
          </div>
          <div class="container-card" v-if="paymentMethod === PaymentMethod.TRANSFER">
            <h3>Coordonnées bancaires</h3>
            <div class="inset">
              <p><strong>Merci d'effectuer le virement sur le compte suivant :</strong></p>
              <p>
                {{ ribInfo.line1 }}<br/>
                {{ ribInfo.line2 }}<br/>
                {{ ribInfo.line3 }}
              </p>
            </div>
          </div>
          <div class="container-card" v-else-if="paymentMethod === PaymentMethod.CHEQUE">
            <h3>Adresse d'envoi</h3>
            <div class="inset">
              <p><strong>Merci de nous envoyer votre chèque à l'adresse suivante :</strong></p>
              <p>
                {{ postalAddress.line1 }}<br/>
                {{ postalAddress.line2 }}<br/>
                {{ postalAddress.line3 }}
              </p>
            </div>
          </div>
        </div>

        <!-- Étape Récapitulatif -->
        <div v-else-if="activeStepId === 'recap'" class="step-content recap-step-content">
          <h2>Récapitulatif</h2>
          <div class="container-card">
            <div class="row">
              <span>Patient :</span>
              <span>{{ patient.reference }}</span>
            </div>
            <div class="row">
              <span>Zone de traitement :</span>
              <span>{{ TreatmentZoneLabel[zone] }}</span>
            </div>
            <div class="row" v-if="this.isFixedContention">
              <span>Clé de collage :</span>
              <span>{{ isStickingPatchIncluded === 'true' ? 'Avec' : 'Sans' }} clé de collage</span>
            </div>
            <div class="row">
              <span>Empreintes :</span>
              <span>{{ imprintCompleteLabel }}</span>
            </div>
            <div class="row" v-if="imprint.imprintType === ImprintType.MATERIAL">
              <span>Adresse d'envoi</span>
              <span>
                {{ postalAddress.line1 }}<br/>
                {{ postalAddress.line2 }}<br/>
                {{ postalAddress.line3 }}
              </span>
            </div>
            <div class="row">
              <span>Précisions :</span>
              <span>{{ message }}</span>
            </div>
            <div class="row" v-if="!isUserDpi">
              <span>Paiement facture :</span>
              <span>{{ PaymentMethodLabel[paymentMethod] }}</span>
            </div>
          </div>
          <h3>Vos informations sont-elles correctes ?</h3>
          <p>Vous pouvez encore les modifier en cliquant sur le bouton “précédent” pour revenir en arrière.</p>
        </div>

        <!-- Footer commun : Précédent/Valider -->
        <div class="step-footer">
          <app-button look="secondary" @click="activeStepIndex === 0 ? activeStepIndex = null : activeStepIndex-= 1" :disabled="isLoadingRequest">Précédent</app-button>
          <app-button v-if="activeStepId === 'recap'" @click="requestContention" :loading="isLoadingRequest" :disabled="isLoadingRequest">Valider ma demande de contention</app-button>
          <app-button v-else @click="activeStepIndex += 1" :disabled="isNextStepDisabled">Valider<SvgArrowLeft class="following"/></app-button>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import treatmentApi from '@/services/api/treatment';
import utils from '@/services/utils/utils';
import auth from '@/services/auth';

import SvgCross from '@/assets/img/icons/cross.svg';
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';

import TreatmentZone from '@/services/enums/treatment-zone.enum';
import TreatmentZoneLabel from '@/services/enums/treatment-zone-label.enum';
import ImprintType from '@/services/enums/imprint-type.enum';
import ImprintTypeLabel from '@/services/enums/imprint-type-label.enum';
import ImprintMaterialTypeLabel from '@/services/enums/imprint-material-type-label.enum';
import PaymentMethod from '@/services/enums/payment-method.enum';
import PaymentMethodLabel from '@/services/enums/payment-method-label.enum';
import TreatmentStep from '@/services/enums/treatment-step.enum';
import TreatmentType from '@/services/enums/treatment-type.enum';

import PersonSelect from '@/components/PersonSelect.vue';
import TunnelSteps from './TunnelSteps.vue';

export default {
  name: 'contention-request-tunnel',

  components: {
    SvgCross,
    SvgArrowLeft,
    PersonSelect,
    TunnelSteps,
  },

  mounted() {
    if (this.$route.params.patient) {
      this.patient = this.$route.params.patient;
    }
  },

  data() {
    return {
      activeStepIndex: 0,
      treatmentType: this.$route.name === 'treatment-new-fixed-contentions' ? TreatmentType.FIXED_CONTENTION : TreatmentType.REMOVABLE_CONTENTION,
      patient: null,
      zone: null,
      isStickingPatchIncluded: null,
      imprint: {
        imprintType: null,
        materialType: null,
        files: [],
      },
      message: null,
      paymentMethod: null,
      showPatientSelectModal: false,
      isLoadingRequest: false,
      treatment: null,

      isFixedContention: this.$route.name === 'treatment-new-fixed-contentions',
      isUserDpi: auth.isDpi(),

      TreatmentZone,
      TreatmentZoneLabel,
      ImprintType,
      ImprintTypeLabel,
      ImprintMaterialTypeLabel,
      PaymentMethod,
      PaymentMethodLabel,
      postalAddress: {
        line1: process.env.VUE_APP_ADDRESS_LINE_1,
        line2: process.env.VUE_APP_ADDRESS_LINE_2,
        line3: process.env.VUE_APP_ADDRESS_LINE_3,
      },
      ribInfo: {
        line1: process.env.VUE_APP_RIB_LINE_1,
        line2: process.env.VUE_APP_RIB_LINE_2,
        line3: process.env.VUE_APP_RIB_LINE_3,
      },
    };
  },
  computed: {
    // Etapes à afficher
    steps() {
      const steps = [
        { id: 'patient', title: 'Patient', data: this.patient ? this.patient.reference : '' },
        { id: 'treatment-zone', title: 'Zone de traitement', data: TreatmentZoneLabel[this.zone] },
        { id: 'sticking-patch', title: 'Clé de collage', data: this.isStickingPatchIncluded === 'true' ? 'Avec' : 'Sans' },
        { id: 'imprints', title: 'Empreintes', data: this.imprintCompleteLabel },
        { id: 'order', title: 'Commande' },
        { id: 'payment', title: 'Paiement', data: PaymentMethodLabel[this.paymentMethod] },
        { id: 'recap', title: 'Recapitulatif' },
      ];
      if (this.isUserDpi) {
        steps.splice(5, 1);
      }
      if (!this.isFixedContention) {
        steps.splice(2, 1);
      }
      return steps;
    },
    activeStepId() {
      if (this.activeStepIndex < 0) return null;
      return this.steps[this.activeStepIndex].id;
    },
    // Bouton 'Suivant' disabled (étape actuelle remplie correctement)
    isNextStepDisabled() {
      switch (this.activeStepId) {
        case 'patient': // Etape patient
          return !this.patient;
        case 'treatment-zone': // Etape Zone
          return !this.zone;
        case 'sticking-patch': // Etape clé de collage
          return !this.isStickingPatchIncluded;
        case 'imprints': // Etape Empreintes
          switch (this.imprint.imprintType) {
            case ImprintType.MATERIAL:
              return !this.imprint.materialType;
            case ImprintType.INTERNAL_SCAN:
              return this.imprint.files.length < 1;
            case ImprintType.EXTERNAL_SCAN:
              return false;
            case ImprintType.OLD_TREATMENT:
              return false;
            case 'MISSING':
              return false;
            default:
              return true;
          }
        case 'order': // Etape Commande
          return !this.message;
        case 'payment': // Etape Paiement
          return !this.paymentMethod;
        default:
          return false;
      }
    },
    // Type d'empreinte + matériel
    imprintCompleteLabel() {
      return utils.imprintCompleteLabel(this.imprint);
    },
  },

  methods: {
    // REQUÊTE API : Création du traitement à la pré-étude
    async requestContention() {
      // Construction du body en form data
      const body = new FormData();
      body.append('patientId', this.patient.patientId);
      body.append('zone', this.zone);
      body.append('step', TreatmentStep.CONTENTION_REQUEST);
      body.append('treatmentType', this.isFixedContention ? TreatmentType.FIXED_CONTENTION : TreatmentType.REMOVABLE_CONTENTION);

      if (!this.isUserDpi) body.append('paymentMethod', this.paymentMethod);

      if (this.isFixedContention && this.isStickingPatchIncluded === 'true') {
        body.append('isStickingPatchIncluded', this.isStickingPatchIncluded);
      }

      if (this.message) {
        body.append('message', this.message);
      }

      body.append('imprints[0][imprintType]', this.imprint.imprintType);
      if (this.imprint.imprintType === ImprintType.MATERIAL) {
        body.append('imprints[0][materialType]', this.imprint.materialType);
      }
      if (this.imprint.imprintType === ImprintType.INTERNAL_SCAN) {
        this.imprint.files.forEach((file) => body.append('scans', file));
      }
      // Requête API
      this.isLoadingRequest = true;
      try {
        const response = await treatmentApi.createTreatment(body);
        this.treatment = response.data;
        this.activeStepIndex += 1;
      } catch (e) {
        let text = 'Une erreur inattendue est survenue durant votre demande de traitement. Veuillez réessayer plus tard.';
        if (e.response.data.message.startsWith('S3 Error')) {
          text = 'Un problème est survenu en ajoutant un fichier. Cela peut être du à un nom de fichier non conforme. Évitez les accents, les caractères spéciaux (sauf - et _) et les espaces.';
        }
        this.$message.show({ title: 'Erreur', text });
      }
      this.isLoadingRequest = false;
    },
  },
};
</script>

<style lang="sass">
.contention-request-tunnel
  @include request-tunnel

  .tunnel-content-container
    @include tunnel-content-container

    .patient-step-content
      @include patient-step-content

    .zone-step-content
      max-width: 800px

    .imprint-step-content
      @include imprint-step-content
      .radio-container
        max-width: 700px

    .order-step-content
      .container-card
        @include container-card
        text-align: left
        margin: 24px 0
        p span
          color: $color-primary-100
        .inset
          @include inset
        strong
          @include text-bold
          color: $color-gray-90
        .app-input
          max-width: none

    .payment-step-content
      @include payment-step-content

    .recap-step-content
      @include recap-step-content
      ul
        list-style-position: inside
        padding: 0
        margin: 0

    .success-content
      @include success-content
</style>
