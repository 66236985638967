<template>
  <section class="practitioner-list">
    <!-- HEADER -->
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h1>Praticiens</h1>
      </div>
    </div>
    <!-- FILTERS -->
    <div class="filters-container">
      <app-search v-model="search" placeholder="Chercher par nom ou mail" />
      <div class="select-filters-container">
        <span>Filtrer par :</span>
        <span>
          <app-filter v-model="status" name="Statut" placeholder="Tous" :options="statusOptions">
            <template v-for="opt in statusOptions" v-slot:[opt.name]>
              <span :key="opt.name" :class="opt.name" class="status-tag">{{ opt.label }}</span>
            </template>
          </app-filter>
        </span>
        <span>
          <app-filter v-model="affiliatedCountry" name="Pays" placeholder="Tous" :options="countryOptions">
            <template v-for="opt in countryOptions" v-slot:[opt.name]>
              <span :key="opt.name" :class="opt.name">{{ opt.label }}</span>
            </template>
          </app-filter>
        </span>
        <span>
          <app-filter v-model="affiliation" name="Affiliation" placeholder="Tous" :options="affiliationOptions">
            <template v-for="opt in affiliationOptions" v-slot:[opt.name]>
              <span :key="opt.name" :class="opt.name">{{ opt.label }}</span>
            </template>
          </app-filter>
        </span>
        <span>
          <app-filter v-model="inscriptionDate" name="Date d'inscripiton" placeholder="Tous" :options="inscriptionDateOptions">
            <template v-for="opt in inscriptionDateOptions" v-slot:[opt.name]>
              <span :key="opt.name" :class="opt.name">{{ opt.label }}</span>
            </template>
          </app-filter>
        </span>
      </div>
    </div>
    <!-- TABLE -->
    <app-table
      :headers="tableHeaders"
      :data="practitioners.data"
      :loading="isLoading"
      class="table"
      @select:line="goToPractitioner"
    >
      <template v-slot:name="{ data }">
        <strong>{{ data.firstname }} {{ data.lastname }}</strong>
      </template>
      <template v-slot:officeName="{ data }">
        <p>{{ data.officeName }}</p>
      </template>
      <template v-slot:inscriptionDate="{ data }">
        <p>{{ data.createdAt | formatDay }}</p>
      </template>
      <template v-slot:affiliation="{ data }">
        <p>{{ affiliationTypeLabel[data.affiliation] }}</p>
      </template>
      <template v-slot:status="{ data }">
        <p :class="`${data.status} status-tag`">{{ practitionerStatusLabel[data.status] }}</p>
      </template>
      <template v-slot:btn>
        <SvgArrowheadLeft class="svg-arrowhead-left" />
      </template>
    </app-table>
    <app-pagination
      :count="practitioners.metadata.count"
      :limit="limit"
      :offset="practitioners.metadata.offset"
    />
  </section>
</template>

<script>
// API
import practitioner from '@/services/api/practitioner';

// Utils
import utils from '@/services/utils/utils';

// Enums
import practitionerStatusLabel from '@/services/enums/practitioner-status-label.enum';
import affiliationTypeLabel from '@/services/enums/affiliation-type-label.enum';
import countryAffiliationLabel from '@/services/enums/country-affiliation-label.enum';
import inscriptionDateOrderLabel from '@/services/enums/inscription-date-order-label.enum';

// Assets
import SvgArrowheadLeft from '@/assets/img/icons/arrowhead-left.svg?inline';

export default {
  name: 'practitioner-list',
  data() {
    return {
      isLoading: false,
      practitionerStatusLabel,
      affiliationTypeLabel,
      search: this.$route.query.search,
      affiliation: this.$route.query.affiliation,
      affiliatedCountry: this.$route.query.affiliatedCountry,
      status: this.$route.query.status,
      inscriptionDate: this.$route.query.inscriptionDate,
      statusOptions: utils.optionsFromEnum(practitionerStatusLabel),
      countryOptions: utils.optionsFromEnum(countryAffiliationLabel),
      affiliationOptions: utils.optionsFromEnum(affiliationTypeLabel),
      inscriptionDateOptions: utils.optionsFromEnum(inscriptionDateOrderLabel),
      tableHeaders: [
        { label: 'Praticien', key: 'name', size: 'auto' },
        { label: 'Cabinet', key: 'officeName', size: 'auto' },
        { label: "Date d'inscription", key: 'inscriptionDate', size: 'auto' },
        { label: 'Affiliation', key: 'affiliation', size: 'auto' },
        { label: 'Statut', key: 'status', size: 'auto' },
        { label: '', key: 'btn', size: 'shrink' },
      ],
      practitioners: {
        data: [],
        metadata: {},
      },
      limit: 10,
    };
  },
  components: {
    SvgArrowheadLeft,
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    affiliation() {
      this.updateQuery({ affiliation: this.affiliation, page: 1 });
    },
    affiliatedCountry() {
      this.updateQuery({ affiliatedCountry: this.affiliatedCountry, page: 1 });
    },
    status() {
      this.updateQuery({ status: this.status, page: 1 });
    },
    inscriptionDate() {
      this.updateQuery({ inscriptionDate: this.inscriptionDate, page: 1 });
    },
    $route() {
      if (!this.isLoading) {
        this.getPractitioners();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getPractitioners();
    this.isLoading = false;
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  methods: {
    async getPractitioners() {
      this.isLoading = true;
      const {
        search, affiliation, affiliatedCountry, status, inscriptionDate,
      } = this.$route.query;
      try {
        this.practitioners = await practitioner.getPractitionerList(
          this.limit,
          this.computeOffset,
          affiliation,
          affiliatedCountry,
          status,
          inscriptionDate,
          search,
        );
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des praticiens',
          cancelText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    goToPractitioner(selectedPractitioner) {
      this.$router.push({
        name: 'practitioner',
        params: {
          practitionerId: selectedPractitioner.practitionerId,
        },
      });
    },
    updateQuery(newQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...newQuery,
        },
      });
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.updateQuery({ search: this.search || undefined, page: 1 });
      }
    },
  },
};
</script>

<style lang="sass">
.practitioner-list
  @include screen
  .header
    margin: 28px 0 52px 0
    h1
      margin: 0
    .app-button,
    .app-button-text
      margin-left: 8px
  .table
    @include table
    .table-header .cell:last-child,
    .table-line .cell:last-child
      width: 20px
  .filters-container
    display: flex
    flex-wrap: wrap
    column-gap: 16px
    flex-direction: row
    align-items: center
    margin-bottom: 12px
  .svg-arrowhead-left
    fill: $color-gray-60
  .status-tag
    @include practitioner-status
  .select-filters-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: center
    column-gap: 8px
</style>
