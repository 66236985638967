<!--
****************************************************************************************************
***                                    Pagination                                                ***
****************************************************************************************************

Composant pagination, il suppose agir sur le paramètre query de la page en cours,
mais une prop :route serait tout à fait envisageable aussi.

*************
*** PROPS ***
*************
  - offset :
    nombre d'éléments décalés

  - limit :
    nombre d'éléments par page

  - count :
    nombre d'éléments total

  Exemple :
  <app-pagination :offset="50" :limit="10" :count="300"/>

-->
<template>
  <div class="app-pagination">
    <span>{{ offset + 1 }}-{{ Math.min(count, offset + limit)}} de {{ count }}</span>
    <router-link
      :class="{ disabled: isFirstPage }"
      :to="{ query: { ...$route.query, page: 1 }}" class="first">
        <SvgFirstPage />
    </router-link>

    <router-link
      :class="{ disabled: isFirstPage }"
      :to="{ query: { ...$route.query, page: currentPage - 1 }}" class="previous">
      <SvgPrevious />
    </router-link>

    <router-link
      :class="{ disabled: isLastPage }"
      :to="{ query: { ...$route.query, page: currentPage + 1 }}" class="after">
        <SvgPrevious />
    </router-link>

    <router-link
      :class="{ disabled: isLastPage }"
      :to="{ query: { ...$route.query, page: lastPage }}" class="last">
        <SvgFirstPage />
    </router-link>
  </div>
</template>

<script>
import SvgFirstPage from '@/assets/img/icons/first-page.svg?inline';
import SvgPrevious from '@/assets/img/icons/previous.svg?inline';

export default {
  components: {
    SvgFirstPage,
    SvgPrevious,
  },
  props: {
    offset: {
      type: [Number],
    },
    limit: {
      type: [Number],
    },
    count: {
      type: [Number],
    },
  },
  computed: {
    currentPage() {
      return Math.floor(this.offset / this.limit) + 1;
    },
    lastPage() {
      return Math.floor(Math.max(0, this.count - 1) / this.limit) + 1;
    },
    isLastPage() {
      return this.currentPage >= this.lastPage;
    },
    isFirstPage() {
      return this.currentPage <= 1;
    },
  },
};
</script>

<style lang="sass">
.app-pagination
  display: inline-flex
  margin: 26px 0
  align-items: center

  span
    @include h6
    color: $color-gray-60

  a
    height: 24px
    width: 24px
    background: $color-primary-40
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    margin: 8px

    &.disabled
      pointer-events: none
      opacity: 0.4

    svg
      max-width: unset
      width: 80%

      path
        stroke: $color-primary-100

  .after, .last

    svg
      transform: rotate(180deg)

</style>
