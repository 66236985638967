export default Object.freeze({
  VALIDATE_TREATMENT: 'VALIDATE_TREATMENT',
  REFUSE_TREATMENT: 'REFUSE_TREATMENT',
  VALIDATE_ESTIMATE: 'VALIDATE_ESTIMATE',
  REFUSE_ESTIMATE: 'REFUSE_ESTIMATE',
  SEE_DOCUMENT: 'SEE_DOCUMENT',
  SEE_INVOICE: 'SEE_INVOICE',
  SEE_BANK_DETAILS: 'SEE_BANK_DETAILS',
  GIVE_OPINION: 'GIVE_OPINION',
});
