import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getTreatmentList = async (limit, offset, search, status, step, task, orderByEstimateValidatedAt, orderByAppointmentDate, orderByProductionDate) => {
  try {
    const response = await axios.get(`${API_URL}/treatments`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
        ...(status && { status }),
        ...(step && { step }),
        ...(task && { task }),
        ...(orderByEstimateValidatedAt && { orderByEstimateValidatedAt }),
        ...(orderByAppointmentDate && { orderByAppointmentDate }),
        ...(orderByProductionDate && { orderByProductionDate }),
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTreatment = async (treatmentId) => {
  try {
    const response = await axios.get(`${API_URL}/treatments/${treatmentId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getTreatmentHistory = async (treatmentId) => {
  try {
    const response = await axios.get(`${API_URL}/treatments/${treatmentId}/event-logs`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateTreatment = async (
  treatmentId,
  status,
  step,
  colissimoCode,
  shippedAt,
  appointmentDate,
  productionDate,
  message,
  insideNote,
  tasks,
  isPeacePackage,
  paymentMethod,
) => {
  try {
    const response = await axios.put(`${API_URL}/treatments/${treatmentId}`, {
      ...(status && { status }),
      ...(step && { step }),
      ...(colissimoCode && { colissimoCode }),
      ...(shippedAt && { shippedAt }),
      ...(appointmentDate && { appointmentDate }),
      ...(message && { message }),
      ...(insideNote && { insideNote }),
      ...(tasks && { tasks }),
      ...(productionDate && { productionDate }),
      ...(typeof isPeacePackage === 'boolean' && { isPeacePackage }),
      ...(paymentMethod && { paymentMethod }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createTreatment = async (body) => {
  try {
    const response = await axios.post(`${API_URL}/treatments`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const requestTreatmentPlan = async (treatmentId, body) => {
  try {
    const response = await axios.put(`${API_URL}/treatments/${treatmentId}/treatment-plan`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPaymentIntent = async (treatmentId) => {
  try {
    const response = await axios.get(`${API_URL}/treatments/${treatmentId}/deposit/payment-intent`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUnpaidInvoices = async () => {
  try {
    const response = await axios.get(`${API_URL}/treatments/unpaid-invoices`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};

api.getTreatmentList = getTreatmentList;
api.getTreatment = getTreatment;
api.getTreatmentHistory = getTreatmentHistory;
api.getPaymentIntent = getPaymentIntent;
api.getUnpaidInvoices = getUnpaidInvoices;
api.updateTreatment = updateTreatment;
api.createTreatment = createTreatment;
api.requestTreatmentPlan = requestTreatmentPlan;

export default api;
