<template>
  <section class="treatment-details">
    <div class="grid-x grid-margin-x">
      <div class="cell treatment-details-container" :class="isContentionTreatmentType ? 'medium-6' : 'auto'">
        <h2>Informations traitement</h2>
        <hr>

        <div class="grid-x grid-margin-x content">
          <!-- INFORMATIONS SUR LE TRAITEMENT -->
          <div class="cell" :class="isContentionTreatmentType ? 'auto' : 'medium-6'">
            <ul class="list">
              <!-- Patient -->
              <li>
                <h4>Patient :</h4>
                <router-link :to="{ name: 'patient', params: { patientId: treatment.patient.patientId } }">
                  {{ treatment.patient.reference }}
                </router-link>
              </li>

              <!-- Praticien -->
              <li>
                <h4>Praticien :</h4>
                <router-link :to="{ name: 'practitioner', params: { practitionerId: treatment.practitioner.practitionerId } }">
                  {{ fullName }}
                </router-link>
              </li>

              <!-- Pour les traitements de type gouttières -->
              <template v-if="!isContentionTreatmentType">
                <!-- Zone de traitement -->
                <li v-if="treatment.zone">
                  <h4>Zone de traitement :</h4>
                  <p>{{ treatmentZoneLabel[treatment.zone] }}</p>
                </li>

                <!-- Nature du traitement -->
                <li v-if="treatmentNature">
                  <h4>Nature du traitement :</h4>
                  <p>{{ treatmentNature }}</p>
                </li>

                <!-- Empreintes -->
                <li>
                  <h4>Empreintes :</h4>
                  <p>{{ imprint }}</p>
                </li>

                <!-- Instructions -->
                <li v-if="treatmentInstructionsExists">
                  <h4>Instructions :</h4>
                  <p class="instructions">{{ treatmentInstructions }}</p>
                </li>

                <!-- Prescription -->
                <li v-if="treatment.requirement">
                  <h4>Prescription :</h4>
                  <p>{{ treatment.requirement }}</p>
                </li>

                <!-- Date de rendez-vous -->
                <li v-if="treatment.appointmentDate">
                  <h4>Date de rendez-vous :</h4>
                  <p>{{ treatment.appointmentDate | formatDay }}</p>
                </li>
              </template>

              <!-- Pour les traitements de type contention -->
              <template v-else>
                <!-- Type de traitement -->
                <li>
                  <h4>Type de traitement :</h4>
                  <p>{{ treatmentTypeLabel[treatment.treatmentType] }}</p>
                </li>

                <!-- Empreintes -->
                <li>
                  <h4>Empreintes :</h4>
                  <p>{{ imprint }}</p>
                </li>

                <!-- Mode de paiement -->
                <li v-if="treatment.paymentMethod">
                  <h4>Mode de paiement :</h4>
                  <p>{{ paymentMethod[treatment.paymentMethod] }}</p>
                </li>
              </template>
            </ul>
          </div>

          <!-- VISUEL -->
          <div v-if="!isContentionTreatmentType" class="cell medium-6">
            <h4>Dents spéciales :</h4>
            <Teeth v-model="specialTeeth" readonly />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Teeth from '@/components/Teeth.vue';
import utils from '@/services/utils/utils';
import treatmentZoneLabel from '@/services/enums/treatment-zone-label.enum';
import treatmentType from '@/services/enums/treatment-type.enum';
import treatmentTypeLabel from '@/services/enums/treatment-type-label.enum';
import treatmentStep from '@/services/enums/treatment-step.enum';
import pathologyLabel from '@/services/enums/pathology-label.enum';
import ImprintType from '@/services/enums/imprint-type-label.enum';
import paymentMethod from '@/services/enums/payment-method-label.enum';

export default {
  name: 'treatment-details',

  components: {
    Teeth,
  },

  props: {
    treatment: Object,
  },

  data() {
    return {
      treatmentZoneLabel,
      treatmentType,
      treatmentTypeLabel,
      treatmentStep,
      pathologyLabel,
      paymentMethod,
    };
  },

  computed: {
    // Récupère le nom complet
    fullName() {
      return `${this.treatment.practitioner.firstname} ${this.treatment.practitioner.lastname}`.toUpperCase();
    },

    // Récupère les dents à afficher
    specialTeeth() {
      return this.treatment.specialTeeth ? this.treatment.specialTeeth : {};
    },

    // Récupère l'état de l'empreinte
    imprint() {
      let response = 'Pas d\'empreintes';

      this.treatment.imprints.forEach((imprint) => {
        if (imprint.type !== ImprintType.PICTURES) {
          response = utils.imprintCompleteLabel(imprint);
        }
      });

      return response;
    },

    // Récupère la liste des pathologies
    treatmentNature() {
      return Object
        .entries(this.treatment.pathologies)
        .filter(([, value]) => !!value)
        .map(([key]) => pathologyLabel[key])
        .join(', ');
    },

    // Récupère les instructions
    treatmentInstructions() {
      return `Versions dentaires - ${this.treatment.isDentalVersionAllowed ? 'OUI' : 'NON'} \nStrippings - ${this.treatment.isStrippingAllowed ? 'OUI' : 'NON'}`;
    },

    // Vérifie si les propriétés isDentalVersionAllowed et isStrippingAllowed sont spécifiés
    treatmentInstructionsExists() {
      return typeof this.treatment.isDentalVersionAllowed === 'boolean' || typeof this.treatment.isStrippingAllowed === 'boolean';
    },

    // Vérifie si le type de traitement est CONTENTION
    isContentionTreatmentType() {
      return this.treatment.treatmentType !== this.treatmentType.GUTTER;
    },
  },
};
</script>

<style lang="sass">
.treatment-details

  hr
    border-top: 2px solid $color-primary-100

  h2
    margin: 0 0 15px 0

  h4
    margin-top: 0

  .treatment-details-container
    padding: 35px 40px
    border-radius: $global-border-radius
    background-color: $white

  .content
    margin-top: 45px

  .list
    padding: 0
    margin: 0

    li
      list-style-type: none
      display: flex

      .instructions
        white-space: pre

      &:not(:first-child)
        margin-top: 40px

      h4
        margin: 0
        width: 40%

      p, a
        flex: 1
        margin: 0
        text-align: left
        color: $color-gray-70

      a
        font-size: 14px
        color: $color-gray-70
        cursor: pointer

</style>
