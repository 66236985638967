<template>
 <app-modal
    :show="show"
    title="Nouvelle contention"
    @update:show="$emit('update:show', $event)"
    size="small"
    class="contention-modal"
  >
    <template v-slot:modal-content>
      <div class="contention-modal-content">
        <div>
          <h4>Quel type de contention voulez-vous ? </h4>
          <p>(sélectionnez un des éléments et validez)</p>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <app-radio
              :value="TreatmentType.FIXED_CONTENTION"
              v-model="treatmentType"
              look="card"
              :title="TreatmentTypeLabel.FIXED_CONTENTION"
              subtitle="Fil avec ou sans clé de collage"
              size="auto"
            >
              <img src="@/assets/img/illustrations/contention.png" alt="Contention fixe" loading="lazy" />
            </app-radio>
          </div>
        </div>
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <app-radio
              :value="TreatmentType.REMOVABLE_CONTENTION"
              v-model="treatmentType"
              look="card"
              :title="TreatmentTypeLabel.REMOVABLE_CONTENTION"
              subtitle="Gouttière 1.0 mm d'épaisseur"
              size="auto"
            >
              <img src="@/assets/img/illustrations/contention-removable.png" alt="Contention amovible" loading="lazy" />
            </app-radio>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <app-button @click="goToRequestTunnel" :disabled="!treatmentType">Valider</app-button>
    </template>
  </app-modal>
</template>

<script>
import TreatmentType from '@/services/enums/treatment-type.enum';
import TreatmentTypeLabel from '@/services/enums/treatment-type-label.enum';

export default {
  name: 'contention-modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
    },
  },
  data() {
    return {
      treatmentType: null,
      TreatmentType,
      TreatmentTypeLabel,
    };
  },
  methods: {
    goToRequestTunnel() {
      let routeName;
      switch (this.treatmentType) {
        case TreatmentType.REMOVABLE_CONTENTION: {
          routeName = 'treatment-new-removable-contentions';
          break;
        }
        case TreatmentType.FIXED_CONTENTION: {
          routeName = 'treatment-new-fixed-contentions';
          break;
        }
        default: return;
      }
      this.$router.push({ name: routeName, params: this.patient ? { patient: this.patient } : {} });
    },
  },
};
</script>

<style lang="sass">
.contention-modal
  &-content
    margin-top: 3rem
    > div:not(:last-child)
      margin-bottom: 1rem

    > div
      h4
        color: $color-gray-70
        margin: 0
      p
        color: $color-gray-70
        margin: 0
</style>
