<template>
  <section class="login">
    <div class="grid-container">
      <img class="logo" src="~@/assets/img/illustrations/logo-dark.png" />
      <div class="form-container">
        <app-title>Connexion {{ isAdmin ? 'Admin' : '' }}</app-title>
        <form ref="register" @submit.prevent="login(user)">
          <div>
            <app-label required>Mail</app-label>
            <app-input autocomplete="email" type="email" placeholder="email" required v-model="user.email" :errorMessage="errorMessage"/>
          </div>
          <div>
            <app-label required>Mot de passe</app-label>
            <app-input autocomplete="current-password" type="password" placeholder="mot de passe" required  v-model="user.password" :errorMessage="errorMessage"/>
            <router-link :to="{ name: `${isAdmin ? 'admin-' : ''}forgot-password` }">Mot de passe oublié ?</router-link>
          </div>
          <div class="btn-container">
            <app-button type="submit" :loading="isLoading">Se connecter</app-button>
            <app-button v-if="!isAdmin" look="secondary" @click="$router.push({ name: 'register' })">S'inscrire</app-button>
          </div>
        </form>
      </div>
    </div>
    <div class="slide-container">
      <app-spinner v-if="isSliderLoading" />
      <app-carousel v-else :slides="slides" :autoplay="true"/>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';
import slideApi from '@/services/api/slide';

export default {
  name: 'login',
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      errorMessage: null,
      isLoading: false,
      isAdmin: this.$route.name.includes('admin'),
      isSliderLoading: false,
      slides: [],
    };
  },
  watch: {
    $route() {
      this.isAdmin = this.$route.name.includes('admin');
    },
  },
  methods: {
    async login(user) {
      if (this.$refs.register.checkValidity()) {
        this.isLoading = true;
        this.errorMessage = null;
        try {
          if (this.isAdmin) {
            await auth.loginAdmin(user);
          } else {
            await auth.login(user);
          }

          this.$notification.show({
            text: 'Login réussi !',
          });

          this.$emit('logged');

          this.$router.push({ name: 'treatment-list' });
        } catch (error) {
          if (error.response.data.statusCode === 403) {
            this.errorMessage = 'Ce compte n\'a pas encore été validé ou a été refusé.';
          } else if (error.response.data.statusCode === 401) {
            this.errorMessage = 'L\'email ou le mot de passe est incorrect';
          }
        }
        this.isLoading = false;
      } else {
        this.$refs.register.reportValidity();
      }
    },
    // API: récupère la liste des slides
    async getSlides() {
      this.isSliderLoading = true;
      try {
        const response = await slideApi.getSlideList();
        this.slides = response.data;
      } catch (error) {
        this.$message.show({ title: 'Erreur', text: 'Il y a eu un problème lors de la récupération du slider' });
      }
      this.isSliderLoading = false;
    },
  },
  mounted() {
    this.getSlides();
  },
};
</script>

<style lang="sass">
.login
  display: flex
  overflow: hidden
  height: 100vh

  .grid-container
    text-align: left
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    padding: 40px 30px 50px 30px

    .logo
      width: 15vw
      left: 24px

    .form-container
      width: 30vw
      max-width: 500px
      min-width: 200px
      padding-left: 30px
      margin: 120px auto 0 auto
      flex: 1
      display: flex
      flex-direction: column

      .app-title
        @include h1
        color: $color-gray-100
        margin: 0

      .sub-title
        color: $color-gray-70

      form
        margin-top: 15px

        > div:not(:first-child):not(.btn-container)
          margin-top: 24px

        .btn-container
          margin-top: 44px

          .app-button:not(:first-child)
            margin-left: 16px

      .login-link
        margin: 0
        color: $color-gray-60

  .slide-container
    width: 60%
    height: 100%
    background: $background-color-green

    .app-spinner
      position: relative
      top: 35%
      margin: auto

    .app-carousel
      margin: 0 30px
      position: relative
      top: 25%
</style>
