<!--
****************************************************************************************************
***                                         Input                                               ***
****************************************************************************************************

Champ texte simple ou multiligne

*************
*** PROPS ***
*************
  - type :
    Type du champ

  Exemple :
  <app-input type="text" v-model="answer4"/>
  <app-input type="textarea" v-model="answer4"/>

-->
<template>
  <div class="app-search">
    <div class="svg-search-container">
      <SvgSearch />
    </div>
    <input class="app-search-input"
      type="text"
      :value="value"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :disabled="disabled"
      @invalid.prevent="handleInvalid"
      @input="$emit('input', $event.target.value)"/>
    <div v-if="value" class="svg-cross-container" @click="$emit('input', '')">
      <SvgCross />
    </div>
  </div>
</template>

<script>
import SvgSearch from '@/assets/img/icons/search.svg?inline';
import SvgCross from '@/assets/img/icons/cross.svg?inline';

export default {
  components: {
    SvgSearch,
    SvgCross,
  },
  props: {
    value: [String, Array, Number],
    autocomplete: {
      default: 'off',
    },
    placeholder: {
      default: 'Cherchez',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    input() {
      return this.value;
    },
  },
};
</script>

<style lang="sass">
.app-search
  width: 100%
  max-width: 400px
  display: flex
  align-items: center
  padding: 12px 16px
  background: white
  font-size: $global-font-size
  border-radius: $global-border-radius
  border: 1px solid $color-gray-20
  transition: all 0.2s ease-in-out
  margin: 4px 0
  &:hover
    border-color: $color-primary-100
  &:focus, &:focus-within
    border-color: $color-primary-100
  &:disabled
    background-color: $color-gray-10
  svg
    vertical-align: bottom
    width: 20px
    path
      fill: $color-gray-60
  .app-search-input
    flex-grow: 2
    border: none
    margin: 0 8px
    @include text
    &::placeholder
      color: $color-gray-60
    &:focus
      outline: 0
  .svg-cross-container
    cursor: pointer

</style>
