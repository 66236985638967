<template>
  <div class="document-card">
    <img :src="require(`@/assets/img/illustrations/${document.icon}.png`)" alt="Icône">
    <p>{{ document.name }}</p>
    <template v-if="document.file">
      <a :href="link" target="_blank" rel="noopener noreferrer">
        Voir document
        <SvgLink/>
      </a>
    </template>
    <div v-else class="button-link" @click="$emit('click', document)">Voir vidéo</div>
  </div>
</template>

<script>
import SvgLink from '@/assets/img/icons/link.svg';

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  components: {
    SvgLink,
  },

  computed: {
    link() {
      return this.document.file ? `${window.origin}/assets/documents/${this.document.file}.pdf` : this.document.url;
    },
  },
};
</script>

<style scoped lang="sass">
.document-card
  width: 200px
  position: relative
  padding: 16px 16px 38px 16px
  background: $white
  border-radius: $global-border-radius
  text-align: center
  display: flex
  flex-direction: column
  align-items: center
  @include h4

  img
    width: unset
    height: 64px

  p
    margin: 6px auto
    width: 100%
    flex-grow: 2
    overflow-wrap: break-word

  a > svg
    margin-left: 5px

  .button-link
    @include link
    cursor: pointer
    color: $color-primary-100
</style>
