<template>
  <section class="patient-list">
    <!-- HEADER -->
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h1>Patients</h1>
      </div>
      <div class="cell shrink" v-if="!isAdmin">
        <app-button @click="isCreateModalOpen = true">+ Nouveau patient</app-button>
      </div>
    </div>
    <!-- FILTERS -->
    <div class="filters-container">
      <app-search v-model="search" placeholder="Chercher par nom, mail ou référence" />
    </div>
    <!-- TABLE -->
    <app-table
      :headers="tableHeaders"
      :data="patients.data"
      :loading="isLoading"
      class="table"
      @select:line="goToPatient"
    >
      <template v-slot:name="{ data }">
        <strong>{{ data.firstname }} {{ data.lastname }}</strong>
      </template>
      <template v-slot:status="{ data }">
        <p
          :class="data.lastTreatment && data.lastTreatment.status ? `${data.lastTreatment.status} status-tag` : null"
        >{{ data.lastTreatment ? treatmentStatusLabel[data.lastTreatment.status] : null }}</p>
      </template>
      <template v-slot:practitioner="{ data }">
        <p>{{ data.practitioner.lastname }}</p>
      </template>
      <template v-slot:btn>
        <SvgArrowheadLeft class="svg-arrowhead-left" />
      </template>
    </app-table>
    <app-pagination
      :count="patients.metadata.count"
      :limit="limit"
      :offset="patients.metadata.offset"
    />

    <app-modal :show="isCreateModalOpen" title="Nouveau patient" class="create-patient-modal" @update:show="isCreateModalOpen = false">
      <template v-slot:modal-content>
        <form @submit.prevent="createPatient" id="create-patient" class="form-create-patient">
          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <app-label>Nom*</app-label>
              <app-input
                type="text"
                v-model="patient.lastname"
                placeholder="Nom..."
                required
              />
            </div>
            <div class="cell auto">
              <app-label>Prénom (3 premières lettres)*</app-label>
              <app-input
                type="text"
                v-model="patient.firstname"
                placeholder="Prénom..."
                minlength="3"
                maxlength="3"
                required
              />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <app-label>Civilité*</app-label>
              <app-select
                :options="genderType"
                v-model="patient.gender"
                placeholder="Civilité..."
                required
              />
            </div>
            <div class="cell auto">
              <app-label>Année de naissance*</app-label>
              <app-input type="number" v-model="patient.birthyear" placeholder="Année de naissance..." required />
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <h6>Si votre patient souhaite recevoir les 3D de son traitement, veuillez renseigner ci-dessous son adresse email (optionnel)</h6>
            </div>
          </div>
          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <app-label>Mail</app-label>
              <app-input type="email" v-model="patient.email" placeholder="xxxxx@gmail.com" />
            </div>
            <div class="cell auto"></div>
          </div>
        </form>
      </template>
      <template v-slot:modal-footer>
        <div class="grid-x grid-margin-x row">
            <div class="cell auto"></div>
            <app-button type="submit" form="create-patient">Valider</app-button>
          </div>
      </template>
    </app-modal>
  </section>
</template>

<script>
// API
import auth from '@/services/auth';
import patient from '@/services/api/patient';

// Utils
import utils from '@/services/utils/utils';

// Enums
import treatmentStatus from '@/services/enums/treatment-status.enum';
import treatmentStatusLabel from '@/services/enums/treatment-status-label.enum';
import genderType from '@/services/enums/gender.enum';

// Assets
import SvgArrowheadLeft from '@/assets/img/icons/arrowhead-left.svg?inline';

export default {
  name: 'patient-list',
  data() {
    return {
      search: null,
      isAdmin: auth.isAdmin(),
      isLoading: false,
      treatmentStatus,
      treatmentStatusLabel,
      genderType: utils.optionsFromEnum(genderType),
      isCreateModalOpen: false,
      patient: {
        firstname: null,
        lastname: null,
        gender: null,
        birthyear: null,
        email: null,
      },
      patients: {
        data: [],
        metadata: {},
      },
      limit: 10,
    };
  },
  components: {
    SvgArrowheadLeft,
  },
  watch: {
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getPatients();
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    this.search = this.$route.query.search || null;
    await this.getPatients();
    this.isLoading = false;
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
    tableHeaders() {
      return this.isAdmin
        ? [
          { label: 'Patient', key: 'name', size: 'small-2' },
          { label: 'Année naissance', key: 'birthyear', size: 'small-1' },
          { label: 'Email', key: 'email', size: 'auto' },
          { label: 'Statut', key: 'status', size: 'small-2' },
          { label: 'Praticien', key: 'practitioner', size: 'auto' },
          { label: '', key: 'btn', size: 'shrink' },
        ]
        : [
          { label: 'Patient', key: 'name', size: 'auto' },
          { label: 'Année naissance', key: 'birthyear', size: 'auto' },
          { label: 'Email', key: 'email', size: 'auto' },
          { label: 'Statut', key: 'status', size: 'auto' },
          { label: '', key: 'btn', size: 'shrink' },
        ];
    },
  },
  methods: {
    async getPatients() {
      this.isLoading = true;
      try {
        this.patients = await patient.getPatientList(this.limit, this.computeOffset, this.$route.query.search);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des patients',
          cancelText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    async createPatient() {
      try {
        this.patient.firstname = this.patient.firstname.substring(0, 3);
        await patient.create(this.patient);
        this.$notification.show({ text: 'Patient créé avec succès !' });
        this.patient = {};
        await this.getPatients();
        this.closeCreateModal();
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la création du patient',
          cancelText: 'Ok',
        });
      }
    },
    closeCreateModal() {
      this.isCreateModalOpen = false;
    },
    goToPatient(selectedPatient) {
      this.$router.push({
        name: 'patient',
        params: {
          patientId: selectedPatient.patientId,
        },
      });
    },
    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.$router.push({
          query: {
            ...this.$route.query,
            search: this.search || undefined,
          },
        });
      }
    },
  },
};
</script>

<style lang="sass">
.patient-list
  @include screen

  .header
    margin: 28px 0 52px 0

    h1
      margin: 0

  .filters-container
    margin-bottom: 12px

  .svg-arrowhead-left
    fill: $color-gray-60

  .app-table
    @include table
    .table-header .cell:last-child, .table-line .cell:last-child
      width: 20px
      text-align: right

  .status-tag
    @include treatment-status

  .form-create-patient
    > div:first-child()
      margin-bottom: 16px
</style>
