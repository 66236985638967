<!--
****************************************************************************************************
***                                     Tunnel Steps                                             ***
****************************************************************************************************

L'affichage de la progression dans un tunnel de traitement

*************
*** PROPS ***
*************
  - steps : Liste des étapes du tunnel à afficher. Chaque étape est un objet de la forme:
  {
    title: String (titre de l'étape à afficher en gras noir)
    data: String (donnée de l'étape une fois passée, à afficher en vert)
  }

  - activeIndex : Number
    Index de l'étape actuelle. Toutes les étapes précédentes seront marquées comme faites. Peut être nulle si aucune étape n'a commencée
-->

<template>
  <div class="tunnel-steps">
    <span v-for="(step, index) in steps" :key="step.key" :class="{ 'active-step': index < activeIndex | index === activeIndex }">
      <span class="step-index">{{ index + 1 }}</span>
      <span class="step-label">
        <div>{{ step.title }}</div>
        <div v-if="index < activeIndex">{{ step.data }}</div>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'tunnel-steps',
  props: {
    steps: Array,
    activeIndex: Number,
  },
};
</script>

<style lang="sass">
.tunnel-steps
  width: 100vw
  display: flex
  justify-content: space-between
  border-bottom: 1px solid $color-primary-20
  position: relative
  > span
    flex-grow: 2
    padding: 16px
    display: flex
    align-items: center
    .step-index
      display: inline-block
      width: 26px
      height: 26px
      margin-right: 16px
      border-radius: 50%
      background-color: $color-primary-20
      color: $main-color
      text-align: center
      line-height: 26px
    .step-label
      > div:first-child
        @include h4
      >div:last-child:not(:first-child)
        @include h6
        color: $main-color
    &.active-step
      border-bottom: 2px solid $main-color
      .step-index
        background-color: $main-color
        color: $white
</style>
