<!--
****************************************************************************************************
***                                          Table                                               ***
****************************************************************************************************

*************
*** PROPS ***
*************

* headers : [Object] - liste d'objets décrivant les en-têtes des colonnes
Le champ "label" est celui qui sera utilisé pour l'affichage
Le champ "key" peut différer selon l'utilisation.
  - Si vous ne comptez pas personnaliser l'affichage des cases de la colonne, donc ne pas spécifier de slot. Alors le champ key doit correspondre à une propriété de l'objet passé en props "data". De cette façon le component affichera automatiquement la valeur de la propriété dans la case, dans une balise <p>
  - Maintenant si vous comptez personnaliser l'affichage des cases de la colonne OU si votre propriété est nested (sous-objet). Alors le champ key servira seulement à retrouver la colonne lors de la spécification du template. Il doit donc être unique mais pas forcément correspondre à une propriété de l'objet en props "data".
  Il sera donné en tant que class de la cellule
Le champ "size" permet de définir la taille de la colonne, selon les spécifications foundation grid XY. La taille auto sera appliquée par défaut.

Exemple:

[
  {
    label: 'Object ID',
    key: 'objectId', //  Ici, on affichera la propriété "objectId" de l'objet
  },
  {
    label: 'Color',
    key: 'color', //  Ici, on affichera la propriété "color" de l'objet
  },
  {
    label: 'Digit',
    key: 'metadata.pagination.page', // Ici, vu que les sous-objets ne sont pas pris en compte, il servira juste pour le retrouver dans les slots
  },
  {
    label: 'Bool',
    key: 'bool', //  Ici, on affichera la propriété "bool" de l'objet
    size: 'small-1', // La colonne aura pour taille small-1 au lieu de auto
  }
]

* data : [Object] - liste d'objets à afficher

Exemple:

[
  {
    objectId: '1',
    color: 'red',
    bool: true,
    metadata: {
      pagination: {
        page: 10,
      },
    },
  },
  {
    objectId: '2',
    color: 'blue',
    bool: true,
    metadata: {
      pagination: {
        page: 22,
      },
    },
  },
  {
    objectId: '3',
    color: 'yellow',
    bool: false,
    metadata: {
      pagination: {
        page: 57,
      },
    },
  }
]

* loading : Boolean - sert à changer l'affichage du tableau par le contenu passé dans le slot "loading"

* clickable: Boolean (défaut true) : chaque ligne du tableau est clickabl. Les lignes hover auront un style particulier

* getLineClass: Function - doit retourner les classes à appliquer sur une ligne du tableau selon ses données

*************
*** SLOTS ***
*************

Doc sur les scope-slots
- https://fr.vuejs.org/v2/guide/components-slots.html#Slots-avec-portee
- https://www.digitalocean.com/community/tutorials/vuejs-scoped-component-slots

Il est possible de personnaliser plusieurs éléments du tableau grâce aux "scope-slots". On peut indiquer une balise "template" pour personnaliser l'affichage des cases d'une colonne, sinon on garde l'affichage par défaut qui est un simple <p>

* header - Avec ce slot on indique quel format auront les cases de header. L'objet "data" nous donne accès aux champs définis dans la liste "headers" en props.
  NOTE : si ce slot n'est pas spécifié, alors le champ "label" de l'objet passé en props "headers" sera affiché dans une balise <label>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="header" slot-scope="{ data }">
      <p><strong>{{ data.label }}</strong></p>
    </template>
  </app-table>

* CLE_CHAMP - Alors pour ce slot, le nom dépend du champ "key" que vous avez défini dans la liste "headers" en props. L'objet "data" nous donne accès aux objets passés en props "data"
  NOTE : si ce slot n'est pas spécifié pour une colonne, alors la propriété définie par le champ "key" du header de l'objet sera affiché dans une balise <p>

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="objectId" slot-scope="{ data }">
      <p><strong>{{ data.objectId }}</strong></p>
    </template>

    <template slot="metadata.pagination.page" slot-scope="{ data }">
      <p><strong>{{ data.metadata.pagination.page }}</strong></p>
    </template>
  </app-table>

* empty-table - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque la liste "data" en props est vide. On aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="empty-table">
      <p>Oups, c'est vide !</p>
    </template>
  </app-table>

* loading - Avec ce slot on indique le contenu que l'on souhaite afficher lorsque le props "loading" est à true. De la même manière que pour le slot "empty-table", on aura toujours les headers affichés, mais le contenu de la table est remplacé par ce template

  Exemple:

  <app-table :headers="headers" :data="data">
    <template slot="loading">
      <p>LOADING...</p>
    </template>
  </app-table>
-->

<template>
  <section class="app-table" :class="look">
    <div class="grid-x table-header">
      <div
        v-for="header in headers"
        :key="header.key"
        class="cell"
        :class="[header.size || 'auto', `header-${header.key}`]">
        <slot v-if="$scopedSlots.header" name="header" :data="header"/>
        <h5 v-else>{{ header.label }}</h5>
      </div>
    </div>

    <div v-if="loading">
      <slot name="loading">
        <app-spinner />
      </slot>
    </div>

    <div v-else-if="data && data.length > 0" class="data">
      <div
        v-for="(line, index) in data"
        :key="index"
        class="table-line grid-x"
        :class="[{ 'pair': index % 2 === 0, 'selected-line': isSelectedLine(line, selected), clickable }, getLineClass(line)]"
        @click="$emit('select:line', line)"
      >
        <div
          v-for="header in headers"
          :key="`${index}-${header.key}`"
          class="cell"
          :class="[header.size || 'auto', `line-${header.key}`]">
          <!-- Affichage custom si le slot est spécifié -->
          <slot
            v-if="$scopedSlots[header.key]"
            :name="header.key"
            :data="line"
          />

          <!-- Affichage par défaut, juste du texte -->
          <p v-else>
            {{ line[header.key] }}
          </p>
        </div>
      </div>
    </div>

    <!-- Si le tableau est vide -->
    <slot v-else name="empty-table">
      Aucune donnée disponible
    </slot>
  </section>
</template>

<script>
import AppSpinner from './AppSpinner.vue';

export default {
  name: 'app-table',
  components: {
    AppSpinner,
  },

  emits: ['select:line'],

  props: {
    // Format [{ label: 'Lorem Ipsum', key: 'lorem', size?: 'shrink' }]
    headers: [Array],

    data: [Array],

    loading: [Boolean],

    look: {
      default: 'normal',
      validator: (val) => ['normal', 'border'].indexOf(val) !== -1,
    },

    selected: { type: String },

    // Ligne du tableau cliquable
    clickable: {
      type: Boolean,
      default: true,
    },

    // Pour ajouter des classes à une ligne du tableau dépendant de ses données
    getLineClass: {
      type: Function,
      default: () => '',
    },
  },

  methods: {
    isSelectedLine(value, selectedValue) {
      return !!Object.values(value).find((selected) => selectedValue === selected);
    },
  },
};
</script>

<style lang="sass">
.app-table
  width: 100%
  @include table

  &.border
    border: 1px solid $color-gray-20

    .table-line
      min-height: 55px
      border-top: 1px solid $color-gray-20

  .table-header
    height: 45px

    h5
      margin: 0

  .table-line
    p
      margin: 0
    &.clickable
        cursor: pointer
        &:hover, &.selected-line
          background-color: $color-primary-20

  .app-spinner
    margin: 40px auto
</style>
