<template>
  <div class="estimate-request-tunnel">
    <!-- Header : toujours là -->
    <tunnel-steps :steps="steps" :activeIndex="activeStepIndex"/>
    <router-link :to="{ name: 'treatment-list' }" v-if="!treatmentReference"><SvgCross />Annuler demande</router-link>

    <div class="tunnel-content-container">
      <!-- Ecran de succès -->
      <template  v-if="treatmentReference">
        <div class="step-content success-content">
          <img src="~@/assets/img/illustrations/request-validated.png" />
          <h4>Référence dossier : {{ treatmentReference }}</h4>
          <h2>Votre demande a bien été prise en compte</h2>
          <p>Nous reviendrons vers vous sous 48 à 72h concernant la faisabilité du traitement de votre patient, sa durée, son nombre de gouttières et son coût estimé.</p>
        </div>
        <div class="step-footer">
          <app-button @click="$router.push({ name: 'treatment-list' })">Retour à mon espace</app-button>
        </div>
      </template>

      <template v-else>

        <!-- Introduction: Step Null -->
        <div v-if="activeStepIndex == null" class="introduction-content">
          <img src="~@/assets/img/illustrations/estimate.png" />
          <h1>Demande de pré-étude</h1>
          <p><strong>E-ALIGNER réalise pour vous une pré-étude de vos cas sur empreintes ou photos, et vous réponds sous 48 à 72 h concernant la faisabilité du traitement de votre patient, sa durée, son nombre de gouttières et son coût estimé.</strong></p>
          <p>Cette pré-étude est sans engagement. Elle permet de vous donner une tendance proche et légèrement surévaluée du traitement final, vous permet de présenter rapidement un devis à valider avec votre patient.</p>
          <app-button @click="activeStepIndex = 0">J'ai compris</app-button>
        </div>

        <template v-else>
          <!-- Header commun à toutes les étapes -->
          <div class="step-common-header">
            <img src="~@/assets/img/illustrations/estimate.png" />
            <h5>Demande de pré-étude</h5>
          </div>

          <!-- Étape Patient -->
          <div v-if="activeStepIndex === 0" class="step-content patient-step-content ">
            <h2>Patient</h2>
            <p>Choisissez le patient à associer au traitement</p>
            <div>
              <app-input :value="patient ? patient.reference : null" placeholder="Référence patient" disabled />
              <app-button @click="showPatientSelectModal = true">Choisir patient</app-button>
            </div>
            <app-modal :show="showPatientSelectModal" title="Choisir un patient" @update:show="showPatientSelectModal = $event">
              <template v-slot:modal-content>
                <PersonSelect @select="patient = $event; showPatientSelectModal = false" person="patient" />
              </template>
            </app-modal>
          </div>

          <!-- Étape Zone de traitement -->
          <div v-else-if="activeStepIndex === 1" class="step-content zone-step-content">
            <h2>Zone de traitement</h2>
            <div class="radio-container">
              <app-radio look="card" v-model="zone" :value="TreatmentZone.MAXIMILLAIRE" :title="TreatmentZoneLabel.MAXIMILLAIRE">
                <img src="~@/assets/img/illustrations/zone-maximillaire.png" />
              </app-radio>
              <app-radio look="card" v-model="zone" :value="TreatmentZone.MANDIBULAIRE" :title="TreatmentZoneLabel.MANDIBULAIRE">
                <img src="~@/assets/img/illustrations/zone-mandibulaire.png" />
              </app-radio>
              <app-radio look="card" v-model="zone" :value="TreatmentZone.COMPLETE" :title="TreatmentZoneLabel.COMPLETE">
                <img src="~@/assets/img/illustrations/zone-complete.png" />
              </app-radio>
            </div>
          </div>

          <!-- Étape Empreintes -->
          <div v-else-if="activeStepIndex === 2" class="step-content imprint-step-content">
            <h2>Avez-vous des empreintes à nous envoyer ?</h2>
            <div class="radio-container">
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.MATERIAL" :title="ImprintTypeLabel.MATERIAL">
                <img src="~@/assets/img/illustrations/imprint-material.png" />
              </app-radio>
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.INTERNAL_SCAN" :title="ImprintTypeLabel.INTERNAL_SCAN" subtitle="envoi immédiat des fichiers">
                <img src="~@/assets/img/illustrations/imprint-internal-scan.png" />
              </app-radio>
              <app-radio look="card" v-model="imprint.imprintType" :value="ImprintType.EXTERNAL_SCAN" :title="ImprintTypeLabel.EXTERNAL_SCAN" subtitle="(via plateforme externe : CS Connect, MEDIT Link, 3Shape...)">
                <img src="~@/assets/img/illustrations/imprint-external-scan.png" />
              </app-radio>
              <app-radio look="card" v-model="imprint.imprintType" value="MISSING" title="Non, je n'ai pas d'empreintes">
                <img src="~@/assets/img/illustrations/imprint-missing.png" />
              </app-radio>
            </div>
            <!-- Empreintes physiques -->
            <div class="material-type-container" v-if="imprint.imprintType === ImprintType.MATERIAL">
              <h3>Type d'empreintes :</h3>
              <p>Silicone à privilégier pour une meilleure tenue dans le temps</p>
              <div class="radio-container">
                <app-radio v-for="[key, label] in Object.entries(ImprintMaterialTypeLabel)" :key="key" v-model="imprint.materialType" :value="key">{{ label }}</app-radio>
              </div>
              <div class="postal-address">
                <p><strong>Merci de nous envoyer vos empreintes à l'adresse :</strong></p>
                <p>{{ postalAddress.line1 }}<br/>
                {{ postalAddress.line2 }}<br/>
                {{ postalAddress.line3 }}</p>
              </div>
            </div>
            <!-- Empreintes STL internes -->
            <div class="imprint-file-container" v-if="imprint.imprintType === ImprintType.INTERNAL_SCAN">
              <h3>Ajoutez vos empreintes ci-dessous :</h3>
              <p>Vous pouvez nous envoyer jusqu’à 4 fichiers STL que nous analyserons.</p>
              <app-upload @input="imprint.files.push(...$event)" accept=".stl" multiple compress/>
              <div v-if="imprint.files.length" class="files-container">
                Fichiers ajoutés :
                <app-file v-for="(file, index) in imprint.files" :key="file.filename" :file="file" @click:delete="imprint.files.splice(index, 1)"/>
              </div>
            </div>
          </div>

          <!-- Étape photos -->
          <div v-else-if="activeStepIndex === 3" class="step-content pictures-step-content">
            <h2>Ajoutez vos photos</h2>
            <p><strong>Merci d'ajouter 5 photos + une panoramique (optionnelle)</strong></p>
            <p>Si vous transmettez des empreintes physiques ou fichiers STL, les photos ne sont pas obligatoires, mais peuvent permettre d’avoir un aperçu plus global (panoramique...).
              Si vous ne souhaitez pas ajouter de photos, vous pouvez passer cette étape.</p>
            <div class="pictures-container">
              <UploadPicture v-for="(pic, index) in pictures" :key="index" :value="pic" @input="$set(pictures, index, $event)" />
            </div>
          </div>

          <!-- Étape Instructions (dents spéciales + requirement) -->
          <div v-else-if="activeStepIndex === 4" class="step-content instructions-step-content">
            <h2>Instructions de traitement</h2>
            <div class="special-teeth-container container-card">
              <p>
                <strong>Dents spéciales du patient</strong><br/>
                Cliquez sur la dent et définissez le type de dent
              </p>
              <Teeth v-model="specialTeeth"/>
            </div>
            <div class="requirement-container container-card">
              <p>
                <strong>Avez-vous des précisions à nous faire ?</strong><br/>
                (Attentes, prescription médicale, doutes éventuels sur la faisabilité du traitement et raisons, classe orthodontique...)
              </p>
              <app-input type="textarea" v-model="requirement" placeholder="Description libre"/>
            </div>
          </div>

          <!-- Étape Récapitulatif -->
          <div v-else-if="activeStepIndex === 5" class="step-content recap-step-content">
            <h2>Récapitulatif</h2>
            <div class="container-card">
              <div class="row">
                <span>Patient :</span>
                <span>{{ patient.reference }}</span>
              </div>
              <div class="row">
                <span>Zone de traitement :</span>
                <span>{{TreatmentZoneLabel[zone] }}</span>
              </div>
              <div class="row">
                <span>Empreintes :</span>
                <span>{{ imprintCompleteLabel }}</span>
              </div>
              <div class="row">
                <span>Prescription :</span>
                <span>{{ requirement }}</span>
              </div>
              <div class="row special-teeth-row">
                <span>Dents spéciales :</span>
                <Teeth readonly :specialTeeth="specialTeeth" />
              </div>
            </div>
            <h3>Vos informations sont-elles correctes ?</h3>
            <p>Vous pouvez encore les modifier en cliquant sur le bouton “précédent” pour revenir en arrière.</p>
          </div>

          <!-- Footer commun : Précédent/Valider -->
          <div class="step-footer">
            <app-button look="secondary" @click="activeStepIndex === 0 ? activeStepIndex = null : activeStepIndex-= 1" :disabled="isLoadingRequest">Précédent</app-button>
            <app-button v-if="activeStepIndex < 5" @click="activeStepIndex += 1" :disabled="isNextStepDisabled">Valider<SvgArrowLeft class="following"/></app-button>
            <app-button v-else @click="requestEstimate" :loading="isLoadingRequest" :disabled="isLoadingRequest">Valider ma demande de pré-étude</app-button>
          </div>
        </template>
      </template>

    </div>
  </div>
</template>

<script>
import treatmentApi from '@/services/api/treatment';
import utils from '@/services/utils/utils';
// SVG
import SvgCross from '@/assets/img/icons/cross.svg';
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';

// Enums
import TreatmentZone from '@/services/enums/treatment-zone.enum';
import TreatmentZoneLabel from '@/services/enums/treatment-zone-label.enum';
import ImprintType from '@/services/enums/imprint-type.enum';
import ImprintTypeLabel from '@/services/enums/imprint-type-label.enum';
import ImprintMaterialTypeLabel from '@/services/enums/imprint-material-type-label.enum';
import TreatmentStep from '@/services/enums/treatment-step.enum';
import TreatmentType from '@/services/enums/treatment-type.enum';

// Composants
import PersonSelect from '@/components/PersonSelect.vue';
import Teeth from '@/components/Teeth.vue';
import UploadPicture from '@/views/Treatments/components/UploadPicture.vue';
import TunnelSteps from './TunnelSteps.vue';

export default {
  name: 'estimate-request-tunnel',
  components: {
    SvgCross,
    SvgArrowLeft,
    PersonSelect,
    Teeth,
    UploadPicture,
    TunnelSteps,
  },
  data() {
    return {
      /** Données dynamiques */
      activeStepIndex: null,
      patient: null,
      zone: null,
      imprint: {
        imprintType: null,
        materialType: null,
        files: [],
      },
      pictures: [null, null, null, null, null, null, null, null],
      specialTeeth: {},
      requirement: null,
      showPatientSelectModal: false,
      isLoadingRequest: false,
      treatmentReference: null,

      /** Données fixes */
      TreatmentZone,
      TreatmentZoneLabel,
      ImprintType,
      ImprintTypeLabel,
      ImprintMaterialTypeLabel,
      postalAddress: {
        line1: process.env.VUE_APP_ADDRESS_LINE_1,
        line2: process.env.VUE_APP_ADDRESS_LINE_2,
        line3: process.env.VUE_APP_ADDRESS_LINE_3,
      },
    };
  },
  mounted() {
    if (this.$route.params.patient) {
      this.patient = this.$route.params.patient;
    }
  },
  computed: {
    // Etapes à afficher
    steps() {
      return [
        { title: 'Patient', data: this.patient && this.patient.reference },
        { title: 'Zone de traitement', data: TreatmentZoneLabel[this.zone] },
        { title: 'Empreintes', data: this.imprintCompleteLabel },
        { title: 'Photos' },
        { title: 'Instructions' },
        { title: 'Recapitulatif' },
      ];
    },
    imprintCompleteLabel() {
      return utils.imprintCompleteLabel(this.imprint);
    },
    // Bouton 'Suivant' disabled (étape actuelle remplie correctemet)
    isNextStepDisabled() {
      switch (this.activeStepIndex) {
        case 0: // Etape patient
          return !this.patient;
        case 1: // Etape Zone
          return !this.zone;
        case 2: // Etape Empreintes
          switch (this.imprint.imprintType) {
            case ImprintType.MATERIAL:
              return !this.imprint.materialType;
            case ImprintType.INTERNAL_SCAN:
              return this.imprint.files.length < 1;
            case ImprintType.EXTERNAL_SCAN:
              return false;
            case 'MISSING':
              return false;
            default:
              return true;
          }
        case 3: // Etape Photos
          return ImprintType[this.imprint.imprintType] ? false : this.pictures.filter((pic) => !!pic).length < 5;
        default:
          return false;
      }
    },
  },
  methods: {
    // REQUÊTE API : Création du traitement à la pré-étude
    async requestEstimate() {
      // Construction du body en form data
      const body = new FormData();
      body.append('patientId', this.patient.patientId);
      body.append('zone', this.zone);
      body.append('step', TreatmentStep.ESTIMATE_REQUEST);
      body.append('treatmentType', TreatmentType.GUTTER);

      if (this.requirement) {
        body.append('requirement', this.requirement);
      }

      Object.entries(this.specialTeeth).forEach(([key, value]) => {
        body.append(`specialTeeth[${key}]`, value);
      });
      let imprintIndex = 0;
      if (this.imprint.imprintType !== 'MISSING') {
        body.append(`imprints[${imprintIndex}][imprintType]`, this.imprint.imprintType);
        if (this.imprint.imprintType === ImprintType.MATERIAL) {
          body.append(`imprints[${imprintIndex}][materialType]`, this.imprint.materialType);
        }
        if (this.imprint.imprintType === ImprintType.INTERNAL_SCAN) {
          this.imprint.files.forEach((file) => body.append('scans', file));
        }
        imprintIndex += 1;
      }
      const pictures = this.pictures.filter((pic) => !!pic);
      if (pictures.length) {
        body.append(`imprints[${imprintIndex}][imprintType]`, ImprintType.PICTURES);
        pictures.forEach((pic) => body.append('pictures', pic));
      }

      if (this.requirement) {
        body.append('message', this.requirement);
      }

      // Requête API
      this.isLoadingRequest = true;
      try {
        const response = await treatmentApi.createTreatment(body);
        this.treatmentReference = response.data.reference;
      } catch (e) {
        let text = 'Une erreur inattendue est survenue durant votre demande de pré-étude. Veuillez rééssayer plus tard.';
        if (e.response.data.message.startsWith('S3 Error')) {
          text = 'Un problème est survenu en ajoutant un fichier. Cela peut être du à un nom de fichier non conforme. Évitez les accents, les caractères spéciaux (sauf - et _) et les espaces.';
        }
        this.$message.show({ title: 'Erreur', text });
      }
      this.isLoadingRequest = false;
    },
  },
};
</script>

<style lang="sass">
.estimate-request-tunnel
  @include request-tunnel
  .tunnel-content-container
    @include tunnel-content-container

    .success-content
      @include success-content

    .introduction-content
      @include introduction-content

    .patient-step-content
      @include patient-step-content

    .zone-step-content
      max-width: 800px

    .imprint-step-content
      @include imprint-step-content
      .radio-container
        max-width: 700px

    .pictures-step-content
      max-width: 600px
      p
        @include h6
        color: $color-gray-70
        strong
          @include text-bold
          color: $color-gray-90
      .pictures-container
        display: flex
        flex-wrap: wrap
        gap: 16px
        justify-content: center
        margin: 40px auto

    .instructions-step-content
      .container-card
        @include container-card
        text-align: left
        margin: 24px 0
        p
          @include h6
          color: $color-gray-70
          margin-bottom: 32px
          strong
            @include text-bold
            color: $color-gray-90
        .app-input
          max-width: none

    .recap-step-content
      @include recap-step-content
      .special-teeth-row
        flex-wrap: wrap
        span:first-child
          width: 100%

</style>
