<template>
  <section class="parameters">
    <!-- Body -->
    <h1>Paramètres</h1>
    <div class="card-block">
      <h2>Personnalisation slider</h2>
      <hr />
      <div class="card-block-content">
        <app-spinner v-if="isLoading" />
        <template v-else>
          <app-carousel :slides="slides"/>
          <div class="slides-edit-container">
            <Slide v-for="(slide, index) in slidesList" :key="index" :slide="slide" @update:slide="openSlideModal(slide, index)" @delete:slide="deleteSlide(slide.slideId)"/>
          </div>
        </template>
      </div>
    </div>

    <!-- Modale d'ajout/modification -->
    <app-modal :show="isModalOpen" title="Ajouter une image" @update:show="closeSlideModal">
      <template v-slot:modal-content>
        <form @submit.prevent="saveSlide" id="slide-form">
          <div class="image-container" v-if="updatedSlide.imageSrc">
            <img :src="updatedSlide.imageSrc" />
            <div class="image-overlay">
              <div class="update-btn" title="Modifier l'image"><label><SvgEdit /><input type="file" @input="addImage" accept="image/*" :disabled="isLoading"></label></div>
              <button class="remove-btn" @click="deleteImage" :disabled="isLoading" title="Supprimer l'image"><SvgCross /></button>
            </div>
          </div>
          <div v-else class="empty-image-container">
            <label>
              <SvgUploadImage />
              <input type="file" @input="addImage" accept="image/*" required/>
            </label>
          </div>
          <span>Taille maximale autorisée: 2MB</span>
          <div class="link-container">
            <label>Ajouter un lien</label>
            <app-input v-model="updatedSlide.linkUrl" placeholder="https://" type="url"/>
          </div>
        </form>
      </template>
      <template v-slot:modal-footer>
        <app-button @click="isModalOpen = false" look="secondary" :disabled="isLoading">Fermer</app-button>
        <app-button :loading="isLoadingSlide" type="submit" form="slide-form">Valider</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import slideApi from '@/services/api/slide';

import SvgUploadImage from '@/assets/img/icons/upload-image.svg';
import SvgEdit from '@/assets/img/icons/edit.svg';
import SvgCross from '@/assets/img/icons/cross.svg';

import Slide from './Slide.vue';

export default {
  name: 'parameters',
  components: {
    Slide,
    SvgUploadImage,
    SvgEdit,
    SvgCross,
  },
  data() {
    return {
      slides: [],
      isLoading: false,
      maxSlides: 5,

      // Editing data
      isModalOpen: false,
      isLoadingSlide: false,
      errors: [],
      updatedSlide: {
        slideId: null,
        imageFile: null,
        imageSrc: null,
        linkUrl: null,
        rank: null,
      },
    };
  },
  mounted() {
    this.getSlides();
  },
  computed: {
    // Créé la liste des slides en insérant des slides vides pour les rangs manquants
    slidesList() {
      const slides = [];
      for (let rank = 1; rank < 6; rank += 1) {
        const slide = this.slides.find((s) => s.rank === rank);
        if (slide) {
          slides.push(slide);
        } else {
          slides.push(null);
        }
      }
      return slides;
    },
  },
  methods: {
    // API: récupère la liste des slides
    async getSlides() {
      this.isLoading = true;
      try {
        const response = await slideApi.getSlideList();
        this.slides = response.data;
      } catch (error) {
        this.$message.show({ title: 'Erreur', text: 'Il y a eu un problème lors de la récupération du slider' });
      }
      this.isLoading = false;
    },
    // Sauvegarde l'image choisie par l'utilisateur
    async addImage(event) {
      const arrayBuffer = await event.target.files[0].arrayBuffer();
      this.updatedSlide.imageSrc = `data:image/jpeg;base64,${Buffer.from(arrayBuffer).toString('base64')}`;
      [this.updatedSlide.imageFile] = event.target.files;
    },
    // Ouvre la modale d'édition/ajout
    openSlideModal(slide, index) {
      if (slide && slide.slideId) {
        this.updatedSlide.slideId = slide.slideId;
        this.updatedSlide.imageSrc = `data:image/jpeg;base64,${slide.image}`;
        this.updatedSlide.linkUrl = slide.linkUrl;
      }
      this.updatedSlide.rank = index + 1;
      this.isModalOpen = true;
    },
    // Ferme la modale d'édition/ajout
    closeSlideModal() {
      this.updatedSlide = {
        slideId: null,
        imageFile: null,
        imageSrc: null,
        linkUrl: null,
        rank: null,
      };
      this.isModalOpen = false;
    },
    // Supprime l'image de la modale d'édition/ajout
    deleteImage() {
      this.updatedSlide.imageFile = null;
      this.updatedSlide.imageSrc = null;
    },
    // Lance la sauvegarde BDD
    // Ferme la modale et récupère la liste des slides si c'est réussi
    async saveSlide() {
      this.isLoadingSlide = true;
      try {
        if (this.updatedSlide.slideId) {
          await this.updateSlide();
        } else {
          await this.createSlide();
        }
        this.closeSlideModal();
        await this.getSlides();
      } catch (err) {
        let errorText = 'Un problème est survenu lors de l\'ajout de la slide';
        switch (err.response.status) {
          case 413:
            errorText = 'La taille de l\'image est trop élevée. Veuillez choisir une image moins lourde';
            break;
          case 400:
            if (err.response.data.message.includes('File type not supported')) {
              errorText = 'Veuillez choisir un fichier de type image.';
            }
            break;
          default: break;
        }
        this.$message.show({ title: 'Erreur', text: errorText });
      }
      this.isLoadingSlide = false;
    },
    // API: création d'une slide
    async createSlide() {
      try {
        await slideApi.createSlide(this.updatedSlide.rank, this.updatedSlide.linkUrl, this.updatedSlide.imageFile);
        this.$notification.show({ text: 'Slide créee avec succès !' });
      } catch (error) {
        throw error;
      }
    },
    // API: mise à jour d'une slide
    async updateSlide() {
      try {
        await slideApi.updateSlide(this.updatedSlide.slideId, this.updatedSlide.linkUrl, this.updatedSlide.imageFile);
        this.$notification.show({ text: 'Slide modifiée avec succès !' });
      } catch (error) {
        throw error;
      }
    },
    // API: suppression d'une slide
    async deleteSlide(slideId) {
      try {
        await slideApi.deleteSlide(slideId);
        this.$notification.show({ text: 'Slide supprimée avec succès !' });
        this.getSlides();
      } catch (err) {
        this.$message.show({ title: 'Erreur', text: 'Un problème est survenu lors de la suppression de la slide' });
      }
    },
  },
};
</script>

<style lang="sass">
.parameters
  @include screen

  .card-block
    padding: 2rem
    margin-bottom: 2rem
    border-radius: $global-border-radius
    background-color: $white

    h2
      margin-top: 0

    hr
      border-top: 2px solid $color-primary-100
      margin-bottom: 2rem

    &-content
      .app-spinner
        margin: 2rem auto
      .app-carousel
        margin: auto
      .slides-edit-container
        margin-top: 4rem
        display: flex
        gap: 1.5rem
        justify-content: space-between
        flex-wrap: wrap
        .slide
          min-width: 15rem
          width: calc(20% - 1.5rem)
          aspect-ratio: 16/9

  .modal-wrapper
    max-height: 70vh
    height: fit-content
    .link-container
      margin-top: 2rem
      label
        color: $color-gray-60
        @include h4
      .app-input
        max-width: none
    .empty-image-container, .image-container
      aspect-ratio: 16/9
      border-radius: $global-border-radius
      max-width: none
      overflow: hidden

    .image-container
      position: relative
      img
        aspect-ratio: 16/9
      .image-overlay
        width: 100%
        height: 100%
        position: absolute
        display: flex
        opacity: 0
        top: 0
        bottom: -3px
        right: 0
        z-index: 4
        align-items: center
        justify-content: center
        background: rgba(255, 255, 255, 0.8)
        transition:  0.2s ease-in
        > .update-btn, .remove-btn
          width: 37px
          height: 37px
          border-radius: 50%
          background-color: $color-primary-100
          margin: 0 7px
          svg path
            fill: $white
          label
            width: 100%
            height: 100%
          &, & label
            align-items: center
            justify-content: center
            display: flex
            cursor: pointer
          input
            width: 1px
            height: 1px
            opacity: 0
        .remove-btn
          background-color: $color-secondary-100
          border: none
        &:hover
          opacity: 1

    .empty-image-container
      border: 1px dashed $color-primary-40
      background: $white
      input
        width: 1px
        height: 1px
        opacity: 0
      label
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        svg
          max-width: none
          width: 61px
        &:hover
          cursor: pointer
      &:hover
        background-color: $color-primary-20
        border-color: $color-primary-100

</style>
