<template>
  <ul class="app-progress">
    <template v-if="isClosed">
      <!-- TRAITEMENT CLÔTURÉ -->
      <li v-for="(step, index) in steps" :key="`step-${index}`" class="step-container">
        <div class="step-bar">
          <span class="step-circle"/>
          <span v-if="index + 1 !== steps.length" class="step-line"/>
        </div>
        <div class="step-text">
          <h4 class="step-name">{{ step.label }}</h4>
        </div>
      </li>
    </template>

    <!-- TRAITEMENT EN COURS -->
    <template v-else>
      <li v-for="(step, index) in steps" :key="`step-${index}`" class="step-container">
        <div class="step-bar">
          <span class="step-circle" :class="{ active: stepIsActive(index)}"/>
          <span v-if="index + 1 !== steps.length" class="step-line" :class="{ active: step.date }"/>
        </div>
        <div class="step-text">
          <h4 class="step-name"  :class="{ active: stepIsActive(index)}">{{ step.label }}</h4>
          <time v-if="step.date" class="step-date">{{ step.date | formatDate( step.prefix || 'le' ) }}</time>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  name: 'app-progress',

  props: {
    steps: [Array],
    isClosed: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    // Une étape est toujours active si la suivante est active
    stepIsActive(index) {
      const nextIsActive = index + 1 >= this.steps.length ? false : this.steps[index + 1].isActive;
      return index === 0 || this.steps[index].isActive || nextIsActive;
    },
  },
};
</script>

<style lang="sass">
.app-progress
  display: flex
  justify-content: space-between
  padding: 0

  .step-container
    display: flex
    flex-direction: column
    list-style-type: none

    &:not(:last-child)
      flex: 1

      .step-text
        padding-left: 2px

    &:last-child
      width: 19px
      position: relative

      .step-text
        padding-right: 4px
        position: absolute
        right: 0
        top: 17px
        display: block
        text-align: right
        width: 200px

    .step-bar
      display: flex
      align-items: center

      .step-circle
        width: 17px
        height: 17px
        border-radius: 50%
        border: 2px solid $color-gray-10
        background-color: $color-gray-40

        &.active
          background-color: $color-primary-100

      .step-line
        flex: 1
        height: 3px
        background-color: $color-gray-40

        &.active
          background-color: $color-primary-100

    .step-name
      margin: 3px 0 0 0
      color: $color-gray-60

      &.active
        color: $color-primary-100

    .step-date
      margin: 2px 0 0 0
      font-size: 11px
      font-weight: bold
      color: $color-gray-40

</style>
