import ImprintType from '../enums/imprint-type.enum';
import ImprintTypeLabel from '../enums/imprint-type-label.enum';
import ImprintMaterialTypeLabel from '../enums/imprint-material-type-label.enum';

const utils = {};

/**
 * Similaire au _debounce de lodash: Retarde l'execution de la fonction de 'wait'ms à chaque appel afin d'appeler la fonction seulement quand il n'y a plus de changement
 * @param {function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Int} wait : temps d'attente (ms) sans appel à la fonction avant de l'exécuter
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const debounce = (func, wait) => {
  let timeout;
  return function executedFunc(...args) {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Permet de limiter la fréquence d'utilisation d'une fonction en ne l'appelant au maximum 1 fois toutes les 'wait'ms
 * @param {Function} func : fonction dont la fréquence d'exécution est à limiter
 * @param {Number} wait : temps d'attente minimum entre 2 appels
 * @returns {Function} fonction qui exécute la fonction en argument, mais à fréquence limitée
 */
const throttle = (func, wait) => {
  let timeout;
  let storedArgs;
  return function executedFunc(...args) {
    storedArgs = args;
    if (!timeout) {
      func(...storedArgs);
      storedArgs = null;
      timeout = setTimeout(() => {
        if (storedArgs) {
          func(...storedArgs);
        }
        timeout = null;
      }, wait);
    }
  };
};

// Génère l'array d'options pour un select ou un filtre
const optionsFromEnum = (enumObject) => Object.entries(enumObject).map(([name, label]) => ({ name, label }));

// Label complet d'une empreinte
const imprintCompleteLabel = (imprint) => {
  switch (imprint.imprintType) {
    case ImprintType.MATERIAL:
      return `${ImprintTypeLabel[imprint.imprintType]} ${ImprintMaterialTypeLabel[imprint.materialType]}`;
    case ImprintType.INTERNAL_SCAN:
      return ImprintTypeLabel[imprint.imprintType];
    case ImprintType.EXTERNAL_SCAN:
      return ImprintTypeLabel[imprint.imprintType];
    case ImprintType.OLD_TREATMENT:
      return ImprintTypeLabel[imprint.imprintType];
    default:
      return 'Pas d\'empreintes';
  }
};

utils.debounce = debounce;
utils.throttle = throttle;
utils.optionsFromEnum = optionsFromEnum;
utils.imprintCompleteLabel = imprintCompleteLabel;

export default utils;
