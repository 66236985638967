<template>
  <section class="treatment-list" :class="isAdmin ? 'admin-display' : ''">

    <!-- Bandeaux de facture non-payées -->
    <div class="banner" v-if="unpaidInvoices && unpaidInvoices.length">
      <div class="banner-content">
        <p>Des factures sont en attente de paiement, vous pouvez les retrouver directement sur la page des traitements suivants :</p>
        <ul>
          <li v-for="(treatment, index) in unpaidInvoices" :key="`treatment-${index}`">
            <router-link :to="{ name: 'treatment',  params: { treatmentId: treatment.treatmentId, tab: 'suivi' } }">{{ treatment.reference }}</router-link>
          </li>
        </ul>
      </div>

      <div class="close-btn" @click="closeBanner()">
        <SvgCross/>
      </div>
    </div>

    <!-- Header -->
    <div class="header grid-x align-middle">
      <div class="cell auto"><h1>Traitements</h1></div>
      <div class="cell shrink" v-if="!isAdmin"><app-button look="secondary" @click="isContentionModalOpen = true">Demande contention</app-button></div>
      <div class="cell shrink" v-if="!isAdmin"><app-button @click="$router.push({ name: 'treatment-new-estimate' })">+ Nouveau traitement</app-button></div>
    </div>

    <!-- Filtres -->
    <div class="filters-container">
      <app-search v-model="search" placeholder="Chercher par référence ou patient" />
      <div class="select-filters-container">
        <span>Filtrer par :</span>
        <span>
          <app-filter v-model="status" name="Statut" placeholder="Tous" :options="statusOptions">
            <template v-for="opt in statusOptions" v-slot:[opt.name]>
              <span :key="opt.name" :class="opt.name" class="status-option">{{ opt.label }}</span>
            </template>
          </app-filter>
        </span>
        <span>
          <app-filter v-model="step" name="Étapes du traitement" placeholder="Tous" :options="stepOptions">
            <template v-for="opt in stepKeys" v-slot:[opt]>
              <treatment-step :key="opt" :step="opt"/>
            </template>
          </app-filter>
        </span>
        <template v-if="isAdmin">
          <span>
            <app-filter v-model="task" name="Tâches à effectuer" placeholder="Tous" :options="taskOptions" />
          </span>
          <span>
            <app-filter v-model="orderByAppointmentDate" name="Date du rendez-vous" placeholder="Tous" :options="dateOptions" />
          </span>
          <span>
            <app-filter v-model="orderByEstimateValidatedAt" name="Date validation PDTT" placeholder="Tous" :options="dateOptions" />
          </span>
          <span>
            <app-filter v-model="orderByProductionDate" name="Date de production" placeholder="Tous" :options="dateOptions" />
          </span>
        </template>
      </div>
    </div>

    <!-- Tableau -->
    <app-table
      :class=" { 'admin-table': isAdmin }"
      :headers="isAdmin ? tableHeadersAdmin : tableHeadersPractitioner"
      :data="treatments"
      :loading="isLoading"
      :getLineClass="getLineClass"
      @select:line="$router.push({ name: 'treatment',  params: { treatmentId: $event.treatmentId, tab: 'suivi' } })">

      <template v-slot:practitioner="{ data }">
        {{ data.practitioner.lastname }} {{ data.practitioner.firstname }}
      </template>

      <template v-slot:appointmentDate="{ data }">{{ data.appointmentDate | formatDay }}</template>

      <template v-slot:productionDate="{ data }">{{ data.productionDate | formatDay }}</template>

      <template v-slot:createdAt="{ data }">{{ data.createdAt | formatDay }}</template>

      <template v-slot:patient="{ data }">
        <template v-if="isAdmin"><strong>{{ data.patient.reference }}</strong></template>
        <template v-else>{{ data.patient.lastname }} {{ data.patient.firstname }}</template>
      </template>

      <template v-slot:treatmentType="{ data }">{{ TreatmentTypeLabel[data.treatmentType ] }}</template>

      <template v-slot:status="{ data }"><span :class="data.status">{{ TreatmentStatusLabel[data.status] }}</span></template>

      <template v-slot:step="{ data }"><treatment-step v-if="isOngoing(data)" :step="data.step" /></template>

      <template v-slot:currentTask="{ data }">{{ TreatmentTaskLabel[data.currentTask] }}</template>

      <template v-slot:arrow><SvgArrowheadLeft /></template>

    </app-table>

    <!-- Pagination -->
    <app-pagination :count="metadata.count" :limit="metadata.limit" :offset="metadata.offset" />

    <!-- Modale choix de contention -->
    <ContentionModal :show="isContentionModalOpen" @update:show="isContentionModalOpen = false" />
  </section>
</template>

<script>
// services
import auth from '@/services/auth';
import utils from '@/services/utils/utils';
import constant from '@/services/utils/constant';
import treatmentApi from '@/services/api/treatment';
import TreatmentStep from '@/components/TreatmentStep.vue';

// Enums
import TreatmentStatus from '@/services/enums/treatment-status.enum';
import TreatmentStatusLabel from '@/services/enums/treatment-status-label.enum';
import TreatmentStepLabel from '@/services/enums/treatment-step-label.enum';
import TreatmentTypeLabel from '@/services/enums/treatment-type-label.enum';
import TreatmentTaskLabel from '@/services/enums/treatment-task-label.enum';

// Composants
import ContentionModal from '@/components/ContentionModal.vue';

// Icônes
import SvgArrowheadLeft from '@/assets/img/icons/arrowhead-left.svg';
import SvgCross from '@/assets/img/icons/cross.svg';

export default {
  name: 'treatment-list',

  components: {
    SvgCross,
    SvgArrowheadLeft,
    TreatmentStep,
    ContentionModal,
  },

  props: {
    unpaidInvoices: {
      type: Array,
    },
  },

  data() {
    return {
      // Données dynamiques
      treatments: [],
      metadata: {
        count: 0,
        limit: 10,
        offset: 0,
      },
      isLoading: false,
      search: this.$route.query.search,
      status: this.$route.query.statush,
      step: this.$route.query.step,
      task: this.$route.query.task,
      orderByEstimateValidatedAt: this.$route.query.orderByEstimateValidatedAt,
      orderByAppointmentDate: this.$route.query.orderByAppointmentDate,
      orderByProductionDate: this.$route.query.orderByProductionDate,
      isContentionModalOpen: false,

      // Données statiques
      isAdmin: auth.isAdmin(),
      ownSteps: auth.isAdmin() ? constant.STEPS_FOR_ADMIN : constant.STEPS_FOR_PRACTITIONER,
      TreatmentStatusLabel,
      TreatmentTypeLabel,
      TreatmentTaskLabel,
      stepKeys: Object.keys(TreatmentStepLabel),
      statusOptions: utils.optionsFromEnum(TreatmentStatusLabel),
      stepOptions: utils.optionsFromEnum(TreatmentStepLabel),
      taskOptions: utils.optionsFromEnum(TreatmentTaskLabel),
      dateOptions: [{ name: 'ASC', label: 'Date croissante' }, { name: 'DESC', label: 'Date décroissante' }],
      tableHeadersPractitioner: [
        { label: 'Référence', key: 'reference' },
        { label: 'Patient', key: 'patient' },
        { label: 'Date début', key: 'createdAt', size: 'shrink' },
        { label: 'Traitement', key: 'treatmentType', size: 'shrink' },
        { label: 'Statut', key: 'status', size: 'shrink' },
        { label: 'Étape du traitement', key: 'step' },
        { label: '', key: 'arrow', size: 'shrink' },
      ],
      tableHeadersAdmin: [
        { label: 'Référence', key: 'reference' },
        { label: 'Praticien', key: 'practitioner' },
        { label: 'Date RDV', key: 'appointmentDate', size: 'shrink' },
        { label: 'Date Production', key: 'productionDate' },
        { label: 'Traitement', key: 'treatmentType', size: 'shrink' },
        { label: 'Statut', key: 'status', size: 'shrink' },
        { label: 'Étape du traitement', key: 'step' },
        { label: 'Tâches', key: 'currentTask' },
        { label: '', key: 'arrow', size: 'shrink' },
      ],
    };
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.$emit('update:unpaidInvoices');
    this.getTreatmentList();
    if (this.isAdmin) {
      this.stepOptions.unshift({ name: 'MY_ACTIONS', label: 'Toutes mes actions' });
    }
  },

  watch: {
    $route() {
      this.getTreatmentList();
    },
    step() { this.updateQuery({ step: this.step, page: 1 }); },
    status() { this.updateQuery({ status: this.status, page: 1 }); },
    task() { this.updateQuery({ task: this.task, page: 1 }); },
    orderByAppointmentDate() { this.updateQuery({ orderByAppointmentDate: this.orderByAppointmentDate, page: 1 }); },
    orderByEstimateValidatedAt() { this.updateQuery({ orderByEstimateValidatedAt: this.orderByEstimateValidatedAt, page: 1 }); },
    orderByProductionDate() { this.updateQuery({ orderByProductionDate: this.orderByProductionDate, page: 1 }); },
    search() { this.debouncedUpdateSearchQuery(); },
  },

  methods: {
    getLineClass(line) {
      if (!this.isOngoing(line)) {
        return '';
      }
      if (this.isAdmin) {
        return line.isAdminActionNeeded ? 'need-action' : 'pending-action';
      }
      return line.isPractitionerActionNeeded ? 'need-action' : 'pending-action';
    },

    async getTreatmentList() {
      this.isLoading = true;
      try {
        // Récupération des paramètres
        const {
          page, search, status, step, task, orderByEstimateValidatedAt, orderByAppointmentDate, orderByProductionDate,
        } = this.$route.query;
        const offset = page ? (page - 1) * this.metadata.limit : 0;
        const steps = step === 'MY_ACTIONS' ? this.ownSteps : step;
        // requête
        const response = await treatmentApi.getTreatmentList(this.metadata.limit, offset, search, status, steps, task, orderByEstimateValidatedAt, orderByAppointmentDate, orderByProductionDate);
        this.treatments = response.data;
        this.metadata = response.metadata;
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
        });
      }
      this.isLoading = false;
    },

    updateQuery(newQuery) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...newQuery,
        },
      });
    },

    updateSearchQuery() {
      if (this.$route.query.search !== this.search) {
        this.updateQuery({ search: this.search || undefined, page: 1 });
      }
    },

    isOngoing(treatment) {
      return [TreatmentStatus.URGENT, TreatmentStatus.ONGOING].includes(treatment.status);
    },

    closeBanner() {
      this.$emit('closeBanner', false);
    },
  },
};
</script>

<style lang="sass">
.treatment-list
  @include screen
  position: relative
  overflow-x: hidden

  .banner
    display: flex
    justify-content: space-between
    margin: -40px -40px 0 -40px
    padding: 10px 48px
    color: white
    background: $color-secondary-100

    .banner-content
      flex: 1
      min-width: 100%
      padding-right: 24px

      p
        @include h5
        margin: 0
        font-size: 13px

      ul
        display: -webkit-box
        grid-gap: 2px 20px
        flex-wrap: wrap
        width: 100%
        margin: 2px 0 0 0
        padding: 0
        overflow: auto

        li
          position: relative
          list-style-type: none

          &:not(:first-child):before
            content: ''
            position: absolute
            top: 20%
            left: -10px
            width: 1px
            height: 60%
            background-color: $white

          a
            color: white
            font-weight: 500

    .close-btn
      display: flex
      align-items: center
      cursor: pointer

      path
        fill: white

  .header
    &.banner-opened
      padding-top: 68px

    h1
      @include h1
    .app-button
      margin-left: 8px
  .filters-container
    display: flex
    margin-bottom: 12px
    flex-wrap: wrap
    gap: 8px
    .app-search
    .select-filters-container
      display: flex
      align-items: center
      flex-wrap: wrap
      gap: 8px
      .app-filter
        .status-option
          @include treatment-status
  .app-table
    min-width: 800px
    .table-line
      strong
        @include text-bold
    .table-header .cell:last-child, .table-line .cell:last-child
      width: 20px
    .header-status, .line-status
      width: 100px
      span
        @include treatment-status
    .header-createdAt,
    .line-createdAt,
    .header-treatmentType,
    .line-treatmentType,
    .header-appointmentDate,
    .line-appointmentDate,
    .line-productionDate
      width: 100px
    .line-createdAt,
    .line-appointmentDate,
    .line-productionDate
      @include h6
      color: $color-gray-60
    .line-arrow
      text-align: right
      svg
        width: 16px
        path
          fill: $color-gray-60
    .line-step
      display: flex
      align-items: center
    .table-line.need-action
      border-left: 2px solid $color-secondary-100
    .table-line.pending-action
      border-left: 2px solid $main-color

    &.admin-table
      .line-reference, .line-patient, .line-treatmentType, .line-step
        @include text-bold
      .line-patient, .header-patient
        width: 100px
      .line-step, .header-step
        margin-right: 1rem

    &:not(.admin-table)
      .line-patient
        @include text-bold
</style>
