<template>
  <section class="treatment">
    <a v-if="previousRoute && previousRoute.name" @click="$router.go(-1)"><SvgArrowLeft />Retour {{ previousRoute.name === 'treatment-list' ? 'liste' : 'patient' }}</a>
    <router-link v-else :to="{ name: 'treatment-list' }"><SvgArrowLeft />Retour liste</router-link>
    <app-spinner v-if="isLoading" />
    <template v-else-if="!!treatment">
      <!-- HEADER -->
      <div class="header">
        <div class="header-title-container">
          <div class="header-title">
            <app-title>Traitement {{ treatment.reference }}</app-title>
            <template v-if="!isAdmin">
              <span v-if="treatment.status === TreatmentStatus.CLOSED" class="treatment-status" :class="treatment.status">{{ TreatmentStatusLabel[treatment.status] }}</span>
              <treatment-step v-else :step="treatment.step" isTag/>
            </template>
            <template v-else>
              <span>
                <app-tag-select :options="statusOptions" :value="treatment.status" @input="updateStatus" class="status-tag-select" :class="treatment.status" :isLoading="isStatusLoading">
                  <template v-for="opt in statusOptions" v-slot:[opt.name]>
                    <span :key="opt.name" :class="opt.name" class="treatment-status">{{ opt.label }}</span>
                  </template>
                </app-tag-select>
              </span>
              <span>
                <app-tag-select :options="stepOptions" :value="treatment.step" @input="updateStep" class="step-tag-select" :class="STEPS_FOR_ADMIN.includes(treatment.step) ? 'active-step' : 'pending-step'" :isLoading="isStepLoading">
                  <template v-slot:result>
                    <treatment-step :step="treatment.step" />
                  </template>
                  <template v-for="opt in stepOptions" v-slot:[opt.name]>
                    <treatment-step :step="opt.name" :key="opt.name"/>
                  </template>
                </app-tag-select>
              </span>
            </template>
          </div>
          <div class="header-subtitle">
            <span><SvgAccount />Patient : <router-link :to="{ name: 'patient', params: { patientId: treatment.patient.patientId } }">{{ treatment.patient.reference }}</router-link></span>
            <span v-if="isAdmin"><SvgAccount />Praticien : <router-link :to="{ name: 'practitioner', params: { practitionerId: treatment.practitioner.practitionerId } }">{{ treatment.practitioner.firstname }} {{ treatment.practitioner.lastname }}</router-link></span>
            <span><SvgTooth />Type : {{ TreatmentTypeLabel[treatment.treatmentType] }}</span>
            <span>Zone : {{ TreatmentZoneLabel[treatment.zone] }}</span>
            <span v-if="treatment.treatmentType === TreatmentType.FIXED_CONTENTION">Clé de collage: {{ treatment.isStickingPatchIncluded ? 'Avec' : 'Sans' }}</span>
            <span><SvgCalendar />Crée le : {{ treatment.createdAt | formatDay }}</span>
            <span v-if="isAdmin">
              <SvgCalendar />
              <span v-if="treatment.productionDate">Produit le : {{ treatment.productionDate | formatDay }}</span>
              <span v-else>Produit le : indéfini</span>
              <span v-if="treatment.step !== TreatmentStep.CLOSED" @click="showProductionModal = true" class="button-link">{{ treatment.productionDate ? 'Modifier' : 'Définir' }}</span>
            </span>
            <span><SvgDocument /> Tranquillité : {{ treatment.isPeacePackage ? "Oui" : "Non" }} <a v-if="isAdmin" class="button-link" @click="showPeacePackageModal = true">Modifier</a></span>
            <span v-if="treatment.appointmentDate"><SvgCalendar />Rendez-vous le {{ treatment.appointmentDate | formatDay }}<span v-if="treatment.step !== TreatmentStep.CLOSED" @click="showAppointmentModal = true" class="button-link">Modifier</span></span>

            <span v-if="treatment.paymentMethod"><SvgCardHolder class="svg-card-holder" /> Paiement : {{ PaymentMethodLabel[treatment.paymentMethod] }} <a v-if="isAdmin" class="button-link" @click="showEditPaymentMethodModal = true">{{ 'Modifier' }}</a></span>
          </div>
        </div>
        <div v-if="!isAdmin" class="header-actions">
          <app-button look="primary" v-if="treatment.status === TreatmentStatus.CLOSED" @click="$router.push({ name: 'treatment-extend', params: { treatmentId: $route.params.treatmentId }})">Suite de traitement</app-button>
        </div>
      </div>

      <!-- ONGLETS -->
      <app-tabs :tabs="isAdmin ? tabsAdmin : tabsPractitioner"/>
      <template v-if="treatment">
        <template v-if="$route.params.tab === 'suivi'">
          <AdminMonitoring v-if="isAdmin" v-model="treatment"/>
          <PractitionerMonitoring v-else v-model="treatment"/>
        </template>
        <Details v-else-if="$route.params.tab === 'details'" :treatment="treatment"/>
        <Documents v-else-if="$route.params.tab === 'documents'" :orders="treatment.orders" :paymentMethod="treatment.paymentMethod" :treatment="treatment"/>
        <Orders v-else-if="$route.params.tab === 'commandes'" v-model="treatment"/>
        <Imprints v-else-if="$route.params.tab === 'empreintes'" v-model="treatment.imprints" :treatmentType="treatment.treatmentType"/>
        <Discussions v-else-if="$route.params.tab === 'discussions'" />
      </template>

      <!-- Modale Envoi commande -->
      <app-modal :show="showOrderModal" title="Informations commande" class="order-modal" @update:show="showOrderModal = $event">
        <template v-slot:modal-content>
          <form id="order-form" @submit.prevent="updateOrderShipped">
            <div>
              <app-label>Date d'envoi</app-label>
              <app-datepicker v-model="shippedAt" required />
            </div>
            <div>
              <app-label>N° de suivi</app-label>
              <app-input v-model="colissimoCode" required />
            </div>
          </form>
        </template>
        <template v-slot:modal-footer>
          <app-button type="submit" form="order-form" :isLoading="isOrderLoading">Valider</app-button>
        </template>
      </app-modal>

      <!-- Modale Modification Rdv -->
      <app-modal :show="showAppointmentModal" title="Date de rendez-vous" size="large" class="appointment-modal" @update:show="showAppointmentModal = $event" isAction>
        <template v-slot:modal-content>
          <form id="appointment-form" @submit.prevent="updateAppointmentDate">
            <app-datepicker required v-model="appointmentDate"/>
          </form>
        </template>
        <template v-slot:modal-footer>
          <app-button type="submit" form="appointment-form" :isLoading="isAppointmentDateLoading">Valider</app-button>
        </template>
      </app-modal>

      <!-- Modale Modification Date Production -->
      <app-modal :show="showProductionModal" title="Date de production" class="production-modal" @update:show="showProductionModal = $event" isAction>
        <template v-slot:modal-content>
          <form id="production-form" @submit.prevent="updateProductionDate">
            <app-datepicker required v-model="productionDate"/>
          </form>
        </template>
        <template v-slot:modal-footer>
          <app-button type="submit" form="production-form" :isLoading="isProductionDateLoading">Valider</app-button>
        </template>
      </app-modal>
    </template>

    <!-- Modale Modification Pack Tranquillité -->
    <app-modal :show="showPeacePackageModal" title="Formule Tranquillité" size="small" class="peace-package-modal" @update:show="showPeacePackageModal = $event" isAction>
      <template v-slot:modal-content>
        <p>Ajouter la formule Tranquillité au traitement ?</p>
        <form id="peace-package-form" @submit.prevent="updatePeacePackage(setPeacePackage)">
          <app-radio value="true" v-model="setPeacePackage">Oui</app-radio>
          <app-radio value="false" v-model="setPeacePackage">Non</app-radio>
        </form>
      </template>
      <template v-slot:modal-footer>
        <app-button type="submit" form="peace-package-form" :isLoading="isPeacePackageLoading">Valider</app-button>
      </template>
    </app-modal>

    <!-- Modal Modification Moyen De Paiement -->
    <app-modal :show="showEditPaymentMethodModal" title="Moyen de paiement" size="small" class="payment-method-modal" @update:show="showEditPaymentMethodModal = $event" isAction>
      <template v-slot:modal-content>
        <form id="payment-method-form" @submit.prevent="updatePaymentMethod(setPaymentMethod)">
          <app-select v-model="setPaymentMethod" :options="paymentMethodsOptions" />
        </form>
      </template>
      <template v-slot:modal-footer>
        <app-button type="submit" form="payment-method-form" :isLoading="isPaymentMethodLoading">Valider</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import treatmentApi from '@/services/api/treatment';
import auth from '@/services/auth';
import utils from '@/services/utils/utils';
import constant from '@/services/utils/constant';

// Enums
import TreatmentStepLabel from '@/services/enums/treatment-step-label.enum';
import TreatmentStatusLabel from '@/services/enums/treatment-status-label.enum';
import TreatmentTypeLabel from '@/services/enums/treatment-type-label.enum';
import TreatmentZoneLabel from '@/services/enums/treatment-zone-label.enum';
import TreatmentStatus from '@/services/enums/treatment-status.enum';
import TreatmentStep from '@/services/enums/treatment-step.enum';
import ImprintValidation from '@/services/enums/imprint-validation.enum';
import TreatmentType from '@/services/enums/treatment-type.enum';
import PaymentMethod from '@/services/enums/payment-method.enum';
import PaymentMethodLabel from '@/services/enums/payment-method-label.enum';

// Svg
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';
import SvgAccount from '@/assets/img/icons/account.svg';
import SvgTooth from '@/assets/img/icons/treatment.svg';
import SvgDocument from '@/assets/img/icons/document.svg';
import SvgCalendar from '@/assets/img/icons/calendar.svg';
import SvgCardHolder from '@/assets/img/icons/card-holder.svg';

// Composants
import TreatmentStepComponent from '@/components/TreatmentStep.vue';
import AdminMonitoring from './tabs/AdminMonitoring.vue';
import PractitionerMonitoring from './tabs/PractitionerMonitoring.vue';
import Details from './tabs/Details.vue';
import Documents from './tabs/Documents.vue';
import Orders from './tabs/Orders.vue';
import Imprints from './tabs/Imprints.vue';
import Discussions from './tabs/Discussions.vue';

export default {
  name: 'treatment',
  components: {
    SvgArrowLeft,
    SvgAccount,
    SvgTooth,
    SvgCalendar,
    SvgDocument,
    SvgCardHolder,
    'treatment-step': TreatmentStepComponent,
    AdminMonitoring,
    PractitionerMonitoring,
    Details,
    Documents,
    Orders,
    Imprints,
    Discussions,
  },
  data() {
    return {
      // Données dynamiques
      treatment: null,
      isLoading: false,
      showStatusOptions: false,
      isStatusLoading: false,
      isStepLoading: false,
      // Données Modale Commande
      showOrderModal: false,
      colissimoCode: null,
      shippedAt: null,
      isOrderLoading: false,
      // Données Modale RDV
      showAppointmentModal: false,
      appointmentDate: null,
      isAppointmentDateLoading: false,
      // Données Modale Production
      showProductionModal: false,
      productionDate: null,
      isProductionDateLoading: false,
      // Données pack Tranquillité
      showPeacePackageModal: false,
      isPeacePackageLoading: false,
      setPeacePackage: null,
      // Données moyen de payment
      showEditPaymentMethodModal: false,
      isPaymentMethodLoading: false,
      setPaymentMethod: PaymentMethod.CARD,

      // Données statiques
      isAdmin: auth.isAdmin(),
      tabsPractitioner: [{
        label: 'Suivi du dossier',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'suivi' } },
      }, {
        label: 'Empreintes',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'empreintes' } },
      }, {
        label: 'Discussions',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'discussions' } },
      }, {
        label: 'Commandes',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'commandes' } },
      }],
      tabsAdmin: [{
        label: 'Suivi du dossier',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'suivi' } },
      }, {
        label: 'Détail traitement',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'details' } },
      }, {
        label: 'Empreintes',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'empreintes' } },
      }, {
        label: 'Documents',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'documents' } },
      }, {
        label: 'Commandes',
        route: { ...this.$route, params: { ...this.$route.params, tab: 'commandes' } },
      }],
      statusOptions: utils.optionsFromEnum(TreatmentStatusLabel),
      stepOptions: constant.STEPS_AVAILABLE_TO_ADMIN.map((key) => ({ name: key, label: TreatmentStepLabel[key] })),
      STEPS_FOR_ADMIN: constant.STEPS_FOR_ADMIN,
      TreatmentStatus,
      TreatmentStep,
      TreatmentType,
      TreatmentStatusLabel,
      TreatmentStepLabel,
      TreatmentTypeLabel,
      TreatmentZoneLabel,
      PaymentMethodLabel,
      paymentMethodsOptions: utils.optionsFromEnum(PaymentMethodLabel),

      previousRoute: null,
      refreshInterval: null,
    };
  },
  watch: {
    treatment: {
      handler() { this.getNotificationsOnTabs(); },
      deep: true,
    },
  },
  mounted() {
    this.getTreatment();

    // Mise à jour du traitement toutes les 5 s
    this.refreshInterval = setInterval(() => {
      this.refreshTreatment();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.refreshInterval);
  },
  // Enregistre la route précédente
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-param-reassign
    next((vm) => { vm.previousRoute = from; });
  },
  methods: {
    // REQUETE API : récupération du traitement
    async getTreatment() {
      this.isLoading = true;
      try {
        const response = await treatmentApi.getTreatment(this.$route.params.treatmentId);
        this.treatment = response.data;

        this.setPeacePackage = this.treatment.isPeacePackage.toString();

        // Initialisation du moyen de paiement
        if (this.treatment.paymentMethod) this.setPaymentMethod = PaymentMethod[this.treatment.paymentMethod];
      } catch (error) {
        this.$message.show({ title: 'Erreur', text: 'Une erreur est survenue lors de la récupération du traitement. Veuillez réessayer plus tard' });
      }
      this.isLoading = false;
    },

    async refreshTreatment() {
      try {
        const response = await treatmentApi.getTreatment(this.$route.params.treatmentId);
        this.treatment = response.data;
      } catch (error) {
        // erreur silencieuse
      }
    },

    // REQUETE API : modification du traitement
    async updateTreatment(status, step, colissimoCode, shippedAt, appointmentDate, productionDate, isPeacePackage, paymentMethod) {
      try {
        const response = await treatmentApi.updateTreatment(
          this.$route.params.treatmentId,
          status,
          step,
          colissimoCode,
          shippedAt,
          appointmentDate,
          productionDate,
          null,
          null,
          null,
          isPeacePackage,
          paymentMethod,
        );
        this.treatment = response.data;
        this.$notification.show({ text: 'Traitement mis à jour avec succès !' });
      } catch (error) {
        let text = 'Une erreur est survenue lors de la mise à jour du traitement. Veuillez réessayer plus tard';
        switch (error.response.data.message) {
          case 'No estimate available':
            text = 'Aucun document de type Pré-étude n\'a été ajouté au traitement';
            break;
          case 'No treatment plan available':
            text = 'Aucun document de type Plan de traitement n\'a été ajouté au traitement';
            break;
          case 'No order found':
            text = 'Aucune commande trouvée associée au traitement. Veuillez attendre que le praticien valide la plan de traitement ou effectue une nouvelle commande.';
            break;
          default:
            break;
        }
        this.$message.show({ title: 'Erreur', text });
      }
    },

    // Modifie le statut
    async updateStatus(status) {
      this.isStatusLoading = true;
      await this.updateTreatment(status, null, null, null, null, null, null);
      this.isStatusLoading = false;
    },

    // Modifie l'étape
    async updateStep(step) {
      if (step === TreatmentStep.ORDER_SHIPPED) {
        this.showOrderModal = true;
      } else {
        this.isStepLoading = true;
        await this.updateTreatment(null, step, null, null, null, null, null);
        this.isStepLoading = false;
      }
    },

    // Modifie le pack Tranquillité
    async updatePeacePackage(isPeacePackage) {
      this.isStepLoading = true;
      const value = isPeacePackage === 'true';
      await this.updateTreatment(null, null, null, null, null, null, value);
      this.isStepLoading = false;
      this.showPeacePackageModal = false;
    },

    // Modifie le moyen de payment
    async updatePaymentMethod(newPaymentMethod) {
      this.isPaymentMethodLoading = true;

      await this.updateTreatment(null, null, null, null, null, null, null, newPaymentMethod);

      this.isPaymentMethodLoading = false;
      this.showEditPaymentMethodModal = false;
    },

    // Modifie l'étape à Commande envoyée, avec les données associées
    async updateOrderShipped() {
      this.isOrderLoading = true;
      await this.updateTreatment(null, TreatmentStep.ORDER_SHIPPED, this.colissimoCode, this.shippedAt, null, null, null);
      this.isOrderLoading = false;
      this.showOrderModal = false;
    },

    // Modifie la date de Rdv
    async updateAppointmentDate() {
      this.isAppointmentDateLoading = true;
      await this.updateTreatment(null, null, null, null, this.appointmentDate, null, null);
      this.isAppointmentDateLoading = false;
      this.showAppointmentModal = false;
    },

    // Modifie la date de production
    async updateProductionDate() {
      this.isProductionDateLoading = true;
      await this.updateTreatment(null, null, null, null, null, this.productionDate, null);
      this.isProductionDateLoading = false;
      this.showProductionModal = false;
    },

    // Ajoutes les pastilles de notifications sur les onglets
    getNotificationsOnTabs() {
      if (!this.treatment) {
        return;
      }

      if (this.isAdmin) {
        this.$set(this.tabsAdmin[2], 'notification', this.treatment.imprints.some((imp) => imp.validation === ImprintValidation.PENDING));
      } else {
        this.$set(this.tabsPractitioner[0], 'notification', this.treatment.activeBanner && this.treatment.activeBanner.type);
        this.$set(this.tabsPractitioner[1], 'notification', this.treatment.imprints.some((imp) => imp.validation === ImprintValidation.REFUSED));
      }
    },
  },
};
</script>

<style lang="sass">
.treatment
  @include screen
  > a
    svg
      width: 12px
      transform: rotate(180deg)
      vertical-align: middle
      margin-right: 4px
  > .app-spinner
    margin: 4rem auto
  .header
    display: flex
    align-items: center
    .header-title-container
      flex-grow: 2
      .header-title
        display: flex
        align-items: center
        .app-title
          margin: 12px 8px 12px 0
        .treatment-status
          @include treatment-status
        > .treatment-status
          line-height: 28px
          vertical-align: text-bottom
          margin-left: 8px
        .app-tag-select
          margin-left: 8px
          .options
            min-width: 8.5rem
        .status-tag-select
          &.ONGOING
            .result
              color: $main-color
              background-color: transparentize($main-color, 0.85)
          &.PAUSED
            .result
              color: $blue
              background-color: transparentize($blue, 0.85)
          &.CLOSED
            .result
              color: $color-gray-70
              background-color: transparentize($color-gray-70, 0.85)
          &.URGENT
            .result
              color: $color-secondary-100
              background-color: transparentize($color-secondary-100, 0.85)
        .step-tag-select
          .treatment-step
            display: inline
          &.active-step
            .result
              color: $color-secondary-100
              background-color: transparentize($color-secondary-100, 0.85)
          &.pending-step
            .result
              color: $main-color
              background-color: transparentize($main-color, 0.85)

      .header-subtitle
        display: flex
        flex-direction: row
        flex-wrap: wrap
        gap: 1rem
        @include h6
        color: $color-gray-70
        a
          color: $color-gray-70
        > span
          svg
            width: 14px
            vertical-align: text-top
            margin-right: 6px
            path
              fill: currentcolor
          .svg-card-holder
            width: 18px
            height: 18px
        .button-link
          @include link
          margin-left: 8px
          color: $main-color
          &:hover
            cursor: pointer
  .order-modal .modal-wrapper
    width: 500px
    height: fit-content
    overflow: visible
    .modal-content form
      > div
        margin-bottom: 24px
  .appointment-modal,
  .production-modal,
    .modal-wrapper
      width: 300px
      height: fit-content
      overflow: visible
      .modal-header
        margin-bottom: 0
  .peace-package-modal
    .modal-wrapper
      height: fit-content
      overflow: visible
    #peace-package-form
      display: flex
      flex-direction: row
      justify-content: center
      gap: 1rem
  .payment-method-modal
    .modal-wrapper
      height: fit-content
      overflow: visible
    #payment-method-form
      display: flex
      flex-direction: row
      justify-content: center
      gap: 1rem
      .app-select
        width: 100%
</style>
