import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const updatePaymentIntent = async (paymentIntentId, paymentMethod) => {
  try {
    const response = await axios.put(`${API_URL}/stripe/payment-intents/${paymentIntentId}`, {
      ...(paymentMethod && { payment_method: paymentMethod }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};

api.updatePaymentIntent = updatePaymentIntent;

export default api;
