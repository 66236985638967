import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

// Upload file REQUÊTE LEGACY DU STARTER
const upload = async (file, handleUploadProgress) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(
      'https://httpbin.org/post',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: handleUploadProgress,
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getList = async (treatmentId) => {
  try {
    const response = await axios.get(`${API_URL}/treatments/${treatmentId}/documents`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const create = async (treatmentId, documentType, amount, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentType', documentType);
  if (amount) formData.append('amount', amount);

  try {
    const response = await axios.post(`${API_URL}/treatments/${treatmentId}/documents`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const update = async (documentId, paymentStatus) => {
  try {
    const response = await axios.put(`${API_URL}/documents/${documentId}`, {
      paymentStatus,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPaymentIntent = async (documentId) => {
  try {
    const response = await axios.get(`${API_URL}/documents/${documentId}/payment-intent`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.upload = upload;
api.getList = getList;
api.create = create;
api.update = update;
api.getPaymentIntent = getPaymentIntent;

export default api;
