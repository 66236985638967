<template>
  <section class="payment-session">
    <img src="~@/assets/img/illustrations/smile.png" alt="" />
    <div class="content-container">
      <div class="content-header">
        <router-link :to="{ name: 'treatment-list' }">
          <SvgArrowLeft />
          Retour à mon espace
        </router-link>
        <img src="~@/assets/img/illustrations/logo-dark.png" alt="e-aligner" />
      </div>
      <div class="content">
        <img src="~@/assets/img/illustrations/payment-method-card.png" alt="" />
        <h1>Paiement par carte bancaire</h1>
        <section v-if="!isDeletingCard">
          <card-bloc v-for="card in cards" :key="card.id" :last4="card.card.last4" :brand="card.card.brand" :isSelected="cardSelected === card.id" @select-card="cardSelected = card.id" @delete-card="() => deleteCard(card.id)" />

          <card-bloc label="Nouvelle carte de crédit" :isSelected="cardSelected === 'NEW'" @select-card="cardSelected = 'NEW'" />
        </section>
        <app-spinner v-else />

        <section v-if="cardSelected === 'NEW'">
          <stripe-element-payment
            class="stripe-element"
            v-if="elementOptions.clientSecret"
            ref="paymentRef"
            :pk="publicKey"
            :elements-options="elementOptions"
            :confirm-params="confirmParams"
            @error="isPaymentLoading = false"
          />
          <app-spinner v-else />
        </section>

        <app-button :loading="isPaymentLoading" :disable="isPaymentLoading" @click="handlePayement">Payer</app-button>
      </div>
    </div>
  </section>
</template>

<script>
import ls from 'local-storage';
import treatmentApi from '@/services/api/treatment';
import practitionerApi from '@/services/api/practitioner';
import stripeApi from '@/services/api/stripe';
import documentApi from '@/services/api/document';
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';
import { StripeElementPayment } from '@vue-stripe/vue-stripe';
import CardBloc from '../../components/CardBloc.vue';

export default {
  components: {
    SvgArrowLeft,
    StripeElementPayment,
    CardBloc,
  },
  data() {
    return {
      elementOptions: {
        clientSecret: null,
        appearance: {
          variables: {
            fontFamily: 'DM Sans, Helvetica, Arial, sans-serif',
            borderRadius: '8px',
            colorPrimary: '#6BBB32',
            colorTextPlaceholder: '#828986',
            colorText: '#3B4D46',
            colorDanger: '#F96F5C',
            spacingGridRow: '16px',
            spacingGridColumn: '8px',
          },
        },
      },
      confirmParams: {
        return_url: `${process.env.VUE_APP_FRONT_URL}${this.$router.resolve({ name: 'treatment-payment-result', query: this.$route.query }).href}`,
      },
      publicKey: process.env.VUE_APP_STRIPE_KEY,
      isPaymentLoading: false,

      cards: null,
      cardSelected: null,
      paymentIntentId: null,

      isDeletingCard: false,
    };
  },
  created() {
    if (this.$route.query.documentId) {
      this.getDocumentPaymentIntent();
    } else {
      this.getTreatmentPaymentIntent();
    }

    this.getPractitionerCards();
  },
  methods: {
    async getTreatmentPaymentIntent() {
      try {
        const response = await treatmentApi.getPaymentIntent(this.$route.params.treatmentId);
        this.elementOptions.clientSecret = response.data.clientSecret;
        this.paymentIntentId = response.data.paymentIntentId;
      } catch (error) {
        this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.' });
      }
    },
    async getPractitionerCards() {
      try {
        const user = ls('USER') || null;

        const response = await practitionerApi.getPractitionerCards(user.userId);

        this.cards = response.data;

        // Initialisation de la sélection si pas de carte déjà enregistrée
        if (response.data.length <= 0) this.cardSelected = 'NEW';
      } catch (error) {
        this.cards = [];
      } finally {
        this.isDeletingCard = false;
      }
    },
    async deleteCard(cardId) {
      this.isDeletingCard = true;

      try {
        const user = ls('USER') || null;

        await practitionerApi.deletePractitionerCard(user.userId, cardId);

        this.getPractitionerCards();
      } catch {
        this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue, impossible de supprimer la carte. Veuillez réessayer plus tard.' });
      } finally {
        // On reset la carte sélectionnée si on vient de la supprimer
        if (this.cardSelected === cardId) this.cardSelected = null;
      }
    },
    async handlePayement() {
      this.isPaymentLoading = true;

      if (this.cardSelected === 'NEW') {
        // On utilise un nouveau moyen de paiement
        this.$refs.paymentRef.submit();
      } else {
        try {
          // On met à jour le moyen de paiement sur le PaymentIntent
          await stripeApi.updatePaymentIntent(this.paymentIntentId, this.cardSelected);

          // Confirmation du PaymentIntent côté Stripe
          await this.$stripe.confirmPayment({
            clientSecret: this.elementOptions.clientSecret,
            confirmParams: this.confirmParams,
          });
        } catch {
          this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.' });
        } finally {
          this.isPaymentLoading = false;
        }
      }
    },
    async getDocumentPaymentIntent() {
      try {
        const response = await documentApi.getPaymentIntent(this.$route.query.documentId);
        this.elementOptions.clientSecret = response.data.clientSecret;
        this.paymentIntentId = response.data.paymentIntentId;
      } catch (error) {
        let text = 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.';
        if (error.response.data.statusCode === 400 && error.response.data.message.includes('no amount')) {
          text = 'Montant de la facture inconnue. Veuillez contacter E-Aligner pour pouvoir payer par CB';
        }
        this.$message.show({ title: 'Erreur', text });
      }
    },
  },
};
</script>

<style lang="sass">
.payment-session
  display: flex
  height: 100%
  overflow: hidden
  > img
    height: 100%
    width: 30%
    object-fit: cover
  .content-container
    padding: 3rem 5% 3rem 10%
    flex-grow: 2
    .content-header
      display: flex
      justify-content: space-between
      align-items: center
      a > svg
        transform: rotate(180deg)
      > img
        width: 150px
    .content
      padding: 3rem 10%
      max-width: 750px
      > img
        width: initial
      h1
        color: $color-gray-100
      .stripe-element
        margin: 3rem 0
      .app-spinner
        margin: 3rem auto
      > .app-button
        width: 100%
</style>
