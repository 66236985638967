<template>
  <section class="guides">
    <h1>Guide pratique</h1>

    <!-- Documents PDF -->
    <h3>Documents PDF</h3>
    <div class="document-container">
      <DocumentCard
        v-for="document in pdfDocuments"
        :key="document.documentId"
        :document="document"
      />
    </div>

    <!-- Liens vidéos -->
    <h3>Liens vidéo</h3>
    <div class="document-container">
      <DocumentCard
        v-for="(document, index) in videoDocuments"
        :key="document.documentId"
        :document="document"
        @click="showingVideoIndex = index"
      />
    </div>

    <!-- Modale de visualisation de la vidéo -->
    <app-modal :title="`Vidéo - ${showingVideo.name}`" :show="showingVideoIndex != null" @update:show="showingVideoIndex = null">
      <template v-slot:modal-content>
        <iframe :src="showingVideo.url" frameborder="0" allow="fullscreen"></iframe>
      </template>
    </app-modal>

  </section>
</template>

<script>
import DocumentCard from './components/DocumentCard.vue';

export default {
  name: 'guides',

  components: {
    DocumentCard,
  },

  data() {
    return {
      showingVideoIndex: null,
      pdfDocuments: [
        {
          icon: 'good-practice',
          name: 'Les bonnes pratiques',
          file: 'bonnes-pratiques',
        },
        {
          icon: 'consent',
          name: 'Consentement éclairés',
          file: 'consentement-eclaire',
        },
        {
          icon: 'imprint-material',
          name: 'Prises d’empreintes',
          file: 'prise-empreinte',
        },
        {
          icon: 'taquets',
          name: 'Poses de taquets',
          file: 'pose-taquets',
        },
        {
          icon: 'catalog',
          name: 'Notre catalogue',
          file: 'catalogue-2023',
        }, {
          icon: 'treatment-extend',
          name: 'Protocoles complets',
          file: 'protocoles_complets',
        }, {
          icon: 'treatment-extend',
          name: 'Protocole contention fixe',
          file: 'protocole_contention_fixe',
        },
        {
          icon: 'consent',
          name: 'Devis patient',
          file: 'devis-patient',
        },
      ],
      videoDocuments: [
        {
          icon: 'contention',
          name: 'Commande de contentions',
          url: 'https://www.youtube.com/embed/ItYscwabRsE',
        },
        {
          icon: 'imprint-internal-scan',
          name: 'Création de patient',
          url: 'https://www.youtube.com/embed/HYeFZsG6FH0',
        },
        {
          icon: 'estimate',
          name: 'Demande de pré-étude',
          url: 'https://www.youtube.com/embed/iKWpytc2uVs',
        },
        {
          icon: 'treatment-plan',
          name: 'Demande de plan de traitement',
          url: 'https://www.youtube.com/embed/yfmrxAwMe-A',
        },
      ],
    };
  },
  computed: {
    showingVideo() {
      return this.showingVideoIndex != null ? this.videoDocuments[this.showingVideoIndex] : {};
    },
  },
};
</script>

<style lang="sass">
.guides
  @include screen

  h3:not(:first-of-type)
    margin-top: 40px
  .document-container
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    row-gap: 24px
    column-gap: 16px
    box-shadow: $medium-shadow

  .modal-wrapper.large
    width: 100vw
    height: 100vh
    max-width: unset
    max-height: unset
    border-radius: 0
    iframe
      width: 100%
      height: 100%
</style>
