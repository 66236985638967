<template>
  <section class="upload-picture" :class="{ loading: isLoading }">
    <div v-if="!value" class="upload-picture-empty">
      <label>
        <SvgUploadImage />
        <input type="file" @input="addFiles" accept="image/*">
      </label>
    </div>
    <div v-else class="upload-picture-filled">
      <img :src="pictureUrl" />
      <div class="edit-btn-container" v-if="!readonly">
        <div class="update-btn"><label><SvgEdit /><input type="file" @input="addFiles" accept="image/*" :disabled="isLoading"></label></div>
        <button class="remove-btn" @click="$emit('input', null)" :disabled="isLoading"><SvgCross /></button>
        <app-spinner v-if="isLoading"/>
      </div>
      <div v-else>
        <app-button @click="showExpandModal = true" class="expand-btn" size="extra-small" look="secondary">Agrandir</app-button>
      </div>
    </div>
    <app-modal class="expand-modal" :title="title" :show="showExpandModal" @update:show="showExpandModal = $event">
      <template v-slot:modal-content>
        <img :src="pictureUrl" />
      </template>
    </app-modal>
  </section>
</template>

<script>
import SvgUploadImage from '@/assets/img/icons/upload-image.svg';
import SvgEdit from '@/assets/img/icons/edit.svg';
import SvgCross from '@/assets/img/icons/cross.svg';

export default {
  name: 'upload-picture',
  components: {
    SvgUploadImage,
    SvgEdit,
    SvgCross,
  },
  props: {
    value: {
      type: [String, File],
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Empreinte',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showExpandModal: false,
    };
  },
  computed: {
    // Enlever le paramètre filename de l'url pour ne pas fausser l'autorisation, s'il existe
    pictureUrl() {
      if (typeof this.value === 'string') {
        const codeRegex = /(?<origin>.+)\/(?<fileKey>.+)\?(?<searchParams>.+)/;
        const { origin, fileKey, searchParams } = this.value.match(codeRegex).groups;
        const params = searchParams.match(/(?<auth>.+)&filename=(?<filename>.+)/)?.groups;
        return params ? `${origin}/${fileKey}?${params.auth}` : `${origin}/${fileKey}?${searchParams}`;
      }
      return URL.createObjectURL(this.value);
    },
  },
  methods: {
    addFiles(event) {
      const upload = this.multiple ? [...event.target.files] : [...event.target.files][0];
      this.$emit('input', upload);
    },
  },
};
</script>

<style lang="sass">
.upload-picture
  width: 173px
  height: 113px

  > div
    width: 100%
    height: 100%
    border-radius: $global-border-radius

  input
    display: none

  .upload-picture-empty
    border: 1px dashed $color-primary-40
    background: $white
    label
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      svg
        max-width: none
        width: 61px
      &:hover
        cursor: pointer
    &:hover
      background-color: $color-primary-20
      border-color: $color-primary-100

  .upload-picture-filled
    position: relative
    > img
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: $global-border-radius
    .edit-btn-container
      width: 100%
      height: 100%
      position: absolute
      display: flex
      opacity: 0
      top: 0
      right: 0
      z-index: 4
      align-items: center
      justify-content: center
      background: rgba(white, 0.8)
      transition: all 0.2s ease-in
      > .update-btn, .remove-btn
        width: 37px
        height: 37px
        border-radius: 50%
        background-color: $color-primary-100
        margin: 0 7px
        svg path
          fill: $white
        label
          width: 100%
          height: 100%
        &, & label
          align-items: center
          justify-content: center
          display: flex
          cursor: pointer
      .remove-btn
        background-color: $color-secondary-100
        border: none
      &:hover
        opacity: 1
        cursor: pointer
    .expand-btn
      display: none
      position: absolute
      top: 2px
      left: 2px
      // Pour surcharger le style du bouton
      &.app-button
        background-color: $white
    &:hover
      .expand-btn
        display: flex
  .expand-modal
    .modal-wrapper
      max-width: none
      max-height: none
      width: 95vw
      height: 95vh
      .modal-header
        margin-bottom: 0
      .modal-content
        height: 80vh
        img
          height: 100%
          object-fit: contain
      .modal-footer
        display: none

  &.loading
    .upload-picture-filled
      .edit-btn-container
        opacity: 1
        cursor: default
        > .update-btn, .remove-btn
          display: none
</style>
