import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Account/Login.vue';
import Register from './views/Account/Register.vue';
import ForgotPassword from './views/Account/ForgotPassword.vue';
import ResetPassword from './views/Account/ResetPassword.vue';
import Account from './views/Account/Account.vue';
import PatientList from './views/Patients/PatientList.vue';
import Patient from './views/Patients/Patient.vue';
import Guides from './views/Guides/Guides.vue';
import AdminList from './views/Admins/AdminList.vue';
import PractitionerList from './views/Practitioners/PractitionerList.vue';
import Practitioner from './views/Practitioners/Practitioner.vue';
import TreatmentList from './views/Treatments/TreatmentList.vue';
import Treatment from './views/Treatments/Treatment/Treatment.vue';
import ContentionRequest from './views/Treatments/Treatment/Tunnels/ContentionRequest.vue';
import EstimateRequest from './views/Treatments/Treatment/Tunnels/EstimateRequest.vue';
import TreatmentPlanRequest from './views/Treatments/Treatment/Tunnels/TreatmentPlanRequest.vue';
import TreatmentViewer from './views/Treatments/Treatment/TreatmentViewer.vue';
import PaymentSession from './views/Payments/PaymentSession.vue';
import PaymentResult from './views/Payments/PaymentResult.vue';
import Parameters from './views/Parameters/Parameters.vue';

import Demo from './views/Demo.vue';

import auth from './services/auth';
import UserRole from './services/enums/user-role.enum';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    /** ** Design System  *** */
    {
      path: '/demo',
      name: 'demo',
      component: Demo,
    },
    /** ** Connexion Praticien  *** */
    {
      path: '/connexion',
      name: 'login',
      component: Login,
    },
    {
      path: '/inscription',
      name: 'register',
      component: Register,
    },
    {
      path: '/mot-de-passe-perdu',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/reinitialisation-mot-de-passe',
      name: 'reset-password',
      component: ResetPassword,
    },
    /** ** Connexion Admin  *** */
    {
      path: '/admin/connexion',
      name: 'admin-login',
      component: Login,
    },
    {
      path: '/admin/mot-de-passe-perdu',
      name: 'admin-forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/admin/reinitialisation-mot-de-passe',
      name: 'admin-reset-password',
      component: ResetPassword,
    },
    /** ** Mon compte  *** */
    {
      path: '/mon-compte',
      name: 'account',
      component: Account,
      meta: {
        requiresAuth: true,
      },
    },
    /** ** Patients  *** */
    {
      path: '/patients',
      name: 'patient-list',
      component: PatientList,
      meta: { requiresAuth: true },
    },
    {
      path: '/patients/:patientId',
      name: 'patient',
      component: Patient,
      meta: { requiresAuth: true },
    },
    /** ** Guides *** */
    {
      path: '/guides-pratiques',
      name: 'guide-list',
      component: Guides,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER },
    },
    /** ** Admins  *** */
    {
      path: '/administrateurs',
      name: 'admin-list',
      component: AdminList,
      meta: { requiresAuth: true, allowedRole: UserRole.ADMIN },
    },
    /** ** Paramètres ** */
    {
      path: '/parametres',
      name: 'parameters',
      component: Parameters,
      meta: { requiresAuth: true, allowedRole: UserRole.ADMIN },
    },
    /** ** Praticiens *** */
    {
      path: '/praticiens',
      name: 'practitioner-list',
      component: PractitionerList,
      meta: { requiresAuth: true, allowedRole: UserRole.ADMIN },
    },
    {
      path: '/praticiens/:practitionerId',
      name: 'practitioner',
      component: Practitioner,
      meta: { requiresAuth: true, allowedRole: UserRole.ADMIN },
    },
    /** ** Traitements  *** */
    {
      path: '/traitements',
      name: 'treatment-list',
      component: TreatmentList,
      meta: { requiresAuth: true },
    },
    {
      path: '/traitements/nouveau/pre-etude',
      name: 'treatment-new-estimate',
      component: EstimateRequest,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER, isFullScreen: true },
    },
    {
      path: '/traitements/:treatmentId/suivi-de-traitement',
      name: 'treatment-extend',
      component: TreatmentPlanRequest,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER, isFullScreen: true },
    },
    {
      path: '/traitements/nouveau/contentions-fixes',
      name: 'treatment-new-fixed-contentions',
      component: ContentionRequest,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER, isFullScreen: true },
    },
    {
      path: '/traitements/nouveau/contentions-amovibles',
      name: 'treatment-new-removable-contentions',
      component: ContentionRequest,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER, isFullScreen: true },
    },
    {
      path: '/traitements/:treatmentId/plan-de-traitement',
      name: 'treatment-plan-request',
      component: TreatmentPlanRequest,
      meta: { requiresAuth: true, allowedRole: UserRole.PRACTITIONER, isFullScreen: true },
    },
    {
      path: '/traitements/visualisation',
      name: 'treatment-viewer',
      component: TreatmentViewer,
      meta: {
        requiresAuth: false,
        isFullScreen: true,
      },
    },
    {
      path: '/traitements/:treatmentId/paiement',
      name: 'treatment-payment-session',
      component: PaymentSession,
      meta: {
        requiresAuth: true,
        allowedRole: UserRole.PRACTITIONER,
        isFullScreen: true,
      },
    },
    {
      path: '/traitements/:treatmentId/paiement/resultat',
      name: 'treatment-payment-result',
      component: PaymentResult,
      meta: {
        requiresAuth: true,
        allowedRole: UserRole.PRACTITIONER,
        isFullScreen: true,
      },
    },
    {
      path: '/traitements/:treatmentId/:tab',
      name: 'treatment',
      component: Treatment,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        const tabs = ['suivi', 'details', 'documents', 'discussions', 'empreintes', 'commandes'];
        if (tabs.includes(to.params.tab)) {
          next();
        } else {
          next({
            name: 'treatment',
            params: { ...to.params, tab: tabs[0] },
            query: { ...to.query },
          });
        }
      },
    },
    {
      path: '*',
      name: 'not-found',
      beforeEnter: (to, from, next) => {
        if (auth.isLoggedIn()) {
          next({ name: 'treatment-list' });
        } else {
          next({ name: 'login' });
        }
      },
    },
    // Exemple de route pour une page connectée
    // {
    //   path: '/une-page-connectée',
    //   name: 'logged-page',
    //   meta: { requiresAuth: true },
    // }
  ],
});

router.beforeEach((to, from, next) => {
  // Vérification du login, navigation au login approprié sinon
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    if (to.meta.allowedRole === UserRole.ADMIN) {
      next({ name: 'admin-login' });
    } else {
      next({ name: 'login' });
    }
    // Vérification du role
  } else if (auth.isLoggedIn() && to.meta.allowedRole) {
    const { role } = auth.getUser();
    if (to.meta.allowedRole === role) {
      next();
    } else {
      next({ name: 'treatment-list' });
    }
  } else {
    next();
  }
});

export default router;
