<template>
  <iframe v-if="documentReady" :src="viewerUrl" class="treatment-viewer" allow="fullscreen" referer="no-referer"></iframe>
</template>

<script>
/* eslint-disable no-console */
export default {
  props: {
    url: String,
  },
  data() {
    return {
      viewerUrl: process.env.VUE_APP_VIEWER_URL,
      viewerOrigin: process.env.VUE_APP_VIEWER_URL.match(/(?<viewerPath>.+)\/.+/).groups.viewerPath,
      documentReady: false,
      appStarted: false,
      isViewerPatient: !!this.$route.query.t,
    };
  },
  mounted() {
    // Ajout du event listener puis de l'iframe
    window.addEventListener('message', (evt) => this.appStartHandler(evt));
    if (this.isViewerPatient) {
      this.viewerUrl = `${this.viewerUrl}?t=1`;
    }
    this.documentReady = true;
    console.log('Document ready !');
  },
  destroyed() {
    window.removeEventListener('message', (evt) => this.appStartHandler(evt));
  },
  methods: {
    appStartHandler(event) {
      console.log('received message', event);
      // Vérification du message
      const { viewerPath } = process.env.VUE_APP_VIEWER_URL.match(/(?<viewerPath>.+)\/.+/).groups;

      if (event.origin === viewerPath && event.data === 'appStart') {
        console.log('message was appStart');
        // Envoi de l'url du fichier au viewer
        const iframe = document.querySelector('iframe');
        iframe.contentWindow.postMessage({ url: this.url || this.$route.query.url }, event.origin);
        console.log('sending url message : ', this.url || this.$route.query.url);
      }
    },
  },
};
</script>

<style lang="sass">
iframe.treatment-viewer
  width: 100%
  height: 100%
  border: none
</style>
