<template>
  <section class="treatment-imprint">

    <!-- Encarts empreintes validées/refusées pour le praticien -->
    <template v-if="!isAdmin">
      <div class="imprint-banner" :class="banner.look" v-if="banner.show">
        <p>{{ banner.title }}</p>
        <p>{{ banner.text }}</p>
      </div>
    </template>

    <!-- Cartes des empreintes -->
    <div class="card-container">
      <ImprintCard v-model="picturesImprint" v-if="picturesImprint" @sent:validation-request="isValidationRequestSent = true"/>
      <ImprintCard v-model="scanImprint" v-if="scanImprint" @sent:validation-request="isValidationRequestSent = true"/>
    </div>

  </section>
</template>

<script>
import auth from '@/services/auth';
import ImprintType from '@/services/enums/imprint-type.enum';
import ImprintValidation from '@/services/enums/imprint-validation.enum';
import TreatmentType from '@/services/enums/treatment-type.enum';
import ImprintCard from '@/views/Treatments/components/ImprintCard.vue';

export default {
  model: {
    prop: 'imprints',
    event: 'input',
  },
  name: 'treatment-imprint',
  components: {
    ImprintCard,
  },
  props: {
    imprints: Array,
    treatmentType: String,
    treatmentStep: String,
  },
  data() {
    return {
      isValidationRequestSent: false,

      isAdmin: auth.isAdmin(),
      TreatmentType,
    };
  },
  computed: {
    // Empreintes photos
    picturesImprint: {
      get() { return this.imprints.find((imprint) => imprint.imprintType === ImprintType.PICTURES); },
      set(value) {
        const index = this.imprints.findIndex((imprint) => imprint.imprintType === ImprintType.PICTURES);
        const newImprints = [...this.imprints];
        newImprints[index] = value;
        this.$emit('input', newImprints);
      },
    },
    // Autres empreintes
    scanImprint: {
      get() { return this.imprints.find((imprint) => imprint.imprintType !== ImprintType.PICTURES); },
      set(value) {
        const index = this.imprints.findIndex((imprint) => imprint.imprintType !== ImprintType.PICTURES);
        const newImprints = [...this.imprints];
        newImprints[index] = value;
        this.$emit('input', newImprints);
      },
    },
    banner() {
      const banner = {
        show: false,
        title: 'Empreintes non conformes',
        text: 'yooo',
        look: 'primary',
      };
      const hasRefused = this.imprints.find((imprint) => imprint.validation === ImprintValidation.REFUSED);
      const hasPending = this.imprints.find((imprint) => imprint.validation === ImprintValidation.PENDING);
      if (hasRefused) {
        banner.title = 'Empreintes non conformes';
        banner.look = 'secondary';
        banner.text = `Pour poursuivre votre demande de ${this.treatmentType === TreatmentType.GUTTER ? 'plan de traitement' : 'contention'}, merci de nous transmettre de nouvelles empreintes. Vous pouvez voir les indications Ealigner dans votre messagerie. Une fois les fichiers remplacés ou renvoyés par la poste, merci de demander une nouvelle vérification.`;
        banner.show = true;
      } else if (hasPending) {
        banner.show = true;
        if (this.treatmentType === TreatmentType.GUTTER) {
          banner.title = 'Demande de vérification reçue';
          banner.text = 'Nous examinons les nouveaux éléments et reviendrons vers vous après vérification et validation.';
          banner.look = 'primary';
        } else {
          banner.title = 'En attente de validation de vos empreintes';
          banner.text = 'Notre équipe étudiera la conformité de vos empreintes dès réception, avant de procéder à l’expédition des contentions. Si les empreintes ne sont pas conformes, vous serez avertis.';
          banner.look = 'secondary';
        }
      }
      return banner;
    },
  },
};
</script>

<style lang="sass">
.treatment-imprint
  .imprint-banner
    width: 100%
    padding: 16px
    border-radius: $global-border-radius
    border: 1px solid currentcolor
    margin-bottom: 26px
    p
      margin: 0
      @include h6
      &:first-child
        @include h4
    &.primary
      color: $main-color
      background-color: $color-primary-20
      border-color: $color-primary-50
    &.secondary
      color: $color-secondary-100
      background-color: $color-secondary-20
      border-color: $color-secondary-40
  .card-container
    display: flex
    justify-content: space-between
    align-items: flex-start
    column-gap: 16px
    .imprint-card
      min-width: 40%
</style>
