<template>
  <section class="treatment-admin-monitoring">
    <div class="grid-x grid-margin-x">
      <div class="cell auto">
        <!-- À FAIRE -->
        <template v-if="treatment.tasks">
          <div class="section-title">
            <h3>À faire</h3>
            <div
              @click="showTodoList = !showTodoList"
              :class="{'arrow-right': !showTodoList}"
            >
              <ArrowDown/>
            </div>
          </div>

          <ul v-if="showTodoList" class="list todo-list">
            <li v-for="(task, index) in tasksList" :key="`task-${index}`">
              <app-checkbox
                :value="task.name"
                v-model="tasksDone"
                @value="updateTreatment"
              >
                {{ task.label }}
              </app-checkbox>
            </li>
          </ul>
        </template>

        <!-- HISTORIQUE DU TRAITEMENT -->
        <div class="section-title">
          <h3>Historique du traitement</h3>
          <div
            @click="showHistory = !showHistory"
            :class="{'arrow-right': !showHistory}"
          >
            <ArrowDown/>
          </div>
        </div>

        <p v-if="!treatmentHistory.length && showHistory" class="no-content">Aucun historique pour le moment</p>

        <ul v-if="treatmentHistory.length && showHistory" class="list history-list">
          <li v-for="history in treatmentHistory" :key="history.eventLogId">
            <div class="history-card">
              <!-- Card admin -->
              <template v-if="history.authorAdmin">
                <UserAvatar
                  v-if="history.targetType === targetType.FILE"
                  type="file"
                  :role="userRole.ADMIN"
                  :user-id="history.authorAdmin.adminId"
                />
                <UserAvatar
                  v-else
                  :role="userRole.ADMIN"
                  :firstname="history.authorAdmin.firstname"
                  :lastname="history.authorAdmin.lastname"
                  :user-id="history.authorAdmin.adminId"
                />
                <div class="history-content">
                  <p v-if="history.targetType === targetType.FILE"><span>{{ history.authorAdmin.firstname }} {{ history.authorAdmin.lastname }}</span> {{ history.description }} <a v-if="history.target" :href="history.target.documentUrl" target="_blank">{{ history.target.documentName }}</a></p>
                  <p v-else><span>{{ history.authorAdmin.firstname }} {{ history.authorAdmin.lastname }}</span> {{ history.description }}</p>
                  <h6>{{ history.createdAt | formatDate('le', true) }}</h6>
                </div>
              </template>

              <!-- Card praticien -->
              <template v-else>
                <UserAvatar
                  :role="userRole.PRACTITIONER"
                  :firstname="history.authorPractitioner.firstname"
                  :lastname="history.authorPractitioner.lastname"
                  :user-id="history.authorPractitioner.practitionerId"
                />
                <div class="history-content">
                  <p><span>{{ history.authorPractitioner.firstname }} {{ history.authorPractitioner.lastname }}</span> {{ history.description }}</p>
                  <h6>{{ history.createdAt | formatDate('le', true) }}</h6>
                </div>
              </template>
            </div>
          </li>
        </ul>
      </div>

      <!-- MESSAGES -->
      <div class="cell auto">
        <Message
          :practitioner="treatment.practitioner"
          :publicMessages="publicMessages"
          :adminMessages="adminMessages"
          :note="treatment.insideNote"
          @saveMessage="onSaveMessage"
          @saveNote="onSaveNote"
          size="small"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Message from '@/components/Message.vue';
import messageApi from '@/services/api/message';
import treatmentApi from '@/services/api/treatment';
import ArrowDown from '@/assets/img/icons/arrowhead-down.svg';
import UserAvatar from '@/components/UserAvatar.vue';
import UserRole from '@/services/enums/user-role.enum';
import TreatmentTaskLabel from '@/services/enums/treatment-task-label.enum';
import MessageType from '@/services/enums/message-type.enum';
import TargetType from '@/services/enums/target-type.enum';

export default {
  name: 'treatment-admin-monitoring',

  props: {
    treatment: Object,
  },

  model: {
    prop: 'treatment',
    event: 'update',
  },

  components: {
    Message,
    ArrowDown,
    UserAvatar,
  },

  data() {
    return {
      treatmentHistory: [],
      treatmentTaskLabel: TreatmentTaskLabel,
      publicMessages: [],
      adminMessages: [],
      userRole: UserRole,

      tasksList: [],
      tasksDone: [],

      showTodoList: true,
      showHistory: true,

      targetType: TargetType,

      refreshTasksDoneInterval: null,
      refreshInterval: null,
    };
  },

  mounted() {
    this.getMessages();
    this.getTreatmentHistory();

    if (this.treatment.tasks) {
      this.formatTasks();
      this.getTasksDone();
    }
    // Mise à jour des messages toutes les 5 s
    this.refreshInterval = setInterval(this.getMessages, 5000);

    // Mise à jour des états des éléments todo
    this.refreshTasksDoneInterval = setInterval(() => {
      this.getTasksDone();
    }, 10000);
  },
  destroyed() {
    clearInterval(this.refreshInterval);
    clearInterval(this.refreshTasksDoneInterval);
  },

  watch: {
    treatment() {
      this.getTreatmentHistory();
    },

    tasks(newValue, oldValue) {
      const newValueCount = newValue ? Object.keys(newValue).length : 0;
      const oldValueCount = oldValue ? Object.keys(oldValue).length : 0;

      if (newValueCount !== oldValueCount && newValueCount) {
        this.formatTasks();
        this.getTasksDone();
      }
    },
  },

  computed: {
    tasks() {
      return this.treatment.tasks;
    },
  },

  methods: {
    // Récupération des messages
    async getMessages() {
      try {
        const [publicResponse, adminResponse] = await Promise.all([
          messageApi.get(this.treatment.treatmentId, MessageType.PUBLIC),
          messageApi.get(this.treatment.treatmentId, MessageType.ADMIN),
        ]);

        this.publicMessages = publicResponse.data;
        this.adminMessages = adminResponse.data;
      } catch (error) {
        if (!error.response || error.response.data.statusCode === 503) {
          // Probablement une erreur de CORS venant exclusivement de Clever-cloud en pré-prod: à ignorer
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la récupération des messages.',
            confirmText: 'Ok',
          });
        }
      }
    },

    // Envoie d'un nouveau message
    async onSaveMessage(message) {
      try {
        await messageApi.create(this.treatment.treatmentId, message);
        this.$notification.show({ text: 'Message enregistré avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la création du message.',
          confirmText: 'Ok',
        });
      }

      this.getMessages();
    },

    // Sauvegarde de la note
    async onSaveNote(note) {
      try {
        const response = await treatmentApi.updateTreatment(this.treatment.treatmentId, null, null, null, null, null, null, null, note);
        this.$emit('update', response.data);
        this.$notification.show({ text: 'Note enregistré avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la l\'enregistrent de la note.',
          confirmText: 'Ok',
        });
      }
    },

    // Récupération de l'historique du traitement
    async getTreatmentHistory() {
      try {
        const response = await treatmentApi.getTreatmentHistory(this.treatment.treatmentId);
        this.treatmentHistory = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la récupération des historiques du traitement.',
          confirmText: 'Ok',
        });
      }
    },

    // Mise à jour du traitement
    async updateTreatment(taskChanged) {
      const tasks = {};
      tasks[taskChanged] = !this.treatment.tasks[taskChanged];

      try {
        const response = await treatmentApi.updateTreatment(this.treatment.treatmentId, null, null, null, null, null, null, null, null, tasks);
        this.$emit('update', response.data);
        this.$notification.show({ text: 'Traitement mis à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la mise à jour du traitement.',
          confirmText: 'Ok',
        });
      }

      this.getTreatmentHistory();
    },

    // Récupère les tâches effectuées
    getTasksDone() {
      this.tasksDone.length = 0;

      Object.entries(this.treatment.tasks).forEach(([key, value]) => {
        if (value) {
          this.tasksDone.push(key);
        }
      });
    },

    // Formate les tâches au format { name: '', label: '' }
    formatTasks() {
      this.tasksList.length = 0;

      Object.entries(this.treatment.tasks).forEach(([key]) => {
        this.tasksList.push({ name: key, label: this.treatmentTaskLabel[key] });
      });
    },
  },
};
</script>

<style lang="sass">
.treatment-admin-monitoring
  padding: 1rem

  .no-content
    text-align: center
    margin-top: 35px
    color: $color-gray-60

  .section-title
    display: flex
    justify-content: space-between
    align-items: center

    &:not(:first-child)
      margin-top: 35px

    h3
      margin: 0

    div
      display: flex
      align-items: center
      cursor: pointer
      transition: .2s

      &.arrow-right
        transform: rotate(-90deg)
        transition: .2s

      svg
        margin: 0

  .list
    padding: 0

    li
      list-style-type: none

  .todo-list
    padding: 24px
    border-radius: $global-border-radius
    background-color: $white

    li

      label
        font-size: 14px

        .checkmark
          margin-right: 8px

      &:not(:first-child)
        margin-top: 12px

  .history-list

    li

      &:not(:first-child)
        margin-top: 5px

      .history-card
        display: flex
        padding: 24px
        border-radius: $global-border-radius
        background-color: $white

        .history-content
          flex: 1
          margin-left: 16px

          p, a
            margin: 0
            color: $color-gray-70

            span
              font-weight: bold
              color: $color-gray-90

          a
            font-size: unset

          h6
            display: block
            margin: 5px 0 0 0
            color: $color-gray-70
</style>
