<!--
****************************************************************************************************
***                                       Upload                                               ***
****************************************************************************************************

Composant upload, il gère :
 - upload simple ou multiple
 - drag & drop de fichier(s)
 - affichage du progress d'axios (simple ou multiple) à l'aide du fichier utilitaire upload.js

*************
*** PROPS ***
*************

  - multiple :
    un ou plusieurs fichiers

  - progress :
    progression de l upload

  - accept :
    type de fichier accepté

  Exemple :
  <app-upload v-model="answer10" :progress="multipleProgressUpload" :multiple="true"/>
  <app-upload v-model="answer11" :progress="progressUpload"/>

  Exemple de gestion d'upload API :
  async handleFiles() {
    if (this.answer10) {
      // Si on a un upload avec des fichiers multiples
      const files = this.answer10;

      // On construit le tableau de promesses
      const uploadPromises = files.map(async (file, index) => {
        await document.upload(file, (progressEvent) => {
          this.multipleProgressUpload = uploadService.getProgress(progressEvent, index);
        });

        this.$notification.show({
          text: `Fichier ${file.name} uploadé !`,
        });
      });

      try {
        await Promise.allSettled(uploadPromises);

        this.multipleProgressUpload = 0;
        this.answer10 = null;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu une erreur lors de l’envoi de vos fichiers.',
          confirmText: 'Ok',
          hasCancel: false,
        });

        throw error;
      }
    }
  }

  async handleSimpleFile() {
    if (this.answer11) {
      try {
        await document.upload(this.answer11, (progressEvent) => {
          // On calcule le pourcentage de la progression
          this.progressUpload = uploadService.getProgress(progressEvent);
        });

        this.$notification.show({
          text: `Fichier ${this.answer11.name} uploadé !`,
        });

        this.progressUpload = 0;
        this.answer11 = null;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu une erreur lors de l’envoi de votre fichier.',
          confirmText: 'OK',
          hasCancel: false,
        });

        throw error;
      }
    }
  }

-->
<template>
  <div class="app-upload" :class="{ drag: isDragOver || progress > 0 || value }"
    @dragenter.prevent="isDragOver = true"
    @dragover.prevent="isDragOver = true"
    @dragleave.prevent="isDragOver = false"
    @drop.prevent="dropFiles">
    <label :class="{ compressing: isCompressing }">
      <template v-if="isCompressing">
        <span><app-spinner /></span>
        <span>Compression en cours...</span>
      </template>
      <template v-else>
        <SvgUpload />
        <small v-if="value && multiple">{{ value.length }} fichiers</small>
        <small v-if="value && !multiple">1 fichier</small>
        <span v-if="progress > 0">Envoi en cours : {{ progress }} %</span>
        <span v-else-if="!value">Cliquez ici pour ajouter {{ multiple ? 'vos fichiers' : 'votre fichier' }}</span>
        <span v-else-if="value">Modifier votre fichier ici</span>
        <input ref="inputFile" type="file" @input="addFiles" :accept="accept" :multiple="multiple">
      </template>
    </label>
  </div>
</template>

<script>
import SvgUpload from '@/assets/img/icons/upload.svg';
import JSZip from 'jszip';

export default {
  components: {
    SvgUpload,
  },
  props: {
    value: null,
    multiple: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: 0,
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    compress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragOver: false,
      isCompressing: false,
    };
  },
  watch: {
    value: 'handleInputFile',
  },
  methods: {
    dropFiles(event) {
      this.isDragOver = false;
      const upload = this.multiple ? [...event.dataTransfer.files] : [...event.dataTransfer.files][0];
      this.$emit('input', upload);
    },
    async addFiles(event) {
      if (this.compress) {
        // Compression des fichiers
        this.isCompressing = true;
        const files = await Promise.all([...event.target.files].map((file) => this.compressFile(file)));
        const upload = this.multiple ? files : files[0];
        this.$emit('input', upload);
        this.isCompressing = false;
      } else {
        const upload = this.multiple ? [...event.target.files] : [...event.target.files][0];
        this.$emit('input', upload);
      }
    },
    handleInputFile(value) {
      if (!value) this.$refs.inputFile.value = null;
    },
    // Compression d'un fichier
    async compressFile(file) {
      // Compression du fichier
      const zip = new JSZip();
      zip.file(file.name, file);
      const zippedFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });

      // Récupération du nom du fichier sans extension
      const { filename } = file.name.match(/(?<filename>.+?)(\.[^.]*$|$)/).groups;
      // retourne le fichier zippé avec l'extension remplacée
      return new File([zippedFile], `${filename}.zip`, { type: zippedFile.type });
    },
  },
};
</script>

<style lang="sass">
.app-upload
  label
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 16px
    border: 1px dashed $color-primary-40
    border-radius: $global-border-radius
    background: $color-primary-10
    @include h6
    &:not(.compressing):hover
      background: $color-primary-20
      border-color: $color-primary-100
      cursor: pointer
    svg
      margin-bottom: 8px
    .app-spinner
      width: 40px
      height: 40px
      margin-bottom: 8px
      span
        border-width: 3px
    input
      display: none
  &.drag
    label svg path
      fill: $main-color
</style>
