<template>
  <div class="user-avatar" :class="colorClass">
    <template v-if="type === 'name'">
      {{ firstname[0] }}{{ lastname[0] }}
    </template>
    <FileUpload v-if="type === 'file'"/>
  </div>
</template>

<script>
import UserRole from '@/services/enums/user-role.enum';
import FileUpload from '@/assets/img/icons/file-upload.svg';

export default {
  name: 'user-avatar',
  components: {
    FileUpload,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    firstname: {
      type: String,
    },
    lastname: {
      type: String,
    },
    role: {
      type: String,
      validator: (val) => Object.keys(UserRole).includes(val),
    },
    type: {
      default: 'name',
      validator: (val) => ['name', 'file'].indexOf(val) !== -1,
    },
  },
  data() {
    return {
      colorClass: '',
      colors: [
        'color-avatar-1',
        'color-avatar-2',
        'color-avatar-3',
        'color-avatar-4',
        'color-avatar-5',
        'color-avatar-6',
        'color-avatar-7',
        'color-avatar-8',
        'color-avatar-9',
        'color-avatar-10',
      ],
    };
  },
  computed: {
    // Gets the first letter of the firstname
    firstnameInitial() {
      return this.user.firstname.charAt(0);
    },
    // Gets the first letter of the lastname
    lastnameInitial() {
      return this.user.lastname.charAt(0);
    },
  },
  mounted() {
    this.getColor();
  },
  methods: {
    getColor() {
      let index = 0;
      if (this.role === UserRole.ADMIN) {
        // Takes all the numbers of the UUID and adds them
        const numbers = this.userId.replace(/\D/g, '');
        let total = 0;
        for (let i = 0; i < numbers.length; i += 1) {
          total += parseInt(numbers[i], 10);
        }
        index = total % (this.colors.length - 1);
      }

      // Select a color depending on the result
      this.colorClass = this.colors[index];
    },
  },
};
</script>

<style lang="sass">
.user-avatar
  width: 40px
  height: 40px
  background-color: rgba($main-color, 0.2)
  border-radius: 50%
  @include text-bold
  font-size: 16px
  color: $main-color
  line-height: 40px
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  svg
    margin-left: 3px
    fill: currentColor
  &.color-avatar-1
    color: $color-avatar-1
    background-color: rgba($color-avatar-1, 0.2)
  &.color-avatar-2
    color: $color-avatar-2
    background-color: rgba($color-avatar-2, 0.2)
  &.color-avatar-3
    color: $color-avatar-3
    background-color: rgba($color-avatar-3, 0.2)
  &.color-avatar-4
    color: $color-avatar-4
    background-color: rgba($color-avatar-4, 0.2)
  &.color-avatar-5
    color: $color-avatar-5
    background-color: rgba($color-avatar-5, 0.2)
  &.color-avatar-6
    color: $color-avatar-6
    background-color: rgba($color-avatar-6, 0.2)
  &.color-avatar-7
    color: $color-avatar-7
    background-color: rgba($color-avatar-7, 0.2)
  &.color-avatar-8
    color: $color-avatar-8
    background-color: rgba($color-avatar-8, 0.2)
  &.color-avatar-9
    color: $color-avatar-9
    background-color: rgba($color-avatar-9, 0.2)
  &.color-avatar-10
    color: $color-avatar-10
    background-color: rgba($color-avatar-10, 0.2)
</style>
