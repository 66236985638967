<template>
  <div class="treatment-step" :class="{ active: needAction, pending: !needAction, 'is-tag': isTag }">
    <div class="dot"></div>
    <span>{{ TreatmentStepLabel[step] }}</span>
  </div>
</template>

<script>
import TreatmentStepLabel from '@/services/enums/treatment-step-label.enum';
import constant from '@/services/utils/constant';
import auth from '@/services/auth';

export default {
  name: 'treatment-step',
  props: {
    step: {
      type: String,
      validator: (val) => Object.keys(TreatmentStepLabel).includes(val),
    },
    isTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TreatmentStepLabel,
    };
  },
  computed: {
    needAction() {
      if (auth.isAdmin()) {
        return constant.STEPS_FOR_ADMIN.includes(this.step);
      }
      return constant.STEPS_FOR_PRACTITIONER.includes(this.step);
    },
  },
};
</script>

<style lang="sass">
.treatment-step
  .dot
    display: inline-block
    width: 8px
    height: 8px
    margin-right: 8px
    border-radius: 50%
  &.is-tag
    padding: 2px 8px 4px
    border-radius: 24px
  &.pending
    .dot
      background: $main-color
    &.is-tag
      color: $main-color
      background: transparentize($main-color, 0.85)
  &.active
    .dot
      background: $color-secondary-100
    &.is-tag
      color: $color-secondary-100
      background: transparentize($color-secondary-100, 0.85)

</style>
