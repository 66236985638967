<template>
  <section class="treatment-documents">

    <app-spinner v-if="isLoadingList || (showGenerator && isLoadingLabelInfo)"/>

    <!-- Générer les étiquettes -->
    <template v-else-if="showGenerator">
      <div class="button-container">
        <app-button look="secondary" @click="showGenerator = false">Annuler</app-button>
        <app-button form="labels-generator-form" type="submit">Générer document</app-button>
      </div>
      <form id="labels-generator-form" @submit.prevent="getLabels">
        <div class="delivery-info container-card">
          <h3>Informations commande</h3>
          <hr />
          <div class="container-card-content">
            <div>
              <app-label>Nom du document *</app-label>
              <app-input v-model="labelsGenerator.documentName" required/>
            </div>
            <div>
              <app-label>Nom du praticien *</app-label>
              <app-input v-model="labelsGenerator.practitionerLastName" required />
            </div>
            <div>
              <app-label>Prénom du praticien *</app-label>
              <app-input v-model="labelsGenerator.practitionerFirstName" required />
            </div>
            <div>
              <app-label>Nom du cabinet *</app-label>
              <app-input v-model="labelsGenerator.practitionerOfficeName" required />
            </div>
            <div>
              <app-label>Adresse *</app-label>
              <app-input v-model="labelsGenerator.practitionerAddress" required/>
            </div>
            <div class="row">
              <div>
                <app-label>Code postal *</app-label>
                <app-input v-model="labelsGenerator.practitionerZipCode" required />
              </div>
              <div>
                <app-label>Ville *</app-label>
                <app-input v-model="labelsGenerator.practitionerCity" required />
              </div>
            </div>
            <div>
              <app-label>Pays *</app-label>
              <app-input v-model="labelsGenerator.practitionerCountry" required />
            </div>
            <div>
              <app-label>Référence patient *</app-label>
              <app-input v-model="labelsGenerator.patientReference" required />
            </div>
          </div>
        </div>
        <div class="delivery-content-info container-card">
          <h3>Contenu commande</h3>
          <hr />
          <div class="container-card-content">
            <div>
              <app-label>Nombre de gouttières maxillaires</app-label>
              <app-input v-model="labelsGenerator.nbGuttersMaximillaire" required/>
            </div>
            <div>
              <app-label>Nombre de transferts maxillaires</app-label>
              <app-input v-model="labelsGenerator.nbTransfersMaximillaire" required/>
            </div>
            <div>
              <app-label>Nombre de contentions maxillaires</app-label>
              <app-input v-model="labelsGenerator.nbContentionsMaximillaire" required/>
            </div>
            <div>
              <app-label>Nombre de gouttières mandibulaires</app-label>
              <app-input v-model="labelsGenerator.nbGuttersMandibulaire" required/>
            </div>
            <div>
              <app-label>Nombre de transferts mandibulaires</app-label>
              <app-input v-model="labelsGenerator.nbTransfersMandibulaire" required/>
            </div>
            <div>
              <app-label>Nombre de contentions mandibulaires</app-label>
              <app-input v-model="labelsGenerator.nbContentionsMandibulaire" required/>
            </div>
          </div>
        </div>
      </form>
    </template>

    <!-- Liste des documents -->
    <template v-else>

      <div class="document-container-card">
        <!-- Header avec boutons pour l'admin -->
        <div class="header">
          <h3>Documents</h3>
          <template v-if="isAdmin">
            <app-button @click="getPractitioner(); showGenerator = true" look="secondary">Générer document</app-button>
            <app-button @click="showCreateDocumentModal = true">+ Ajouter document</app-button>
            <app-button look="dark-primary" @click="showConverterModal = true">Générer Visualisation 3D</app-button>
          </template>
        </div>
        <!-- Contenu : liste des documents + iframe pour visualisation -->
        <div class="content">
          <div class="document-list-container">
            <DocumentRadioCard v-for="document in documents" :key="document.documentId" :document="document" v-model="openedDocumentId"/>
            <div class="document-list-placeholder" v-if="!documents.length">
              <h4>Aucun document disponible</h4>
            </div>
          </div>
          <div class="document-viewer-container">
            <template v-if="openedDocumentId">
              <div class="iframe-container" :class="{ fullscreen: isDocumentFullscreen }" @keyup.esc="isDocumentFullscreen = false">
                <p v-if="openedDocument.isImprint3d && isMobilePhone">Si la visualisation 3D ne s'affiche pas, cliquez sur le bouton "Ouvrir dans un nouvel onglet" ou essayer sur un ordinateur</p>
                <iframe :src="openedDocument.documentUrl" width="100%" height="100%" allow="fullscreen" class="document-iframe"></iframe>
                <app-button v-if="isMobilePhone" look="secondary" size="small" @click="openDocumentOnNewTab(openedDocument.documentUrl)">Ouvrir dans un nouvel onglet</app-button>
                <app-button
                  v-else
                  look="secondary"
                  size="small"
                  @click="isDocumentFullscreen = !isDocumentFullscreen">
                  {{ isDocumentFullscreen ? 'Réduire' : 'Agrandir' }}
                </app-button>
              </div>
              <div class="cta-container" v-if="!openedDocument.isImprint3d">
                <template v-if="openedDocument.documentType.includes('INVOICE')">
                  <template v-if="isAdmin">
                    <app-button
                      v-if="openedDocument.paymentStatus === PaymentStatusType.PAYED"
                      size="small"
                      theme="warning"
                      look="primary"
                      @click="updateOpenedDocumentPayment(PaymentStatusType.PENDING)"
                    >Marquer comme non-payée
                    </app-button>
                    <app-button
                      v-if="openedDocument.paymentStatus === PaymentStatusType.PENDING"
                      size="small"
                      look="secondary"
                      theme="main"
                      @click="updateOpenedDocumentPayment(PaymentStatusType.PAYED)"
                    >Marquer comme payée
                    </app-button>
                  </template>
                  <app-button v-else-if="!isAdmin && !isDpi && treatment.paymentMethod === PaymentMethod.CARD && openedDocument.paymentStatus !== PaymentStatusType.PAYED" theme="warning" @click="$router.push({ name: 'treatment-payment-session', query: { documentId: openedDocument.documentId } })">Payer ma facture</app-button>
                  <app-button v-else-if="!isDpi && openedDocument.paymentStatus !== PaymentStatusType.PAYED" size="small" look="secondary" @click="showPaymentInfoModal = true">Voir nos coordonéees</app-button>
                </template>
                <app-button size="small" @click="downloadDocument">Télécharger <SvgDownload class="following" /></app-button>
              </div>
            </template>
            <!-- Placeholder si aucun document à visualiser -->
            <div v-else class="iframe-placeholder">
              <img src="~@/assets/img/illustrations/consent.png" />
            </div>
          </div>
        </div>
      </div>

    </template>

    <!-- Modale Création d'un document -->
    <app-modal class="create-document-modal" title="Ajouter document" @update:show="showCreateDocumentModal = $event" :show="showCreateDocumentModal">
      <template v-slot:modal-content>
        <form>
          <app-label>Type</app-label>
          <app-select :options="documentOptions" v-model="documentType" required></app-select>
          <template v-if="documentType === DocumentType.ORDER_INVOICE">
            <app-label>Montant de la facture (€)</app-label>
            <app-input placeholder="Montant" type="number" v-model="amount" required/>
          </template>
          <app-upload accept="application/pdf" @input="file = $event"></app-upload>
          <app-file v-if="file" :file="file" @click:delete="file = null"></app-file>
        </form>
      </template>
      <template v-slot:modal-footer>
        <app-button :disabled="!file || !documentType || (documentType === DocumentType.ORDER_INVOICE && !amount)" @click="checkDocumentOverride" :loading="isLoadingNew">Ajouter document</app-button>
      </template>
    </app-modal>

    <!-- Modale Infos de paiment -->
    <template v-if="!isAdmin && treatment.paymentMethod">
      <app-modal class="payment-method-info-modal" :title="paymentMethodInfo.title" :show="showPaymentInfoModal" @update:show="showPaymentInfoModal = $event">
        <template v-slot:modal-content>
          <div>{{ paymentMethodInfo.content }}</div>
        </template>
      </app-modal>
    </template>

    <!-- Modale génération des empreintes 3D -->
    <app-modal title="Convertisseur 3D" @update:show="showConverterModal = $event" :show="showConverterModal" class="converter-modal">
      <template v-slot:modal-content>
        <iframe v-if="!isLoadingImprint3d" :src="converterUrl" id="3d-converter"/>
        <div v-else>
          <app-spinner />
          Compression et enregistrement du fichier généré...
        </div>
      </template>
    </app-modal>
  </section>
</template>

<script>
import auth from '@/services/auth';
import utils from '@/services/utils/utils';
import documentApi from '@/services/api/document';
import practitionerApi from '@/services/api/practitioner';
import DocumentType from '@/services/enums/document-type.enum';
import DocumentTypeLabel from '@/services/enums/document-type-label.enum';
import PaymentMethod from '@/services/enums/payment-method.enum';
import PaymentStatusType from '@/services/enums/payment-status-type.enum';

import * as JSZip from '@/services/zip/jszip.min';
import { saveAs } from 'file-saver';

import SvgDownload from '@/assets/img/icons/download.svg';

import DocumentRadioCard from '../../components/DocumentRadioCard.vue';

export default {
  name: 'treatment-document',
  components: {
    SvgDownload,
    DocumentRadioCard,
  },
  props: {
    treatment: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      /** Données dynamiques */
      documents: [],
      openedDocumentId: this.$route.query.documentId,
      isLoadingList: false,
      isLoadingImprint3d: false,
      isUpdatingDocument: false,
      isDocumentFullscreen: false,
      showConverterModal: false,
      showPaymentInfoModal: false,

      // Nouveau document
      isLoadingNew: false,
      showCreateDocumentModal: false,
      documentType: null,
      amount: null,
      file: null,

      // Générer étiquettes
      showGenerator: false,
      isLoadingLabelInfo: false,
      labelsGenerator: {
        documentName: 'etiquettes',
        practitionerFirstName: this.treatment.practitioner.firstname,
        practitionerLastName: this.treatment.practitioner.lastname,
        practitionerOfficeName: null,
        practitionerAddress: null,
        practitionerZipCode: null,
        practitionerCity: null,
        practitionerCountry: null,
        patientReference: this.treatment.patient.reference,
        nbGuttersMaximillaire: 0,
        nbGuttersMandibulaire: 0,
        nbContentionsMaximillaire: 0,
        nbContentionsMandibulaire: 0,
        nbTransfersMaximillaire: 0,
        nbTransfersMandibulaire: 0,
      },

      /** Données fixes */
      isAdmin: auth.isAdmin(),
      isDpi: auth.isDpi(),
      isMobilePhone: navigator.userAgent.includes('Mobi'),
      documentOptions: utils.optionsFromEnum(DocumentTypeLabel),
      converterUrl: process.env.VUE_APP_CONVERTER_URL,
      DocumentType,
      PaymentMethod,
      PaymentStatusType,
    };
  },
  computed: {
    // Dernière commande
    lastOrderId() {
      return this.treatment.orders[0].orderId; // car classés par ordre décroissant
    },
    // Document actuellement ouvert
    openedDocument() {
      const document = this.documents.find((doc) => doc.documentId === this.openedDocumentId);
      const isImprint3d = document && document.documentType === 'IMPRINT_3D';
      return document ? {
        ...document,
        isImprint3d,
      } : null;
    },
    // Informations nécessaires au paiement (selon chèque ou virement)
    paymentMethodInfo() {
      const info = { title: 'Title', content: 'Content' };
      switch (this.treatment.paymentMethod) {
        case PaymentMethod.TRANSFER:
          info.title = 'Coordonées bancaires';
          info.content = `${process.env.VUE_APP_RIB_LINE_1}\n${process.env.VUE_APP_RIB_LINE_2}\n${process.env.VUE_APP_RIB_LINE_3}`;
          break;
        case PaymentMethod.CHEQUE:
          info.title = 'Adresse d\'envoi';
          info.content = `${process.env.VUE_APP_ADDRESS_LINE_1}\n${process.env.VUE_APP_ADDRESS_LINE_2}\n${process.env.VUE_APP_ADDRESS_LINE_3}`;
          break;
        default:
          break;
      }
      return info;
    },
  },
  watch: {
    // Modifie le document ouvert en fonction de la route
    $route() {
      if (this.$route.query.documentId !== this.openedDocumentId) {
        this.openedDocumentId = this.$route.query.documentId;
      }
    },
    // Modifie la route au changement de document ouvert
    openedDocumentId() {
      if (this.$route.query.documentId !== this.openedDocumentId) {
        this.saveOpenDocumentInQuery();
      }
    },
  },
  created() {
    this.getDocuments();
    window.addEventListener('message', this.generateImprint3d);
  },
  destroyed() {
    window.removeEventListener('message', this.generateImprint3d);
  },
  methods: {
    // Vérifie si le nouveau document en écrasera un autre
    checkDocumentOverride() {
      const orderDocs = [DocumentType.ORDER_INVOICE, DocumentType.LABELS, DocumentType.DMSM, DocumentType.CONFORMITY];
      const willOverride = this.documents.some((doc) => doc.documentType === this.documentType && doc.orderId === (orderDocs.includes(this.documentType) ? this.lastOrderId : null));
      if (willOverride) {
        this.showCreateDocumentModal = false;
        this.$message.show({
          title: `Souhaitez-vous importer un nouveau document de type ${DocumentTypeLabel[this.documentType]} ?`,
          text: 'Ce document a déjà été transmis au praticien, vous pouvez ajouter un nouveau document mais il écrasera et remplacera automatiquement le précédent.',
          hasCancel: true,
          confirmText: 'Ajouter document',
          onConfirm: () => { this.showCreateDocumentModal = true; this.createDocument(); },
        });
      } else {
        this.createDocument();
      }
    },
    // Modifie la query documentId en fonction du document ouvert
    saveOpenDocumentInQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          documentId: this.openedDocumentId,
        },
      });
    },
    downloadDocument() {
      if (this.openedDocument) {
        saveAs(this.openedDocument.documentUrl, this.openedDocument.documentName);
      }
    },
    openDocumentOnNewTab(url) {
      window.open(url, '_blank');
    },
    // REQUÊTE API : liste des documents
    async getDocuments() {
      this.isLoadingList = true;
      try {
        const response = await documentApi.getList(this.$route.params.treatmentId);
        this.documents = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur innattendue est survenue. Veuillez rééssayer plus tard.',
        });
      }
      this.isLoadingList = false;
    },
    // REQUÊTE API : Nouveau document
    async createDocument() {
      this.isLoadingNew = true;
      try {
        await documentApi.create(this.$route.params.treatmentId, this.documentType, this.amount * 100, this.file);
        this.getDocuments();
        this.showCreateDocumentModal = false;
      } catch (e) {
        let text = 'Une erreur est survenue lors de l\'ajout du document. Veuillez rééssayer plus tard.';
        if (e.response.data.message.startsWith('S3 Error')) {
          text = 'Un problème est survenu lors de l\'ajout du document. Cela peut être du à un nom de fichier non conforme. Évitez les accents, les caractères spéciaux (sauf - et _) et les espaces.';
        }
        this.$message.show({
          title: 'Erreur',
          text,
        });
      }
      this.isLoadingNew = false;
    },
    // REQUÊTE API : Récupérer le praticien
    async getPractitioner() {
      this.isLoadingLabelInfo = true;
      try {
        const { data } = await practitionerApi.getPractitioner(this.treatment.practitioner.practitionerId);
        this.labelsGenerator = {
          ...this.labelsGenerator,
          practitionerFirstName: data.firstname,
          practitionerLastName: data.lastname,
          practitionerOfficeName: data.officeName,
          practitionerAddress: data.deliveryAddress.addressLine,
          practitionerZipCode: data.deliveryAddress.zipCode,
          practitionerCity: data.deliveryAddress.city,
          practitionerCountry: data.deliveryAddress.country,
        };
      } catch (err) {
        this.$message.show({ title: 'Erreur', text: 'Une erreur est survenue en récupérant l\'adresse de livraison du praticien Veuillez compléter les informations manuellement.' });
      }
      this.isLoadingLabelInfo = false;
    },

    // REQUÊTE API : télécharge le document généré
    async getLabels() {
      const base = `${process.env.VUE_APP_API_URL}/treatments/${this.treatment.treatmentId}/documents/labels`;
      const query = Object.entries(this.labelsGenerator).map(([key, value]) => `${key}=${value}`).join('&');
      const url = `${base}?${query}&token=${auth.getToken().token}`;
      window.open(url, '_blank');
    },
    // REQUÊTE API : Modifie le document affiché (payé/impayé)
    async updateOpenedDocumentPayment(paymentStatus) {
      this.isUpdatingDocument = true;
      try {
        const response = await documentApi.update(this.openedDocument.documentId, paymentStatus);
        const index = this.documents.findIndex((doc) => doc.documentId === response.data.documentId);
        this.documents.splice(index, 1, response.data);
        this.$notification.show({ text: 'Modification enregistrée avec succès !' });
      } catch (error) {
        throw this.$message.show({
          title: 'Error',
          text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard',
        });
      }
      this.isUpdatingDocument = false;
    },
    // Génère le fichier 3D après message du convertisseur
    async generateImprint3d(event) {
      // Vérification de l'origine du message
      const { converterPath } = process.env.VUE_APP_CONVERTER_URL.match(/(?<converterPath>.+)\/.+/).groups;
      if (event.origin === converterPath) {
        this.isLoadingImprint3d = true;
        try {
          // Compression du fichier reçu
          const zip = new JSZip();
          zip.file('data.json', JSON.stringify(event.data.data));
          const content = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
          const visualisationFile = new File([content], '3D-file.zip', { type: content.type });

          // Enregistrement du fichier & récupération de la liste des documents
          await documentApi.create(this.$route.params.treatmentId, DocumentType.IMPRINT_3D, null, visualisationFile);
          const documentList = await documentApi.getList(this.$route.params.treatmentId);
          this.documents = documentList.data;

          // Notification de succès
          this.$notification.show({ text: 'Fichier enregistré avec succès !' });
        } catch (err) {
          this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue pendant la génération du fichier. Veuillez réessayer plus tard.' });
        }
        // fermeture de la modale
        this.isLoadingImprint3d = false;
        this.showConverterModal = false;
      }
    },
  },
};
</script>

<style lang="sass">
.treatment-documents
  // Génération document
  .button-container
    display: flex
    gap: 16px
    justify-content: flex-end
  .container-card
    @include container-card
    margin: 16px 0
    h3
      margin-top: 0
    hr
      border: 1px solid $main-color
    .container-card-content
      padding-top: 40px
  .delivery-info .container-card-content
    column-count: 2
    gap: 44px
    > div
      break-inside: avoid
      margin-bottom: 44px
      width: 400px
      &.row
        display: flex
        gap: 16px
        > div
          flex-grow: 2
  .delivery-content-info .container-card-content
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 44px

  // Liste des documents
  .app-spinner
    margin: 4rem auto

  .document-container-card
    background: $white
    border-radius: 8px
    padding: 24px
    .header
      display: flex
      padding-bottom: 16px
      border-bottom: 1px solid $color-gray-20
      gap: 16px
      margin-bottom: 24px
      h3
        flex-grow: 2
    .content
      display: flex
      gap: 24px
      .document-list-container
        display: flex
        flex-direction: column
        gap: 16px
        .document-list-placeholder
          @include inset
          min-width: 350px
          max-width: 30%
          color: $color-gray-40
      .document-viewer-container
        width: 100%
        .iframe-container, .iframe-placeholder
          min-height: 500px
          height: 30vw
          width: auto
        .iframe-placeholder
          background: $background-color
          display: flex
          justify-content: center
          align-items: center
          border-radius: 8px
          img
            width: 105px
        .iframe-container
          position: relative
          iframe
            border-radius: 8px
          .app-button
            position: absolute
            bottom: 16px
            right: 16px
            background: $white
            &:hover
              background: $color-gray-20
          &.fullscreen
            position: fixed
            width: 100vw
            height: 100vh
            top: 0
            left: 0
            bottom: 0
        .cta-container
          display: flex
          gap: 16px
          justify-content: flex-end
          margin-top: 24px
          .app-button
            a
              text-decoration: none
              color: unset

  .create-document-modal
    .modal-wrapper
      max-width: 480px
      max-height: unset
      height: fit-content
      form
        margin: 40px 0
        .app-label
          margin-bottom: 5px
        .app-select
          margin-bottom: 10px
        .app-upload
          margin: 24px 0

  .payment-method-info-modal
    .modal-wrapper
      height: fit-content
      width: 400px
      .modal-content
        margin-bottom: 24px
        > div
          @include inset
          white-space: pre-wrap

  .converter-modal .modal-wrapper
    max-height: none
    max-width: none
    width: 100vw
    height: 100vh
    border-radius: 0
    .modal-content
      iframe
        width: 100%
        height: 100%
      div
        margin: 40px auto
        text-align: center
</style>
