import TreatmentStep from '../enums/treatment-step.enum';

const constant = {};

// Constantes: étapes nécessitant une action du praticien ou de l'admin
const STEPS_FOR_PRACTITIONER = [
  TreatmentStep.ESTIMATE_AVAILABLE,
  TreatmentStep.TREATMENT_AVAILABLE,
  TreatmentStep.ESTIMATE_VALIDATED,
];
const STEPS_FOR_ADMIN = [
  TreatmentStep.ESTIMATE_REQUEST,
  TreatmentStep.ESTIMATE_ONGOING,
  TreatmentStep.TREATMENT_REQUEST,
  TreatmentStep.TREATMENT_ONGOING,
  TreatmentStep.TREATMENT_VALIDATED,
  TreatmentStep.TREATMENT_REFUSED,
  TreatmentStep.ORDER_REQUEST,
  TreatmentStep.CONTENTION_REQUEST,
  TreatmentStep.PRODUCTION_ONGOING,
];

// Étape définissable par un admin. Les autres doivent être faite côté praticien
const STEPS_AVAILABLE_TO_ADMIN = [
  TreatmentStep.ESTIMATE_ONGOING,
  TreatmentStep.ESTIMATE_AVAILABLE,
  TreatmentStep.TREATMENT_ONGOING,
  TreatmentStep.TREATMENT_AVAILABLE,
  TreatmentStep.PRODUCTION_ONGOING,
  TreatmentStep.ORDER_SHIPPED,
];

constant.STEPS_FOR_PRACTITIONER = STEPS_FOR_PRACTITIONER;
constant.STEPS_FOR_ADMIN = STEPS_FOR_ADMIN;
constant.STEPS_AVAILABLE_TO_ADMIN = STEPS_AVAILABLE_TO_ADMIN;

export default constant;
