<template>
  <app-radio
    :input="input"
    @input="$emit('input', $event)"
    look="card"
    size="auto"
    :title="isImprint3d ? 'Visualisation 3D' : document.documentName"
    :value="document.documentId"
    class="document-radio-card">
    <template v-slot:default>
      <img v-if="isInvoice" src="~@/assets/img/illustrations/invoice.png" />
      <img v-else-if="isImprint3d" src="~@/assets/img/illustrations/imprint-3d.png" />
      <img v-else src="~@/assets/img/illustrations/estimate.png" />
    </template>
    <template v-if="isInvoice && !isDpi" v-slot:below>
      <span v-if="document.paymentStatus === PaymentStatusType.PAYED" class="payed">Paiement validé</span>
      <span v-if="document.paymentStatus === PaymentStatusType.PROCESSING" class="processing">Paiement en attente</span>
      <span v-if="document.paymentStatus === PaymentStatusType.PENDING" class="pending">Non payé</span>
    </template>
    <template v-slot:after>
      <div class="after-container">
        <SvgArrowheadLeft/>
      </div>
    </template>
  </app-radio>
</template>

<script>
import auth from '@/services/auth';
import DocumentType from '@/services/enums/document-type.enum';
import PaymentStatusType from '@/services/enums/payment-status-type.enum';

import SvgArrowheadLeft from '@/assets/img/icons/arrowhead-left.svg';

import clickOutside from '@/basics/utils/clickOutside';

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    input: {
      type: String,
      default: '',
    },
  },
  components: {
    SvgArrowheadLeft,
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      showDotMenu: false,
      isDpi: auth.isDpi(),
      DocumentType,
      PaymentStatusType,
    };
  },
  computed: {
    // Est une facture
    isInvoice() {
      return [DocumentType.ORDER_INVOICE, DocumentType.DEPOSIT_INVOICE].includes(this.document.documentType);
    },
    // Est une empreinte 3D
    isImprint3d() {
      return this.document.documentType === 'IMPRINT_3D';
    },
  },
};
</script>

<style lang="sass">
.document-radio-card
  label.radio-card
    min-width: 350px
    max-width: 30%
    &:not(:hover):not(.checked)
      border-color: $color-gray-20

    span
      margin-top: 6px
      padding: 6px 8px
      width: fit-content
      border-radius: 24px

      &.payed
        color: $main-color
        background-color: transparentize($main-color, 0.85)

      &.processing
        color: $color-gray-70
        background-color: transparentize($color-gray-70, 0.85)

      &.pending
        color: $color-secondary-100
        background-color: transparentize($color-secondary-100, 0.85)

  .after-container
    display: flex
    justify-content: flex-end
    gap: 16px

    svg
      fill: $color-gray-60

</style>
