import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getAdminList = async () => {
  try {
    const response = await axios.get(`${API_URL}/admins`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createAdmin = async (admin) => {
  try {
    const response = await axios.post(`${API_URL}/admins`, admin);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAdminMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/admins/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateAdminMe = async (admin) => {
  try {
    const response = await axios.put(`${API_URL}/admins/me`, admin);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateAdminMePassword = async (updatePassword) => {
  try {
    await axios.put(`${API_URL}/admins/me/password`, updatePassword);
  } catch (error) {
    throw error;
  }
};

const api = {};

api.getAdminList = getAdminList;
api.createAdmin = createAdmin;
api.getAdminMe = getAdminMe;
api.updateAdminMe = updateAdminMe;
api.updateAdminMePassword = updateAdminMePassword;

api.getAdminMe = getAdminMe;

export default api;
