<template>
  <section class="account">
    <!-- HEADER ADMIN ET PRATICIEN -->
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h1>Mon compte</h1>
      </div>
      <div class="cell shrink">
        <app-button
          v-if="isAdmin"
          size="medium"
          look="primary"
          type="submit"
          form="admin-form"
          :loading="isUserAccountLoading"
        >
          Enregistrer les modifications
        </app-button>
        <app-button
          v-else
          size="medium"
          look="primary"
          type="submit"
          form="practitioner-form"
          :loading="isUserAccountLoading"
        >
          Enregistrer les modifications
        </app-button>
      </div>
    </div>

    <!-- LOADER -->
    <template v-if="isLoading">
      <slot name="loading">
        <app-spinner />
      </slot>
    </template>

    <!-- FORMULAIRES -->
    <template v-else>
      <!-- FORMULAIRE ADMIN -->
      <form v-if="isAdmin && admin" id="admin-form" @submit.prevent="updateAdmin(admin)">
        <!-- INFORMATIONS -->
        <div class="block">
          <h2>Informations</h2>
          <hr>

          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <app-label>Prénom</app-label>
              <app-input type="text" placeholder="Prénom" v-model="admin.firstname"/>
            </div>
            <div class="cell auto">
              <app-label>Nom</app-label>
              <app-input type="text" placeholder="Nom" v-model="admin.lastname"/>
            </div>
          </div>
        </div>

        <!-- IDENTIFIANTS DE CONNEXION -->
        <div class="block">
          <h2>Identifiants de connexion</h2>
          <hr>

          <div class="grid-x grid-margin-x row">
            <div class="cell auto">
              <app-label>Mail</app-label>
              <app-input type="email" placeholder="Mail" disabled :value="admin.email"/>
            </div>
            <div class="password cell auto">
              <app-label>Mot de passe</app-label>
              <app-input type="text" placeholder="Mot de passe" disabled value="**********"/>
              <app-button class="button" size="medium" look="primary" @click="isModalVisible = true">Modifier mon mot de passe</app-button>
            </div>
          </div>
        </div>
      </form>

      <!-- FORMULAIRE PRATICIEN -->
      <form v-else-if="practitioner" id="practitioner-form" @submit.prevent="updatePractitioner(practitioner)">
        <!-- IDENTIFIANTS DE CONNEXION -->
        <div class="block">
          <h2>Identifiants de connexion</h2>
          <hr>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label>Mail</app-label>
              <app-input type="email" placeholder="Mail" disabled :value="practitioner.email"/>
            </div>
            <div class="password cell medium-5">
              <app-label>Mot de passe</app-label>
              <app-input type="password" placeholder="Mot de passe" disabled value="**********"/>
              <app-button class="button" size="medium" look="primary" @click="isModalVisible = true">Modifier mon mot de passe</app-button>
            </div>
          </div>
        </div>

        <!-- INFORMATIONS PERSONNELLES -->
        <div class="block">
          <h2>Informations personnelles</h2>
          <hr>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label required>Civilité</app-label>
              <app-select v-model="practitioner.gender" :options="genderList"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label required>Prénom</app-label>
              <app-input placeholder="Prénom" required v-model="practitioner.firstname"/>
            </div>
            <div class="cell medium-5">
              <app-label required>Nom</app-label>
              <app-input placeholder="Nom" required v-model="practitioner.lastname"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label required>Vous êtes ?</app-label>
              <app-select v-model="practitioner.occupation" :options="occupationTypeLabelList"/>
            </div>
            <div class="cell medium-5">
              <app-label required>Numéro professionnel</app-label>
              <app-input placeholder="Numéro professionnel" required v-model="practitioner.professionalId"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-10">
              <app-label>Type de matériel (S Connect, Medit Link, 3Shape, etc)</app-label>
              <app-input type="textarea" placeholder="Type de matériel" v-model="practitioner.imprintsScanMaterial"/>
            </div>
          </div>
        </div>

        <!-- INFORMATIONS CABINET -->
        <div class="block">
          <h2>Informations cabinet</h2>
          <hr>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label required>Nom du cabinet</app-label>
              <app-input placeholder="Nom du cabinet" required v-model="practitioner.officeName"/>
            </div>
            <div class="cell medium-5">
              <app-label>Numéro DPI</app-label>
              <app-input
                type="text"
                placeholder="N° DPI..."
                v-model="practitioner.dpiNumber"
              />
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label required>Téléphone fixe</app-label>
              <app-input type="number" placeholder="Téléphone fixe" required v-model="practitioner.deskPhone"/>
            </div>
            <div class="cell medium-5">
              <app-label>Téléphone portable</app-label>
              <app-input type="number" placeholder="Téléphone portable" v-model="practitioner.mobilePhone"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <app-label>N°Siret</app-label>
              <app-input placeholder="N°Siret" v-model="practitioner.siret"/>
            </div>
            <div class="cell medium-5">
              <app-label>N° TVA Intracom</app-label>
              <app-input placeholder="N° TVA Intracom" v-model="practitioner.tvaNumber"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-10">
              <app-label>Vos horaires d’ouverture</app-label>
              <app-input type="textarea" placeholder="Ex : 8h00 - 16h00 du lundi au vendredi" v-model="practitioner.openHours"/>
            </div>
          </div>

          <div class="grid-x grid-margin-x row">
            <div class="cell medium-5">
              <h4>Adresse postale</h4>

              <app-label required>Adresse</app-label>
              <app-input placeholder="Adresse" required v-model="practitioner.deliveryAddress.addressLine"/>

              <div class="grid-x grid-margin-x row">
                <div class="cell medium-4">
                  <app-label required>Code Postal</app-label>
                  <app-input type="number" placeholder="Code Postal" required v-model="practitioner.deliveryAddress.zipCode"/>
                </div>

                <div class="cell auto">
                  <app-label required>Ville</app-label>
                  <app-input placeholder="Ville" required v-model="practitioner.deliveryAddress.city"/>
                </div>
              </div>

              <app-label required class="row">Pays</app-label>
              <app-input placeholder="Pays" required v-model="practitioner.deliveryAddress.country"/>
            </div>

            <div class="cell medium-5">
              <h4>Adresse fiscale</h4>

              <app-label required>Adresse</app-label>
              <app-input placeholder="Adresse" required v-model="practitioner.invoiceAddress.addressLine"/>

              <div class="grid-x grid-margin-x row">
                <div class="cell medium-4">
                  <app-label required>Code Postal</app-label>
                  <app-input type="number" placeholder="Code Postal" required v-model="practitioner.invoiceAddress.zipCode"/>
                </div>

                <div class="cell auto">
                  <app-label required>Ville</app-label>
                  <app-input placeholder="Ville" required v-model="practitioner.invoiceAddress.city"/>
                </div>
              </div>

              <app-label required class="row">Pays</app-label>
              <app-input placeholder="Pays" required v-model="practitioner.invoiceAddress.country"/>
            </div>
          </div>
        </div>
      </form>
    </template>

    <!-- MODALE DE MODIFICATION DE MOT DE PASSE ADMIN ET PRATICIEN -->
    <app-modal class="modale" :show.sync="isModalVisible" title="Modifier mot de passe" @update:show="resetForm()">
      <template v-slot:modal-content>
        <form id="update-password" @submit.prevent="updatePassword(passwordForm)">
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-label>Mot de passe actuel</app-label>
              <app-input type="password" placeholder="mot de passe" required v-model="passwordForm.oldPassword"/>
            </div>
            <div class="cell medium-6">
              <app-label>Nouveau mot de passe</app-label>
              <app-input type="password" placeholder="mot de passe" required v-model="passwordForm.newPassword" :errorMessage="errorMessageNewPassword"/>
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
              <app-label>Confirmer le nouveau mot de passe</app-label>
              <app-input type="password" placeholder="mot de passe" required v-model="passwordForm.newPasswordConfirmation" :errorMessage="errorMessageNewPasswordConfirmation"/>
            </div>
          </div>
        </form>
      </template>

      <template v-slot:modal-footer>
        <app-button type="submit" form="update-password" :loading="isPasswordUpdateLoading">Modifier le mot de passe</app-button>
      </template>
    </app-modal>
  </section>
</template>

<script>
import auth from '@/services/auth';
import practitionerApi from '@/services/api/practitioner';
import adminApi from '@/services/api/admin';
import gender from '@/services/enums/gender.enum';
import utils from '@/services/utils/utils';
import occupationTypeLabel from '@/services/enums/occupation-type-label.enum';

export default {
  name: 'account',

  data() {
    return {
      isAdmin: auth.isAdmin(),

      admin: null,
      practitioner: null,

      passwordForm: {
        newPassword: null,
        newPasswordConfirmation: null,
      },

      isLoading: false,
      isUserAccountLoading: false,
      isPasswordUpdateLoading: false,
      isModalVisible: false,
      errorMessageOldPassword: null,
      errorMessageNewPassword: null,
      errorMessageNewPasswordConfirmation: null,

      utils,
      occupationTypeLabel,
    };
  },

  mounted() {
    if (this.isAdmin) {
      this.getAdmin();
    } else {
      this.getPractitioner();
    }
  },

  computed: {
    // Récupère la liste des genres
    genderList() {
      return utils.optionsFromEnum(gender);
    },

    // Récupère la liste des métiers
    occupationTypeLabelList() {
      return utils.optionsFromEnum(occupationTypeLabel);
    },
  },

  methods: {
    // Reset du formulaire
    async resetForm() {
      this.passwordForm = {
        oldPassword: null,
        newPassword: null,
        newPasswordConfirmation: null,
      };
      this.errorMessageNewPassword = null;
      this.errorMessageNewPasswordConfirmation = null;
    },

    // Récupération de l'admin
    async getAdmin() {
      this.isLoading = true;

      try {
        const response = await adminApi.getAdminMe();
        this.admin = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la récupération des données. Veuillez réessayer plus tard.',
        });
      }

      this.isLoading = false;
    },

    // Mise à jour de l'admin
    async updateAdmin(admin) {
      this.isUserAccountLoading = true;

      try {
        const response = await adminApi.updateAdminMe(admin);
        this.admin = response.data;
        this.$notification.show({ text: 'Informations mises à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'enregistrement des données. Veuillez réessayer plus tard.',
        });
      }

      this.isUserAccountLoading = false;
    },

    // Récupération du praticien
    async getPractitioner() {
      this.isLoading = true;

      try {
        const response = await practitionerApi.getPractitionerMe();
        this.practitioner = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la récupération des données. Veuillez réessayer plus tard.',
        });
      }

      this.isLoading = false;
    },

    // Mise à jour du praticien
    async updatePractitioner(practitioner) {
      this.isUserAccountLoading = true;

      try {
        const response = await practitionerApi.updatePractitionerMe(practitioner);
        this.practitioner = response.data;
        this.$notification.show({ text: 'Informations mises à jour avec succès !' });
      } catch (error) {
        if (error.response.data.message === 'Professional Id already used') {
          this.$message.show({
            title: 'Erreur',
            text: 'Le numéro professionnel renseigné est déjà utilisé.',
          });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de l\'enregistrement des données. Veuillez réessayer plus tard.',
          });
        }
      }

      this.isUserAccountLoading = false;
    },

    // Mise à jour du mot de passe
    async updatePassword(updatePassword) {
      this.isPasswordUpdateLoading = true;
      this.errorMessageNewPassword = null;
      this.errorMessageNewPasswordConfirmation = null;

      // Longueur nouveau mot de passe
      if (updatePassword.newPassword.length < 8) {
        this.errorMessageNewPassword = 'Le mot de passe doit faire au minimum 8 caractères.';
        this.isPasswordUpdateLoading = false;
        return;
      }

      // Correspondance mot de passe
      if (updatePassword.newPassword !== updatePassword.newPasswordConfirmation) {
        this.errorMessageNewPassword = 'Les mots de passe ne correspondent pas.';
        this.errorMessageNewPasswordConfirmation = 'Les mots de passe ne correspondent pas.';
        this.isPasswordUpdateLoading = false;
        return;
      }

      try {
        if (this.isAdmin) {
          await adminApi.updateAdminMePassword(updatePassword);
        } else {
          await practitionerApi.updatePractitionerMePassword(updatePassword);
        }

        this.$notification.show({ text: 'Mot de passe mis à jour avec succès !' });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de la modification du mot de passe. Veuillez réessayer plus tard.',
        });
      }

      this.isModalVisible = false;
      this.isPasswordUpdateLoading = false;
      this.resetForm();
    },
  },
};
</script>

<style lang="sass">
.account
  @include screen

  .app-spinner
    margin: 4rem auto

  .header
    margin: 28px 0 52px 0
    background-color: $background-color
    z-index: 1

    h1
      @include h1
      margin: 0

  .block
    padding: 2rem
    margin-bottom: 2rem
    border-radius: $global-border-radius
    background-color: $white

    h2
      margin-top: 0

    hr
      border-top: 2px solid $color-primary-100
      margin-bottom: 2rem

    .row
      margin-top: 20px

    input
      height: 46px

    .app-input, .app-select
      max-width: unset

      textarea
        height: 90px!important

    .app-select

      .box
        padding: 0 0 0 16px

    .password

      input
        margin-bottom: 0.5rem

    .button
      margin: 10px 0 0 0

  .modal-wrapper
    height: unset

    .modal-content
      margin: 40px 0 20px 0

      form > div:not(:first-child)
        margin-top: 25px

</style>
