import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getPractitionerList = async (limit, offset, affiliation, affiliatedCountry, status, orderByCreatedAt, search) => {
  try {
    const response = await axios.get(`${API_URL}/practitioners`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(affiliation && { affiliation }),
        ...(affiliatedCountry && { affiliatedCountry }),
        ...(status && { status }),
        ...(orderByCreatedAt && { orderByCreatedAt }),
        ...(search && { search }),
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const createPractitioner = async (practitioner) => {
  try {
    const response = await axios.post(`${API_URL}/practitioners/auth/register`, practitioner);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPractitioner = async (practitionerId) => {
  try {
    const response = await axios.get(`${API_URL}/practitioners/${practitionerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePractitioner = async (practitionerId, practitioner) => {
  try {
    const response = await axios.put(`${API_URL}/practitioners/${practitionerId}`, practitioner);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPractitionerMe = async () => {
  try {
    const response = await axios.get(`${API_URL}/practitioners/me`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deletePractitioner = async (practitionerId) => {
  try {
    const response = await axios.delete(`${API_URL}/practitioners/${practitionerId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePractitionerMe = async (practitioner) => {
  try {
    const response = await axios.put(`${API_URL}/practitioners/me`, practitioner);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePractitionerMePassword = async (updatePassword) => {
  try {
    await axios.put(`${API_URL}/practitioners/me/password`, updatePassword);
  } catch (error) {
    throw error;
  }
};

const getPractitionerCards = async (practitionerId) => {
  try {
    const response = await axios.get(`${API_URL}/practitioners/${practitionerId}/cards`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deletePractitionerCard = async (practitionerId, cardId) => {
  try {
    await axios.delete(`${API_URL}/practitioners/${practitionerId}/cards/${cardId}`);
  } catch (error) {
    throw error;
  }
};

const api = {};

api.getPractitionerList = getPractitionerList;
api.createPractitioner = createPractitioner;
api.getPractitioner = getPractitioner;
api.updatePractitioner = updatePractitioner;
api.deletePractitioner = deletePractitioner;
api.getPractitionerMe = getPractitionerMe;
api.updatePractitionerMe = updatePractitionerMe;
api.updatePractitionerMePassword = updatePractitionerMePassword;

api.getPractitionerMe = getPractitionerMe;
api.getPractitionerCards = getPractitionerCards;
api.deletePractitionerCard = deletePractitionerCard;

export default api;
