import axios from 'axios';

// À mettre à jour avec l'url d'upload nécessaire
const API_URL = process.env.VUE_APP_API_URL;

// Upload file
const uploadFile = async (imprintId, file, view, handleUploadProgress) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('view', view);
  try {
    const response = await axios.post(
      `${API_URL}/imprints/${imprintId}/upload`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: handleUploadProgress,
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Modifie une empreinte
const updateImprint = async (imprintId, validation, invalidReason, materialType) => {
  try {
    const response = await axios.put(`${API_URL}/imprints/${imprintId}`, {
      validation,
      ...(validation && { validation }),
      ...(invalidReason && { invalidReason }),
      ...(materialType && { materialType }),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.uploadFile = uploadFile;
api.updateImprint = updateImprint;

export default api;
