<template>
  <section class="app-banner">
    <slot/>
  </section>
</template>

<script>
export default {
  name: 'app-banner',
};
</script>

<style lang="sass">
.app-banner
  padding: 16px
  background-color: $color-secondary-20
  border: 1px solid $color-secondary-40
  border-radius: $global-border-radius
  color: $color-secondary-100

  a
    color: $color-secondary-100
  .app-button a
    color: $white

</style>
