<template>
  <section class="treatment-practitioner-monitoring">
    <!-- BANNIÈRES D'ÉTAT -->
    <app-banner v-if="this.treatment.activeBanner && this.treatment.activeBanner.type && !hideTreatmentBanner" class="banner">
      <!-- Pré-étude en attente de validation -->
      <template v-if="treatment.activeBanner.type === bannerType.ESTIMATE_AVAILABLE">
        <div class="banner-message">
          <h4>Souhaitez-vous valider la pré-étude ?</h4>
          <h6>Attention, si vous refusez, votre dossier sera automatiquement clôturé.</h6>
        </div>
        <div class="banner-buttons">
          <app-button class="button" theme="warning" look="secondary" v-on:click="openTargetDocument">Voir le document</app-button>
          <app-button class="button" theme="warning" v-on:click="showModaleType = modaleType.REFUSE_ESTIMATE">Refuser</app-button>
          <app-button class="button" theme="main" v-on:click="showModaleType = modaleType.VALIDATE_ESTIMATE">Valider</app-button>
        </div>
      </template>

      <!-- Pré-étude validée, mais demande de plan de traitement incomplete -->
      <template v-else-if="treatment.activeBanner.type === bannerType.TREATMENT_PENDING">
        <div class="banner-message">
          <h4>Demande de plan de traitement</h4>
          <h6>Pour poursuivre votre dossier, vous devez effectuer une “demande de plan de traitement”. Des informations supplémentaires sur le traitement ainsi qu’un acompte de {{ depositPrice }} vous seront demandés.</h6>
        </div>
        <div class="banner-buttons">
          <app-button
            class="button"
            theme="warning"
            v-on:click="$router.push({ name: 'treatment-plan-request', params: { treatmentId: $route.params.treatmentId }})"
          >
            Demande de plan de traitement
          </app-button>
        </div>
      </template>

      <!-- Plan de traitement en attente de validation -->
      <template v-else-if="treatment.activeBanner.type === bannerType.TREATMENT_AVAILABLE">
        <div class="banner-message">
          <h4>Souhaitez-vous valider le plan de traitement ?</h4>
          <h6>Vous pouvez accepter ou refuser si vous souhaitez des modifications sur le plan de traitement. Si vous ne souhaitez pas poursuivre, merci de nous l’indiquer.</h6>
        </div>
        <div class="banner-buttons">
          <app-button class="button" theme="warning" look="secondary" v-on:click="openTargetDocument">Voir le document</app-button>
          <app-button class="button" theme="warning" v-on:click="showModaleType = modaleType.REFUSE_TREATMENT">Refuser</app-button>
          <app-button class="button" theme="main" v-on:click="showModaleType = modaleType.VALIDATE_TREATMENT">Valider</app-button>
        </div>
      </template>

      <!-- Commande expédiée (et facture disponible) -->
      <template v-else-if="treatment.activeBanner.type === bannerType.ORDER_SHIPPED">
        <div class="banner-message">
          <h4>Votre traitement a été expédié !</h4>
          <h6>Votre facture est disponible dans vos documents. Vous pouvez <a href="https://www.laposte.fr/outils/suivre-vos-envois" target="_blank">suivre votre colis</a> et rentrer le n° de suivi : {{ treatment.orders[0].colissimoCode }}</h6>
        </div>
        <div class="banner-buttons">
          <app-button class="button" theme="warning" look="secondary" v-on:click="openTargetDocument">Voir ma facture</app-button>
          <app-button class="button" theme="warning" v-on:click="showModaleType = modaleType.GIVE_OPINION">Donnez votre avis</app-button>
        </div>
      </template>

      <!-- Facture d’acompte disponible -->
      <template v-else-if="treatment.activeBanner.type === bannerType.DEPOSIT_INVOICE">
        <div class="banner-message">
          <h4>Facture d’acompte</h4>
          <h6>Pour recevoir notre plan de traitement, merci de régler la facture d’acompte. Vous pouvez retrouver ci-contre ou dans vos documents. Selon le moyen de paiement choisi, vous pouvez aussi revoir nos coordonnées.</h6>
        </div>
        <div class="banner-buttons">
          <app-button class="button" theme="warning" look="secondary" v-on:click="openTargetDocument">Voir ma facture</app-button>
          <app-button class="button" theme="warning" v-on:click="showModaleType = modaleType.SEE_BANK_DETAILS">Voir les coordonnées</app-button>
        </div>
      </template>

      <!-- Paiement d’acompte disponible -->
      <template v-else-if="treatment.activeBanner.type === bannerType.DEPOSIT_PREPAYMENT">
        <div class="banner-message">
          <h4>Paiement d’acompte</h4>
          <h6>Pour recevoir notre traitement, merci de régler par carte bancaire l’acompte de {{ depositPrice }} demandé. La facture d’acompte sera disponible sous peu dans vos documents.</h6>
        </div>
        <div class="banner-buttons">
          <app-button class="button" theme="warning" @click="$router.push({ name: 'treatment-payment-session' })">Payer l'acompte</app-button>
        </div>
      </template>
    </app-banner>

    <!-- PROGRESSION -->
    <app-progress
      v-if="treatment.treatmentType === 'GUTTER'"
      :steps="steps"
      :isClosed="treatment.status === treatmentStatus.CLOSED || treatment.step === treatmentStep.ORDER_REQUEST"
    />

    <!-- MESSAGES -->
    <Documents :treatment="treatment"/>

    <!-- MODALES DE VALIDATION / REFUS -->
    <app-modal :show.sync="showConfirmationModale" class="confirm-modale" isAction>
      <template v-slot:modal-content>
        <!-- Refus pour la pré-étude -->
        <template v-if="showModaleType === modaleType.REFUSE_ESTIMATE">
          <h2>Refuser la pré-étude</h2>
          <p>Êtes-vous sûr de vouloir refuser la pré-étude ? Le patient refuse le devis, cela entraîne la clôture automatique de votre dossier.</p>
        </template>

        <!-- Validation pour la pré-étude -->
        <template v-if="showModaleType === modaleType.VALIDATE_ESTIMATE">
          <h2>Valider la pré-étude</h2>
          <p>Êtes-vous sûr de vouloir valider la pré-étude ?</p>
        </template>

        <!-- Refus pour le plan de traitement -->
        <template v-if="showModaleType === modaleType.REFUSE_TREATMENT">
          <h2>Refuser le plan de traitement</h2>
          <p class="modale-subtitle">Pouvez-vous nous préciser les éléments à corriger ?</p>
          <p>Si vous ne souhaitez pas poursuivre, nous clôturerons le dossier. Si vous souhaitez des modifications, merci de nous l’indiquer ci-dessous :</p>
          <app-input v-model="treatmentRefusedMessage" type="textarea"/>
        </template>

        <!-- Validation pour le plan de traitement -->
        <template v-if="showModaleType === modaleType.VALIDATE_TREATMENT">
          <h2>Valider le plan de traitement</h2>
          <p>Êtes-vous sûr de vouloir valider le plan de traitement ? Une fois accepté, nous commencerons la production de votre traitement.</p>
          <div class="peace-package">
            <h3>Souhaitez-vous la formule Tranquillité ?</h3>
            <ul>
              <li>
                <p>2 plans de traitement avec 3D incluse</p>
              </li>
              <li>
                <p>Finitions incluses</p>
              </li>
              <li>
                <p>Contention incluse</p>
              </li>
              <li>
                <p>Casse ou perte de 3 gouttières incluses par arcade</p>
              </li>
              <li>
                <p>Frais d’envoi inclus</p>
              </li>
              <li>
                <p>Garantie 2 ans à compter de l'envoi du traitement</p>
              </li>
            </ul>
            <div class="peace-package-checkbox-container">
              <app-checkbox v-model="isPeacePackageChecked" value="peace-accepted">
                Je souhaite la formule Tranquillité
              </app-checkbox>
            </div>
          </div>
        </template>

        <!-- Voir les coordonnées bancaires -->
        <template v-if="showModaleType === modaleType.SEE_BANK_DETAILS">
          <h2>Nos coordonnées bancaires</h2>
          <p>Merci de bien renseigner systématiquement le numéro de facture et de dossier correspondant.</p>
          <div class="bank-details">
            <template v-if="treatment.paymentMethod === paymentMethod.TRANSFER">
              <p>Merci d’effectuer le virement sur le compte suivant :</p>
              <ul>
                <li>{{ bankDetails.line1 }}</li>
                <li>{{ bankDetails.line2 }}</li>
                <li>{{ bankDetails.line3 }}</li>
              </ul>
            </template>

            <template v-else>
              <p>Merci d'envoyer le chèque à l'adresse suivante :</p>
              <ul>
                <li>{{ postalAddress.line1 }}</li>
                <li>{{ postalAddress.line2 }}</li>
                <li>{{ postalAddress.line3 }}</li>
              </ul>
            </template>
          </div>
        </template>

        <!-- Avis sur l'expérience -->
        <template v-if="showModaleType === modaleType.GIVE_OPINION">
          <h2>Donnez votre avis sur votre expérience !</h2>
          <p>Faites nous part de vos remarques sur la simplicité d’utilisation, rapidité et suivi des demandes, satisfaction globale avec note sur 5, pistes d’améliorations possibles...</p>
          <app-input type="textarea" v-model="userOpinion"/>
        </template>
      </template>

      <template v-slot:modal-footer>
        <!-- Bouton d'annulation -->
        <app-button
          v-if="showCancelModaleButton"
          look="secondary"
          v-on:click="showModaleType = null"
        >
          Annuler
        </app-button>

        <!-- Bouton refus pour la pré-étude -->
        <template v-if="showModaleType === modaleType.REFUSE_ESTIMATE">
          <app-button theme="warning" v-on:click="updateTreatment(treatmentStep.ESTIMATE_REFUSED)" :loading="buttonLoading">Refuser la pré-étude</app-button>
        </template>

        <!-- Bouton validation pour la pré-étude -->
        <template v-if="showModaleType === modaleType.VALIDATE_ESTIMATE">
          <app-button v-on:click="updateTreatment(treatmentStep.ESTIMATE_VALIDATED)" :loading="buttonLoading">Valider la pré-étude</app-button>
        </template>

        <!-- Bouton refus pour le plan de traitement -->
        <template v-if="showModaleType === modaleType.REFUSE_TREATMENT">
          <app-button theme="warning" v-on:click="updateTreatment(treatmentStep.TREATMENT_REFUSED)" :loading="buttonLoading">Refuser le plan de traitement</app-button>
        </template>

        <!-- Bouton validation pour le plan de traitement -->
        <template v-if="showModaleType === modaleType.VALIDATE_TREATMENT">
          <app-button v-on:click="updateTreatment(treatmentStep.TREATMENT_VALIDATED)" :loading="buttonLoading">Valider le plan de traitement</app-button>
        </template>

        <!-- Bouton de fermeture pour les coordonnées bancaires -->
        <template v-if="showModaleType === modaleType.SEE_BANK_DETAILS">
          <app-button v-on:click="hideModale">Fermer</app-button>
        </template>

        <!-- Avis sur l'expérience -->
        <template v-if="showModaleType === modaleType.GIVE_OPINION">
          <app-button v-on:click="addOpinion" :disabled="!userOpinion" :loading="buttonLoading">Envoyer</app-button>
        </template>
      </template>
    </app-modal>
  </section>
</template>

<script>
import messageApi from '@/services/api/message';
import treatmentApi from '@/services/api/treatment';
import auth from '@/services/auth';
import messageType from '@/services/enums/message-type.enum';
import documentType from '@/services/enums/document-type.enum';
import treatmentStep from '@/services/enums/treatment-step.enum';
import treatmentStatus from '@/services/enums/treatment-status.enum';
import modaleType from '@/services/enums/modale-validation.enum';
import bannerType from '@/services/enums/banner-type.enum';
import paymentMethod from '@/services/enums/payment-method.enum';
import * as dayjs from 'dayjs';
import Documents from './Documents.vue';

export default {
  name: 'treatment-practitioner-monitoring',

  components: {
    Documents,
  },

  props: {
    treatment: Object,
  },

  model: {
    prop: 'treatment',
    event: 'update',
  },

  data() {
    return {
      messageType,
      treatmentRefusedMessage: null,
      userOpinion: null,
      treatmentStep,
      treatmentStatus,
      documentType,
      modaleType,
      bannerType,
      paymentMethod,
      isPeacePackageChecked: [],

      showModaleType: null,

      buttonLoading: false,

      isDpi: auth.isDpi(),
      postalAddress: {
        line1: process.env.VUE_APP_ADDRESS_LINE_1,
        line2: process.env.VUE_APP_ADDRESS_LINE_2,
        line3: process.env.VUE_APP_ADDRESS_LINE_3,
      },

      bankDetails: {
        line1: process.env.VUE_APP_RIB_LINE_1,
        line2: process.env.VUE_APP_RIB_LINE_2,
        line3: process.env.VUE_APP_RIB_LINE_3,
      },
      depositPrice: process.env.VUE_APP_DEPOSIT_PRICE,
    };
  },

  destroyed() {
    clearInterval(this.refreshInterval);
  },

  computed: {
    // Vérifie si on affiche la modale
    showConfirmationModale: {
      get() {
        const modaleTypes = [
          this.modaleType.VALIDATE_TREATMENT,
          this.modaleType.REFUSE_TREATMENT,
          this.modaleType.VALIDATE_ESTIMATE,
          this.modaleType.REFUSE_ESTIMATE,
          this.modaleType.SEE_BANK_DETAILS,
          this.modaleType.GIVE_OPINION,
        ];

        return modaleTypes.includes(this.showModaleType);
      },

      set() {
        this.hideModale();
      },
    },

    // Vérifie si on affiche le bouton d'annulation sur la modale
    showCancelModaleButton() {
      const modaleTypes = [
        this.modaleType.VALIDATE_TREATMENT,
        this.modaleType.REFUSE_TREATMENT,
        this.modaleType.VALIDATE_ESTIMATE,
        this.modaleType.REFUSE_ESTIMATE,
        this.modaleType.GIVE_OPINION,
      ];

      return modaleTypes.includes(this.showModaleType);
    },

    // Grandes étapes du traitement avec leur dates
    steps() {
      const steps = [{
        label: 'Pré-étude',
        date: this.treatment.estimateValidatedAt,
        prefix: null,
        isActive: !!this.treatment.estimateValidatedAt,
      }, {
        label: 'Plan de traitement',
        date: this.treatment.treatmentPlanValidatedAt,
        prefix: null,
        isActive: !!this.treatment.treatmentPlanValidatedAt,
      }, {
        label: 'Validation',
        date: this.treatment.productionStartedAt,
        isActive: !!this.treatment.productionStartedAt,
      }, {
        label: 'Expédition',
        date: this.treatment.productionDate ? dayjs(this.treatment.productionDate).businessDaysAdd(1) : null,
        isActive: !!this.treatment.productionDate,
      }];

      // Si la date de production existe et est après aujourd'hui, on enlève le côté actif et on ajoute 'Prévue le'
      if (this.treatment.productionDate && dayjs().isBefore(dayjs(this.treatment.productionDate), 'day')) {
        steps[3].isActive = false;
        steps[3].prefix = 'prévue le';
      }

      return steps;
    },

    hideTreatmentBanner() {
      return [bannerType.DEPOSIT_INVOICE, bannerType.DEPOSIT_PREPAYMENT].includes(this.treatment.activeBanner.type) && this.isDpi;
    },
  },

  methods: {
    // Mise à jour du traitement
    async updateTreatment(step) {
      this.buttonLoading = true;

      try {
        const response = await treatmentApi.updateTreatment(
          this.treatment.treatmentId,
          null,
          treatmentStep[step],
          null,
          null,
          null,
          null,
          this.treatmentRefusedMessage,
          null,
          null,
          this.isPeacePackageChecked.includes('peace-accepted'),
        );
        this.$emit('update', response.data);
      } catch (error) {
        if (step === treatmentStep.ESTIMATE_REFUSED || step === treatmentStep.ESTIMATE_VALIDATED) {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la mise à jour de la pré-étude.',
            confirmText: 'Ok',
          });
        } else {
          this.$message.show({
            title: 'Erreur',
            text: 'Une erreur est survenue lors de la mise à jour du traitement.',
            confirmText: 'Ok',
          });
        }
      }

      this.treatmentRefusedMessage = null;
      this.buttonLoading = false;
      this.hideModale();
    },

    // Ajout de l'avis utilisateur
    async addOpinion() {
      this.buttonLoading = true;

      try {
        await messageApi.create(this.$route.params.treatmentId, { message: this.userOpinion });
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenue lors de l\'envoi du message.',
          confirmText: 'Ok',
        });
      }

      this.hideModale();
      this.userOpinion = null;
      this.buttonLoading = false;
    },

    // Ferme la modale
    hideModale() {
      this.showModaleType = null;
    },

    // Redirection vers la page de paiement d'une facture
    redirectToPayment() {
      this.$router.push({ name: 'treatment-payment-session', params: { treatmentId: this.treatment.treatmentId } });
    },

    // Ouvre le document concerné
    openTargetDocument() {
      if (this.treatment.activeBanner.target && this.treatment.activeBanner.target.documentId) {
        this.$router.push({ ...this.$route, query: { documentId: this.treatment.activeBanner.target.documentId } });
      } else {
        this.$message.show({
          title: 'Erreur',
          text: 'Le document demandé n\'est pas disponible. Veuillez contacter E-aligner via l\'onglet Discussions pour signaler le problème.',
          confirmText: 'Ok',
        });
      }
    },
  },
};
</script>

<style lang="sass">
.treatment-practitioner-monitoring

  .banner
    margin-bottom: 45px
    display: flex

    .banner-message
      flex: 1

      h4, h6
        margin: 0

      h6
        margin-top: 5px

    .banner-buttons
      display: flex
      align-items: center
      margin-left: 20px

      .button
        height: 36px

  .treatment-documents
    margin-top: 40px

  .confirm-modale

    .action-modal
      max-width: 500px
      height: unset

    h2
      margin: 0
      color: $black

    .modale-subtitle
      color: $color-primary-100
      @include text-bold

  .peace-package
    border: 1px solid $color-gray-20
    border-radius: $global-border-radius
    padding: 1rem
    margin-top: 2rem
    text-align: left
    h3
      margin: 0
      color: $color-gray-90
      margin-bottom: 1rem
      @include text-bold
    ul
      margin: 0
      padding-left: 1rem
    li
      margin: 0
      padding: 0
      &:not(:last-child)
        margin-bottom: 0.5rem
      p
        line-height: 1
        margin: 0

  .peace-package-checkbox-container
    margin-top: 1.5rem
    display: flex
    flex-direction: row

  .bank-details
    margin-top: 30px
    border: 1px solid $color-gray-20
    border-radius: $global-border-radius
    padding: 16px
    text-align: left

    p
      @include text-bold
      margin: 0
      color: $color-gray-90

    ul
      margin: 10px 0 0 0
      padding: 0

      li
        list-style-type: none
        line-height: 22px
        text-transform: uppercase
</style>
