import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getPatientList = async (limit, offset, search) => {
  try {
    const response = await axios.get(`${API_URL}/patients`, {
      params: {
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(search && { search }),
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const create = async (patient) => {
  try {
    const response = await axios.post(`${API_URL}/patients`, patient);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Modification d'un patient
 * @param {string} patientId id du patient
 * @param {object} patient patient à mettre à jour
 */
const update = async (patientId, patient) => {
  try {
    const response = await axios.put(`${API_URL}/patients/${patientId}`, patient);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Récupère un patient avec son id
 * @param {string} patientId id du patient
 */
const getById = async (patientId) => {
  try {
    const response = await axios.get(`${API_URL}/patients/${patientId}`);
    return response.data.data;
  } catch (er) {
    throw er;
  }
};

const api = {};

api.getPatientList = getPatientList;
api.create = create;
api.update = update;
api.getById = getById;

export default api;
