<template>
  <div class="card-bloc" :class="`${isSelected ? 'selected' : ''}`">
    <section :class="`${label ? 'label' : ''}`" @click="handleSelectCard">
      <div :class="`checkbox ${isSelected ? 'selected' : ''}`">
        <div v-if="isSelected" class="selected-checkbox" />
      </div>

      <p>{{ text }}</p>
    </section>

    <section class="brand">
      <div v-if="/visa/i.test(brand) || /mastercard/i.test(brand)" :class="`${/visa/i.test(brand) ? 'visa-svg' : ''} ${/mastercard/i.test(brand) ? 'mastercard-svg' : ''}`">
        <SvgMasterCard v-if="/mastercard/i.test(brand)" />
        <SvgVisa v-else-if="/visa/i.test(brand)" />
      </div>
    </section>

    <section v-if="last4" class="delete-card" @click="handleDeleteCard">
      <div>
        <SvgTrash />
      </div>
    </section>
  </div>
</template>

<script>
import SvgMasterCard from '@/assets/img/icons/mastercard.svg';
import SvgVisa from '@/assets/img/icons/visa.svg';
import SvgTrash from '@/assets/img/icons/trash.svg';

export default {
  name: 'card-bloc',
  components: {
    SvgMasterCard,
    SvgVisa,
    SvgTrash,
  },
  props: {
    last4: {
      type: String,
    },
    label: {
      type: String,
    },
    brand: {
      type: String,
    },
    cardId: {
      type: String,
    },
    isSelected: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    text() {
      return this.label || `**** **** **** ${this.last4}`;
    },
  },
  methods: {
    handleSelectCard(evt) {
      this.$emit('select-card', evt);
    },
    handleDeleteCard(evt) {
      this.$emit('delete-card', evt);
    },
  },
};
</script>

<style lang="sass" scoped>
.card-bloc
  display: flex
  flex-direction: row
  margin: 16px 0px
  border: 1px solid $color-gray-40
  border-radius: $global-border-radius
  background-color: $white
  &.selected
    border-color: $color-primary-100
    background-color: $color-primary-40
  &:hover:not(.selected)
    border: 1px dashed $color-primary-40
    background-color: $color-primary-20
  & section:first-child
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    padding: 10px 0px 10px 12px
    cursor: pointer
    &.label
      padding: 0px 12px
    .checkbox
      display: flex
      justify-content: center
      align-items: center
      width: 12px
      height: 12px
      border: 1px solid $color-gray-40
      border-radius: 12px
      background-color: $white
      &.selected
        border-color: $color-primary-100
      .selected-checkbox
        width: 8px
        height: 8px
        border-radius: 4px
        background-color: $color-primary-100
    p
      flex: 1
      padding: 0px 16px
      @include text-bold
  .brand
    display: flex
    justify-content: center
    align-items: center
    div
      display: flex
      justify-content: center
      align-items: center
      width: 66px
      height: 40px
      border: 1px solid $color-gray-40
      border-radius: 4px
      &.mastercard-svg
        background-color: $black
        svg
          height: 90%
          width: 90%
      &.visa-svg
        svg
          height: 70%
          width: 70%
  .delete-card
    display: flex
    align-items: center
    padding: 0px 16px
    cursor: pointer
    &:hover
      div
        background-color: $color-gray-70-transparent-20
    div
      display: flex
      justify-content: center
      align-items: center
      width: 32px
      height: 32px
      border-radius: 16px
      svg
        width: 16px
        height: 16px
</style>
