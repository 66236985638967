<template>
  <div class="app-spinner" :class="{ small }">
    <span></span>
  </div>
</template>

<script>
export default {
  props: {
    small: Boolean,
  },
};
</script>

<style lang="sass">
.app-spinner
  position: relative
  width: 80px
  height: 80px

  span
    display: block
    width: 100%
    height: calc(100% / 2)
    background-color: transparent
    border: 5px solid
    border-top-left-radius: 100px
    border-top-right-radius: 100px
    border-color: $main-color
    border-bottom: 0
    animation: rotate-animation 1s linear infinite
    transform-origin: bottom

  &.small
    width: 1rem
    height: 1rem
    span
      border-width: 1px

@keyframes rotate-animation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

</style>
