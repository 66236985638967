<!--
****************************************************************************************************
***                                       Datepicker                                             ***
****************************************************************************************************

Composant datepicker, configuré par défaut en fr

*************
*** PROPS ***
*************
  pas de props autre que le v-model

  Exemple :
  <app-datepicker v-model="answer5"/>

-->
<template>
  <div class="app-datepicker" :class="{ invalid: isInvalid }">
    <datepicker
    :value="input"
    :language="languages['fr']"
    :required="required"
    format="dd/MM/yyyy"
    :disabled-dates="disabledDates"
    :open-date="openDate"
    @selected="onSelect"
    @invalid="handleInvalid"
    clear-button></datepicker>
    <span class="svg-container"><SvgCalendar /></span>
    <div v-if="errorText" class="error-text">{{ errorText }}</div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';

import SvgCalendar from '@/assets/img/icons/calendar.svg';

export default {
  components: {
    Datepicker,
    SvgCalendar,
  },
  props: {
    value: [Date],
    required: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    openDate: {
      type: [String, Date],
    },
  },
  data() {
    return {
      languages: lang,
      errorText: this.errorMessage,
      isInvalid: !!this.errorMessage,
    };
  },
  computed: {
    input() {
      return this.value;
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.isInvalid = true;
        this.errorText = this.errorMessage;
      } else {
        this.isInvalid = false;
        this.errorText = null;
      }
    },
  },
  methods: {
    onSelect(val) {
      this.$emit('input', val);
    },
    handleInvalid(evt) {
      evt.preventDefault();
      this.errorText = evt.target.validationMessage;
      this.isInvalid = true;
    },
    preventDefault(evt) {
      evt.preventDefault();
    },
  },
  mounted() {
    const input = this.$el.querySelector('input');
    // permet de valider l'input par soumission d'un formulaire
    input.removeAttribute('readonly');
    // empeche l'utilisateur de taper ou coller la date
    input.addEventListener('keydown', this.preventDefault);
    // permet d'afficher le message d'invalidité comme on veut
    input.addEventListener('invalid', this.handleInvalid);
  },
  destroyed() {
    const input = this.$el.querySelector('input');
    input.removeEventListener('invalid', this.handleInvalid);
    input.removeEventListener('keydown', this.preventDefault);
  },
};
</script>

<style lang="sass">
.app-datepicker
  position: relative
  display: inline-block
  width: 100%
  max-width: 400px
  input
    width: 100%
    padding: 12px 16px
    background: white
    font-size: $global-font-size
    border-radius: $global-border-radius
    border: 1px solid $color-gray-20
    transition: all 0.2s ease-in-out
    &:hover:not(:disabled)
      border-color: $color-primary-100
    &:focus:not(:disabled)
      outline: 0
      border-color: $color-primary-100
    &:disabled
      background-color: $color-gray-10

  .vdp-datepicker__calendar header span
    &:not(.disabled):hover
      background: $color-primary-20

  .vdp-datepicker__calendar .cell
    border-radius: $global-border-radius
    &.selected,  &.selected:hover
      background: $main-color
      color: $white
    &:hover,
      background: $color-primary-20
      border-width: 0 !important
  .vdp-datepicker__clear-button, .vdp-datepicker__calendar-button
    position: absolute
    top: 50%
    right: 40px
    margin-top: -10px

  .svg-container
    position: absolute
    top: 11px
    right: 12px
  .error-text
    color: $color-secondary-100

  &.invalid
    input
      border-color: $color-secondary-100
</style>
