<template>
  <div class="person-select">
    <!-- Bar de recherche -->
    <app-search
      class="search-bar"
      :placeholder="`Rechercher un ${person === 'patient' ? 'patient' : 'praticien'}`"
      v-model="search"
    />

    <!-- Tableau -->
    <app-table
      class="table"
      :headers="tableHeaders"
      :data="persons"
      :loading="isLoading"
      @select:line="selectPerson($event)"
      :selected="selectPersonId"
      look="border"
    >
      <template slot="name" slot-scope="{ data }">
        <p class="name">{{ data.firstname }} {{ data.lastname }}</p>
      </template>

      <template slot="birthyear" slot-scope="{ data }">
        <p class="birthyear">{{ data.birthyear }}</p>
      </template>

      <template slot="loading">
        <app-spinner class="loading"/>
      </template>

      <template slot="empty-table">
        <p class="empty-table">Aucun {{ person === 'patient' ? 'patient' : 'praticien' }} trouvé</p>
      </template>
    </app-table>

    <div v-if="persons.length > 0" class="selection-container">
      <!-- Pagination -->
      <app-pagination
        class="pagination"
        :count="metadata.count"
        :offset="metadata.offset"
        :limit="limit"
      />

      <!-- Bouton de validation -->
      <app-button
        class="submit-button"
        v-on:click="validateSelectedPerson"
        :disabled="!selectedPerson"
      >
        Sélectionner
      </app-button>
    </div>
  </div>
</template>

<script>
import patientApi from '@/services/api/patient';
import practitionerApi from '@/services/api/practitioner';
import utils from '@/services/utils/utils';

export default {
  name: 'person-select',

  data() {
    return {
      persons: [],
      metadata: {},

      limit: 5,
      search: null,

      isLoading: true,

      selectedPerson: null,
    };
  },

  props: {
    person: {
      type: String,
      validator: (val) => ['patient', 'practitioner'].indexOf(val) !== -1,
    },
  },

  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },

  mounted() {
    this.search = this.$route.query.search || null;
    if (this.$props.person === 'patient') {
      this.getPatients();
    } else {
      this.getPractitioners();
    }
  },

  watch: {
    $route() {
      if (this.$props.person === 'patient') {
        this.getPatients(this.computeOffset);
      } else {
        this.getPractitioners(this.computeOffset);
      }
    },

    search() {
      this.debouncedUpdateSearchQuery();
    },
  },

  computed: {
    tableHeaders() {
      if (this.$props.person === 'patient') {
        return [
          {
            key: 'name',
            label: 'Nom',
          },
          {
            key: 'birthyear',
            label: 'Année de naissance',
          },
          {
            key: 'email',
            label: 'Email',
          },
        ];
      }
      return [
        {
          key: 'name',
          label: 'Nom',
        },
        {
          key: 'officeName',
          label: 'Cabinet',
        },
        {
          key: 'email',
          label: 'Email',
        },
      ];
    },

    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }

      return null;
    },

    selectPersonId() {
      if (this.selectedPerson) {
        if (this.selectedPerson.patientId) {
          return this.selectedPerson.patientId;
        }

        return this.selectedPerson.practitionerId;
      }

      return null;
    },
  },

  methods: {
    // Récupération des patients
    async getPatients(offset) {
      this.isLoading = true;

      try {
        const persons = await patientApi.getPatientList(this.limit, offset, this.search);

        this.persons = persons.data;
        this.metadata = persons.metadata;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des patients.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Récupération des praticiens
    async getPractitioners(offset) {
      this.isLoading = true;

      try {
        const persons = await practitionerApi.getPractitionerList(this.limit, offset, null, null, null, this.search);

        this.persons = persons.data;
        this.metadata = persons.metadata;
      } catch (error) {
        this.$message.show({
          title: 'Erreur',
          text: 'Une erreur est survenu lors de la récupération des praticiens.',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }

      this.isLoading = false;
    },

    // Met à jour le query search dans la route pour déclencher la recherche
    updateSearchQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          page: 1,
        },
      });
    },

    // Sélectionne / désélectionne un patient
    selectPerson(person) {
      if (this.selectedPerson && (this.selectedPerson.patientId === person.patientId || this.selectedPerson.practitionerId === person.practitionerId)) {
        this.selectedPerson = null;
      } else {
        this.selectedPerson = person;
      }
    },

    // Valide la sélection
    validateSelectedPerson() {
      this.$emit('select', this.selectedPerson);
    },
  },
};
</script>

<style lang="sass">
.person-select

  .search-bar
    margin-top: 10px
    max-width: unset

  .table
    margin-top: 24px

  .selection-container
    display: flex
    align-items: center
    justify-content: space-between
    margin: 24px 0 10px 0

    .pagination
      margin: 0

    .submit-button
      margin: 0

  .name
    font-weight: bold

  .birthyear
    color: $color-gray-60

  .loading
    margin: 30px auto

  .empty-table
    text-align: center
    color: $color-gray-60
</style>
