<!--
****************************************************************************************************
***                                         Button                                               ***
****************************************************************************************************

Un bouton pour toutes les occasions.

*************
*** PROPS ***
*************
  - type : button ou submit
    Type du bouton

  - size : small ou medium
    Taille du bouton

  - theme: main ou warning
    Palette de couleur utilisée pour le bouton

  - look : primary ou secondary
    Style du bouton

  - loading
    Présence du loader ou non

  Exemple :
  <app-button size="small" look="second" :loading="true">Bouton petit avec loader</app-button>
-->
<template>
  <button class="app-button" :type="type" :class="[size, look, theme]" @click="handleClick">
    <span v-if="loading" class="loader">
      <span></span>
      <span></span>
      <span></span>
    </span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: 'button',
      validator: (val) => ['button', 'submit'].indexOf(val) !== -1,
    },
    size: {
      default: 'medium',
      validator: (val) => ['extra-small', 'small', 'medium', 'large'].indexOf(val) !== -1,
    },
    theme: {
      default: 'main',
      validator: (val) => ['main', 'warning'].indexOf(val) !== -1,
    },
    look: {
      default: 'primary',
      validator: (val) => ['primary', 'secondary', 'dark-primary'].indexOf(val) !== -1,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    handleClick(evt) {
      if (!this.loading) {
        this.$emit('click', evt);
      }
    },
  },
};
</script>

<style lang="sass">
.app-button
  display: inline-flex
  align-items: center
  justify-content: center
  margin: 0.2rem
  padding: 12px
  background: $color-primary-100
  color: $white
  border: 0
  border-radius: $global-border-radius
  transition: all ease-in-out 0.2s
  cursor: pointer
  @include button-text
  a
    text-decoration: none
    color: $white

  &:disabled
    opacity: 0.4
    cursor: initial
  &:hover:not(:disabled)
    background: $color-primary-120
  &:focus
    outline: thick solid $color-primary-40

  &.warning
    background: $color-secondary-100
    &:hover
      background: $color-secondary-120
    &:focus
      outline-color: $color-secondary-40

  &.main.secondary
    background: transparent
    color: $color-gray-70
    border: 1px solid $color-gray-40
    &:hover
      background: $color-gray-20
    &:focus
      outline-color: $color-gray-20

  &.main.dark-primary
    background: $color-primary-180
    &:hover
      background: $color-primary-200

  &.warning.secondary
    background: transparent
    color: $color-secondary-100
    border: 1px solid currentcolor
    &:hover
      background: $color-secondary-20
    &:focus
      outline-color: $color-secondary-20

  &.extra-small
    padding: 3px 3px
    font-size: 10px
  &.small
    padding: 6px 10px
    svg.following
      margin-left: 6px
    svg.leading
      margin-right: 6px
  &.medium, &.large
    padding: 12px
    svg.following
      margin-left: 10px
    svg.leading
      margin-right: 10px
  &.large
    padding: 12px 26px

  svg
    width: 16px
    path
      fill: currentcolor

  .loader
    display: inline-block
    position: relative
    width: 40px
    height: 8px

  .loader span
    display: inline-block
    position: absolute
    left: 0
    width: 5px
    background: currentcolor
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite

  .loader span:nth-child(1)
    left: 0
    animation-delay: -0.24s

  .loader span:nth-child(2)
    left: 10px
    animation-delay: -0.12s

  .loader span:nth-child(3)
    left: 20px
    animation-delay: 0

  @keyframes loader
    0%
      top: 0
      height: 180%

    50%, 100%
      top: 0
      height: 100%

</style>
