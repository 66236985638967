<template>
  <section class="payment-result">
    <img src="~@/assets/img/illustrations/smile.png" alt="" />
    <div class="content-container">
      <div class="content-header">
        <router-link :to="{ name: 'treatment-list' }">
          <SvgArrowLeft />
          Retour à mon espace
        </router-link>
        <img src="~@/assets/img/illustrations/logo-dark.png" alt="e-aligner" />
      </div>
      <app-spinner v-if="isLoading" />
      <div v-else class="content">
        <img :src="require(`@/assets/img/illustrations/${statusIcon}.png`)" alt="" />
        <h1>{{ isSuccess ? 'Paiement bien pris en compte' : 'Échec de paiement' }}</h1>
        <p>{{ isSuccess ? 'Nous vous confirmons que votre paiement a bien été pris en compte.\nVotre demande sera traitée dans les plus brefs délais.' : 'Votre paiement n’a pas aboutit, vous pouvez réessayer ultérieurement.\nEn cas de problème récurrent, n’hésitez pas à nous contacter sur votre espace.'}}</p>
        <router-link :to="{ name: 'treatment-list' }"><app-button>Retour à mon espace</app-button></router-link>
      </div>
    </div>
  </section>
</template>

<script>
import treatmentApi from '@/services/api/treatment';
import documentApi from '@/services/api/document';
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';

export default {
  components: {
    SvgArrowLeft,
  },
  data() {
    return {
      paymentStatus: null,
      isLoading: false,
    };
  },
  computed: {
    isSuccess() {
      return ['succeeded', 'processing'].includes(this.paymentStatus);
    },
    statusIcon() {
      return this.isSuccess ? 'payment-attempt-success' : 'payment-attempt-fail';
    },
  },
  created() {
    this.getResult();
  },
  methods: {
    async getResult() {
      this.isLoading = true;
      try {
        const response = this.$route.query.documentId ? await documentApi.getPaymentIntent(this.$route.query.documentId) : await treatmentApi.getPaymentIntent(this.$route.params.treatmentId);
        this.paymentStatus = response.data.status;
      } catch (error) {
        this.$message.show({ title: 'Erreur', text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.' });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.payment-result
  display: flex
  height: 100%
  overflow: hidden
  > img
    height: 100%
    width: 30%
    object-fit: cover
  .app-spinner
    margin: 5rem auto
  .content-container
    padding: 3rem 5% 3rem 10%
    flex-grow: 2
    .content-header
      display: flex
      justify-content: space-between
      align-items: center
      a > svg
        transform: rotate(180deg)
      > img
        width: 150px
    .content
      padding: 3rem 10%
      > img
        width: initial
      h1
        color: $color-gray-100
      p
        color: $color-gray-70
        line-height: 22px
        max-width: 550px
        margin: 3rem 0
        white-space: pre
</style>
