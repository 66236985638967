<template>
  <section class="admin-list">
    <!-- Header -->
    <div class="header grid-x align-middle">
      <div class="cell auto">
        <h1>Administrateurs</h1>
      </div>
      <div class="cell shrink">
        <app-button size="medium" look="primary" @click="isModalVisible = true">+ Ajouter un admin</app-button>
      </div>
    </div>

    <!-- Tableau -->
    <app-table
      :headers="tableHeader"
      :data="admins"
      :loading="isTableLoading"
      :clickable="false"
    >

      <template v-slot:avatar="{ data }">
        <user-avatar
        :userId="data.adminId"
        :firstname="data.firstname"
        :lastname="data.lastname"
        role="ADMIN" />
      </template>

      <template v-slot:admin="{ data }">
        {{ data.lastname }} {{ data.firstname }}
      </template>

      <template v-slot:email="{ data }">
        {{ data.email }}
      </template>
    </app-table>

    <!-- Modal -->
    <app-modal :show.sync="isModalVisible" title="Ajouter un administrateur" @update:show="resetForm()" :isAction="false">
      <template v-slot:modal-content>
        <form id="create-admin" @submit.prevent="create(admin)">
          <div class="grid-x grid-margin-x row">
              <div class="input cell medium-6">
                <app-label required>Nom</app-label>
                <app-input type="text" placeholder="nom" required v-model="admin.lastname"/>
              </div>
              <div class="input cell medium-6">
                <app-label required>Prénom</app-label>
                <app-input type="text" placeholder="prénom" required v-model="admin.firstname"/>
              </div>
              <div class="input cell medium-6">
                <app-label required>Email</app-label>
                <app-input type="email" placeholder="email" required v-model="admin.email"/>
              </div>
              <div class="input cell medium-6">
                <app-label required>Mot de passe</app-label>
                <app-input type="password" placeholder="mot de passe" required v-model="admin.password"/>
              </div>
          </div>
        </form>
      </template>

      <template v-slot:modal-footer>
        <app-button type="submit" form="create-admin" :loading="isAdminCreationLoading">Ajouter un membre</app-button>
      </template>
    </app-modal>

  </section>
</template>

<script>
// services
import adminApi from '@/services/api/admin';

// composants
import UserAvatar from '@/components/UserAvatar.vue';

export default {
  name: 'admin-list',
  components: {
    UserAvatar,
  },
  data() {
    return {
      // Données dynamiques
      admin: {
        lastname: '',
        firstname: '',
        email: '',
        password: '',
      },
      admins: [],
      isTableLoading: false,
      isAdminCreationLoading: false,
      isModalVisible: false,

      // Données statiques
      tableHeader: [
        { label: '', key: 'avatar', size: 'small-1' },
        { label: 'Membre', key: 'admin' },
        { label: 'Email', key: 'email' },
      ],
    };
  },
  mounted() {
    this.getAdminList();
  },

  methods: {
    async getAdminList() {
      this.isTableLoading = true;
      try {
        // requête
        const response = await adminApi.getAdminList();
        this.admins = response.data;
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
        });
      }
      this.isTableLoading = false;
    },

    async create(admin) {
      this.isAdminCreationLoading = true;
      try {
        // requête
        await adminApi.createAdmin(admin);
        this.isModalVisible = false;
        this.resetForm();
        this.$notification.show({
          text: 'Admin créé avec succès !',
        });
        this.getAdminList();
      } catch (error) {
        if (error.response.data.statusCode === 409) {
          this.$message.show({
            title: 'Error',
            text: 'L\'email est déjà utilisé.',
          });
        } else {
          this.$message.show({
            title: 'Error',
            text: 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
          });
        }
      }
      this.isAdminCreationLoading = false;
    },

    async resetForm() {
      this.admin = {
        lastname: '',
        firstname: '',
        email: '',
        password: '',
      };
    },
  },
};
</script>

<style lang="sass">
.admin-list
  @include screen
  .header
    margin: 28px 0 52px 0
    h1
      @include h1
      margin: 0

  .input
    margin-top: 40px

</style>
