<!--
****************************************************************************************************
***                                        Checkbox                                              ***
****************************************************************************************************

Le composant checkbox est proche de celui en HTML5, c'est à dire une variable qui peut prendre 0 à n valeurs.

Pour la gestion de réponses booléennes (vrai/faux), AppSwitch est plus adapté.

*************
*** PROPS ***
*************
  - Value :
    C'est la valeur que prendra la variable dans v-model

    Exemple :
    <app-checkbox :value="value1" v-model="answer1">{{ label1 }}</app-checkbox>
    <app-checkbox :value="value2" v-model="answer1">{{ label2 }}</app-checkbox>
    <app-checkbox :value="value3" v-model="answer1">{{ label3 }}</app-checkbox>

-->
<template>
  <div class="app-checkbox">
    <label>
      <input type="checkbox"
        :checked="Array.isArray(input) && input.includes(value)"
        :required="required"
        @change="onChange()"/>
      <span class="checkmark"></span>
      <slot></slot>
    </label>
  </div>
</template>

<script>

export default {
  model: {
    prop: 'input',
    event: 'input',
  },
  props: {
    input: [String, Array, Number],
    value: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    onChange() {
      let response = null;
      response = Array.isArray(this.input) ? this.input : [];

      if (response.includes(this.value)) {
        response.splice(response.indexOf(this.value), 1);
      } else {
        response.push(this.value);
      }
      this.$emit('input', response);
      this.$emit('value', this.value);
    },
  },
};
</script>

<style lang="sass">
.app-checkbox
  display: inline-block
  label
    display: flex
    position: relative
    align-items: center
    justify-content: flex-start
    margin: 0 8px
    cursor: pointer
    user-select: none
    @include h6

    input
      position: absolute
      opacity: 0
      cursor: pointer
      height: 0
      width: 0

      &:checked ~ .checkmark
        background-color: $color-primary-100
        border-color: transparent

      &:checked ~ .checkmark:after
        opacity: 1

    .checkmark
      display: inline-block
      margin-right: 5px
      height: 12px
      width: 12px
      border: 1px solid $color-gray-40
      background: $white
      border-radius: 3px
      transition: background ease-in-out 0.2s
      position: relative
      flex-shrink: 0
      &:after
        content: ""
        position: absolute
        opacity: 0
        left: 3px
        top: 0
        width: 3px
        height: 6px
        border: solid white
        border-width: 0 1px 1px 0
        transform: rotate(45deg)
        transition: opacity ease-in-out 0.2s

</style>
