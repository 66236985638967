<!--
****************************************************************************************************
***                                           Tabs                                               ***
****************************************************************************************************

Composant de tabs, la gestion de tabs avec des routes est à priviligier.

*************
*** PROPS ***
*************
  - tabs
    C'est un tableau de propriétés pour les Tabs le format [{ label: 'Tab1', route: routeTab1, notification: false }]

    Exemple :
    <app-tab :tabs="[
      { label: 'Infos', route: { name: 'informations', params: { id: 453-324 } }, notification: true },
      { label: 'Actions', route: { name: 'actions', params: { id: 453-324 } }, notification: false }]" />

-->
<template>
  <div class="app-tabs">
    <router-link class="tab"
      v-for="tab in tabs"
      :class="{ notification: tab.notification }"
      :key="`tab-${tab.label}`"
      :to="tab.route"
      replace
    >
        {{ tab.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
  },
};
</script>

<style lang="sass">
.app-tabs
  display: inline-flex
  align-items: center
  position: relative
  width: 100%
  margin: 40px 0
  &::after
    content: ''
    position: absolute
    width: calc(100% + 80px)
    height: 1px
    background-color: $color-gray-20
    bottom: 0
    left: -40px
  .tab
    padding: 8px 0
    color: $color-gray-60
    border-radius: $global-border-radius $global-border-radius 0 0
    transition: all ease-in-out 0.25s
    @include text
    text-decoration: none
    position: relative
    &:not(:first-child)
      margin-left: 32px
    &:hover
      border-bottom: 2px solid currentcolor
    &.router-link-active
      color: $main-color
      border-bottom: 2px solid currentcolor
    &.notification
      &:after
        content: ""
        position: absolute
        width: 7px
        height: 7px
        border-radius: 50%
        top: 0
        left: 100%
        background: $color-accent-2
        transform: translateY(50%)

</style>
