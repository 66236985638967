<template>
  <nav>
    <img src="~@/assets/img/illustrations/logo-light.png" class="logo"/>
    <div class="router-container">
      <template v-if="isAdmin">
        <router-link :to="{ name: 'treatment-list' }">
          <SvgTreatment />
          Traitements
        </router-link>
        <router-link :to="{ name: 'practitioner-list' }">
          <SvgPractitioners />
          Utilisateurs
        </router-link>
        <router-link :to="{ name: 'patient-list' }">
          <SvgPatients />
          Patients
        </router-link>
        <router-link :to="{ name: 'account' }">
          <SvgAccount />
          Compte
        </router-link>
        <router-link :to="{ name: 'admin-list' }">
          <SvgAdmins />
          Administrateurs
        </router-link>
        <router-link :to="{ name: 'parameters' }">
          <SvgParameters />
          Paramètres
        </router-link>
      </template>
      <template v-else>
        <router-link :to="{ name: 'treatment-list' }">
          <SvgTreatment />
          Mes traitements
        </router-link>
        <router-link :to="{ name: 'patient-list' }">
          <SvgPatients />
          Mes patients
        </router-link>
        <router-link :to="{ name: 'account' }">
          <SvgAccount />
          Mon compte
        </router-link>
        <router-link :to="{ name: 'guide-list' }">
          <SvgGuide />
          Guide pratique
        </router-link>
      </template>
    </div>
    <div class="user-container">
      <user-avatar v-bind="user" />
      <div class="user-name">{{ user.firstname }} {{ user.lastname }}</div>
      <a @click="logout">Déconnexion</a>
    </div>
  </nav>
</template>

<script>
import auth from '@/services/auth';
import UserRole from '@/services/enums/user-role.enum';

// Icones
import SvgPatients from '@/assets/img/icons/patients.svg';
import SvgPractitioners from '@/assets/img/icons/practitioners.svg';
import SvgTreatment from '@/assets/img/icons/treatment.svg';
import SvgAccount from '@/assets/img/icons/account.svg';
import SvgGuide from '@/assets/img/icons/guide.svg';
import SvgAdmins from '@/assets/img/icons/admins.svg';
import SvgParameters from '@/assets/img/icons/parameters.svg';

// composants
import UserAvatar from './UserAvatar.vue';

export default {
  name: 'side-nav',
  components: {
    SvgPatients,
    SvgPractitioners,
    SvgTreatment,
    SvgAccount,
    SvgGuide,
    SvgAdmins,
    SvgParameters,
    UserAvatar,
  },
  data() {
    return {
      user: auth.getUser(),
    };
  },
  computed: {
    isAdmin() {
      return this.user ? this.user.role === UserRole.ADMIN : false;
    },
  },
  methods: {
    logout() {
      auth.logout();
      if (this.isAdmin || this.user.isImpersonated) {
        this.$router.push({ name: 'admin-login' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
nav
  padding: 40px 24px
  background: $color-primary-200
  width: 240px
  height: 100vh
  display: flex
  position: fixed
  flex-direction: column
  .router-container
    flex-grow: 2
    display: flex
    flex-direction: column
    margin: 5rem 0
    a
      padding: 16px 12px
      margin: 8px 0
      border-radius: $global-border-radius
      @include text-bold
      color: $white
      text-decoration: none
      text-align: left
      svg
        margin-right: 12px
        vertical-align: middle
        width: 20px
      &.router-link-active, &:hover
        background: $color-primary-190
  .user-container
    text-align: left
    position: relative
    padding-left: 56px
    .user-avatar
      position: absolute
      left: 0
      top: -3px
    .user-name
      @include text-bold
      color: $white
</style>
