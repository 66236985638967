export default Object.freeze({
  ESTIMATE_REQUEST: 'ESTIMATE_REQUEST',
  ESTIMATE_ONGOING: 'ESTIMATE_ONGOING',
  ESTIMATE_AVAILABLE: 'ESTIMATE_AVAILABLE',
  ESTIMATE_VALIDATED: 'ESTIMATE_VALIDATED',
  ESTIMATE_REFUSED: 'ESTIMATE_REFUSED',
  TREATMENT_REQUEST: 'TREATMENT_REQUEST',
  TREATMENT_ONGOING: 'TREATMENT_ONGOING',
  TREATMENT_AVAILABLE: 'TREATMENT_AVAILABLE',
  TREATMENT_VALIDATED: 'TREATMENT_VALIDATED',
  TREATMENT_REFUSED: 'TREATMENT_REFUSED',
  CONTENTION_REQUEST: 'CONTENTION_REQUEST',
  ORDER_REQUEST: 'ORDER_REQUEST',
  PRODUCTION_ONGOING: 'PRODUCTION_ONGOING',
  ORDER_SHIPPED: 'ORDER_SHIPPED',
});
