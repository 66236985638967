<template>
  <section class="practitioner">
    <div class="grid-x">
      <div class="cell auto">
        <router-link class="back-to-list" :to="{ name: 'practitioner-list' }">
          <SvgArrowLeft />
          <span>Liste des praticiens</span>
        </router-link>
      </div>
    </div>
    <!-- HEADER -->
    <div class="header">
      <div>
        <div>
          <h1>{{ isLoading ? 'Praticien...' : `${practitioner.firstname} ${practitioner.lastname}` }}</h1>
          <span :class="['status-tag', practitioner.status]">{{ practitionerStatusLabel[practitioner.status] }}</span>
        </div>
        <div>
          <span>Inscrit depuis le {{ practitioner.createdAt | formatDay }}</span>
        </div>
      </div>
      <div v-if="practitioner.status === PractitionerStatus.NEW">
        <app-button theme="warning" @click="updatePractitioner(PractitionerStatus.REFUSED)">Refuser</app-button>
        <app-button type="submit" form="update-practitioner" @click="updatePractitioner(PractitionerStatus.VALIDATED)">Valider l'inscription</app-button>
      </div>
      <div v-else-if="practitioner.status === PractitionerStatus.VALIDATED">
        <app-button look="secondary" @click="impersonate">Se connecter avec ce compte</app-button>
        <app-button theme="warning" @click="deletePractitioner" :loading="isLoadingDeleting">Supprimer</app-button>
        <app-button type="submit" form="update-practitioner">Enregistrer les modifications</app-button>
      </div>
      <div v-else>
        <app-button theme="warning" @click="deletePractitioner" :loading="isLoadingDeleting">Supprimer</app-button>
      </div>
    </div>
    <!-- BODY -->
    <div class="content" v-if="!isLoading">
      <form id="update-practitioner" @submit.prevent="updatePractitioner()">
        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <article class="card">
              <div class="card-header">
                <h3>Informations personnelles</h3>
              </div>
              <div class="card-content">
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Civilité</app-label>
                    <app-select
                      name="gender"
                      :options="genderOptions"
                      v-model="practitioner.gender"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label>Email</app-label>
                    <app-input
                      name="email"
                      v-model="practitioner.email"
                      disabled
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Prénom</app-label>
                    <app-input
                      type="text"
                      placeholder="Prénom..."
                      v-model="practitioner.firstname"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Nom de famille</app-label>
                    <app-input
                      type="text"
                      placeholder="Nom de famille..."
                      v-model="practitioner.lastname"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Vous êtes ?</app-label>
                    <app-select
                      :options="occupationOptions"
                      v-model="practitioner.occupation"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Numéro professionnel</app-label>
                    <app-input
                      type="text"
                      placeholder="N°..."
                      v-model="practitioner.professionalId"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label>Type de matériel (S Connect, Medit Link, 3Shape, etc)</app-label>
                    <app-input
                      type="textarea"
                      v-model="practitioner.imprintsScanMaterial"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label>Type d’utilisateur (à renseigner)</app-label>
                    <app-select
                      :options="affiliationOptions"
                      v-model="practitioner.affiliation"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label>Pays (à renseigner)</app-label>
                    <app-select
                      :options="countryOptions"
                      v-model="practitioner.affiliatedCountry"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div class="grid-x grid-margin-x">
          <div class="cell auto">
            <article class="card">
              <div class="card-header">
                <h3>Informations cabinet</h3>
              </div>
              <div class="card-content">
                <div class="grid-x grid-margin-x">
                  <div class="cell small-6">
                    <app-label required>Nom du cabinet</app-label>
                    <app-input
                      type="text"
                      placeholder="Cabinet..."
                      v-model="practitioner.officeName"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label>Numéro DPI</app-label>
                    <app-input
                      type="text"
                      placeholder="N° DPI..."
                      v-model="practitioner.dpiNumber"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Téléphone fixe</app-label>
                    <app-input
                      type="text"
                      placeholder="XX XX XX XX XX..."
                      v-model="practitioner.deskPhone"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label>Téléphone portable</app-label>
                    <app-input
                      type="text"
                      placeholder="XX XX XX XX XX..."
                      v-model="practitioner.mobilePhone"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label>N° Siret</app-label>
                    <app-input
                      type="text"
                      placeholder="Siret..."
                      v-model="practitioner.siret"
                      minlength="14"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label>N° TVA Intracom</app-label>
                    <app-input
                      type="text"
                      placeholder="TVA..."
                      v-model="practitioner.tvaNumber"
                      minlength="5"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Vos horaires d’ouverture :</app-label>
                    <app-input
                      type="textarea"
                      placeholder="Horaires..."
                      v-model="practitioner.openHours"
                      :rows="3"
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <div class="card-content-header">
                      <h4>Adresse postale</h4>
                    </div>
                    <app-label required>Adresse</app-label>
                    <app-input
                      type="text"
                      placeholder="10 rue des Champs-Élysées"
                      v-model="practitioner.deliveryAddress.addressLine"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <div class="card-content-header">
                      <h4>Adresse fiscale</h4>
                    </div>
                    <app-label required>Adresse</app-label>
                    <app-input
                      type="text"
                      placeholder="10 rue des Champs-Élysées"
                      v-model="practitioner.invoiceAddress.addressLine"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Code Postal</app-label>
                    <app-input
                      type="text"
                      placeholder="XXXXX"
                      minlength="5"
                      maxlength="5"
                      v-model="practitioner.deliveryAddress.zipCode"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Ville</app-label>
                    <app-input
                      type="text"
                      placeholder="NANTES"
                      v-model="practitioner.deliveryAddress.city"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Code Postal</app-label>
                    <app-input
                      type="text"
                      placeholder="XXXXX"
                      minlength="5"
                      maxlength="5"
                      v-model="practitioner.invoiceAddress.zipCode"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Ville</app-label>
                    <app-input
                      type="text"
                      placeholder="NANTES"
                      v-model="practitioner.invoiceAddress.city"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
                <div class="grid-x grid-margin-x">
                  <div class="cell auto">
                    <app-label required>Pays</app-label>
                    <app-input
                      type="text"
                      placeholder="Pays..."
                      v-model="practitioner.deliveryAddress.country"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                  <div class="cell auto">
                    <app-label required>Pays</app-label>
                    <app-input
                      type="text"
                      placeholder="Pays..."
                      v-model="practitioner.invoiceAddress.country"
                      minlength="2"
                      required
                      :disabled="practitioner.status === PractitionerStatus.REFUSED"
                    />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
// API
import auth from '@/services/auth';
import practitioner from '@/services/api/practitioner';

// Assets
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';

// Enums
import gender from '@/services/enums/gender.enum';
import practitionerStatusLabel from '@/services/enums/practitioner-status-label.enum';
import PractitionerStatus from '@/services/enums/practitioner-status.enum';
import occupationTypeLabel from '@/services/enums/occupation-type-label.enum';
import affiliationTypeLabel from '@/services/enums/affiliation-type-label.enum';
import countryTypeLabel from '@/services/enums/country-affiliation-label.enum';

// Utils
import utils from '@/services/utils/utils';

export default {
  name: 'practitioner',
  components: {
    SvgArrowLeft,
  },
  data() {
    return {
      isAdmin: auth.isAdmin(),
      isLoading: false,
      isLoadingDeleting: false,
      practitioner: {
        email: null,
        repeatEmail: null,
        password: null,
        repeatPassword: null,
        firstname: null,
        lastname: null,
        occupation: null,
        professionalId: null,
        gender: null,
        imprintsScanMaterial: null,
        siret: null,
        tvaNumber: null,
        deskPhone: null,
        dpiNumber: null,
        status: null,
        mobilePhone: null,
        openHours: null,
        officeName: null,
        acceptConditions: [],
        deliveryAddress: {
          city: null,
          zipCode: null,
          addressLine: null,
          country: null,
        },
        invoiceAddress: {
          city: null,
          zipCode: null,
          addressLine: null,
          country: null,
        },
        createdAt: null,
      },
      practitionerStatusLabel,
      genderOptions: utils.optionsFromEnum(gender),
      occupationOptions: utils.optionsFromEnum(occupationTypeLabel),
      affiliationOptions: utils.optionsFromEnum(affiliationTypeLabel),
      countryOptions: utils.optionsFromEnum(countryTypeLabel),
      PractitionerStatus,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const { data } = await practitioner.getPractitioner(this.$route.params.practitionerId);
        this.practitioner = data;
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du praticien',
          confirmText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    async deletePractitioner() {
      this.isLoadingDeleting = true;
      try {
        await practitioner.deletePractitioner(this.practitioner.practitionerId);
        this.$notification.show({
          title: 'Succès',
          text: 'Le praticien a bien été supprimé',
          confirmText: 'Ok',
        });
        this.$router.push({ name: 'practitioner-list' });
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Des patients sont suivis par ce praticien. Vous ne pouvez pas supprimer ce praticien tant que des patients lui sont attribués. Vous pouvez changer le praticien sur la fiche patient.',
          confirmText: 'Ok',
        });
      }
      this.isLoadingDeleting = false;
    },
    async impersonate() {
      this.isLoading = true;
      try {
        await auth.impersonate(this.practitioner.practitionerId);
        this.$router.push({ name: 'treatment-list' });
        window.location.reload();
      } catch (err) {
        let message = 'Il y a eu un problème lors de la connexion au praticien';
        if (err.response.data.statusCode === 403) {
          message = 'Ce compte n\'a pas encore été validé ou a été refusé.';
        }
        this.$message.show({
          title: 'Erreur',
          text: message,
          confirmText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    async updatePractitioner(status) {
      this.isLoading = true;
      try {
        const response = await practitioner.updatePractitioner(this.practitioner.practitionerId, {
          ...this.practitioner,
          ...(status && { status }),
        });
        this.practitioner = response.data;
        this.$notification.show({
          title: 'Succès',
          text: 'Le praticien a été mis à jour',
          confirmText: 'Ok',
        });
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: err.response.data.message === 'Professional Id already used'
            ? 'Un praticien avec ce N° professionnel existe déjà'
            : 'Il y a eu un problème lors de la mise à jour du praticien',
          confirmText: 'Ok',
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.practitioner
  @include screen

  .back-to-list
    display: flex
    flex-direction: row
    align-items: center

    svg
      transform: rotate(180deg)

    span
      margin-left: 4px

  .header
    display: flex
    justify-content: space-between
    margin: 18px 0 52px 0

    >div:first-child
      > div:first-child
        display: flex
        align-items: center

        h1
          margin: 0

        span
          margin-left: 1rem

      span
        margin-top: 8px
        color: $color-gray-70
    .app-button:not(:last-child)
      margin-right: 8px

    .status-tag
      @include practitioner-status

  .content
    > div:not(:last-child)
      margin-bottom: 40px

    .card
      background-color: $white
      border-radius: $global-border-radius
      padding: 28px 40px 44px 40px

    .card-content
      max-width: 800px
      > div:not(:last-child)
        margin-bottom: 3rem

      .app-input
        max-width: 100%

    .card-header
        position: relative
        padding-bottom: 16px
        margin-bottom: 34px
        h3
          margin: 0
        &::after
          content: ""
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          background-color: $color-primary-100
          height: 1.5px
</style>
