<template>
  <section class="patient" >
    <div class="grid-x">
      <div class="cell auto">
        <router-link class="back-to-list" :to="{ name: 'patient-list' }">
          <SvgArrowLeft/>
          <span>Liste des patients</span>
        </router-link>
      </div>
    </div>
    <!-- HEADER -->
    <div class="header grid-x">
      <div class="cell auto">
        <h1>{{ isLoading ? 'Patient...' : patient.reference }}</h1>
      </div>
      <div class="cell shrink">
        <app-button @click="updatePatient">
          <span class="app-button-text">Enregistrer les modifications</span>
        </app-button>
      </div>
    </div>
    <!-- BODY -->
    <div class="content" v-if="!isLoading">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <!-- PATIENT -->
          <article class="card-patient">
            <div class="card-header">
              <h3>Informations patient</h3>
            </div>
            <div class="card-content-patient">
              <div class="grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Civilité</app-label>
                  <app-select
                    name="gender"
                    :options="genderOptions"
                    v-model="patient.gender"
                    required
                  />
                </div>
                <div class="cell auto">
                  <app-label>Référence patient</app-label>
                  <app-input
                    type="text"
                    placeholder="Référence..."
                    v-model="patient.reference"
                    disabled
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label>Prénom</app-label>
                  <app-input
                    type="text"
                    placeholder="Prénom..."
                    v-model="patient.firstname"
                    maxlength="3"
                    minlength="3"
                    required
                  />
                </div>
                <div class="cell auto">
                  <app-label>Nom de famille</app-label>
                  <app-input
                    type="text"
                    placeholder="Nom de famille..."
                    v-model="patient.lastname"
                    required
                  />
                </div>
              </div>
              <div class="grid-x grid-margin-x">
                <div class="cell auto">
                  <app-label required>Année de naissance</app-label>
                  <app-input
                    type="number"
                    placeholder="Année..."
                    minlength="4"
                    maxlength="4"
                    v-model="patient.birthyear"
                    required
                  />
                </div>
                <div class="cell auto">
                  <app-label>Email</app-label>
                  <app-input
                    type="email"
                    placeholder="Email..."
                    v-model="patient.email"
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="cell auto">
          <!-- PRACTITIONER -->
          <article v-if="isAdmin" class="card-practitioner">
            <div class="card-header">
              <h3>Praticien associé</h3>
            </div>
            <div class="card-content-practitioner grid-x grid-margin-x">
              <div class="cell shrink">
                <app-input
                  type="email"
                  placeholder="Praticien..."
                  :value="`${patient.practitioner.firstname} ${patient.practitioner.lastname}`"
                  disabled
                />
              </div>
              <div class="cell auto">
                <app-button size="small" @click="isChangePractitionerModalOpen = true">Modifier le praticien</app-button>
              </div>
            </div>
          </article>
          <!-- TREATMENTS -->
          <article class="card-treatments">
            <div class="card-header">
              <h3>Traitements</h3>
            </div>
            <div class="card-content-treatment">
              <ul v-if="patient.treatments">
                <li v-for="treatment in patient.treatments" :key="treatment.treatmentId">
                  <router-link class="treatment-link" :to="{ name: 'treatment', params: { treatmentId: treatment.treatmentId, tab: 'suivi' } }">
                    <SvgLink />
                    <strong>{{ treatment.reference }}</strong>
                  </router-link>
                  <span class="treatment-date">{{ treatment.createdAt | formatDate('', false, false) }}</span>
                  <p :class="`${treatment.status} status-tag`">{{ treatmentStatusLabel[treatment.status] }}</p>
                </li>
              </ul>
              <p v-else class="no-treatment">Aucun traitement en cours</p>
              <div class="grid-x" v-if="!isAdmin">
                <div class="cell auto">
                  <button type="button" class="add-treatment" @click="isAddTreatmentModalOpen = true">
                    <SvgPlus />
                    <span>Nouveau traitement</span>
                  </button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="patient-loading" v-else>
      <app-spinner />
    </div>
    <!-- CHANGE PRACTITIONER MODAL -->
    <app-modal :show="isChangePractitionerModalOpen" title="Choisir le praticien" class="change-practitioner-modal" @update:show="isChangePractitionerModalOpen = false">
      <template v-slot:modal-content>
        <PersonSelect @select="changePractitioner($event); isChangePractitionerModalOpen = false" person="practitioner" />
      </template>
    </app-modal>
    <!-- ADD TREATMENT MODAL -->
    <app-modal
      :show="isAddTreatmentModalOpen"
      title="Ajouter un traitement"
      @update:show="isAddTreatmentModalOpen = false"
      size="small"
    >
      <template v-slot:modal-content>
        <div class="add-treatment-modal-content">
          <div>
            <h4>Quel type de traitement voulez-vous créer ? </h4>
            <p>(sélectionnez un des éléments et validez)</p>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
              <app-radio
                value="CONTENTION"
                v-model="addTreatment"
                look="card"
                title="Contention"
                subtitle="Idéal pour terminer un traitement"
                size="auto"
              >
                <img src="@/assets/img/illustrations/contention.png" alt="Contention" loading="lazy" />
              </app-radio>
            </div>
          </div>
          <div class="grid-x grid-margin-x">
            <div class="cell auto">
              <app-radio
                value="NEW_TREATMENT"
                v-model="addTreatment"
                look="card"
                title="Nouveau traitement"
                subtitle="Pour un nouveau patient ou un traitement repris à zéro"
                size="auto"
              >
                <img src="@/assets/img/illustrations/new-treatment.png" alt="Nouveau traitement" loading="lazy" />
              </app-radio>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <app-button @click="newTreatment">Valider</app-button>
      </template>
    </app-modal>

    <ContentionModal :show="isContentionModalOpen" :patient="patient" @update:show="isContentionModalOpen = false" />
  </section>
</template>

<script>
// API
import auth from '@/services/auth';
import patient from '@/services/api/patient';
import practitioner from '@/services/api/practitioner';

// Components
import PersonSelect from '@/components/PersonSelect.vue';
import ContentionModal from '@/components/ContentionModal.vue';

// Utils
import utils from '@/services/utils/utils';

// Enums
import treatmentStatus from '@/services/enums/treatment-status.enum';
import treatmentStatusLabel from '@/services/enums/treatment-status-label.enum';
import gender from '@/services/enums/gender.enum';

// Assets
import SvgArrowLeft from '@/assets/img/icons/arrow-left.svg';
import SvgPlus from '@/assets/img/icons/plus.svg';
import SvgLink from '@/assets/img/icons/link.svg';

export default {
  name: 'patient',
  components: {
    SvgArrowLeft,
    SvgPlus,
    SvgLink,
    PersonSelect,
    ContentionModal,
  },
  data() {
    return {
      isAdmin: auth.isAdmin(),
      treatmentStatus,
      treatmentStatusLabel,
      isChangePractitionerModalOpen: false,
      isAddTreatmentModalOpen: false,
      isContentionModalOpen: false,
      addTreatment: null,
      genderOptions: utils.optionsFromEnum(gender),
      isLoading: false,
      patient: {
        practitioner: {
          firstname: null,
          lastname: null,
        },
        gender: null,
        email: null,
        reference: null,
        treatments: [],
        birthyear: null,
      },
      search: null,
      tableHeaders: [
        { label: 'Praticien', key: 'name' },
        { label: 'Cabinet', key: 'officeName' },
        { label: 'Email', key: 'email' },
        { label: '', key: 'radio' },
      ],
      limit: 5,
      practitioners: {
        data: [],
        metadata: {
          count: 0,
          offset: 0,
        },
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
  },
  computed: {
    computeOffset() {
      if (this.$route.query.page) {
        return (this.$route.query.page - 1) * this.limit;
      }
      return null;
    },
  },
  created() {
    this.debouncedUpdateSearchQuery = utils.debounce(this.updateSearchQuery, 500);
  },
  watch: {
    isChangePractitionerModalOpen(val) {
      if (val) {
        this.getPractitioner();
      }
    },
    search() {
      this.debouncedUpdateSearchQuery();
    },
    $route() {
      if (!this.isLoading) {
        this.getPractitioner();
      }
    },
  },
  methods: {
    async getData() {
      try {
        if (!this.$route.params.patientId) {
          throw new Error();
        }
        this.patient = await patient.getById(this.$route.params.patientId);
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération du patient',
          cancelText: 'Ok',
        });
      }
    },
    async getPractitioner() {
      this.isLoading = true;
      try {
        this.practitioners = await practitioner.getPractitionerList(this.limit, this.computeOffset, null, null, null, this.search);
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la récupération des praticiens',
          cancelText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    async updatePatient() {
      this.isLoading = true;
      // On remplace le champs de test par null si il est vide
      if (this.patient.email && this.patient.email.match(/^\s*$/g)) {
        this.patient.email = null;
      }
      try {
        await patient.update(this.patient.patientId, {
          gender: this.patient.gender,
          birthyear: this.patient.birthyear,
          firstname: this.patient.firstname,
          lastname: this.patient.lastname,
          email: this.patient.email,
          practitionerId: this.patient.practitioner.practitionerId,
        });
        await this.getData();
      } catch (err) {
        this.$message.show({
          title: 'Erreur',
          text: 'Il y a eu un problème lors de la modification du patient',
          cancelText: 'Ok',
        });
      }
      this.isLoading = false;
    },
    newTreatment() {
      if (this.addTreatment === 'CONTENTION') {
        this.isAddTreatmentModalOpen = false;
        this.isContentionModalOpen = true;
      } else if (this.addTreatment === 'NEW_TREATMENT') {
        this.$router.push({
          name: 'treatment-new-estimate',
          params: {
            patient: this.patient,
          },
        });
      }
    },
    formatDate(date) {
      const dateFormat = new Date(date);
      return `${dateFormat.getDate()}/${dateFormat.getMonth()}/${dateFormat.getFullYear()}`;
    },
    updateSearchQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          search: this.search || undefined,
          page: 1,
        },
      });
    },
    changePractitioner(val) {
      this.patient.practitioner = {
        firstname: val.firstname,
        lastname: val.lastname,
        practitionerId: val.practitionerId,
      };
      this.isChangePractitionerModalOpen = false;
    },
  },
};
</script>

<style lang="sass">
.patient
  @include screen

  .table
    border: 1px $color-gray-20 solid

  .change-practitioner-search
    margin-bottom: 1rem
    max-width: 100% !important

  .patient-loading
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-content: center

  .back-to-list
    display: flex
    flex-direction: row
    align-items: center

    svg
      transform: rotate(180deg)
    span
      margin-left: 4px

  .header
    margin: 18px 0 52px 0

    h1
      margin: 0

    .app-button,
    .app-button-text
      margin-left: 8px

  .content
    article
      background-color: $white
      border-radius: $global-border-radius
      padding: 28px 40px 44px 40px

      .card-content-patient
        > div + div
          margin-top: 24px

      .card-content-treatment
        max-width: 90%
        margin: 0 auto

      .card-content-practitioner
        display: flex
        align-items: center

        p
          margin: 0
          text-decoration: underline

      .card-header
        position: relative
        padding-bottom: 16px
        margin-bottom: 34px

        h3
          margin: 0

        &::after
          content: ""
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          background-color: $color-primary-120
          height: 1.5px

    article + article
      margin-top: 34px

    .status-tag
      @include treatment-status

    .card-treatments
      ul
        display: flex
        flex-direction: column
        padding: 0
        list-style: none

        li
          display: flex
          justify-content: space-between
          align-items: center
          column-gap: 2rem

          .treatment-date
            color: $color-gray-70

          .treatment-link
            display: flex
            align-items: center
            text-decoration: none

            strong
              font-size: 14px
              color: $text-color
              margin-left: 8px
              font-weight: bold

      .no-treatment
        color: $color-gray-70
        display: flex
        justify-content: center

      .add-treatment
        @include link
        color: $main-color
        cursor: pointer
        display: flex
        align-items: center
        border: none
        background-color: transparent
        margin: 16px 0 0 0
        padding: 0

        span
          margin-left: 4px

        svg
          fill: $main-color
          width: 12px

  .add-treatment-modal-content
    margin-top: 3rem
    > div:not(:last-child)
      margin-bottom: 1rem

    > div
      h4
        color: $color-gray-70
        margin: 0
      p
        color: $color-gray-70
        margin: 0
</style>
