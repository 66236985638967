<template>
  <section class="message" :class="{'message-small': size === 'small'}">
    <!-- Version large -->
    <template v-if="size === 'large'">
      <!-- Titre de la conversation -->
      <h3 class="message-title">Discussion avec Ealigner ({{ publicMessages.length }})</h3>

      <ul v-if="publicMessages && publicMessages.length" class="list message-list" :class="{ 'max-height': maxHeight }">
        <li v-for="(message, index) in publicMessages" :key="`message-${index}`">
          <!-- Avatar admin -->
          <UserAvatar
            v-if="message.authorAdmin"
            :role="userRole.ADMIN"
            :firstname="message.authorAdmin.firstname"
            :lastname="message.authorAdmin.lastname"
            :user-id="message.authorAdmin.adminId"
          />

          <!-- Avatar praticien -->
          <UserAvatar
            v-else
            :role="userRole.PRACTITIONER"
            :firstname="message.authorPractitioner.firstname"
            :lastname="message.authorPractitioner.lastname"
            :user-id="message.authorPractitioner.practitionerId"
          />

          <!-- Message admin ou praticien -->
          <div class="message-content">
            <div>
              <p v-if="message.authorAdmin" class="message-content-author">{{ message.authorAdmin.firstname }} {{ message.authorAdmin.lastname }}</p>
              <p v-else class="message-content-author">{{ message.authorPractitioner.firstname }} {{ message.authorPractitioner.lastname }}</p>
              <time class="message-date">{{ message.createdAt | formatDate('le', true) }}</time>
            </div>
            <p class="message-content-text">{{ message.message }}</p>
          </div>
        </li>
      </ul>

      <p v-else class="no-message">Aucun message pour le moment</p>

      <!-- Zone de saisie du nouveau message -->
      <app-input
        placeholder="Votre message"
        context="message"
        type="textarea"
        v-model="newMessage"
        v-on:send="saveMessage"
      />
    </template>

    <!-- Version réduite avec liste des conversations -->
    <template v-else>
      <!-- Liste des discussions -->
      <div class="conversation-list-container" ref="conversation-list-container" :style="{ 'margin-left': showingDiscussions ? '0' : '-100%' }">
        <ul class="list conversation-list">
          <li v-on:click="showRightSection(messageTypeEnum.PUBLIC)">
            <!-- Avatar praticien -->
            <UserAvatar
              :role="userRole.PRACTITIONER"
              :firstname="practitioner.firstname"
              :lastname="practitioner.lastname"
              :user-id="practitioner.practitionerId"
            />

            <!-- Titre de la messagerie -->
            <div class="message-content">
              <h3>{{ practitioner.firstname }} {{ practitioner.lastname }} ({{ publicMessages ? publicMessages.length : 0 }})</h3>
              <p class="conversation-context">Messagerie praticien</p>
            </div>

            <!-- Flèche -->
            <div class="message-arrow">
              <SvgArrow/>
            </div>
          </li>

          <li v-on:click="showRightSection(messageTypeEnum.ADMIN)">
            <!-- Avatar admin -->
            <div class="badge ealigner">
              <SvgEaligner/>
            </div>

            <!-- Titre de la messagerie -->
            <div class="message-content">
              <h3>Admin E-Aligner ({{ adminMessages ? adminMessages.length : 0 }})</h3>
              <p class="conversation-context">Messagerie interne</p>
            </div>

            <!-- Flèche -->
            <div class="message-arrow">
              <SvgArrow/>
            </div>
          </li>

          <li v-on:click="showRightSection()">
            <!-- Icône note -->
            <div class="badge note">
              <SvgNotes/>
            </div>

            <!-- Titre de la messagerie -->
            <div class="message-content">
              <h3>Note interne</h3>
            </div>

            <!-- Flèche -->
            <div class="message-arrow">
              <SvgArrow/>
            </div>
          </li>
        </ul>
      </div>

      <!-- Liste des messages -->
      <div class="message-list-container" ref="message-list-container" :style="{ 'margin-right': showingDiscussions ? '-100%' : '0' }">
        <div class="message-list-header" v-on:click="showDiscussions">
          <div class="message-list-header-arrow">
            <SvgArrow/>
          </div>
          <div v-if="messagesType" class="message-list-header-content">
            <h3>{{ messagesType === messageTypeEnum.PUBLIC ? `${practitioner.firstname} ${practitioner.lastname}` : 'Admin E-Aligner' }} ({{ messageCount }})</h3>
            <p>{{ messagesType === messageTypeEnum.PUBLIC ? 'Messagerie praticien' : 'Messagerie interne' }}</p>
          </div>
          <div v-else class="message-list-header-content">
            <h3>Note interne</h3>
          </div>
        </div>

        <div v-if="messagesType" class="message-list-content">
          <p v-if="messagesType === messageTypeEnum.PUBLIC && publicMessages.length === 0">Aucun message pour le moment</p>
          <p v-if="messagesType === messageTypeEnum.ADMIN && adminMessages.length === 0">Aucun message pour le moment</p>

          <!-- Liste des messages -->
          <ul class="list message-list" id="small-message-list">
            <li
              v-for="(message, index) in messagesType === messageTypeEnum.PUBLIC ? publicMessages : adminMessages"
              :key="`message-${index}`"
            >

              <!-- Avatar admin -->
              <UserAvatar
                v-if="message.authorAdmin"
                :role="userRole.ADMIN"
                :firstname="message.authorAdmin.firstname"
                :lastname="message.authorAdmin.lastname"
                :user-id="message.authorAdmin.adminId"
              />

              <!-- Avatar praticien -->
              <UserAvatar
                v-else
                :role="userRole.PRACTITIONER"
                :firstname="message.authorPractitioner.firstname"
                :lastname="message.authorPractitioner.lastname"
                :user-id="message.authorPractitioner.practitionerId"
              />

              <!-- Message admin ou praticien -->
              <div class="message-content">
                <div>
                  <p v-if="message.authorAdmin" class="message-content-author">{{ message.authorAdmin.firstname }} {{ message.authorAdmin.lastname }}</p>
                  <p v-else class="message-content-author">{{ message.authorPractitioner.firstname }} {{ message.authorPractitioner.lastname }}</p>
                  <time class="message-date">{{ message.createdAt | formatDate('le', true) }}</time>
                </div>
                <p class="message-content-text">{{ message.message }}</p>
              </div>
            </li>
          </ul>

          <!-- Zone de saisie du nouveau message -->
          <app-input
            placeholder="Votre message"
            context="message"
            type="textarea"
            v-model="newMessage"
            v-on:send="saveMessage"
          />
        </div>

        <!-- Zone de saisie d'une note -->
        <form
          v-else
          class="note-container"
          @submit.prevent="saveNote"
        >
          <app-input
            placeholder="Notez quelque chose ici..."
            v-model="newNote"
            type="textarea"
          />
          <app-button type="submit" class="save-button">Enregistrer</app-button>
        </form>
      </div>
    </template>
  </section>
</template>

<script>
import UserRole from '@/services/enums/user-role.enum';
import SvgArrow from '@/assets/img/icons/arrowhead-left.svg';
import SvgEaligner from '@/assets/img/icons/ealigner.svg';
import SvgNotes from '@/assets/img/icons/notes.svg';
import UserAvatar from './UserAvatar.vue';
import messageTypeEnum from '../services/enums/message-type.enum';

export default {
  name: 'message',

  props: {
    practitioner: {
      type: Object,
    },

    publicMessages: {
      type: Array,
    },

    adminMessages: {
      type: Array,
    },

    note: {
      type: String,
    },

    size: {
      default: 'large',
      validator: (val) => ['small', 'large'].indexOf(val) !== -1,
    },

    maxHeight: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    UserAvatar,
    SvgArrow,
    SvgEaligner,
    SvgNotes,
  },

  data() {
    return {
      userRole: UserRole,
      messagesType: null,
      newMessage: null,
      newNote: this.note,
      showingDiscussions: true,
      messageTypeEnum,
    };
  },

  computed: {
    // Récupère le nombre de messages pour le type sélectionné
    messageCount() {
      if (this.messagesType === messageTypeEnum.PUBLIC) {
        return this.publicMessages.length;
      }

      return this.adminMessages.length;
    },
  },

  methods: {
    // Affiche la liste des messages
    showRightSection(messageType) {
      this.messagesType = messageType;

      this.showingDiscussions = false;

      // Scroll to bottom of the list
      setTimeout(() => {
        const messageListEl = this.$el.querySelector('ul.list.message-list');
        messageListEl.scrollTop = messageListEl.scrollHeight;
      }, 100);
    },

    // Affiche la liste des discussions
    showDiscussions() {
      this.showingDiscussions = true;

      setTimeout(() => {
        this.messagesType = null;
        this.newMessage = null;
      }, 400);
    },

    // Reçois l'event d'un envoie de message
    saveMessage() {
      // Verification message vide
      if (this.newMessage.match(/^\s*$/g)) {
        this.newMessage = null;
        return;
      }
      this.$emit('saveMessage', {
        message: this.newMessage,
        visibility: this.messagesType,
      });

      this.newMessage = null;
    },

    // Reçois l'event d'une sauvegarde de note
    saveNote() {
      this.$emit('saveNote', this.newNote);
    },
  },
};
</script>

<style lang="sass">
.message

  .no-message
    margin: 30px 0
    text-align: center
    color: $color-gray-60

  h3
    margin: 0

  .app-input
    max-width: unset
    height: fit-content

  &:not(.message-small)
    background: $white
    padding: 24px
    border-radius: $global-border-radius

  &.message-small
    display: flex
    height: min-content
    min-height: 500px
    padding: 0
    position: relative
    overflow-x: hidden

  .message-title
    padding: 0 0 22px 0
    border-bottom: 1px solid $color-gray-20

  .list
    margin: 0
    padding: 0
    list-style: none

  .message-list
    overflow-y: auto

    &.max-height
      max-height: 500px

    li
      display: flex
      padding: 30px 0
      border-top: 1px solid $color-gray-20

      &:first-child
        border-top: none

      p, time
        margin: 0

  .conversation-list-container, .message-list-container
    width: 100%
    transition: .4s ease-in-out
    background-color: $white
    border-radius: $global-border-radius

  .conversation-list-container
    padding: 0 24px
    max-height: 600px

  .message-list-container

    .message-list
      max-height: 400px

      li:first-child
        border-top: none

  .message-list-header
    display: flex
    background-color: $color-primary-20
    padding: 24px
    cursor: pointer

    .message-list-header-arrow
      display: flex
      align-items: flex-end
      padding-bottom: 2px
      transform: rotate(180deg)

      svg
        fill: $color-primary-100

    .message-list-header-content
      margin-left: 16px

  .message-list-content
    padding: 0 24px 24px 24px

  .conversation-list

    li
      display: flex
      padding: 30px 0
      cursor: pointer

      &:not(:first-child)
        border-top: 1px solid $color-gray-20

  .conversation-context, .message-list-header-content p
    margin: 3px 0 0 0
    color: $color-gray-70

  .message-content
    flex: 1
    align-self: center
    margin-left: 16px

    > div
      display: flex
      align-items: center
      justify-content: space-between

    .message-content-author
      @include text-bold

    .message-content-text
      margin-top: 5px
      white-space: pre-wrap

  .message-date
    margin-left: 20px
    color: $color-gray-60

    @include h6

  .message-arrow
    display: flex
    align-items: center

    svg
      fill: $color-primary-100

  .note-container
    display: flex
    flex-direction: column
    padding: 24px

    textarea
      min-height: 120px

    button
      margin: 15px 0 0 auto

  .badge
    width: 40px
    height: 40px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%

    &.ealigner
      background-color: rgba($main-color, .2)

      svg
        width: 28px

    &.note
      background-color: rgba($color-gray-80, .2)

      svg
        margin-left: 4px
        width: 24px

    svg
      max-width: none

</style>
