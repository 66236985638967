export default Object.freeze({
  ORDER_INVOICE: 'ORDER_INVOICE',
  DEPOSIT_INVOICE: 'DEPOSIT_INVOICE',
  ESTIMATE: 'ESTIMATE',
  TREATMENT_PLAN: 'TREATMENT_PLAN',
  DMSM: 'DMSM',
  CONFORMITY: 'CONFORMITY',
  LABELS: 'LABELS',
  TECHNICAL_DOCUMENT: 'TECHNICAL_DOCUMENT',
  IMPRINT_3D: 'IMPRINT_3D',
});
