export default Object.freeze({
  ESTIMATE_REQUEST: 'Demande de pré-étude',
  ESTIMATE_ONGOING: 'Pré-étude en cours',
  ESTIMATE_AVAILABLE: 'Pré-étude en attente',
  ESTIMATE_VALIDATED: 'Pré-étude validée',
  ESTIMATE_REFUSED: 'Pré-étude refusée',
  TREATMENT_REQUEST: 'Demande de traitement',
  TREATMENT_ONGOING: 'Plan de traitement en cours',
  TREATMENT_AVAILABLE: 'Plan de traitement en attente',
  TREATMENT_VALIDATED: 'Plan de traitement validé',
  TREATMENT_REFUSED: 'Plan de traitement refusé',
  CONTENTION_REQUEST: 'Demande de contentions',
  ORDER_REQUEST: 'Nouvelle commande',
  PRODUCTION_ONGOING: 'Production en cours',
  ORDER_SHIPPED: 'Commande envoyée',
});
