<!--
****************************************************************************************************
***                                         Select                                               ***
****************************************************************************************************

Composant TagSelect, il gère :
 - le déplacement aux fleches haute et basse avec validation du résultat avec entrée
 - le clic en dehors qui ferme le filtre (directive clickOutside)

*************
*** PROPS ***
*************

  - options :
    Les valeurs d'options sont un tableau d'objet contenent un label et un clé
    sous le format { name: 'aaa', label: 'bbb' }

  Exemple :
  <app-select
    :options="[{ name: 'lorem', label: 'Lorem Ipsum'}, { name: 'lorem', label: 'Lorem Ipsum'}]"
    v-model="selectValue"/>

-->
<template>
  <div class="app-tag-select" :class="{ open: isOpen }">
    <div class="box" @click="openBox">
      <span class="result">
        <slot name="result">
          {{ options.find(o => o.name === value)['label'] }}
        </slot>
        <span v-if="isLoading" class="spinner-container"><app-spinner small /></span>
        <span v-else class="svg-container">
          <SvgArrowheadDown />
        </span>
      </span>
      <input type="text" class="filter" :class="{ visible : filter.length > 0}"
        ref="filter"
        @keydown.down="keyDown()"
        @keydown.up="keyUp()"
        @keydown.enter="onChange(preselectedOption)"
        v-model="filter">
    </div>

    <transition name="filter">
      <ul v-if="isOpen" class="options"
        v-click-outside="closeFilter"
        ref="optionsBox"
        >
          <li v-for="option in filteredOptions"
            :key="option.name"
            :ref="`option${option.name}`"
            :class="{ selected: option.name === value || (value && value.includes(option.name)), preselected: option.name === preselectedOption }"
            @click="onChange(option.name)"
            @mouseenter="preselectedOption = option.name">
            <slot :name="option.name">
              {{ option.label }}
            </slot>
          </li>
          <li v-if="filteredOptions.length === 0" @click="isOpen = false">Aucun résultat</li>
      </ul>
    </transition>

    <select :value="value" v-bind="$attrs">
      <option v-for="option in options" :key="option.name" :value="option.name" >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import SvgArrowheadDown from '@/assets/img/icons/arrowhead-down.svg';
import clickOutside from '../utils/clickOutside';

export default {
  components: {
    SvgArrowheadDown,
  },
  props: {
    value: [String, Array],
    options: Array,
    isLoading: [Boolean],
  },
  data() {
    return {
      isOpen: false,

      preselectedOption: null,
      filter: '',

    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((o) => o.label.includes(this.filter));
    },
  },
  directives: {
    clickOutside,
  },
  watch: {
    isOpen: 'scrollAuto',
    filter: 'openOptions',
  },
  methods: {
    openBox() {
      if (!this.isLoading) {
        this.isOpen = !this.isOpen;
        this.$refs.filter.focus();
      }
    },
    closeFilter() {
      this.$refs.filter.focus();
      this.isOpen = false;
    },
    keyDown() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.min(this.filteredOptions.length - 1, index + 1)].name;
      }
      this.isOpen = true;
      this.scrollAuto();
    },
    keyUp() {
      if (this.isOpen) {
        const index = this.filteredOptions.indexOf(this.filteredOptions.find((o) => o.name === this.preselectedOption));
        this.preselectedOption = this.filteredOptions[Math.max(0, index - 1)].name;
      }
      this.isOpen = true;
      this.scrollAuto();
    },
    updateValue(val) {
      this.$emit('input', val);
    },
    onChange(val) {
      this.updateValue(val);
      this.preselectedOption = val;
      this.isOpen = false;
      this.$refs.filter.focus();
    },
    scrollAuto() {
      if (this.isOpen && this.preselectedOption) {
        // On attend la prochaine maj du dom pour faire scroller le dom de la box d'options (lié au v-if sur options)
        this.$nextTick(() => {
          this.$refs.optionsBox.scrollTo(0, this.$refs[`option${this.preselectedOption}`][0].offsetTop);
        });
      } else {
        this.filter = '';
      }
    },
    openOptions() {
      if (this.filter.length > 0 && !this.isLoading) this.isOpen = true;
    },
  },
};
</script>

<style lang="sass">
.app-tag-select
  position: relative
  text-align: left
  transition: all 0.2s ease-in-out
  cursor: pointer
  width: fit-content

  select
    display: none

  .box
    position: relative
    display: flex
    align-items: center
    span
      margin: 0 4px
    .result
      @include button-text
      padding: 2px 8px 4px
      border-radius: 24px
      color: $main-color
      background: transparentize($main-color, 0.85)
    .svg-container
      transform: translateY(1px)
      svg
        width: 16px
        vertical-align: text-bottom
        path
          fill: currentcolor
    .spinner-container
      display: inline-block
      transform: translateY(3px)
      .app-spinner
        width: 14px
        height: 14px
        margin: 0
        span
          border-color: currentcolor
    .filter
      width: 0
      outline: 0 !important
      border: 0 !important
      opacity: 0
      margin-left: -4px
      &.visible
        margin-left: 0.2rem
        width: 200px
        opacity: 1

    .empty
      display: inline-flex
      color: $color-gray-60

  .options
    position: absolute
    top: calc(100% + 8px)
    left: -1px
    right: -1px
    margin: 0
    padding: 0
    max-height: 200px
    list-style-type: none
    background: white
    border: 1px solid $color-gray-20
    border-radius: $global-border-radius
    overflow: hidden
    overflow-y: scroll
    z-index: 200
    li
      padding: 8px 16px
      transition: all 0.2s ease-in-out
      @include h6
      &.preselected
        background: $color-primary-10
      &.selected
        background: $color-primary-20

.select-enter-active, .select-leave-active
  transition: all 0.25s ease-in-out

.select-enter, .select-leave-to
  opacity: 0
  transform: translateY(-10px)

</style>
