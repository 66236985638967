<template>
  <section class="forgot-password">
    <div class="grid-container">
      <img class="logo" src="~@/assets/img/illustrations/logo-dark.png" alt="Logo E-Aligner"/>

      <div class="form-container">
        <div class="form-content">
          <app-title>Mot de passe oublié</app-title>
          <p class="sub-title">Nous vous enverrons un email avec un lien<br>pour réinitialiser votre mot de passe.</p>

          <form ref="register" @submit.prevent="requestNewPassword(email)">
            <div>
              <app-label class="form-label" required>Mail</app-label>
              <app-input
                autocomplete="email"
                type="email"
                placeholder="email"
                required
                v-model="email"
                :errorMessage="errorMessage"
              />
            </div>
            <div class="btn-container">
              <app-button type="submit" :loading="isLoading">Envoyer</app-button>
            </div>
          </form>
          <p v-if="confirmMessage" class="confirmMessage">Vous recevrez bientôt un mail avec un lien vous permettant de modifier votre mot de passe.</p>
        </div>

        <h6 class="login-link">Vous connaissez votre mot de passe ? <router-link :to="{ name: `${isAdmin ? 'admin-' : ''}login` }">Se connecter</router-link></h6>
      </div>
    </div>

    <div class="image-container">
      <img src="~@/assets/img/illustrations/smile.png" alt="Illustration"/>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import UserRole from '@/services/enums/user-role.enum';

export default {
  name: 'forgot-password',

  data() {
    return {
      email: null,
      isLoading: false,
      isAdmin: this.$route.name.includes('admin'),
      confirmMessage: false,
      errorMessage: null,
    };
  },

  methods: {
    // Demande de changement de mot de passe
    async requestNewPassword() {
      this.isLoading = true;
      this.confirmMessage = false;

      try {
        await authApi.resetPassword(
          this.isAdmin ? UserRole.ADMIN : UserRole.PRACTITIONER,
          this.email,
        );

        this.confirmMessage = true;
      } catch (error) {
        this.errorMessage = 'Une erreur est survenu';
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass">
.forgot-password
  display: flex
  overflow: hidden
  height: 100vh

  .grid-container
    text-align: left
    flex: 1
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    padding: 40px 30px 50px 30px

    .logo
      width: 15vw
      left: 24px

    .form-container
      width: 30vw
      max-width: 500px
      min-width: 200px
      margin: 80px auto 0 auto
      flex: 1
      display: flex
      flex-direction: column
      justify-content: space-between

      .app-title
        @include h1
        color: $color-gray-100
        margin: 0

      .sub-title
        color: $color-gray-70

      form
        margin-top: 15px

        > div:not(:first-child):not(.btn-container)
          margin-top: 24px

        .form-label
          margin-top: 30px

        .btn-container
          margin-top: 44px

          .app-button:not(:first-child)
            margin-left: 16px

      .login-link
        margin: 0
        color: $color-gray-60

      .confirmMessage
        margin-top: 20px
        color: $color-primary-100

  .image-container
    width: 50%
    height: 100%

    img
      width: 100%
      height: 100%
      object-fit: cover
</style>
