<!--
****************************************************************************************************
***                                         Title                                                ***
****************************************************************************************************

Titre

  Exemple :
  <app-title>Titre<app-title/>

-->
<template>
  <h1 class="app-title"><slot></slot></h1>
</template>

<script>
export default {
};
</script>

<style lang="sass">
.app-title
  display: inline-block
  position: relative
  margin: 0.2rem auto 40px
  padding: 0.5rem 0
  @include h1
  line-height: 32px
  text-align: left
  color: $color-gray-90
</style>
