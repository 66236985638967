<!--
****************************************************************************************************
***                                      Modale                                                 ***
****************************************************************************************************

Composant modale simple

*************
*** PROPS ***
*************
  - show :
    paramètre indiquant la visibilité de la modale

  Exemple :
  <app-modal :show.sync="isModalVisible">
    <app-subtitle>Modale</app-subtitle>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, molestiae aut. Eveniet ab aperiam laboriosam debitis, repellendus natus ex nostrum repudiandae nulla sapiente optio, blanditiis animi necessitatibus? Libero, ex explicabo?</p>
    <app-button @click="isModalVisible = false">Fermer</app-button>
  </app-modal>

-->
<template>
  <div>
    <transition name="modal">
      <div class="modal-wrapper" v-if="visible" :class="[{ 'action-modal': isAction }, size]">
        <div class="modal-header">
          <button class="remove-btn" title="fermer" @click="$emit('update:show')">
            <SvgCross />
          </button>
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-content">
          <slot name="modal-content"/>
        </div>
        <div v-if="$slots['modal-footer']" class="modal-footer">
          <slot name="modal-footer"/>
        </div>
      </div>
    </transition>
    <transition name="screen">
      <div class="modal-screen" v-if="visible" @click="$emit('update:show')"/>
    </transition>
  </div>
</template>

<script>
import SvgCross from '@/assets/img/icons/cross.svg?inline';

export default {
  components: {
    SvgCross,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    isAction: { // Appliquer le style d'une modale action
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val) => ['large', 'small'].indexOf(val) !== -1,
      default: 'large',
    },
  },
  computed: {
    visible() {
      return this.show;
    },
  },
  events: ['update:show'],
  watch: {
    show: 'updateScroll',
  },
  beforeDestroy() {
    document.documentElement.style.overflow = 'auto';
  },
  methods: {
    updateScroll() {
      document.documentElement.style.overflow = this.show ? 'hidden' : 'auto';
    },
  },
};
</script>

<style lang="sass">
.modal-wrapper
  position: fixed
  top: 50%
  left: 50%
  width: 95%
  height: 95%
  max-height: 500px
  background: white
  border-radius: $global-border-radius
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1)
  z-index: 1000
  transform: translate(-50%, -50%)
  padding: 24px 40px
  display: flex
  flex-direction: column
  overflow-y: auto

  &.large
    max-width: 700px

  &.small
    max-width: 500px

  .modal-header
    text-align: left

    .remove-btn
      position: absolute
      right: 32px
      top: 40px
      background: transparent
      border: none
      cursor: pointer

      svg
        width: 20px
        path
          fill: $color-gray-60
  .modal-content
    flex-grow: 2

  .modal-footer
    border-top: 1px solid $color-gray-20
    display: flex
    justify-content: flex-end
    padding-top: 24px
    margin-top: 24px

    .app-button
      margin: 0 8px

  &.action-modal

    .modal-header
      text-align: center
      margin-bottom: 0
      h3
        @include h2
      .remove-btn
        position: relative
        right: -50%
        top: 0
    .modal-content
      text-align: center
      color: $color-gray-70
      .app-input
        margin-top: 30px
        max-width: unset
        textarea
          min-height: 110px
    .modal-footer
      border-top: none
      padding-top: 0
      justify-content: center

.modal-screen
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0, 0, 0, 0.4)
  z-index: 800

.screen-enter-active
  transition: opacity .9s

.screen-leave-active
  transition: opacity .3s

.screen-enter, .screen-leave-to
  opacity: 0

.modal-enter-active, .modal-leave-active
  transition: transform .2s, opacity .2s, scale 0.15s ease-in-out

.modal-enter, .modal-leave-to
  opacity: 0
  transform: translateY(-130px) translateX(-50%) scale(0.9)

</style>
